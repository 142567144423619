export const defaultsModifierGridColumns = {
  default: ['selected', 'name', 'sets', 'price', 'taxTypeId', 'operatorId', 'isActive'],
  all: ['selected', 'name', 'sets', 'price', 'taxTypeId', 'operatorId', 'isActive'],
};

export const defaultContactsGridColumns = {
  default: ['selected', 'firstName', 'lastName', 'title', 'email', 'phone'],
  all: ['selected', 'firstName', 'lastName', 'title', 'email', 'phone', 'account', 'city', 'state', 'salesRep', 'location'],
};

export const defaultContactsGridColumnsCoverLite = {
  default: ['selected', 'firstName', 'lastName', 'title', 'email', 'phone'],
  all: ['selected', 'firstName', 'lastName', 'title', 'email', 'phone', 'city', 'state', 'location'],
};

export const defaultLeadsGridColumns = {
  default: ['selected', 'firstName', 'lastName', 'company', 'title', 'email', 'phone'],
  all: ['selected', 'firstName', 'lastName', 'company', 'title', 'email', 'phone', 'salesRep'],
};

export const defaultAccountsGridColumns = {
  default: ['selected', 'name', 'type', 'reference', 'email', 'phone', 'salesRep'],
  all: ['selected', 'name', 'type', 'reference', 'email', 'phone', 'salesRep', 'streetAddress1', 'streetAddress2', 'city', 'state', 'zipCode', 'website', 'primaryContact.name', 'primaryContact.phone', 'primaryContact.email', 'location'],
};

export const defaultBookingsGridColumns = {
  default: ['selected', 'name', 'status', 'phone', 'account', 'contact', 'salesRep'],
  all: ['selected', 'name', 'status', 'phone', 'account', 'contact', 'salesRep', 'dateBooked', 'startDateTime', 'endDateTime', 'eventManager', 'events', 'balance', 'cost', 'discount', 'paid', 'profit', 'serviceCharged', 'subTotal', 'taxCharged', 'total', 'location'],
};

export const defaultMenuCategoryItemsGridColumns = {
  default: ['name', 'price', 'cost', 'description', 'calories', 'allergens'],
  all: ['name', 'price', 'cost', 'description', 'calories', 'allergens'],
};

export const defaultOrdersGridColumns = {
  default: ['selected', 'id', 'orderStatus', 'total', 'customerName', 'placedDateTime', 'customerPhone', 'source' ],
  all: ['selected', 'id', 'type', 'orderStatus', 'total', 'customerName', 'customerPhone', 'source', 'address','requestedDateTime', 'placedDateTime', 'createdByName', 'location' ],
};

export const defaultDriversGridColumns = {
  default: ['driver', 'numOrders', 'timeIn', 'timeOut', 'hourlyRate', 'totalCost', 'phone'],
  all: ['driver', 'numOrders', 'timeIn', 'timeOut', 'hourlyRate', 'totalCost', 'phone'],
};

export const defaultPackagesGridColumns = {
  default: ['selected', 'item', 'additionalPrice', 'calories', 'allergens', 'description'],
  all: ['selected', 'item', 'additionalPrice', 'calories', 'allergens', 'description'],
};

export const defaultMenuItemGridColumns = {
  default: ['selected', 'name', 'price', 'allergens', 'description', 'revenueTypeId'],
  all: ['selected', 'name', 'price', 'allergens', 'description', 'revenueTypeId', 'calories', 'prepArea'],
};

export const defaultBookingMenuGridColumns = {
  default: ['name', 'quantity', 'unitPrice', 'total', 'allergens', 'description', 'revenueTypeId'],
  all: ['name', 'quantity', 'unitPrice', 'total', 'allergens', 'description', 'revenueTypeId', 'calories', 'prepArea', 'hasTax', 'hasGratuity', 'hasServiceCharge', 'linked'],
};
