import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import BookingHeader from './BookingHeader';
import { Drawer, IconButton, Paper } from '@material-ui/core';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import BookingEventSelector from './BookingEventSelector';
import EventTabs from './EventTabs';
import BookingSideTabs from './BookingSideTabs';
import BookingStatusBar from './BookingStatusBar';
import SimpleDialog from 'Components/SimpleDialog';
import SectionContext from 'Components/Contexts/SectionContext';
import ChitChat from 'Components/ChitChat';
import Email from 'Components/Email';
import BookingContext from './BookingContext';
import SaveBar from 'Components/SaveBar';
import SelectEvent from './SelectEvent';
import Clone from './Clone';
import {
  toggleEditMode,
  getBooking,
  deleteBooking,
  closeBooking,
  getTaxConfig,
  saveStoreBooking,
  storeBookingSetIsEditing,
  revertStoreBookingChanges,
  storeBookingSetStoreType,
  clearStoreBooking,
  addStoreBookingEvent,
  saveBookingStatus,
} from 'actions/booking';
import { addToRecents } from 'actions/recents';
import {
  fetchChitChat,
  logChitChatSystemMessage,
} from 'actions/chitchat';
import { ENTITY_TYPES } from 'constants/entityTypes';
import { getStatusHistory } from 'actions/statusHistory';
import Toast from 'Components/Toast';

const drawerWidth = 250;
const notesContainerWidth = 340;
const fullHeight = 134;
const smallScreenHeight = 52;
const detailsContainerWidth = `calc(100% - ${notesContainerWidth}px - ${drawerWidth}px - 32px)`;

const styles = theme => ({
  upperBody: {
    display: 'flex',
    flexDirection: 'column',
    height: fullHeight, //we don't "need" the height here, but putting it here to document how the height of the lowerBody is calculated"
    [theme.breakpoints.down('md')]: {
      height: smallScreenHeight,
    },
  },
  lowerBody: {
    display: 'flex',
    height: `calc(100% - ${fullHeight}px - 120px)`, // subtract BookingHeader and upperBody height
    backgroundColor: theme.palette.background.default,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.down('md')]: {
      height: `calc(100% - ${smallScreenHeight}px - 120px)`, // subtract BookingHeader and upperBody height
    },
  },
  statusBar: {
    display: 'flex',
    flex: '0 0 auto',
    marginTop: 16,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  subHeaderBar: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    padding: '8px 16px',
    height: 52, // keeps height consistent between views
  },
  eventsPanelHeader: {
    display: 'flex',
    flexGrow: 0,
    alignItems: 'center',
    transition: theme.transitions.create('min-width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    minWidth: drawerWidth,
  },
  eventsPanelHeaderShift: {
    transition: theme.transitions.create('min-width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    minWidth: 36,
  },
  eventDetailsHeader: {
    display: 'flex',
    flexGrow: 0,
    maxWidth: '75%',
    flexBasis: '75%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    fontSize: 18,
  },
  blankHeader: {
    flexGrow: 0,
    maxWidth: '25%',
    flexBasis: '25%',
  },
  eventsPanelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 0,
    width: drawerWidth,
  },
  iconButton: {
    padding: 6,
    marginRight: 6,
    color: theme.palette.common.black,
  },
  eventDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginLeft: 12,
    width: detailsContainerWidth,
  },
  notesContainer: {
    display: 'flex',
    minWidth: notesContainerWidth,
    maxWidth: notesContainerWidth,
    paddingLeft: 8,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    backgroundColor: 'transparent',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerContent: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  drawerContentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: -drawerWidth,
  },
  docked: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerWidth,
  },
  dockedShift: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 0,
  },
  hidden: {
    display: 'none',
  },
  flexGrow: {
    display: 'flex',
    flexGrow: 1,
  },
  drawerOpenHeader: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
      filesLastUpdated: new Date(),
      isDrawerOpen: true,
      financialDetails: null,
      stepMessage: null,
      taxConfig: null,
      statusHistory: [],
    };
    this.cloneModal = React.createRef();
    this.notFoundDialog = React.createRef();
    this.dialog = React.createRef();
  }

  componentDidMount() {
    this.props.storeBookingSetStoreType('manager');
    this.getBooking();
    // this.props.getTaxConfig(this.getBookingId()).then(taxConfig => this.setState({ taxConfig }));
  }

  componentDidUpdate(prevProps) {
    const { storeBooking } = this.props;

    //if !storeBooking then assume the current booking is loading
    if (storeBooking && prevProps.location !== this.props.location) {
      if (storeBooking.id !== this.getBookingId()) {
        this.getBooking();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearStoreBooking();
  }

  getBookingId = () => {
    return parseInt(this.props.match.params.id, 10);
  }

  getBooking = () => {
    const { match } = this.props;
    const bookingId = parseInt(match.params.id, 10);

    this.props.getStatusHistory('Booking', bookingId).then(statusHistory => {
      this.setState({ statusHistory });
    });

    return this.props.getBooking(bookingId).then(booking => {
      this.props.addToRecents(booking, match.url, ENTITY_TYPES.booking);
    }).catch(error => {
      console.error('Error in Booking lookup: ', error);
      this.showNotFoundMessage();
    });
  }

  showNotFoundMessage = () => {
    const { notFoundDialog } = this;

    notFoundDialog && notFoundDialog.current && notFoundDialog.current.open().then(this.goToBookingsGrid);
  }

  reloadBooking = () => {
    this.getBooking()
      .catch(error => {
        console.error('Error in Booking reload: ', error);
      });
  }

  handleDrawer = () => {
    this.setState(prevState => ({ isDrawerOpen: !prevState.isDrawerOpen }));
  }

  selectEvent = (selectedEvent, eventIndex) => {
    this.props.setFocusedEventIndex(eventIndex);
    // this.props.history.push(`/bookings/${this.props.storeBooking.id}/${selectedEvent.id}`);//#4 clicking event TODO
  };

  deleteBooking = () => {
    this.dialog.current.open('Are you sure you want to delete this booking?')
      .then(this.confirmDeleteBooking);
  }

  confirmDeleteBooking = () => {
    this.props.deleteBooking(this.props.storeBooking.id)
      .then(this.goToBookingsGrid); //removing the booking will unmount the event and prevent any navigation intercepts
  }

  goToBookingsGrid = () => {
    this.props.history.push('/bookings');
  }

  toggleEditMode = () => {
    const { toggleEditMode, isEditing, storeBooking } = this.props;

    if (isEditing) {
      this.props.history.push(`/bookings/${storeBooking.id}`);
    }

    toggleEditMode();
  }

  cloneBooking = () => {
    this.cloneModal.current.open(this.props.storeBooking);
  }

  handleCloneSuccess = newBookingId => {
    this.dialog.current.open('Successfully cloned! Would you like to go to the newly created booking?')
      .then(() => this.props.history.push(`/bookings/${newBookingId}`))
      .catch(this.refreshChitChat);
  }

  refreshChitChat = () => {
    const { storeBooking } = this.props;
    const { viewState } = this.props;

    if (viewState === 'Chit Chat') {
      this.props.fetchChitChat(ENTITY_TYPES.booking, storeBooking.id);
    }
  }

  eventToRedirect = () => {
    const {
      match: { params },
      storeBooking,
    } = this.props;

    if (storeBooking.events.length) {
      const focusedEventId = params.eventId ? parseInt(params.eventId, 10) : 0;

      if (!storeBooking.events.find(e => e.id === focusedEventId)) {
        return storeBooking.events[0];
      }
    }

    return null;
  }

  filesUpdated = () => {
    this.setState({ filesLastUpdated: new Date() });
  }

  openEmailWithFile = file => {
    this.props.history.push(`/bookings/${this.props.storeBooking.id}/email?fileId=${file.id}`);
  }

  onEmailDone = () => {
    this.props.history.push(`/bookings/${this.props.storeBooking.id}`);
    this.setState({
      autoAttachFileId: null,
      successMessageOpen: true,
      successMessage: 'Email sent successfully',
    });
  }

  onEmailCancel = () => {
    this.props.history.push(`/bookings/${this.props.storeBooking.id}`);
  }

  handleSuccessMessageClose = () => {
    this.setState({
      successMessageOpen: false,
      successMessage: null,
    });
  }

  afterFileUpload = file => {
    this.chitChatSystemMessage(`File Added: ${file.name}`).then(this.refreshChitChat);
  }

  chitChatSystemMessage = message => {
    const { storeBooking } = this.props;
    const { logChitChatSystemMessage } = this.props;
    const relationship = {
      id: storeBooking.id,
      entityType: ENTITY_TYPES.booking,
    };
    const chitChatRecordName = `Booking: ${storeBooking.name}`;

    return logChitChatSystemMessage(message, relationship, chitChatRecordName);
  }

  closeBooking = () => {
    this.dialog.current.open('Are your sure you want to close this booking?').then(() => {
      this.props.closeBooking().then(() => {
        this.reloadBooking();
        this.refreshChitChat();
      });
    });
  }

  saveBookingApi = () => {
    return this.props.saveStoreBooking().then(() => {
      this.props.storeBookingSetIsEditing(false);
    });
  }

  cancelChanges = () => {
    this.props.revertStoreBookingChanges();
    this.props.storeBookingSetIsEditing(false);

    return Promise.resolve();
  }

  //return true allow the transition
  handleBlockedNavigation = nextLocation => {
    if (this.hasUnsavedChanges() === true) {
      this.dialog.current.open('Save your changes?')
        .then(() => this.saveBookingApi())
        .catch(() => {
          this.props.storeBookingSetIsEditing(false);
        })
        .then(() => {
          this.props.history.push(nextLocation.pathname);
        });
    } else {
      return true; //allow transition
    }

    return false; //block Navigation;
  }

  hasUnsavedChanges = () => {
    const {
      storeBooking,
      storeBookingRef,
      isEditing,
    } = this.props;

    return isEditing && !!storeBookingRef && Object.keys(storeBooking).some(key => storeBooking[key] !== storeBookingRef[key]); //TODO: may need better checking
  }

  quickAddEvent = () => {
    this.props.addStoreBookingEvent();
    this.props.storeBookingSetIsEditing(true);
  };

  updateBookingStatus = statusId => {
    return this.props.saveBookingStatus(statusId).then(statusHistory => {
      this.setState({ statusHistory });
      this.refreshChitChat();
    });
  };

  render() {
    const {
      classes,
      viewState,
      storeBooking,
      isEditing,
    } = this.props;
    const {
      isDrawerOpen,
      financialDetails,
      filesLastUpdated,
      statusHistory,
      successMessage,
      successMessageOpen,
    } = this.state;
    let chitChatRecordName = storeBooking ? storeBooking.name : '';

    if (storeBooking && storeBooking.account && storeBooking.account.name) chitChatRecordName += ` - ${storeBooking.account.name}`;

    if (!storeBooking) {
      return <SimpleDialog
        message="Booking Not Found. Return to Bookings Grid?"
        innerRef={this.notFoundDialog}
      />;
    }
    if (viewState === 'Event Details') {
      const redirectEvent = this.eventToRedirect();

      if (redirectEvent) {
        return <Redirect to={`/bookings/${storeBooking.id}/${redirectEvent.id}`} />;
      }
    }

    const sectionContext = {
      relationship: {
        entityId: storeBooking.id,
        entityType: ENTITY_TYPES.booking,
      },
      contact: storeBooking.contact,
      onEmailDone: this.onEmailDone,
      onEmailCancel: this.onEmailCancel,
      chitChatRecordName,
      defaultEmail: storeBooking.contact && storeBooking.contact.email ? storeBooking.contact.email : '',
    };

    const bookingContext = {
      bookingId: storeBooking.id, //EventTabs is mounting/remounting several times so can't trust the location.
      onBookingChanged: booking => this.setState({ booking }),
      onChitChatUpdated: this.refreshChitChat,
      filesLastUpdated,
      reloadBooking: this.reloadBooking,
      closeBooking: this.closeBooking,
      taxConfig: this.state.taxConfig,
      onFileAddSuccess: this.afterFileUpload,
      onEmailDone: this.onEmailDone,
      contact: storeBooking.contact,
      statusHistory,
      onUpdateStatus: this.updateBookingStatus,
    };

    return (
      <BookingContext.Provider value={bookingContext}>
        <SectionContext.Provider value={sectionContext}>
          <BookingHeader
            filesUpdated={this.filesUpdated}
            financial={financialDetails}
            label="Booking"
            onEdit={this.toggleEditMode}
            onClone={this.cloneBooking}
            onDelete={this.deleteBooking}
            onCloseBooking={this.closeBooking}
            openEmailWithFile={this.openEmailWithFile}
            viewState={viewState}
          />

          <div className={classes.upperBody}>
            <div className={classes.statusBar}>
              <BookingStatusBar />
            </div>

            <div className={classes.subHeaderBar}>
              {viewState === 'Event Details' &&
                <div className={classNames(classes.eventsPanelHeader, !isDrawerOpen && classes.eventsPanelHeaderShift)}>
                  <IconButton onClick={this.handleDrawer} classes={{ root: classes.iconButton }}>
                    <MenuIcon />
                  </IconButton>

                  {isDrawerOpen &&
                    <div className={classNames(classes.drawerOpenHeader)}>
                      <p className={classNames(classes.flexGrow)}>Events</p>
                      <IconButton className={classNames(classes.iconButton)} onClick={this.quickAddEvent}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  }
                </div>
              }

              <div className={classes.eventDetailsHeader}>
                <p>{viewState}</p>
              </div>
              <div className={classes.blankHeader}></div>

            </div>
          </div>

          <div className={classes.lowerBody}>
            <Switch>
              <Route path="/bookings/:id/chitchat" render={() => (
                <Paper className={classes.flexGrow}>
                  <ChitChat
                    relationshipId={storeBooking.id}
                    chitChatRelationship={ENTITY_TYPES.booking}
                    recordName={chitChatRecordName}
                  />
                </Paper>
              )} />
              <Route path="/bookings/:id/email" component={Email} />
              <Route path="*" render={() => (
                <>
                  <Drawer
                    variant="persistent"
                    anchor="left"
                    open={isDrawerOpen}
                    classes={{
                      paper: classes.drawerPaper,
                      docked: classNames(classes.docked, !isDrawerOpen && classes.dockedShift),
                    }}
                  >
                    <div className={classNames(classes.eventsPanelContainer, classes.drawerContent, !isDrawerOpen && classes.drawerContentShift)}>
                      <BookingEventSelector
                        events={storeBooking.events}
                        onSelectEvent={this.selectEvent}
                        onDelete={this.deleteEvent}
                        onEventClone={this.cloneBookingEvent}
                      />
                    </div>
                  </Drawer>
                  <div className={classes.eventDetailsContainer}>
                    <Switch>
                      <Route path="/bookings/:id/:eventId(\d+)" component={EventTabs} />
                      <Route component={SelectEvent} />
                    </Switch>
                    {isEditing && <SaveBar onSave={this.saveBookingApi} onCancel={this.cancelChanges} isSticky={true} />}
                  </div>
                </>)} />
            </Switch>
            <div className={classes.notesContainer}>
              <BookingSideTabs booking={storeBooking} />
            </div>
          </div>
          <Clone innerRef={this.cloneModal} onCloneSuccess={this.handleCloneSuccess} />
          <SimpleDialog innerRef={this.dialog} />
          <Toast message={successMessage} variant="success" open={successMessageOpen} onClose={this.handleSuccessMessageClose} />
          <Prompt message={this.handleBlockedNavigation} />
        </SectionContext.Provider>
      </BookingContext.Provider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    location: {
      pathname,
    },
  } = ownProps;
  const {
    booking: {
      manager: {
        storeBooking,
        storeBookingRef,
        focusedEventIndex,
        isEditing,
      },
    },
  } = state;

  let viewState = 'Event Details';

  if (/chitchat$/i.test(pathname))
    viewState = 'Chit Chat';
  else if (/email$/i.test(pathname))
    viewState = 'Email';

  return {
    viewState,
    storeBooking,
    storeBookingRef,
    focusedEventIndex,
    isEditing,
  };
};

const mapDispatchToProps = {
  toggleEditMode,
  getBooking,
  saveStoreBooking,
  deleteBooking,
  addToRecents,
  fetchChitChat,
  closeBooking,
  getTaxConfig,
  logChitChatSystemMessage,
  storeBookingSetIsEditing,
  revertStoreBookingChanges,
  storeBookingSetStoreType,
  clearStoreBooking,
  addStoreBookingEvent,
  saveBookingStatus,
  getStatusHistory,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Booking)));
