import React, { Component } from 'react';
import { getCalendarEventsByDates, saveCalendarEvent, deleteCalendarEvent, selectDate, getCalendarEventsByDatesByResource } from 'actions/calendar';
import { connect } from 'react-redux';
import AddEditEventModal from './AddEditEventModal';
import BookingWizard from 'bookings/BookingWizard/BookingWizard';
import { CALENDAR_MODE, CALENDAR_VIEW } from 'constants/calendarMode';

import { REQUEST_TYPES } from 'constants/requestTypes';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import './Scheduler.scss';
import { withRouter } from 'react-router-dom';
import ValidationBanner from 'Components/ValidationBanner';
import { getRooms } from 'actions/rooms';
import Prints from 'bookings/Prints';
import ReportPreviewModal from 'Components/ReportPreviewModal';
import { getReportDetails } from 'actions/reports';
import { Eventcalendar, Popup, formatDate } from '@mobiscroll/react';

class Scheduler extends Component {
  constructor(props) {
    super(props);
    this.selectedDate = new Date(); //use this to select a range

    this.state = {
      selectedAppointment: null,
      isLoading: true,
      wizardType: null,
      selectedProposalId: null,
      printsModalOpen: false,
      reportPreviewOpen: false,
    };

    this.mode = props.currentMode;
    this.view = props.currentView || CALENDAR_VIEW.Calendar;
    this.validationBanner = React.createRef();
    this.schedule = React.createRef();
    this.loadingRef = React.createRef();
  }

  componentDidMount() {
    this.props.getRooms();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDate !== prevProps.selectedDate) {
      this.schedule.current.navigate(this.props.selectedDate);
    }
  }

  quickSaveEvent = newAppointment => {
    newAppointment.endDateTime = moment(newAppointment.startDateTime).add(1, 'day').add(-1, 'minute');
    this.props.saveCalendarEvent(newAppointment).catch(error => {
      if (error && error.message) {
        this.validationBanner.current.open(error.message);
      } else {
        this.validationBanner.current.open('Error occured');
      }
    }).then(() => this.getCalendarEvents(REQUEST_TYPES.forceCacheUpdate));
  }

  deleteCalendarEvent = id => {
    this.props.deleteCalendarEvent(id);
  }

  buildSelectedAppointment = data => {
    if (!data) { // New Event
      const { selection } = this.state;

      if (selection) {
        return {
          startDateTime: selection.startTime,
          endDateTime: selection.endTime,
        };
      }

      return null;
    }

    if (data.id && data.startDateTime) { // Existing Event
      return data;
    }
    if (data.syncFusionStart && (!data.id || !data.startDateTime)) { // Quick Add -> More Details
      return {
        name: data.name,
        calendarEventType: 'Task',
        startDateTime: data.syncFusionStart,
        // for some reason, syncFusionEnd is same as syncFusionStart, so we must add 24 hours manually (Syncfusion bug?).
        endDateTime: moment(data.syncFusionStart).add(24, 'hours').toDate(),
      };
    }

    return null;
  }

  isCalendarEvent = () => {
    const { quickPopupEvent } = this.state;

    return quickPopupEvent.calendarEventType === 'Task' || quickPopupEvent.calendarEventType === 'Personal';
  }

  printCalendarEvent = eventData => {
    if (this.isCalendarEvent()) {
      this.onGeneratePrint('Calendar-Print-PersonalToDo.repx');
    } else {
      this.setState({ printsModalOpen: true });
    }
  }

  onGeneratePrint = print => {
    this.setState({ printsModalOpen: false });
    if (print) {
      const { quickPopupEvent } = this.state;
      const reportParams = this.isCalendarEvent()
        ? { eventId: quickPopupEvent ? quickPopupEvent.id : 0 }
        : { bookingId: quickPopupEvent ? quickPopupEvent.bookingId : 0 };

      this.props.getReportDetails(print).then(reportPreview => {
        this.setState({ reportPreviewOpen: true, reportPreview, reportParams });
      });
    }
  }

  onSavedAndEmailed = (isSaved, andEmail) => {
    this.setState({ reportPreviewOpen: false });
  }

  startNewEvent = eventData => {
    if (eventData && eventData.bookingId && eventData.calendarEventType === 'Booking') {
      //could also check eventData.eventType===0
      this.props.history.push(`bookings/${eventData.bookingId}/${eventData.baseId}`);

      return; //not using the AddEditEventModal
    }
    if (eventData && eventData.calendarEventType === 'Proposal') {
      this.openBookingWizard({ wizardType: 'proposal', selectedProposalId: eventData.id });

      return;
    }
    const selectedAppointment = this.buildSelectedAppointment(eventData);

    this.setState({ isAddingEditingEvent: true, selectedAppointment });
  }

  //once bug gets fixed in syncfusion we can do this in the componentDidUpdate() and get rid of this function
  //if (prevProps.currentMode !== this.props.currentMode) {
  //this.schedule.current.currentView = this.props.currentMode;
  //this.schedule.current.dataBind();
  //}

  gotoMode = ({ mode, view }) => {
    this.mode = mode;
    this.view = view;
  }

  openBookingWizard = ({ wizardType, selectedProposalId }) => {
    this.setState({ wizardType, selectedProposalId });
  }

  closeBookingWizard = () => {
    this.setState({ wizardType: null, selectedProposalId: null });
  }

  openEvent = event => {
    if (event.calendarEventType === 'task' || event.calendarEventType === 'personal') {
      this.setState({ selectedAppointment: event, isAddingEditingEvent: true });
    }
    else {
      console.log(event);
      this.setState({ wizardType: event.calendarEventType, selectedProposalId: event.id, selection: event });
    }
  }

  closeCalendarEventModal = (shouldRefresh = false) => {
    this.setState({ isAddingEditingEvent: false });
  }

  popupTimer;

  render() {
    const {
      isAddingEditingEvent,
      selectedAppointment,
      wizardType,
      selection,
      selectedProposalId,
      printsModalOpen,
      reportPreviewOpen,
      reportPreview,
      reportParams,
      quickPopupAnchor,
      quickPopupEvent,
      quickPopupOpen,
    } = this.state;
    const { theme, selectedDate, rooms } = this.props;

    const miniButtonStyle = {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[100]}`,
      color: theme.palette.common.black,
      minHeight: 32,
      padding: '0 15px',
      textTransform: 'initial',
      fontWeight: '400',
      borderRadius: '0',
      borderRight: 'none',
      '&:firstChild': {
        borderRadius: '4px 0 0 4px',
      },
      '&:lastChild': {
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: '0 4px 4px 0',
      },
    };

    const view = () => {
      if (this.view === CALENDAR_VIEW.Calendar) {
        if (this.mode === CALENDAR_MODE.Month) {
          return { calendar: { labels: true } };
        }

        return { schedule: { type: this.mode, size: 1 } };
      }
      else {
        return {
          timeline: {
            type: this.mode,
          },
        };
      }
    };

    const onPageLoaded = (event, instance) => {
      const params = { StartDate: event.firstDay.toISOString(), EndDate: event.lastDay.toISOString(), Switches: { Proposal: true, Booking: true, Personal: true, Task: true } };

      if (this.props.params !== params) {
        if (this.view === CALENDAR_VIEW.Calendar) {
          this.props.getCalendarEventsByDates(params);
        }
        else {
          this.props.getCalendarEventsByDatesByResource(params);
        }
      }
    };

    const onEventClick = args => {
      this.openEvent(args.event);
    };

    const onEventCreated = args => {
      const endTime = args.event.end ? args.event.end : args.event.start;

      const event = { ...args.event, startDateTime: args.event.start, endDateTime: endTime, name: args.event.title, id: undefined };

      this.setState({ selectedAppointment: event, isAddingEditingEvent: true });

      return false;
    };

    const onEventDragEnd = (event, inst) => {
      const endTime = event.event.end ? event.event.end : event.event.start;

      const updatedEvent = { ...event.event, startDateTime: formatSavedDate(event.event.start), endDateTime: formatSavedDate(endTime) };

      this.props.saveCalendarEvent(updatedEvent);
    };

    const formatSavedDate = date => {
      var zone = '', temp = -date.getTimezoneOffset() / 60 * 100;

      zone = temp >= 0 ? '+' : '-';

      zone += String(Math.abs(temp)).padStart(4, '0');

      // "2009-6-4T14:7:32+10:00"
      return date.getFullYear()   // 2009
        + "-"
        + String(date.getMonth() + 1).padStart(2, '0') // 6
        + "-"
        + String(date.getDate()).padStart(2, '0')       // 4
        + "T"
        + String(date.getHours()).padStart(2, '0')      // 14
        + ":"
        + String(date.getMinutes()).padStart(2, '0')    // 7
        + ":"
        + String(date.getSeconds()).padStart(2, '0')    // 32
        + zone.substr(0, 3)    // +10
        + ":"
        + String(temp).substr(-2); // 00        ;
    };

    const onEventHoverOut = () => {
      this.popupTimer = setTimeout(() => { this.setState({ quickPopupOpen: false }); }, 200);
    };

    const onEventHoverIn = args => {
      const event = args.event;
      const time = formatDate('hh:mm A', new Date(event.start)) + ' - ' + formatDate('hh:mm A', new Date(event.end));

      this.setState({ quickPopupEvent: { ...event, time }, quickPopupAnchor: args.domEvent.target, quickPopupOpen: true });

      if (this.popupTimer) {
        clearTimeout(this.popupTimer);
      }
    };

    const onMouseEnter = () => {
      if (this.popupTimer) {
        clearTimeout(this.popupTimer);
      }
    };

    const onMouseLeave = () => {
      this.popupTimer = setTimeout(() => { this.setState({ quickPopupOpen: false }); }, 200);
    };

    const dateChange = (event, inst) => {
      this.props.selectDate(event.date);
    };

    return (
      <>
        <Eventcalendar
          theme="ios"
          themeVariant="light"
          clickToCreate={true}
          dragToCreate={true}
          dragToMove={true}
          dragToResize={true}
          eventDelete={true}
          data={this.props.events.events}
          view={view()}
          onPageLoaded={onPageLoaded}
          onEventClick={onEventClick}
          onEventCreate={onEventCreated}
          onEventDragEnd={onEventDragEnd}
          onEventHoverIn={onEventHoverIn}
          onEventHoverOut={onEventHoverOut}
          onSelectedDateChange={dateChange}
          selectedDate={selectedDate}
          ref={this.schedule}
          dataTimezone='utc'
          resources={this.view === CALENDAR_VIEW.Timeline ? rooms : undefined}
        />
        <Popup
          display="anchored"
          isOpen={!!quickPopupOpen}
          anchor={quickPopupAnchor}
          touchUi={false}
          showOverlay={false}
          contentPadding={false}
          closeOnOverlayClick={false}
          width={350}
          cssClass="md-tooltip"
        >
          {quickPopupEvent && <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="md-tooltip-header" style={{ backgroundColor: quickPopupEvent.color }}>
              <span className="md-tooltip-name-age">{quickPopupEvent.name}</span>
              <span className="md-tooltip-time">{quickPopupEvent.time}</span>
            </div>
            <div className="md-tooltip-info">
              <div className="md-tooltip-title"><span className="md-tooltip-location md-tooltip-text">{quickPopupEvent.description}</span></div>
            </div>
            <div>
              <Button style={miniButtonStyle} onClick={() => this.printCalendarEvent(quickPopupEvent)}>Print</Button>
              <Button style={miniButtonStyle} onClick={() => { onMouseLeave(); this.startNewEvent(quickPopupEvent); }}>View</Button>
              <Button style={miniButtonStyle} onClick={() => this.startNewEvent(quickPopupEvent)}>Delete</Button>
            </div>
          </div>}
        </Popup>
        <ValidationBanner
          innerRef={this.validationBanner}
        />
        {
          isAddingEditingEvent && (
            <AddEditEventModal
              onModalClosed={this.closeCalendarEventModal}
              appointment={selectedAppointment}
              hasRelationship={selectedAppointment && !!selectedAppointment.bookingId} //TODO: change to relationshipId if/when Tasks can be linked to other entities
            />
          )
        }
        {
          Boolean(wizardType) &&
          <BookingWizard
            onClose={this.closeBookingWizard}
            selection={selection}
            proposal={wizardType === 'proposal'}
            selectedProposalId={selectedProposalId}
          />
        }
        <Prints
          isOpened={printsModalOpen}
          onGenerate={this.onGeneratePrint}
        />
        <ReportPreviewModal
          onSaveAndEmail={this.onSavedAndEmailed}
          isOpened={reportPreviewOpen}
          params={reportParams}
          report={reportPreview}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  getCalendarEventsByDates,
  saveCalendarEvent,
  deleteCalendarEvent,
  getRooms,
  selectDate,
  getReportDetails,
  getCalendarEventsByDatesByResource,
};

const mapStateToProps = state => {
  const {
    api: {
      calendarEvents: {
        json,
        params,
      },
      rooms,
    },
    calendar: {
      selectedEventTypes,
      selectedDate,
    },
  } = state;

  return {
    selectedEventTypes,
    events: json,
    params,
    selectedDate,
    rooms,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Scheduler));
