import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  deleteRevenueType,
  transitionRevenueType,
} from 'actions/RevenueType';

const styles = theme => ({
  container: {
    padding: '20px 26px',
  },
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  table: {
    fontSize: 12,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    '& th': {
      padding: '6px 13px',
    },
    '& tr': {
      borderTop: 0,
      borderBottom: 0,
    },
  },
  borderedCell: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
  },
  rowLabel: {
    color: theme.palette.grey[300],
    paddingTop: 11,
    paddingBottom: 11,
  },
  centerText: {
    textAlign: 'center',
  },
  sectionLabel: {
    height: 16,
    display: 'flex',
    paddingLeft: 13,
    marginTop: 8,
    alignItems: 'center',
  },
  moveDown: {
    marginTop: 13,
    marginRight: 0,
  },
  exemptContents: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftColumn: {
    minWidth: 126,
  },
  rightColumn: {
    flexGrow: 1,
  },
  flex: {
    display: 'flex',
  },
  switchTextContainer: {
    textAlign: 'center',
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  switchText: {
    fontSize: 11,
    color: '#9d9d9d',
  },
  switch: {
    marginTop: -5,
  },
  codeExists: {
    margin: '0 16px',
    color: theme.palette.error.dark,
  },
});

const RevenueTypeModal = ({ classes, RevenueType, onCloseModal, revenueTypes, transitionRevenueType, deleteRevenueType }) => {
  const [transitionToRevenueTypeid, setTransitionRevenueTypeId] = useState();

  const onDelete = async () => {
    console.log("test");

    await transitionRevenueType(RevenueType.id, transitionToRevenueTypeid);
    await deleteRevenueType(RevenueType);

    onCloseModal();
  };

  const onCancel = () => {
    onCloseModal();
  };

  return (<Modal
    isOpened={true}
    dimensions={{
      width: 340,
      height: 250,
    }}
    onCancel={onCancel}
    onSave={onDelete}
    isSaveDisabled={(!transitionToRevenueTypeid)}
    saveText="Delete"
    title='Delete Revenue Type'
    addTitleBottomBorder={true}
  >
    <div className={classes.container}>
      <div className={classes.exemptContents}>
        <Select
          label="Move Menu Items To"
          value={transitionToRevenueTypeid}
          onFieldChange={setTransitionRevenueTypeId}
        >
          {revenueTypes && revenueTypes.filter(r => r && r.id !== RevenueType.id).map(r =>
            <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
          )}
        </Select>
      </div>

    </div>
  </Modal>
  );
};

const mapStateToProps = state => {
  const {
    api,
  } = state;

  return {
    revenueTypes: api && api.revenueTypes ? api.revenueTypes : [],
  };
};

const mapDispatchToProps = {
  deleteRevenueType,
  transitionRevenueType,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RevenueTypeModal));
