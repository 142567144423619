import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import {
  SET_SHIFT_TYPES,
} from 'actions/constants';

export const getShiftTypes = () => dispatch => {
  dispatch(callApi('ShiftType'))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(shiftTypes => {
      const defaultShiftTypes = {
        server: 'Server',
        bartender: 'Bartender',
        busser: 'Busser',
        hostess: 'Hostess',
        captain: 'Captain',
      };

      const storeDefaultShiftTypes = {
        server: shiftTypes.find(shiftType => shiftType.name === defaultShiftTypes.server),
        bartender: shiftTypes.find(shiftType => shiftType.name === defaultShiftTypes.bartender),
        busser: shiftTypes.find(shiftType => shiftType.name === defaultShiftTypes.busser),
        hostess: shiftTypes.find(shiftType => shiftType.name === defaultShiftTypes.hostess),
        captain: shiftTypes.find(shiftType => shiftType.name === defaultShiftTypes.captain),
      };

      dispatch(setStoreShiftTypes(shiftTypes, storeDefaultShiftTypes));
    })
    .catch(console.error);
};

export const setStoreShiftTypes = (shiftTypes, storeDefaultShiftTypes) => ({
  type: SET_SHIFT_TYPES,
  shiftTypes,
  storeDefaultShiftTypes,
});

