import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import {
  MERCHANTACCOUNT_RECEIVED,
} from './constants';
import IMerchantAccount from 'models/IMerchantAccount';

const merchantAccountsReceived = (merchantAccounts: IMerchantAccount[]) => ({
  type: MERCHANTACCOUNT_RECEIVED,
  merchantAccounts,
});

export function getMerchantAccounts() {
  return (dispatch: any) =>
    dispatch(callApi('merchantAccount'))
      .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response))
      .then((merchantAccounts: IMerchantAccount[]) => dispatch(merchantAccountsReceived(merchantAccounts)))
      .catch(console.error);
}

export function addMerchntAccount(freedomPayAccountId: string, esKey: string, accountId: string, terminalId: string) {
  const body = { freedomPayAccountId, esKey, accountId, terminalId };

  return (dispatch: any) =>
    dispatch(callApi('merchantAccount', { method: 'POST', body }))
      .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);
}

export function getMerchantAccountLink(merchantAccountId: string) {
  const returnUrl = window.location.href;

  return (dispatch: any) =>
    dispatch(callApi(`merchantAccount/link/${merchantAccountId}?returnUrl=${returnUrl}`))
      .then((result: any) => result.text())
      .catch(console.error);
}

export function setDefaultMerchantAccount(merchantAccountId: string) {
  return (dispatch: any) =>
    dispatch(callApi(`merchantAccount/setDefault/${merchantAccountId}`, { method: 'PUT' }))
      .then((result: any) => getMerchantAccounts()(dispatch))
      .catch(console.error);
}
