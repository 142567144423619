import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import { fetchInvoicesSuccess } from './api';

export function getInvoices() {
  let okay = true;
  let status = 0;
  let statusText;

  return dispatch => {
    dispatch(callApi('invoice'))
      .then(response => {
        if (!response.ok) {
          status = response.status;
          statusText = response.statusText;
          okay = false;
        }

        return ParseJsonOnSuccessOrStop(dispatch, response);
      })
      .then(json => {
        if (okay) {
          return json;
        }
        if (json.message) {
          throw Error(json.message);
        }
        if (statusText || status) {
          throw Error(`${status} - ${statusText}`);
        }
        throw Error('unknown');
      })
      .then(json => dispatch(fetchInvoicesSuccess(json)))
      .catch(error => console.log(`Error getting invoices ${error}`));
  };
}

export function saveInvoice(invoice) {
  return dispatch => dispatch(callApi('invoice', { body: invoice }));
}
