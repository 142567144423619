import {
  ADD_TO_RECENTS,
  REMOVE_RECENT,
  RESET_RECENTS,
} from 'actions/constants';

const initialState = {
  Account: [],
  Booking: [],
  Contact: [],
  Lead: [],
  Order: [],
  EmailTemplate: [],
};

function addRecent(viewedRecord, url, stateRecents) {
  const newRecord = {
    id: viewedRecord.id,
    name: viewedRecord.name || viewedRecord.id, // Order does not have a 'Name'
    url,
  };

  let recentRecords = {};

  if (stateRecents) {
    recentRecords = [newRecord, ...stateRecents];
  } else {
    recentRecords = [newRecord];
  }

  return removeDupes(recentRecords);
}

function removeFromRecents(deletedRecordId, stateRecents) {
  const indexOfOldRecord = stateRecents && stateRecents.findIndex(r => r.id === deletedRecordId);

  if (indexOfOldRecord && indexOfOldRecord > -1) {
    stateRecents.splice(indexOfOldRecord, 1);

    return removeDupes(stateRecents);
  }
}

// Remove duplicates, only keep 5 most recent records
function removeDupes(records) {
  const ids = records.map(r => r.id);

  return records.filter((record, i) => ids.indexOf(record.id) === i).slice(0, 5);
}

export function recents(state = initialState, action) {
  switch (action.type) {

    case ADD_TO_RECENTS: {
      const { record, url, recordType } = action;
      const recentsOfType = addRecent(record, url, state[recordType]);
      const newState = {
        ...state,
        [recordType]: recentsOfType,
      };

      return newState;
    }
    case REMOVE_RECENT: {
      const { id, recordType } = action;
      const recentsOfType = removeFromRecents(id, state[recordType]);

      return {
        ...state,
        [recordType]: recentsOfType,
      };
    }
    case RESET_RECENTS: {
      return initialState;
    }

    default:
      return state;
  }
}
