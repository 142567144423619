import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  Paper,
  AppBar,
  Menu,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Person as ContactIcon,
  Forum as ChitChatIcon,
  Email as EmailIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as CloneIcon,
} from '@material-ui/icons';
import HeaderButton from 'Components/Buttons/HeaderButton';
import MiniButtonSet from 'Components/Buttons/MiniButtonSet';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';

const styles = theme => ({
  appBarOverrides: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    fontSize: 16,
    height: 'unset',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 14px',
    backgroundColor: theme.palette.contact,
    color: theme.palette.common.white,
  },
  topLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  topRight: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 32,
    width: 32,
    marginRight: 16,
  },
  contactIcon: {
    fontSize: '48px',
    color: theme.palette.common.white,
  },
  rightIcon: {
    fontSize: '20px',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  smallerFont: {
    fontSize: 17,
  },
  verticalAlign: {
    verticalAlign: 'middle',
  },
  contactName: {
    fontSize: 22,
    lineHeight: 1,
  },
  bottomBar: {
    display: 'flex',
    padding: '12px 16px',
    backgroundColor: theme.common.blueGrey,
  },
  marginRight: {
    marginRight: 16,
  },
  glanceLabel: {
    color: theme.palette.grey[200],
    fontSize: 10,
  },
  glanceData: {
    color: theme.palette.grey[300],
    fontSize: 12,
    minWidth: 86,
  },
  glanceDataLong: {
    color: theme.palette.grey[300],
    fontSize: 12,
    minWidth: 174,
  },
  linkColor: {
    color: theme.palette.primary.dark,
  },
});

class ContactHeader extends Component {

  state = {
    optionsOpenedBy: null,
  }

  openToolsMenu = event => {
    this.setState({ optionsOpenedBy: event.currentTarget });
  }

  closeToolsMenu = () => {
    this.setState({ optionsOpenedBy: null });
  }

  onAddContact = () => {
    this.closeToolsMenu();
    this.props.onAddContact();
  }

  onSetView = (synthEvent, viewState) => {
    synthEvent.stopPropagation();
    this.props.onSetView(viewState);
  }

  render() {
    const {
      classes,
      label,
      contact,
      // salesReps,
      onEdit,
      onDelete,
      isEditing,
      onClone,
      viewState,
      userHasFullCover,
      disableDeleteMessage,
    } = this.props;
    const {
      optionsOpenedBy,
    } = this.state;

    // const salesRep = !!salesReps && salesReps.find(s => s.id === contact.salesRepId);

    return (
      <AppBar position="static" classes={{ root: classes.appBarOverrides }}>
        <div className={classes.topBar}>
          <div className={classes.topLeft}>
            <div className={classes.iconContainer}>
              <ContactIcon className={classes.contactIcon} />
            </div>
            <div className={classes.verticalAlign}>
              <div className={classes.smallerFont}>{label}</div>
              <p className={classes.contactName}>
                {contact.name}
              </p>
            </div>
          </div>
          <div className={classes.topRight}>
            <MiniButtonSet>
              <HeaderButton
                onClick={synthEvent => this.onSetView(synthEvent, 'contactDetails')}
                isActive={viewState === 'contactDetails'}
                name="Contact"
                icon={<ContactIcon className={classes.rightIcon} />}>
              </HeaderButton>
              <HeaderButton
                onClick={synthEvent => this.onSetView(synthEvent, 'chitChat')}
                isActive={viewState === 'chitChat'}
                name="Chit Chat"
                icon={<ChitChatIcon className={classes.rightIcon} />}>
              </HeaderButton>
              <HeaderButton
                onClick={synthEvent => this.onSetView(synthEvent, 'email')}
                isActive={viewState === 'email'}
                name="Email"
                icon={<EmailIcon className={classes.rightIcon} />}>
              </HeaderButton>
            </MiniButtonSet>
            <MiniButtonSet>
              <HeaderButton onClick={onEdit} isActive={isEditing} name="Edit" icon={<EditIcon className={classes.rightIcon} />}></HeaderButton>
              <HeaderButton
                onClick={disableDeleteMessage ? null : onDelete}
                name={disableDeleteMessage ? disableDeleteMessage : 'Delete'}
                icon={<DeleteIcon className={
                  classNames(
                    classes.rightIcon,
                    { [classes.disabled]: disableDeleteMessage },
                  )
                }/>}

              />
              <HeaderButton onClick={onClone} name="Clone" icon={<CloneIcon className={classes.rightIcon} />}></HeaderButton>
              <ArrowDownButton onClick={this.openToolsMenu} />
              <Menu
                id="options-menu"
                anchorEl={optionsOpenedBy}
                open={Boolean(optionsOpenedBy)}
                onClose={() => this.closeToolsMenu()}
              >
                <MenuItem onClick={this.onAddContact}>Add New Contact</MenuItem>
              </Menu>
            </MiniButtonSet>
          </div>
        </div>
        <Paper elevation={1} square className={classes.bottomBar}>
          {userHasFullCover &&
            <div className={classes.marginRight}>
              <p className={classes.glanceLabel}>Primary Account</p>
              <p className={classes.glanceData}>
                {contact.primaryAccount && <a href={`/accounts/${contact.primaryAccount.id}`}>{contact.primaryAccount.name}</a>}
              </p>
            </div>
          }
          <div className={classes.marginRight}>
            <p className={classes.glanceLabel}>Email</p>
            <a href={`mailto:${contact.email}`}><p className={classNames(classes.glanceDataLong, classes.linkColor)}>{contact.email}</p></a>
          </div>
          <div className={classes.marginRight}>
            <p className={classes.glanceLabel}>Phone</p>
            <p className={classes.glanceData}>{contact.phone}</p>
          </div>
        </Paper>
      </AppBar>
    );
  }
}

ContactHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(ContactHeader);
