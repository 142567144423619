import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import EventMenuCategory from './EventMenuCategory';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HighlightIfEdited from 'Components/HighlightIfEdited';
import TextField from 'Components/TextField';
import {
  addMenuItemToEventMenuCategory,
  getBookingMenuColumns,
  deleteMenuItemFromCategory,
  updateEventMenuCategories,
} from 'actions/eventMenu';
import { getMasterMenus } from 'actions/masterMenuManagement';
import { updateStoreFocusedEvent } from 'actions/booking';
import { getStoreMenu } from 'actions/storemenu';
import { getRevenueTypes } from 'actions/item';
import { getMenuItems } from 'actions/menu';
import { MenuType } from 'models/MenuType';

const styles = theme => ({
  sortableMenuSelection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.common.paper,
    borderRadius: 4,
    overflowX: 'auto',
    '&::-webkit-scrollbar': { //custom horizontal scrollbar
      width: 0,
      height: 10,
      background: theme.palette.grey[50],
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey[500],
    },
  },
  field: {
    width: 328,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  marginTop: {
    display: 'flex',
    marginTop: 8,
    '&:first-child': {
      marginTop: 0,
    },
  },
  fallbackMessage: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 60,
  },
});

class SortableMenuSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      originalName: null,
      eventMenuName: null,
      eventMenuCategories: [],
    };
  }

  componentDidMount() {
    this.props.getMasterMenus();
    this.props.getBookingMenuColumns();
    this.props.getStoreMenu(MenuType.Booking);
    this.props.getRevenueTypes();
    this.props.getMenuItems();
    this.reRender();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focusedEvent !== this.props.focusedEvent) {
      this.reRender();
    }
  }

  reRender = () => {
    const { eventMenuCategories, eventMenuName } = this.props;

    this.setState({
      eventMenuCategories: [...eventMenuCategories],
      eventMenuName: eventMenuName,
      originalName: eventMenuName,
    });
  }

  isNameChanged = () => {
    const { isLoading, originalName, eventMenuName } = this.state;
    const { isEditing, showWhenMenuNameChanged } = this.props;

    if (isLoading) {
      return false;
    };

    return isEditing && showWhenMenuNameChanged && originalName !== eventMenuName;
  }

  onNameChanged = eventMenuName => {
    this.setState({ eventMenuName });
  }

  nameEditDone = () => {
    const { eventMenuName } = this.state;
    const { focusedEvent } = this.props;

    focusedEvent.eventMenuName = eventMenuName;

    this.props.updateStoreFocusedEvent(focusedEvent);
  }

  getMenuCategory = menuCategoryId => {
    const { eventMenuCategories } = this.state;
    const parsedId = parseInt(menuCategoryId, 10);

    return eventMenuCategories.find(s => (s.tempId && s.tempId === menuCategoryId) || s.id === parsedId);
  }

  onDragEnd = result => {
    const { source, destination, type } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (type === 'CATEGORY') {
      this.onCategorySortEnd(source.index, destination.index);
    }

    if (type === 'ITEM') {
      this.onItemSortEnd(source, destination);
    }
    this.forceUpdate(); // to make sure render is triggered for drag and drop
  };

  onCategorySortEnd = (oldIndex, newIndex) => {
    const { eventMenuCategories } = this.state;
    const sortedMenuCategories = arrayMove(eventMenuCategories, oldIndex, newIndex);
    const sortOrderedMenuCategories = sortedMenuCategories.map((category, index) => {
      category.sortOrder = index;

      return category;
    });

    this.props.updateEventMenuCategories(sortOrderedMenuCategories);
  };

  onItemSortEnd = (source, destination) => {
    const sourceId = parseInt(source.droppableId);
    const destinationId = parseInt(destination.droppableId);

    if (sourceId === destinationId) {
      this.reorderItems(source, destination);
    } else {
      this.moveItemToAnotherCategory(source, destination);
    }
  };

  reorderItems = (source, destination) => {
    const { eventMenuCategories } = this.state;
    const menuCategoryId = parseInt(source.droppableId);
    const originalMenuCategory = this.getMenuCategory(menuCategoryId);
    const sortedMenuItems = arrayMove(originalMenuCategory.eventMenuCategoryItems, source.index, destination.index);
    const sortOrderedItems = this.assignSortOrderToItems(sortedMenuItems);

    const foundIndex = eventMenuCategories.findIndex(x => x.id === menuCategoryId);
    const sortedMenuCategory = {
      ...originalMenuCategory,
      eventMenuCategoryItems: sortOrderedItems,
    };
    const newEventMenuCategories = [...eventMenuCategories];

    newEventMenuCategories.splice(foundIndex, 1, sortedMenuCategory);

    this.props.updateEventMenuCategories(newEventMenuCategories);
  }

  moveItemToAnotherCategory = (source, destination) => {
    const { eventMenuCategories } = this.state;
    const sourceMenuCategoryId = source.droppableId;
    const sourceMenuCategoryIndex = source.index;
    const destinationMenuCategoryId = destination.droppableId;
    const destinationMenuCategoryIndex = destination.index;

    const newEventMenuCategories = [...eventMenuCategories];
    const sourceCategory = newEventMenuCategories.find(category => category.id === parseInt(sourceMenuCategoryId, 10) || category.tempId === sourceMenuCategoryId);
    const destinationCategory = newEventMenuCategories.find(category => category.id === parseInt(destinationMenuCategoryId, 10) || category.tempId === destinationMenuCategoryId);

    if (sourceCategory && destinationCategory) {
      const sourceItem = sourceCategory.eventMenuCategoryItems.splice(sourceMenuCategoryIndex, 1)[0];

      if (sourceItem) {
        if (sourceItem.bookingEventMenuCategoryId) delete sourceItem.bookingEventMenuCategoryId; // let API assign the correct ID
        destinationCategory.eventMenuCategoryItems.splice(destinationMenuCategoryIndex, 0, sourceItem);

        sourceCategory.eventMenuCategoryItems = this.assignSortOrderToItems(sourceCategory.eventMenuCategoryItems);
        destinationCategory.eventMenuCategoryItems = this.assignSortOrderToItems(destinationCategory.eventMenuCategoryItems);

        return this.props.updateEventMenuCategories(newEventMenuCategories);
      } else {
        console.error('Drag and Drop Error - Item not found.');
      }
    } else {
      console.error(`Drag and Drop Error - EventMenuCategory not found.
      \nSource Category Droppable ID: ${sourceMenuCategoryId}
      \nDestination Category Droppable ID: ${destinationMenuCategoryId}`);
    }
  }

  assignSortOrderToItems = sortedMenuItems => {
    return sortedMenuItems.map((item, index) => {
      item.sortOrder = index;

      return item;
    });
  }

  addItemToCategory = category => items => {
    items.forEach(item => {
      item.quantity = item.menuItem.defaultQuantity ? item.menuItem.defaultQuantity : 1;
    });

    return this.props.addMenuItemToEventMenuCategory(category, items);
  }

  deleteItemFromCategory = category => item => {
    this.props.deleteMenuItemFromCategory(category, item).then(this.reRender);
  }

  setDragIdsAndFilter = eventMenuCategories => {
    return eventMenuCategories.filter(category => category.recordStatus === 'Active')
      .map(category => {
        if (category.id) {
          category.dragId = category.id.toString();
        } else {
          category.dragId = category.tempId;
        }

        return category;
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }

  render() {
    const {
      classes,
      defaultQuantity,
      isEditing,
      isImagesOpenedFirstOnItemPicker,
      showModifiers,
      useDefaultColumns,
      toolBar,
      onLaunchEditMode,
    } = this.props;

    const {
      isLoading,
      eventMenuName,
      eventMenuCategories,
    } = this.state;

    return (
      <div className={classes.sortableMenuSelection} onDoubleClick={onLaunchEditMode}>
        <div className={classes.topBar}>
          <HighlightIfEdited isChanged={this.isNameChanged()}>
            <TextField
              fieldName="eventMenuName"
              placeholder="Menu Name"
              value={eventMenuName}
              className={classes.field}
              onFieldChange={this.onNameChanged}
              disabled={!isEditing}
              onBlur={this.nameEditDone}
            />
          </HighlightIfEdited>
          <div className={classes.toolbar}>
            {toolBar}
          </div>
        </div>

        <div className={classes.menu}>
          {eventMenuCategories && eventMenuCategories.length ?
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable
                droppableId="categoryDroppable"
                key="categoryDroppable"
                type="CATEGORY"
                isDropDisabled={!isEditing}
              >
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {this.setDragIdsAndFilter(eventMenuCategories).map((eventMenuCategory, index) => {
                      return (
                        <Draggable
                          draggableId={eventMenuCategory.dragId}
                          key={eventMenuCategory.dragId}
                          index={index}
                          isDragDisabled={!isEditing}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.marginTop}
                            >
                              <Droppable
                                droppableId={eventMenuCategory.dragId}
                                type="ITEM"
                                isDropDisabled={!isEditing}
                              >
                                {provided => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <EventMenuCategory
                                      eventMenuCategory={eventMenuCategory}
                                      showModifiers={showModifiers}
                                      defaultQuantity={defaultQuantity}
                                      isEditing={isEditing}
                                      isImagesOpenedFirstOnItemPicker={isImagesOpenedFirstOnItemPicker}
                                      isLoading={isLoading}
                                      onMenuCategoryChanged={this.reRender}
                                      useDefaultColumns={useDefaultColumns}
                                      onAddMenuItem={this.addItemToCategory(eventMenuCategory)}
                                      onDeleteMenuItem={this.deleteItemFromCategory(eventMenuCategory)}
                                    />
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          )}

                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                    <div style={{ width: '100%', height: 95, visibility: 'hidden' }}>
                      &nbsp;{/*this is to make room for QuickAdd on bottom*/}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            :
            <div className={classes.fallbackMessage}>Add a menu category to get started!</div>
          }
        </div>
      </div>
    );
  }
};

SortableMenuSelection.propTypes = {
  defaultQuantity: PropTypes.number,
  isEditing: PropTypes.bool,
  isImagesOpenedFirstOnItemPicker: PropTypes.bool,
  showModifiers: PropTypes.bool,
  storeType: PropTypes.string, // 'wizard' or 'manager'
};

const mapStateToProps = (state, ownProps) => {
  const {
    booking: {
      [ownProps.storeType]: {
        focusedEvent,
      },
    },
  } = state;

  return {
    focusedEvent,
    eventMenuName: focusedEvent.eventMenuName,
    eventMenuCategories: focusedEvent.bookingEventMenuCategories,
    defaultQuantity: focusedEvent.estimatedGuestCount ?  focusedEvent.estimatedGuestCount : 1,
  };
};

const mapDispatchToProps = {
  addMenuItemToEventMenuCategory,
  getBookingMenuColumns,
  deleteMenuItemFromCategory,
  updateEventMenuCategories,
  updateStoreFocusedEvent,
  getStoreMenu,
  getRevenueTypes,
  getMasterMenus,
  getMenuItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SortableMenuSelection));
