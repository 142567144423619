import {
  RECEIVE_MODIFIER_CATEGORIES,
  RECEIVE_OPERATORS,
  RECEIVE_SETS,
  FETCH_INVOICES_SUCCESS,
  CONTACTS_REQUEST_BEGIN,
  CONTACTS_SELECTED,
  LEADS_REQUEST_BEGIN,
  FETCH_BOOKINGS_SUCCESS,
  CALENDAR_EVENTS_REQUEST_BEGIN,
  RECEIVE_CALENDAR_EVENTS,
  AGENDA_EVENTS_REQUEST_BEGIN,
  RECEIVE_AGENDA_EVENTS,
  SALES_DATA_RECEIVED,
  MODIFIERS_REQUEST_BEGIN,
  RECEIVE_LEADS,
  RECEIVE_MODIFIERS,
  MODIFIER_COLUMNS_RECEIVED,
  CONTACT_COLUMNS_RECEIVED,
  BOOKINGS_SELECTED,
  BOOKING_COLUMNS_RECEIVED,
  LEAD_COLUMNS_RECEIVED,
  FETCH_BOOKINGS_BEGIN,
  FETCH_CHITCHAT_MESSAGES_BEGIN,
  FETCH_CHITCHAT_MESSAGES_SUCCESS,
  FETCH_CHITCHAT_MESSAGES_FAILURE,
  CONFIRMED_BOOKINGS_TOGGLED,
  FETCH_PROPOSALS_BEGIN,
  PROPOSALS_RECEIVED,
  FETCH_ORDERS_BEGIN,
  FETCH_ORDERS_SUCCESS,
  ORDERS_SELECTED,
  ORDER_RECEIVED,
  ORDER_COLUMNS_RECEIVED,
  FETCH_ACCOUNTS_BEGIN,
  FETCH_ACCOUNTS_SUCCESS,
  ACCOUNTS_SELECTED,
  ACCOUNT_RECEIVED,
  ACCOUNT_COLUMNS_RECEIVED,
  ON_SCHEDULER_DAY_SELECTED,
  CLONE_BOOKING_PREFERENCES_RECEIVED,
  ROOM_RECEIVE,
  SETUP_STYLES_RECEIVED,
  LEADS_SELECTED,
  UNAVAILABLE_ROOMS_RECEIVED,
  PARENT_ROOMS_RECEIVED,
} from './constants';

export const fetchModifiersBegin = params => ({
  type: MODIFIERS_REQUEST_BEGIN,
  params,
});

export const modifierColumnsReceived = columns => ({
  type: MODIFIER_COLUMNS_RECEIVED,
  columns,
});

export const fetchModifiersSuccess = (response, params, skipParamCheck = false) => ({
  type: RECEIVE_MODIFIERS,
  response,
  params,
  skipParamCheck,
});

export const leadsReceived = (data, append) => ({
  type: RECEIVE_LEADS,
  data,
  append,
});

export const contactsSelected = contactIdHash => ({
  type: CONTACTS_SELECTED,
  contactIdHash,
});

export const leadsSelected = leadIdHash => ({
  type: LEADS_SELECTED,
  leadIdHash,
});

export const contactColumnsReceived = (columns, userHasFullCover) => ({
  type: CONTACT_COLUMNS_RECEIVED,
  columns,
  userHasFullCover,
});

export const modifierCategoriesReceived = modifierCategories => ({
  type: RECEIVE_MODIFIER_CATEGORIES,
  modifierCategories,
});

export const operatorsReceived = operators => ({
  type: RECEIVE_OPERATORS,
  operators,
});

export const setsReceived = sets => ({
  type: RECEIVE_SETS,
  sets,
});

export const fetchInvoicesSuccess = invoices => ({
  type: FETCH_INVOICES_SUCCESS,
  invoices,
});

export const fetchBookingsBegin = params => ({
  type: FETCH_BOOKINGS_BEGIN,
  params,
});

export const fetchBookingsSuccess = (data, append) => ({
  type: FETCH_BOOKINGS_SUCCESS,
  data,
  append,
});

export const bookingsSelected = bookingIdHash => ({
  type: BOOKINGS_SELECTED,
  bookingIdHash,
});

export const confirmedBookingsToggled = isConfirmedToggled => ({
  type: CONFIRMED_BOOKINGS_TOGGLED,
  isConfirmedToggled,
});

export const bookingColumnsReceived = columns => ({
  type: BOOKING_COLUMNS_RECEIVED,
  columns,
});

export const fetchContactsBegin = params => ({
  type: CONTACTS_REQUEST_BEGIN,
  params,
});

export const fetchLeadsBegin = params => ({
  type: LEADS_REQUEST_BEGIN,
  params,
});

export const leadColumnsReceived = columns => ({
  type: LEAD_COLUMNS_RECEIVED,
  columns,
});

export const fetchCalendarEventsBegin = () => ({
  type: CALENDAR_EVENTS_REQUEST_BEGIN,
});

export const calendarEventsReceived = (json, params, dates) => ({
  type: RECEIVE_CALENDAR_EVENTS,
  json,
  params,
  dates,
});

export const fetchAgendaEventsBegin = () => ({
  type: AGENDA_EVENTS_REQUEST_BEGIN,
});

export const agendaEventsReceived = (json, params, dates) => ({
  type: RECEIVE_AGENDA_EVENTS,
  json,
  params,
  dates,
});

export const salesDataReceived = data => ({
  type: SALES_DATA_RECEIVED,
  data,
});

export const fetchChitChatBegin = relationshipId => ({
  type: FETCH_CHITCHAT_MESSAGES_BEGIN,
  relationshipId,
});

export const fetchChitChatSuccess = threads => ({
  type: FETCH_CHITCHAT_MESSAGES_SUCCESS,
  threads,
});

export const fetchChitChatFail = error => ({
  type: FETCH_CHITCHAT_MESSAGES_FAILURE,
  error,
});

export const fetchProposalsBegin = contactId => ({
  type: FETCH_PROPOSALS_BEGIN,
  contactId,
});

export const proposalsReceived = proposals => ({
  type: PROPOSALS_RECEIVED,
  proposals,
});

export const fetchOrdersBegin = params => ({
  type: FETCH_ORDERS_BEGIN,
  params,
});

export const fetchOrdersSuccess = ({ data, meta }) => ({
  type: FETCH_ORDERS_SUCCESS,
  data,
  meta,
});

export const orderReceived = order => ({
  type: ORDER_RECEIVED,
  order,
});

export const ordersSelected = orders => ({
  type: ORDERS_SELECTED,
  orders,
});

export const orderColumnsReceived = columns => ({
  type: ORDER_COLUMNS_RECEIVED,
  columns,
});

export const fetchAccountsBegin = params => ({
  type: FETCH_ACCOUNTS_BEGIN,
  params,
});

export const fetchAccountsSuccess = ({ data, append }) => ({
  type: FETCH_ACCOUNTS_SUCCESS,
  data,
  append,
});

export const accountReceived = account => ({
  type: ACCOUNT_RECEIVED,
  account,
});

export const accountsSelected = accountIdHash => ({
  type: ACCOUNTS_SELECTED,
  accountIdHash,
});

export const accountColumnsReceived = columns => ({
  type: ACCOUNT_COLUMNS_RECEIVED,
  columns,
});

export const disSchedulerDaySelected = dateSelected => ({
  type: ON_SCHEDULER_DAY_SELECTED,
  dateSelected,
});

export const cloneBookingPreferencesReceived = clonePreferences => ({
  type: CLONE_BOOKING_PREFERENCES_RECEIVED,
  clonePreferences,
});

export const roomsReceived = rooms => ({
  type: ROOM_RECEIVE,
  rooms,
});

export const setupStylesReceived = setupStyles => ({
  type: SETUP_STYLES_RECEIVED,
  setupStyles,
});

export const unavailableRoomsReceived = unavailableRooms => ({ type: UNAVAILABLE_ROOMS_RECEIVED, unavailableRooms });
export const parentRoomsReceived = parentRooms => ({ type: PARENT_ROOMS_RECEIVED, parentRooms });
