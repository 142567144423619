import React, { Component } from 'react';
import {
  FormControl,
  InputLabel,
  Select as MaterialSelect,
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  flexGrowWithPad: {
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
  },
  contactLabel: {
    color: theme.palette.grey[200],
  },
  contactInput: {
    color: theme.palette.grey[700],
  },
  visible: {
    visibility: 'visible',
    color: theme.palette.common.black,
  },
  hide: {
    visibility: 'hidden',
  },
});

class Select extends Component {

  fieldChanged = event => {
    if (!this.props.onFieldChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    this.props.onFieldChange(event.target.value);
  }

  getValue = () => {
    const { value } = this.props;

    if (value) {
      return value;
    }

    if (value === false) {
      return false;
    }

    return ''; //value is falsy
  }

  render() {
    const {
      classes,
      disabled,
      fieldName,
      label,
      className,
      children,
      icon,
    } = this.props;

    const value = this.getValue();

    return (
      <FormControl className={classNames(classes.flexGrowWithPad, className)} margin="normal" disabled={disabled}>
        <InputLabel classes={{ root: classes.contactLabel }}>{label}</InputLabel>
        <MaterialSelect
          value={value}
          onChange={this.fieldChanged}
          name={fieldName}
          classes={{
            root: classes.contactInput,
            icon: disabled ? classes.hide : classes.visible,
          }}
          IconComponent={icon}
        >
          {children}
        </MaterialSelect>
      </FormControl>
    );
  }
}

export default withStyles(styles)(Select);
