import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import DataLoader from 'Components/DataLoader';
import IconButton from 'Components/Buttons/IconButton';
import { Add as AddIcon } from '@material-ui/icons';
import SmallOrderGrid from './SmallOrderGrid';
import OrderWizard from 'orders/wizard';
import { getOrdersByContact } from 'actions/order';

const styles = theme => ({
  orderSection: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  sectionLabel: {
    fontSize: 17,
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[50],
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionContainer: {
    paddingBottom: 18,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  gridWrapper: {
    paddingTop: 16,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  loader: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    marginTop: 48,
  },
});

class OrdersSection extends Component {
  state = { orders: [], isLoading: false };

  componentDidMount() {
    this.getOrders();
  }

  getOrders = () => {
    this.setState({ isLoading: true });
    this.props.getOrdersByContact(this.props.contact.id).then(orders => this.setState({ orders, isLoading: false }));
  }

  openOrderWizard = () => {
    this.setState({ isWizardOpen: true });
  }

  closeOrderWizard = () => {
    this.setState({ isWizardOpen: false });
    this.getOrders();
  }

  render() {
    const {
      classes,
      contact,
    } = this.props;
    const {
      orders,
      isWizardOpen,
      isLoading,
    } = this.state;

    return (
      <div className={classes.orderSection}>
        <div className={classes.sectionContainer}>
          <div className={classes.sectionLabel}>
            <div>Orders</div>
            <div>
              <IconButton height={31} icon={AddIcon} onClick={this.openOrderWizard} />
            </div>
          </div>
          <div className={classes.gridWrapper}>
            {isLoading ?
              <div className={classes.loader}>
                <DataLoader />
              </div>
              :
              <SmallOrderGrid orders={orders} />
            }
          </div>
        </div>
        {isWizardOpen &&
          <OrderWizard
            contactId={contact.id}
            onClose={this.closeOrderWizard}
          />
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  getOrdersByContact,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(OrdersSection));
