import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CoverDatePicker from 'Components/CoverDatePicker';
import CoverTimePicker from 'Components/CoverTimePicker';
import HighlightIfEdited from 'Components/HighlightIfEdited';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import ComboBox from 'Components/ComboBox';
import BookingTimeDetailsModal from './BookingTimeDetailsModal';
import SelectVenueModal from './SelectVenueModal';
import SiteLocationModal from './SiteLocationModal';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';
import { TextField, Button, InputAdornment } from '@material-ui/core';
import {
  Clear as DeleteIcon,
  PlayForWork as SelectNewIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import moment from 'moment';
import ShiftGrid from './ShiftGrid';
import VenueGrid from './VenueGrid';
import SiteGrid from './SiteGrid';
import { connect } from 'react-redux';
import { getVenues } from 'actions/venue';
import { getShiftTypes } from 'actions/shiftType';
import {
  deleteShiftFromFocusedEvent,
  deleteEventSection,
  deleteSiteFromFocusedEvent,
} from 'actions/booking';
import ShiftsModal from './ShiftsModal';
import ShiftPickerModal from './ShiftPickerModal';
import {
  getEventTypes,
} from 'actions/pickListOption';

const styles = theme => ({
  eventDetails: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .k-grid-header thead th': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  formSection: {
    flexGrow: 1,
  },
  formGroupColumn: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '50%',
  },
  formGroupRow: {
    display: 'flex',
    flexGrow: 1,
  },
  flexWrap: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  formField: {
    flexGrow: 1,
    margin: '16px 16px 8px',
  },
  smallField: {
    minWidth: 100,
    maxWidth: 130,
  },
  label: {
    color: theme.palette.grey[200],
  },
  input: {
    color: theme.palette.grey[300],
  },
  guestDetailsLeft: {
    marginRight: 20,
  },
  guestDetailsRight: {
    maxWidth: '50%',
  },
  actionButton: {
    display: 'flex',
    alignSelf: 'flex-end',
    textTransform: 'initial',
    minHeight: 32,
    maxHeight: 32,
    padding: '0 0',
    color: theme.palette.secondary.main,
  },
  lowerRight: {
    display: 'flex',
  },
  adornment: {
    '& p': {
      color: theme.palette.grey[300],
    },
  },
  visibleIcon: {
    visibility: 'visible',
    color: theme.palette.common.black,
  },
  hidden: {
    visibility: 'hidden',
  },
  timeDetailsButton: {
    minWidth: 95,
  },
  width100: {
    width: '100%',
  },
  fieldIsEdited: {
    background: theme.palette.error.light,
  },
  overrideFlexWithPad: {
    margin: 'unset',
  },
  noScrollBar: {
    '& .k-grid-content': {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  sectionExpansionIconButton: {
    left: 0,
  },
  sectionExpansionSummary: {
    paddingLeft: 48,
    '&:hover $hoverDelete': {
      visibility: 'visible',
    },
  },
  hoverDelete: {
    color: theme.palette.error.main,
    visibility: 'hidden',
  },
});

class EventDetailsTab extends Component {
  state = {
    isBookingTimeDetailsModalOpen: false,
    isSelectVenueModalOpen: false,
    isSiteLocationModalOpen: false,
    isShiftSelectModalOpen: false,
    venues: [],
    bookingEventTypeSuggestions: [],
  };

  componentDidMount() {
    // this.getVenues();
    // this.getSites();
    this.props.getShiftTypes();
    this.props.getEventTypes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookingEventTypes !== this.props.bookingEventTypes) {
      this.setupEventTypeSuggestions(this.props.bookingEventTypes);
    }
  }

  onFieldChange = fieldName => event => {
    this.props.onFieldChange(fieldName)(event.target.value);
  }

  getVenues = () => {
    const {
      focusedEvent: { venueConfigId },
      getVenues,
    } = this.props;

    if (!venueConfigId) {
      this.setState({ venues: [] });

      return;
    }

    getVenues(venueConfigId).then(venues => {
      if (venues) { //if venues was removed this will be null
        this.setState({ venues });
      }
    });
  }

  openSiteLocationModal = synthEvent => {
    if (synthEvent && synthEvent.stopPropagation) {
      synthEvent.stopPropagation();
    }
    this.setState({ isSiteLocationModalOpen: true });
  }

  closeSiteLocationModal = () => {
    // this.getSites(); TODO
    this.setState({ isSiteLocationModalOpen: false });
  }

  cancelSiteLocationModal = () => {
    // TODO: maybe store sitesRef in redux, then here we call a resetToRef() to restore siteConfig?
    this.closeSiteLocationModal();
  }

  onDeleteEventSite = siteIndex => {
    this.props.deleteSiteFromFocusedEvent(siteIndex);
  }

  openSelectVenueModal = synthEvent => {
    if (synthEvent && synthEvent.stopPropagation) {
      synthEvent.stopPropagation();
    }
    this.setState({ isSelectVenueModalOpen: true });
  }

  openSelectShiftsModal = synthEvent => {
    synthEvent.stopPropagation();
    this.setState({ isShiftSelectModalOpen: true });
  }

  closeShiftsModal = () => {
    this.setState({ isShiftSelectModalOpen: false });
  }

  closeSelectVenueModal = () => {
    this.setState({ isSelectVenueModalOpen: false });
    this.getVenues();
  }

  openBookingTimeDetailsModal = () => {
    this.setState({ isBookingTimeDetailsModalOpen: true });
  }

  closeBookingTimeDetailsModal = () => {
    this.setState({ isBookingTimeDetailsModalOpen: false });
  }

  openShiftPickerModal = clickedShift => {
    const shiftEdit = {
      ...clickedShift,
      timeIn: new Date(clickedShift.timeIn),
      timeOut: new Date(clickedShift.timeOut),
    };

    this.setState({ shiftEdit });
  }

  onShiftDelete = shiftIndex => {
    this.props.deleteShiftFromFocusedEvent(shiftIndex);
  }

  closeShiftPickerModal = () => {
    this.setState({ shiftEdit: null, existingShiftIndex: null });
  }

  onShiftEdit = (clickedShift, shiftIndex) => {
    this.setState({ shiftEdit: { ...clickedShift }, existingShiftIndex: shiftIndex });
  }

  handleDateChange = fieldName => value => {
    const { onFieldChange, event } = this.props;

    if (fieldName === 'startDate') {
      const startDateTime = moment(moment(value).format("YYYY/MM/DD") + ' ' + moment(event.startDateTime).format('HH:MM A'));
      const endDateTime = moment(moment(value).format("YYYY/MM/DD") + + ' ' + moment(event.endDateTime).format('HH:MM A'));

      this.props.onFieldChange('startDateTime')(startDateTime);
      onFieldChange('endDateTime')(endDateTime);
    }
    if (fieldName === 'startTime') {
      const startDateTime = moment(moment(event.startDateTime).format("YYYY/MM/DD") + ' ' + value);

      this.props.onFieldChange('startDateTime')(startDateTime.toISOString());
    }
    if (fieldName === 'endTime') {
      const endDateTime = moment(moment(event.startDateTime).format("YYYY/MM/DD") + ' ' + value);

      this.props.onFieldChange('endDateTime')(endDateTime.toISOString());
    }
  }

  handleDeleteEventSection = (synthEvent, section) => {
    synthEvent.stopPropagation();
    this.props.onDeleteEventSection(section);
  }

  setupEventTypeSuggestions = eventTypes => {
    if (eventTypes) {
      const { comboBoxSuggestions } = setupComboBoxSuggestions(eventTypes);

      this.setState({ bookingEventTypeSuggestions: comboBoxSuggestions });
    }
  }

  handleQuickPick = fieldName => pick => {
    if (pick && pick.value && pick.value.name) {
      this.props.onFieldChange(fieldName)(pick.value.name);
    }
  }

  handleCustomChoice = fieldName => value => {
    this.props.onFieldChange(fieldName)(value);
  }

  render() {
    const {
      classes,
      onFieldChange,
      isFieldChanged,
      event,
      bookingId,
      isEditing,
      hasShifts,
      hasSites,
      hasVenues,
    } = this.props;
    const {
      isSelectVenueModalOpen,
      isSiteLocationModalOpen,
      // venues,
      isShiftSelectModalOpen,
      isBookingTimeDetailsModalOpen,
      shiftEdit,
      existingShiftIndex,
      bookingEventTypeSuggestions,
    } = this.state;
    const getCurrencyAdornment = fieldName => {
      return event[fieldName] ? <InputAdornment className={classes.adornment} position="start">$</InputAdornment> : null;
    };

    const eventDate = moment(event.startDateTime).format("YYYY/MM/DD");
    const eventStartTime = moment(event.startDateTime).format("H:mm A");
    const eventEndTime = moment(event.endDateTime).format("H:mm A");

    return (
      <>
        <div className={classes.eventDetails}>
          <CoverExpansionPanel
            title="General"
            customstyles={{
              summary: classes.sectionExpansionSummary,
              iconButton: classes.sectionExpansionIconButton,
            }}
            defaultExpanded={true}>
            <div className={classes.formSection}>
              <div className={classes.formGroupRow}>
                <div className={classes.formGroupColumn}>
                  <HighlightIfEdited className={classes.formField} isChanged={isFieldChanged('name')}>
                    <TextField
                      label="Event Name"
                      className={classes.width100}
                      onChange={this.onFieldChange('name')}
                      name="name"
                      value={event.name || ''}
                      disabled={!isEditing}
                      InputLabelProps={{
                        classes: {
                          root: classes.label,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                          disabled: classes.input,
                        },
                      }}
                    />
                  </HighlightIfEdited>
                </div>
                <div className={classes.formGroupColumn}>
                  <HighlightIfEdited isChanged={isFieldChanged('bookingEventType')}>
                    <ComboBox
                      label="Type"
                      value={event.bookingEventType}
                      suggestions={bookingEventTypeSuggestions}
                      isClearable={true}
                      isCreatable={true}
                      handleChange={this.handleQuickPick('bookingEventType')}
                      handleQuickAdd={this.handleCustomChoice('bookingEventType')}
                      disabled={!isEditing}
                    />
                  </HighlightIfEdited>
                </div>
              </div>
              <div className={classes.formGroupRow}>
                <div className={classes.formGroupColumn}>
                  <HighlightIfEdited className={classes.formField} isChanged={isFieldChanged('startDateTime')}>
                    <CoverDatePicker
                      name="startDateTime"
                      label="Date"
                      className={classNames(classes.overrideFlexWithPad, !isEditing && classes.width100)}
                      disabled={!isEditing}
                      value={eventDate}
                      minDate={moment(event.startDateTime).clone().subtract(10, 'years').toDate()}
                      onFieldChange={this.handleDateChange('startDate')}
                    />
                  </HighlightIfEdited>
                </div>
                <div className={classes.formGroupColumn}>
                  <div className={classes.lowerRight}>
                    <HighlightIfEdited className={classes.formField} isChanged={isFieldChanged('startDateTime')}>
                      <CoverTimePicker
                        name="startTime"
                        label="Start Time"
                        value={eventStartTime}
                        onFieldChange={this.handleDateChange('startTime')}
                        disabled={!isEditing}
                        className={classes.overrideFlexWithPad}
                      />
                    </HighlightIfEdited>
                    <HighlightIfEdited className={classes.formField} isChanged={isFieldChanged('endDateTime')}>
                      <CoverTimePicker
                        name="end"
                        label="End Time"
                        value={eventEndTime}
                        onFieldChange={this.handleDateChange('endTime')}
                        disabled={!isEditing}
                        className={classes.overrideFlexWithPad}
                      />
                    </HighlightIfEdited>
                    <Button
                      variant="outlined"
                      className={classNames(classes.actionButton, classes.formField, classes.timeDetailsButton)}
                      onClick={this.openBookingTimeDetailsModal}>
                      Time Details
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </CoverExpansionPanel>
          <CoverExpansionPanel
            title="Guest Details"
            customstyles={{
              summary: classes.sectionExpansionSummary,
              iconButton: classes.sectionExpansionIconButton,
            }}
            defaultExpanded={true}>
            <div className={classNames(classes.flexWrap)}>
              <div className={classNames(classes.formGroupRow, classes.guestDetailsLeft)}>
                <HighlightIfEdited className={classNames(classes.formField, classes.smallField)} isChanged={isFieldChanged('minimumGuestCount')}>
                  <TextField
                    label="Minimum Count"
                    onChange={this.onFieldChange('minimumGuestCount')}
                    name="minimumGuestCount"
                    type="number"
                    value={event.minimumGuestCount || ''}
                    disabled={!isEditing}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.input,
                        disabled: classes.input,
                      },
                    }}
                  />
                </HighlightIfEdited>
                <HighlightIfEdited className={classNames(classes.formField, classes.smallField)} isChanged={isFieldChanged('estimatedGuestCount')}>
                  <TextField
                    label="Estimated Count"
                    onChange={this.onFieldChange('estimatedGuestCount')}
                    name="estimatedGuestCount"
                    type="number"
                    value={event.estimatedGuestCount || ''}
                    disabled={!isEditing}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.input,
                        disabled: classes.input,
                      },
                    }}
                  />
                </HighlightIfEdited>
                <HighlightIfEdited className={classNames(classes.formField, classes.smallField)} isChanged={isFieldChanged('guaranteedGuestCount')}>
                  <TextField
                    label="Guaranteed Count"
                    onChange={this.onFieldChange('guaranteedGuestCount')}
                    name="guaranteedGuestCount"
                    type="number"
                    value={event.guaranteedGuestCount || ''}
                    disabled={!isEditing}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.input,
                        disabled: classes.input,
                      },
                    }}
                  />
                </HighlightIfEdited>
                <HighlightIfEdited className={classNames(classes.formField, classes.smallField)} isChanged={isFieldChanged('actualGuestCount')}>
                  <TextField
                    label="Actual Count"
                    onChange={this.onFieldChange('actualGuestCount')}
                    name="actualGuestCount"
                    type="number"
                    value={event.actualGuestCount || ''}
                    disabled={!isEditing}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.input,
                        disabled: classes.input,
                      },
                    }}
                  />
                </HighlightIfEdited>
              </div>
              <div className={classNames(classes.formGroupRow, classes.guestDetailsRight)}>
                <HighlightIfEdited className={classNames(classes.formField, classes.smallField)} isChanged={isFieldChanged('minimumSpend')}>
                  <TextField
                    label="Minimum Spend"
                    onChange={this.onFieldChange('minimumSpend')}
                    name="minimumSpend"
                    type="number"
                    value={event.minimumSpend || ''}
                    disabled={!isEditing}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.input,
                        disabled: classes.input,
                      },
                      startAdornment: getCurrencyAdornment('minimumSpend'),
                    }}
                  />
                </HighlightIfEdited>
                <HighlightIfEdited className={classNames(classes.formField, classes.smallField)} isChanged={isFieldChanged('estimatedSpend')}>
                  <TextField
                    label="Estimated Spend"
                    onChange={this.onFieldChange('estimatedSpend')}
                    name="estimatedSpend"
                    type="number"
                    value={event.estimatedSpend || ''}
                    disabled={!isEditing}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.input,
                        disabled: classes.input,
                      },
                      startAdornment: getCurrencyAdornment('estimatedSpend'),
                    }}
                  />
                </HighlightIfEdited>
              </div>
            </div>
          </CoverExpansionPanel>
          {hasVenues &&
            <CoverExpansionPanel
              title="Venue Details"
              customstyles={{
                summary: classes.sectionExpansionSummary,
                iconButton: classes.sectionExpansionIconButton,
              }}
              defaultExpanded={true}
              rightcontent={isEditing &&
                <>
                  <div onClick={this.openSelectVenueModal}>
                    <SelectNewIcon />
                  </div>
                  <DeleteIcon classes={{ root: classes.hoverDelete }} onClick={synthEvent => this.handleDeleteEventSection(synthEvent, 'Venues')} />
                </>
              }
            >
              <div onDoubleClick={this.openSelectVenueModal}>
                {isEditing ?
                  <VenueGrid
                    venues={event.bookingEventRooms}
                    isEditing={true}
                    onEdit={this.openSelectVenueModal}
                    className={classes.noScrollBar}
                  />
                  :
                  <VenueGrid
                    venues={event.bookingEventRooms}
                    isEditing={false}
                    className={classes.noScrollBar}
                  />}
              </div>
            </CoverExpansionPanel>
          }
          {hasSites &&
            <CoverExpansionPanel
              title="Site Details"
              customstyles={{
                summary: classes.sectionExpansionSummary,
                iconButton: classes.sectionExpansionIconButton,
              }}
              defaultExpanded={true}
              rightcontent={isEditing &&
                <>
                  <div onClick={this.openSiteLocationModal}>
                    <SelectNewIcon />
                  </div>
                  <DeleteIcon classes={{ root: classes.hoverDelete }} onClick={synthEvent => this.handleDeleteEventSection(synthEvent, 'Sites')} />
                </>
              }
            >
              <div onDoubleClick={this.openSiteLocationModal}>
                <SiteGrid
                  bookingEventSites={event.bookingEventSites}
                  onSelectLocation={this.handleSiteLocationClick}
                  onDeleteSite={this.onDeleteEventSite}
                  isEditing={isEditing}
                  onEdit={isEditing ? this.openSiteLocationModal : () => { }}
                  showWebsite={true}
                  className={classes.noScrollBar}
                />
              </div>
            </CoverExpansionPanel>
          }
          {hasShifts &&
            <CoverExpansionPanel
              title="Shifts"
              customstyles={{
                summary: classes.sectionExpansionSummary,
                iconButton: classes.sectionExpansionIconButton,
              }}
              defaultExpanded={true}
              rightcontent={isEditing &&
                <>
                  <div onClick={this.openSelectShiftsModal}>
                    <SelectNewIcon />
                  </div>
                  <DeleteIcon classes={{ root: classes.hoverDelete }} onClick={synthEvent => this.handleDeleteEventSection(synthEvent, 'Shifts')} />
                </>
              }
            >
              <div onDoubleClick={this.openSelectShiftsModal}>
                {isEditing ?
                  <ShiftGrid
                    shifts={event.bookingEventShifts}
                    isEditing={true}
                    onEdit={this.onShiftEdit}
                    onDelete={this.onShiftDelete}
                    className={classes.noScrollBar}
                  />
                  :
                  <ShiftGrid
                    shifts={event.bookingEventShifts}
                    isEditing={false}
                    onDelete={this.onShiftDelete}
                    className={classes.noScrollBar}
                  />}
              </div>
            </CoverExpansionPanel>
          }
        </div>
        {isSelectVenueModalOpen && <SelectVenueModal
          onModalClosed={this.closeSelectVenueModal}
          venueConfigId={event.venueConfigId}
        />}
        {isShiftSelectModalOpen && <ShiftsModal
          onModalClosed={this.closeShiftsModal}
          defaultStartTime={event.startTime}
          defaultEndTime={event.endTime}
          storeType="manager"
        />}
        {isSiteLocationModalOpen && <SiteLocationModal
          onModalClosed={this.closeSiteLocationModal}
          siteConfigId={event.siteConfigId}
          onCancel={this.cancelSiteLocationModal}
        />}
        {shiftEdit && <ShiftPickerModal
          onModalClosed={this.closeShiftPickerModal}
          shift={shiftEdit}
          existingShiftIndex={existingShiftIndex}
        />}
        {isBookingTimeDetailsModalOpen && <BookingTimeDetailsModal
          bookingId={bookingId}
          bookingEvent={event}
          onClose={this.closeBookingTimeDetailsModal}
          // onLaunchEditMode={onLaunchEditMode} //TODO
          onFieldChange={onFieldChange}
        />}
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    booking: {
      manager: {
        storeBooking,
        focusedEventIndex,
        focusedEvent,
        isEditing,
      },
    },
    api: {
      bookingEventTypes,
    },
  } = state;

  return {
    storeBooking,
    focusedEventIndex,
    focusedEvent,
    isEditing,
    hasShifts: focusedEvent && (focusedEvent.hasShifts || (focusedEvent.bookingEventShifts && focusedEvent.bookingEventShifts.length > 0)),
    hasSites: focusedEvent && (focusedEvent.hasSites || (focusedEvent.bookingEventSites && focusedEvent.bookingEventSites.length > 0)),
    hasVenues: focusedEvent && (focusedEvent.hasVenues || (focusedEvent.bookingEventRooms && focusedEvent.bookingEventRooms.length > 0)),
    bookingEventTypes,
  };
};

const mapDispatchToProps = {
  getVenues,
  getShiftTypes,
  deleteShiftFromFocusedEvent,
  deleteSiteFromFocusedEvent,
  deleteEventSection,
  getEventTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventDetailsTab));
