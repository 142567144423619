import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

export const getCardPaymentUrl = (orderId, amount) => dispatch =>
  dispatch(callApi(`Order/${orderId}/CardPaymentUrl/${amount}`))
    .then(response => response.text());

export const getOrderPayments = orderId => dispatch =>
  dispatch(callApi(`Order/${orderId}/payment`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const addOrderPayment = orderPaymentTransaction => dispatch =>
  dispatch(callApi(`OrderPaymentTransaction`, { method: 'POST', body: orderPaymentTransaction }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const refundOrderPayment = orderPaymentTransactionId => dispatch =>
  dispatch(callApi(`OrderPaymentTransaction/${orderPaymentTransactionId}/refund`, { method: 'PUT' }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));
