import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import MenuItemCard from 'portal/admin/menumanagement/MenuItemCard';
import { provideIntlService } from '@progress/kendo-react-intl';
import {
  clearSearch,
} from 'actions/search';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  menuItem: {
    display: 'flex',
    color: theme.palette.text.primary,
    margin: '8px 2%',
    width: '29%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  avatar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.common.bluegrey,
    fontSize: 30,
    fontWeight: '600',
    minWidth: '70px',
    maxWidth: '70px',
    height: 45,
    color: theme.palette.grey['50'],
    borderRadius: 5,
  },
  menuItemsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  noResults: {
    paddingLeft: '20px',
    fontSize: '0.9rem',
    color: '#65666c',
    fontWeight: '100',
  },
});

class MenuItemSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAddress: null,
      addressParts: {},
    };
  }

  noResults() {
    const { classes } = this.props;

    return (<Typography className={classes.noResults} variant="subtitle1" gutterBottom>No items found...</Typography>);
  }

  renderMenuItemResults() {
    const { classes, items } = this.props;
    const formatter = provideIntlService(this);

    return (
      <div className={classes.menuItemsContainer}>
        {items.map(item => (
          <Button key={`menuitem-${item.id}`} className={classes.menuItem} component="a" onClick={() => this.props.clearSearch()} href={`/admin/items/${item.id}`}>
            <MenuItemCard menuItem={item} formatter={formatter} />
          </Button>
        ))}
      </div>
    );
  }

  render() {
    const { items } = this.props;

    return items.length > 0 ? this.renderMenuItemResults() : this.noResults();
  }
}

const mapStateToProps = state => {
  const {
    search: {
      searchResults: {
        menuItems: {
          items,
        },
      },
    } } = state;

  return {
    items,
  };
};

const mapDispatchToProps = {
  clearSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuItemSearchResults));
