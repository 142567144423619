import React, { Component } from 'react';
import AsyncCreatable from 'react-select/async-creatable';
import {
  Paper,
  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { searchContacts } from 'actions/contact';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    margin: '0px 16px 8px',
  },
  input: {
    display: 'flex',
    height: '48px',
    padding: 0,
    '& div[aria-hidden="true"]': { //Decrease padding around icons to make input size match (height: 32px)
      padding: '6px',
    },
    color: theme.palette.grey[700],
  },
  valueContainer: {
    color: theme.palette.grey[700],
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.grey[200],
  },
  singleValue: {
    fontSize: 16,
    color: theme.palette.grey[700],
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    color: theme.palette.grey[200],
  },
  menuItem: {
    height: 20,
    color: theme.palette.grey[300],
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    flexDirection: 'row',
    fontSize: 14,
    lineHeight: 1.5,
    justifyContent: 'space-between',
    borderBottom: '2px solid #efefef',
  },
  menu: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    borderRadius: '4px',
  },
  label: {
    color: theme.palette.grey[200],
  },

  contactListEntryName: {
    width: '20%',
  },
  contactListEntryEmail: {
    width: '20%',
    textAlign: 'left',
  },
  shortenText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  contactListEntryPhone: {
    width: '20%',
    textAlign: 'right',
  },
  contactListEntryAddress: {
    width: '25%',
    textAlign: 'left',
  },
  contactListEntrySeparator: {
    width: '5%',
    textAlign: 'center',
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth={true}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  if (props.data.__isNew__) {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        className={props.selectProps.classes.menuItem}
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }

  return renderContact(props, props.data);
}

function renderContact(props, contact) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.selectProps.classes.menuItem}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <span className={classNames(props.selectProps.classes.contactListEntryName, props.selectProps.classes.shortenText)}>
        {contact.firstName} {contact.lastName}
      </span>
      <span className={props.selectProps.classes.contactListEntrySeparator}>&nbsp;</span>
      <div className={classNames(props.selectProps.classes.contactListEntryAddress, props.selectProps.classes.shortenText)}>
        {contact.address1 &&
          <span
            className={props.selectProps.classes.shortenText}
          >
            {contact.address1}, {contact.city ? contact.city : ''}, {contact.state ? contact.state : ''}, {contact.postalCode ? contact.postalCode : ''}
          </span>
        }
      </div>
      <span className={props.selectProps.classes.contactListEntrySeparator}>&nbsp;</span>
      <span className={classNames(props.selectProps.classes.contactListEntryEmail, props.selectProps.classes.shortenText)}>
        {contact.email}
      </span>
      <span className={props.selectProps.classes.contactListEntrySeparator}>&nbsp;</span>
      <span className={props.selectProps.classes.contactListEntryPhone}>
        {contact.phone || contact.phone2}
      </span>
    </MenuItem >
  );
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.hasValue ? renderContact(props, props.getValue()[0]) : props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.menu} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class ContactSearchField extends Component {
  state = {
    value: this.props.value,
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentWillUnmount() {
    this.setState({ value: {} });
  }

  handleSelection = value => {
    if (value !== this.state.value) {
      this.props.onSelectContact(value);
    }
  }

  onInputChange = (input, { action }) => {
    if (action !== 'input-blur' && action !== 'menu-close') { // allow parent to update
      this.setState({ inputValue: input });

      return input;
    }
  }

  onCreateOption = value => {
    this.props.onCreateOption(this.props.fieldName, value);
  }

  getContacts = input => {
    return this.props.searchContacts(input).then(results => results);
  }

  render() {
    const {
      className,
      classes,
      label,
      fieldName,
      autoLoadOptions,
      disabled,
      placeholder,
      ...rest
    } = this.props;
    const {
      value,
      inputValue,
    } = this.state;

    return (
      <div className={classNames(className, classes.root)}>
        <AsyncCreatable
          {...rest}
          isClearable={true}
          classes={classes}
          multi={false}
          isDisabled={disabled}
          inputValue={inputValue}
          value={value}
          onChange={this.handleSelection}
          onInputChange={this.onInputChange}
          onCreateOption={this.onCreateOption}
          loadOptions={this.getContacts}
          defaultOptions={autoLoadOptions}
          components={components}
          minimumInput={1}
          placeholder={placeholder || "Search or Create New..."}
          getOptionLabel={option => {
            return option.__isNew__ ? option.label : `${option}`; //how do we tell if it's 2 contacts sharing a phone number?
          }}
          backspaceRemovesValue={true}
          textFieldProps={{
            label: label,
            InputLabelProps: {
              shrink: true,
              classes: {
                root: classes.label,
              },
            },
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  searchContacts,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(ContactSearchField));
