import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Authenticate from 'auth/Authenticate';

import '@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css';
import '@progress/kendo-theme-default/dist/all.css'; //TODO: look at switching to @progress/kendo-theme-material since we're using material
import '@syncfusion/ej2/material.css';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import bgNumbers from 'cldr-numbers-full/main/bg/numbers.json';
import bgLocalCurrency from 'cldr-numbers-full/main/bg/currencies.json';
import bgCaGregorian from 'cldr-dates-full/main/bg/ca-gregorian.json';
import bgDateFields from 'cldr-dates-full/main/bg/dateFields.json';

import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from 'cldr-dates-full/main/en/dateFields.json';

import gbNumbers from 'cldr-numbers-full/main/en-GB/numbers.json';
import gbLocalCurrency from 'cldr-numbers-full/main/en-GB/currencies.json';
import gbCaGregorian from 'cldr-dates-full/main/en-GB/ca-gregorian.json';
import gbDateFields from 'cldr-dates-full/main/en-GB/dateFields.json';

import esNumbers from 'cldr-numbers-full/main/es-US/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es-US/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es-US/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es-US/dateFields.json';

import frNumbers from 'cldr-numbers-full/main/fr-CA/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr-CA/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr-CA/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr-CA/dateFields.json';

import { connect } from 'react-redux';
import esMessages from './language/es.json';
import frMessages from './language/fr.json';
import enMessages from './language/en.json';
import CustomLocalizationProvider from './language/CustomLocalizationProvider';

import { IntlProvider, load, loadMessages } from '@progress/kendo-react-intl';
import ErrorBoundary from 'Components/ErrorBoundary';

load(
  likelySubtags,
  currencyData,
  weekData,
  bgNumbers,
  bgLocalCurrency,
  bgCaGregorian,
  bgDateFields,
  usNumbers,
  usLocalCurrency,
  usCaGregorian,
  usDateFields,
  gbNumbers,
  gbLocalCurrency,
  gbCaGregorian,
  gbDateFields,
  esNumbers,
  esLocalCurrency,
  esCaGregorian,
  esDateFields,
  frNumbers,
  frLocalCurrency,
  frCaGregorian,
  frDateFields
);

loadMessages(esMessages, 'es');//Spanish
loadMessages(enMessages, 'en');//English
loadMessages(frMessages, 'fr');//French

const mapStateToProps = state => ({
  language: state.locale.language,
  locale: state.locale.locale,
});

const AppContainer = connect(mapStateToProps)(App);

function App({ locale, language }) {
  return (
    <ErrorBoundary>
      <CustomLocalizationProvider language={language}>
        <IntlProvider locale={locale}>
          <BrowserRouter>
            <Authenticate />
          </BrowserRouter>
        </IntlProvider>
      </CustomLocalizationProvider>
    </ErrorBoundary>
  );
}

export default AppContainer;
