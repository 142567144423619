import {
  GET_COUPONS,
  COUPONS_LOADED,
  SET_CURRENT_COUPON,
  CLEAR_CURRENT_COUPON,
  COUPON_CREATED,
  COUPON_UPDATED,
} from 'actions/constants';

const initialState = {
  data: [],
  loading: false,
  currentCoupon: undefined,
};

export function coupons(state = initialState, action) {
  switch (action.type) {
    case GET_COUPONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_CURRENT_COUPON: {
      const { coupon } = action;

      return {
        ...state,
        currentCoupon: coupon,
      };
    }
    case CLEAR_CURRENT_COUPON: {
      return {
        ...state,
        currentCoupon: undefined,
      };
    }
    case COUPON_CREATED: {
      const { coupon } = action;

      return {
        ...state,
        data: [...state.data, coupon],
      };
    }
    case COUPON_UPDATED: {
      const { coupon } = action;

      console.log(coupon);

      return {
        ...state,
        data: state.data.map(c => c.id === coupon.id ? coupon : c),
      };
    }
    case COUPONS_LOADED: {
      const { data, append } = action;

      const newData = append ? state.data.concat(data) : data;

      return {
        ...state,
        data: newData,
        loading: false,
      };
    }
    default:
      return state;
  }
}
