import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTitle,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import moment from 'moment';
import SalesWidgetModal from './SalesWidgetModal';
import { getWidgetColor } from 'shared/themes';

const styles = theme => ({
  graphContainer: {
    height: '100%',
    cursor: 'pointer',
  },
  totalContainer: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'space-between',
  },
  totalDescription: {
    color: theme.palette.grey[200],
    fontSize: '12px',
  },
});

class SalesWidget extends React.Component {
  constructor(props) {
    super(props);

    this.chartContainerRef = React.createRef();
    this.salesChartRef = React.createRef();
    this.quantityChartRef = React.createRef();

    this.state = {
      showSalesPopup: false,
      showQuantitySoldPopup: false,
    };
  }

  componentDidMount() {
    this.salesChartRef.current.onClick = () => console.log("sales clicked");
  };

  render() {
    const {
      classes,
      data,
      height = '50%',
      width = '100%',
    } = this.props;

    const {
      salesModalOpen,
      salesModalTitle,
      salesModalField,
      salesModalValueFormat,
      salesModalColor,
    } = this.state;

    const renderQuantityTooltip = ({ point, dataItem }) => {
      const date = dataItem ? dataItem.date : point.dataItem.date;
      const value = dataItem ? dataItem.quantitySold : point.dataItem.quantitySold;

      return `${moment(date).format("l")} : ${value}`;
    };

    const renderSalesTooltip = ({ point, dataItem }) => {
      const date = dataItem ? dataItem.date : point.dataItem.date;
      const value = dataItem ? dataItem.totalSold : point.dataItem.totalSold;

      return `${moment(date).format("l")} : $${value}`;
    };

    const salesClicked = () => {
      this.setState({
        salesModalOpen: true,
        salesModalTitle: "Sales (Last 30 days)",
        salesModalField: "totalSold",
        salesModalValueFormat: "C",
        salesModalColor: getWidgetColor(0),
      });
    };

    const quantityClicked = () => {
      this.setState({
        salesModalOpen: true,
        salesModalTitle: "Quantity Sold (Last 30 days)",
        salesModalField: "quantitySold",
        salesModalValueFormat: "",
        salesModalColor: getWidgetColor(1),
      });
    };

    const closeModal = () => {
      this.setState({ salesModalOpen: false });
    };

    return (
      <div ref={this.chartContainerRef} className={classes.graphContainer}>
        {data && <div onClick={salesClicked}><Chart ref={this.salesChartRef} style={{ height, width }}>
          <ChartTitle text="Sales (Last 30 days)" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartValueAxis>
          <ChartSeries >
            <ChartSeriesItem type="area" data={data.totals} field="totalSold" line={{ style: 'normal' }} markers={{ visible: false }} color={getWidgetColor(0)} opacity={0.1} />
            <ChartSeriesItem type="line" data={data.totals} field="totalSold" line={{ style: 'normal' }} markers={{ visible: false }} color={getWidgetColor(0)} opacity={1} />
          </ChartSeries>
          <ChartTooltip render={renderSalesTooltip} />
        </Chart></div>}
        {data && <div onClick={quantityClicked}> <Chart ref={this.quantityChartRef} style={{ height, width }}>
          <ChartTitle text="Quantity Sold (Last 30 days)" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              line={{ visible: false }}
              majorGridLines={{ visible: false }}
              visible={false}
            />
          </ChartValueAxis>
          <ChartSeries>

            <ChartSeriesItem type="line" data={data.totals} field="quantitySold" line={{ style: 'normal' }} markers={{ visible: false }} color={getWidgetColor(1)} opacity={1} />
            <ChartSeriesItem type="area" data={data.totals} field="quantitySold" line={{ style: 'normal' }} markers={{ visible: false }} color={getWidgetColor(1)} opacity={0.1} />

          </ChartSeries>

          <ChartTooltip render={renderQuantityTooltip} />
        </Chart></div>}
        {data && salesModalOpen && <SalesWidgetModal data={data} title={salesModalTitle} field={salesModalField} isOpen={salesModalOpen} onClose={closeModal} valueFormat={salesModalValueFormat} graphColor={salesModalColor}></SalesWidgetModal>}
      </div>
    );
  }
}

export default withStyles(styles)(SalesWidget);
