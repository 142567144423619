import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Menu as HamburgerIcon,
  DeviceHub as TriForce,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Clear as CancelIcon,
} from '@material-ui/icons';
import {
  TextField,
} from '@material-ui/core';
import CheckBox from 'Components/Checkbox';
import {
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

const styles = theme => ({
  pickNameRow: {
    paddingLeft: 13,
    paddingRight: 13,
  },
  pickNameContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover $crudIcons': {
      visibility: 'visible',
    },
  },
  crudIcons: {
    visibility: 'hidden',
  },
  modifyIcon: {
    marginRight: 7,
    marginLeft: 3,
    color: theme.palette.grey[700],
    fontSize: 26,
    cursor: 'pointer',
  },
  colorSquare: {
    height: 19,
    width: 19,
    borderRadius: 2,
    display: 'table-cell', //helps with verticlal alignment
  },
  colorContainer: {
    paddingLeft: 18,
  },
  dataRow: {
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
  },
  inputFocused: {
    fontSize: 18,
    color: theme.palette.grey[700],
  },
});

var sortable = { cursor: 'row-resize' };
const DragHandle = SortableHandle(() => <HamburgerIcon style={sortable} />);

class PickListOptionRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
    };

    this.textInputClasses = {
      classes: {
        focused: props.classes.inputFocused,
      },
    };

  }

  componentDidMount() {
    if (this.props.item && this.props.item.new) {
      this.setState({
        isEditing: true,
        editedItem: this.props.item,
      });
    }
  }

  startEditing = () => {
    this.setState({ isEditing: true, editedItem: this.props.item });
  }

  saveItem = () => {
    const {
      onItemEdit,
      item,
    } = this.props;

    this.setState({ isEditing: false });

    if (this.state.editedItem === item) {
      return;
    }

    onItemEdit(this.state.editedItem);
  }

  onNameChange = event => {
    this.setState({
      editedItem: {
        ...this.state.editedItem,
        name: event.target.value,
      },
    });
  }

  cancelEditing = () => {
    this.setState({ isEditing: false });
  }

  enterPressed = event => {
    if (event.key !== 'Enter') {
      return;
    }
    this.saveItem();
  }

  disabledChange = event => {
    const {
      onItemEdit,
      item,
    } = this.props;

    const toggleCheck =
    {
      ...item,
      disabled: event,
    };

    onItemEdit(toggleCheck);
  }

  onDelete = () => {
    const {
      item,
    } = this.props;

    this.props.onItemDelete(item);
  }

  render() {
    const {
      item,
      classes,
    } = this.props;

    const {
      isEditing,
      editedItem,
    } = this.state;

    console.log(isEditing);

    return (
      <tr className={classes.dataRow}>
        {!isEditing && <td><DragHandle /></td>}
        <th scope="row" className={classes.pickNameRow} colSpan={isEditing ? 2 : 1}>
          <div className={classes.pickNameContent}>
            {isEditing ?
              (<>
                <TextField
                  autoFocus
                  fullWidth
                  onChange={this.onNameChange}
                  name="name"
                  value={editedItem.name}
                  margin="none"
                  InputProps={this.textInputClasses}
                  onKeyPress={this.enterPressed}
                />
                <div className={classes.crudIcons}>
                  <SaveIcon onClick={this.saveItem} className={classes.modifyIcon} />
                  <CancelIcon onClick={this.cancelEditing} className={classes.modifyIcon} />
                </div> </>
              )
              : (<>
                <span>
                  {item.name} {item.isEditable}
                </span>
                <div className={classes.crudIcons}>
                  <EditIcon onClick={this.startEditing} className={classes.modifyIcon} />
                  <DeleteIcon onClick={this.onDelete} className={classes.modifyIcon} />
                </div></>
              )}
          </div>
        </th>
        <td><TriForce /></td>
        <td><CheckBox checked={item.disabled} onFieldChange={this.disabledChange} /></td>
      </tr>
    );
  }
}

export default withStyles(styles)(SortableElement(PickListOptionRow));
