import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

export const getTaxSchedules = () => dispatch =>
  dispatch(callApi('RevenueConfiguration'))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results));

export const getTaxSchedule = taxScheduleId => dispatch =>
  dispatch(callApi(`RevenueConfiguration/${taxScheduleId}`))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

export const updateTaxSchedule = taxSchedule => dispatch =>
  dispatch(callApi(`RevenueConfiguration/${taxSchedule.id}`, { body: taxSchedule, method: 'PUT' }));

export const createTaxSchedule = taxSchedule => dispatch =>
  dispatch(callApi(`RevenueConfiguration`, { body: taxSchedule, method: 'POST' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

export const updateTaxScheduleOrder = (taxScheduleId, sortOrder) => dispatch =>
  dispatch(callApi(`taxSchedules/${taxScheduleId}/reorder`, { body: sortOrder, method: 'PUT' }));
