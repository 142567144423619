import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SmallGrid from 'Components/Grid/SmallGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import * as moment from 'moment';
import { buildGridCellModalLinkByIndex } from 'Components/buildGridCellLink';
import {
  Clear as DeleteIcon,
} from '@material-ui/icons';
import { provideIntlService } from '@progress/kendo-react-intl';

const styles = () => ({
  pointer: {
    cursor: 'pointer',
    display: 'flex',
  },
});

const htmlDescription = ({ dataItem }) => (
  <td>
    <div dangerouslySetInnerHTML={{ __html: dataItem.notes }} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} />
  </td>
);

class ShiftGrid extends Component {
  constructor(props) {
    super(props);
    this.formatter = provideIntlService(this);
    if (!this.props.onEdit) {
      return;
    }
    this.nameCell = buildGridCellModalLinkByIndex({
      onOpenModal: (item, shiftIndex) => this.props.onEdit(item, shiftIndex),
      text: item => item.name,
    });
  }

  deleteShift = props => {
    const shiftIndex = props.dataIndex;

    this.props.onDelete(shiftIndex);
  }

  totalCostCell = props => {
    /* TODO: calculate cost based on  props.dataItem
      Ex: if hourly, get diff between timeIn and timeOut, multiply by rate
    */

    return <td>{this.formatter.formatNumber(props.dataItem.numberOfEmployees * props.dataItem.rate, 'c2')}</td>;
  }

  render() {
    const {
      shifts,
      isEditing,
      className,
      classes,
    } = this.props;

    return (
      <SmallGrid
        data={shifts}
        editField="isEditing"
        className={className}
        style={{ overflow: 'hidden' }}
      >
        <GridColumn field="name" title="Shift" cell={this.nameCell} />
        <GridColumn field="numberOfEmployees" title="# Needed" />
        <GridColumn field="timeIn" title="Time In" cell={props => (
          <td>{moment(props.dataItem.timeIn).format('LT')}</td>
        )} />
        <GridColumn field="timeOut" title="Time Out" cell={props => (
          <td>{moment(props.dataItem.timeOut).format('LT')}</td>
        )} />
        <GridColumn field="rate" title="Rate" format="{0:C}" />
        <GridColumn field="total" title="Total Cost" format="{0:C}" cell={this.totalCostCell} />
        <GridColumn field="notes" title="Notes" cell={htmlDescription} />
        {isEditing && <GridColumn
          field=""
          width="45px"
          cell={props => (<td>
            <DeleteIcon className={classes.pointer} onClick={() => this.deleteShift(props)} />
          </td>)}
        />}
      </SmallGrid>
    );
  }
}

export default withStyles(styles)(ShiftGrid);
