import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import AppContainer from './App';
import registerServiceWorker from './registerServiceWorker';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './helpers';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { Theme1 } from 'shared/themes';

import { OidcProvider, loadUser } from 'redux-oidc';
import userManager from 'auth/userManager';

/* ------- Froala ------- */
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
//froala plugins... colors plugin
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/css/plugins/colors.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/link.min.js';
// Require Font Awesome. (required from some of the buttons in froala)
import 'font-awesome/css/font-awesome.css';
/* ------- Froala ------- */

/* ------- OIDC Debug ------ */
//import Oidc from 'oidc-client';
// Oidc.Log.logger = console;
// Oidc.Log.level = Oidc.Log.DEBUG;

loadUser(store, userManager);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={Theme1}>
      <OidcProvider store={store} userManager={userManager}>
        <PersistGate loading={null} persistor={persistor}>
          <AppContainer />
        </PersistGate>
      </OidcProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();
