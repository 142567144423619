import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  getAccountContacts,
  deleteAccountContact,
  setContactAsPrimary,
  addAccountContact,
} from 'actions/account';
import SectionContext from 'Components/Contexts/SectionContext';
import AccountContact from './AccountContact';
import SimpleDialog from 'Components/SimpleDialog';
import {
  ArrowDropDown,
  Add,
} from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import classNames from 'classnames';
import { searchContacts } from 'actions/contact';
import ComboBoxAsync from 'Components/ComboBoxAsync';
import QuickContactModal from 'contacts/QuickContactModal';
import AccountContext from './AccountContext';

const styles = theme => ({
  filterLabel: {
    color: theme.palette.grey[900],
    fontSize: 16,
  },
  filterRow: {
    display: 'flex',
    padding: 16,
    justifyContent: 'space-between',
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
});

class AccountContactsPanel extends Component {
  state = {
    isQuickContactOpened: false,
    editContact: {},
    startAddContact: false,
    contacts: [],
  };

  confirmDeleteFileDialog = React.createRef();

  componentDidMount() {
    this.getAccountContacts();
  }

  getAccountContacts = () => {
    return this.props.getAccountContacts(this.props.accountId)
      .then(contacts => {
        this.setState({ contacts });

        return contacts;
      });
  }

  onRemoveContact = contact => {
    this.confirmDeleteFileDialog.current.open().then(() => {
      const { contacts } = this.state;

      if (contacts[0].id === contact.id) {
        if (contacts.length === 1) {
          this.props.primaryContactUpdated({});
        } else {
          this.props.primaryContactUpdated(contacts[1]);
        }
      }

      this.props.deleteAccountContact(this.props.accountId, contact.id).then(() => {
        this.getAccountContacts();
      });
    });
  }

  onMarkPrimary = contact => {
    this.props.primaryContactUpdated(contact);
    this.props.setContactAsPrimary(this.props.accountId, contact.id).then(() => {
      this.getAccountContacts();
    });
  }

  onContactQuickPick = contactToAdd => {
    this.setState({ startAddContact: false });

    if (!contactToAdd || !contactToAdd.id) {
      return;
    }

    const {
      addAccountContact,
      accountId,
    } = this.props;

    addAccountContact(accountId, contactToAdd.id).then(() => {
      this.getAccountContacts();
    });
  }

  onEditContact = contact => {
    this.setState({
      isQuickContactOpened: true,
      editContact: contact,
    });
  }

  onQuickAddContact = payload => {
    const splitName = payload.split(' ');
    const firstName = splitName.length > 0 ? splitName[0] : '';
    const lastName = splitName.length > 1 ? splitName[1] : '';

    this.setState({
      isQuickContactOpened: true,
      editContact: { firstName, lastName, accountId: this.props.accountId },
    });
  }

  onLeaveAddContact = () => {
    this.setState({ startAddContact: false });
  }

  closeQuickContactModal = () => {
    this.setState({
      isQuickContactOpened: false,
      editContact: {},
    });
  }

  onStartAddContact = () => {
    this.setState({ startAddContact: true });
  }

  quickContactSaved = () => {
    const {
      editContact,
      contacts,
    } = this.state;

    this.closeQuickContactModal();

    const getAccountContacts = this.getAccountContacts();

    if (editContact.id === contacts[0].id) {
      getAccountContacts.then(contacts => {
        this.props.primaryContactUpdated(contacts[0]);
      });
    }
  }

  render() {
    const { classes, searchContacts, account } = this.props;
    const {
      contacts,
      isQuickContactOpened,
      editContact,
      startAddContact,
    } = this.state;

    return (

      <div className={classes.accountContacts}>
        {!startAddContact && <div className={classes.filterRow}>
          <div className={classes.filterLabel}>
            Filter <ArrowDropDown />
          </div>
          <HighlightButton onClick={this.onStartAddContact}>
            <Add />
          </HighlightButton>
        </div>}
        {startAddContact && <div>
          <ComboBoxAsync
            label="Add Contact"
            autoFocus={true}
            onBlur={this.onLeaveAddContact}
            getData={searchContacts}
            autoLoadOptions={true}
            resultCount={5}
            onSelect={this.onContactQuickPick}
            onCreateOption={this.onQuickAddContact}
          />
        </div>}
        <div className={classNames(classes.filterLabel, classes.filterRow, classes.borderBottom)}>
          Contacts ({contacts ? contacts.length : 0})
        </div>
        {contacts.length > 0 && contacts.map((contact, index) => {
          return contact && (
            <AccountContact
              onRemove={this.onRemoveContact}
              onMarkPrimary={this.onMarkPrimary}
              onEdit={this.onEditContact}
              key={contact.id}
              item={contact}
              isPrimary={index === 0}
              baseUrl="/contacts/"
            />);
        })}

        <SimpleDialog
          message="Are you sure you remove the contact from this account?"
          innerRef={this.confirmDeleteFileDialog}
        />
        <QuickContactModal
          isOpened={isQuickContactOpened}
          contact={editContact}
          onCancel={this.closeQuickContactModal}
          onSave={this.quickContactSaved}
          account={account}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getAccountContacts,
  deleteAccountContact,
  setContactAsPrimary,
  addAccountContact,
  searchContacts,
};

//can storybook this guy
export const AccountContactsPanelWithNoContext = withStyles(styles)(AccountContactsPanel);

export default connect(undefined, mapDispatchToProps)(props => {
  const {
    relationship: { entityId },
  } = useContext(SectionContext);
  const {
    account,
  } = useContext(AccountContext);

  return (
    <AccountContactsPanelWithNoContext
      accountId={entityId}
      account={account}
      {...props}
    />
  );
});
