import React, { Component, useContext } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import NotesPanel from 'Components/NotesPanel';
import FilesPanel from 'Components/FilesPanel';
import ActivityPanel from 'Components/ActivityPanel';
import BlueUnderlineTab from 'Components/Tabs/BlueUnderlineTab';
import OrderContext from './OrderContext';

const styles = theme => ({
  contactNotesTabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    width: 340,
  },
  tabsWrapper: {
    height: 40,
  },
  contentArea: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
});

const tabs = ['Notes', 'Files', 'Activity'];

class SideTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }

  handleChange = tabIndex => {
    this.setState({ tabIndex });
  };

  render() {
    const {
      classes,
      onChitChatUpdated,
      onFileAddSuccess,
      readOnly,
    } = this.props;
    const { tabIndex } = this.state;

    return (
      <Paper className={classes.contactNotesTabsRoot}>
        <div className={classes.tabsWrapper}>
          <BlueUnderlineTab
            onTabChange={this.handleChange}
            tabs={tabs}
            tabIndex={tabIndex}
          />
        </div>
        {tabIndex === 0 && <NotesPanel displayAddButton={true} onRefresh={onChitChatUpdated} readOnly={readOnly} />}
        {tabIndex === 1 && <FilesPanel onFileAddSuccess={onFileAddSuccess} readOnly={readOnly} />}
        {tabIndex === 2 && <ActivityPanel refresh={onChitChatUpdated} readOnly={readOnly} />}
      </Paper>
    );
  }
}

const OrderSideTabsWithContext = props => {
  const {
    onChitChatUpdated,
    onFileAddSuccess,
  } = useContext(OrderContext);

  return (<SideTab
    {...props}
    onChitChatUpdated={onChitChatUpdated}
    onFileAddSuccess={onFileAddSuccess}
  />);
};

const mapStateToProps = state => {
  return {
    readOnly: state.settings.allAreaSettings.ENABLE_CATEREASE_INTEGRATION
      ? state.settings.allAreaSettings.ENABLE_CATEREASE_INTEGRATION.value
      : true,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(OrderSideTabsWithContext));
