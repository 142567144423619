import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import ReactCrop, { Crop } from 'react-image-crop';
import ISetting from 'models/ISetting';

import "./CompanyLogo.css";
import {
  Publish as UploadImageIcon,
} from '@material-ui/icons';

import {
  IconButton,
} from '@material-ui/core';

const styles = createStyles((theme: Theme) => ({
  originImage: {
    maxHeight: '300px',
  },
  inputFile: {
    marginTop: 15,
    maxWidth: '425px',
  },
  companyLogo: {
    height: 'auto',
    position: 'relative',
    display: 'inline-block',
    '&:hover $imageButtonsContainer': {
      visibility: 'visible',
    },
    maxHeight: '175px',
  },
  imageButtonsContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    backgroundColor: 'rgba(107, 105, 102, 0.40);',
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  imageButton: {
    color: 'white',
    width: 60,
    height: 60,
  },
}));

interface IProps extends WithStyles {
  setting: ISetting,
  crop?: Crop,
  logoSettingOnChange: (logoBlob: any, logoFileName: string) => void,
  logoBlob: any,
}

interface IState {
  src: string,
  fileType: string,
  fileName: string,
  crop: Crop,
  zoom: number,
  croppedImageUrl: string,
  landscape: boolean,
}

const defaultState: IState = {
  src: null,
  fileType: '',
  fileName: '',
  landscape: false,
  crop: {
    unit: '%',
    width: 100,
    height: 100,
  },
  zoom: 1,
  croppedImageUrl: null,
}

class CompanyLogo extends Component<IProps, IState> {
  state = defaultState;
  imageRef: any;
  fileUrl: string;
  croppedBlob: any;



  onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result as string, fileType: file.type, fileName: file.name })
      );
      reader.readAsDataURL(file);
    }
  };

  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: Crop, percentCrop: Crop) => {
    this.setState({ crop });
  };

  onZoomChange = (zoom: number) => {
    this.setState({ zoom });
  }

  async makeClientCrop(crop: Crop) {
    const { logoSettingOnChange } = this.props;
    const { fileName } = this.state;

    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        fileName,
      ) as string;

      this.setState({ croppedImageUrl });
      logoSettingOnChange(this.croppedBlob, fileName);
    }
  }

  getCroppedImg(image: any, crop: Crop, fileName: string) {
    const { fileType } = this.state;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        this.croppedBlob = blob;
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        (blob as any).name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, fileType);
    });
  }

  public componentDidMount() {
    const { setting } = this.props;

    this.setState({ croppedImageUrl: setting ? setting.value : '' });
  }

  public componentDidUpdate(prevProps: IProps) {
    const { setting, logoBlob } = this.props;
    const settingSavedOrCanceled = !!prevProps.logoBlob && !logoBlob;

    if (prevProps.setting !== setting || settingSavedOrCanceled) {
      this.setState({
        ...defaultState,
        croppedImageUrl: setting ? setting.value : 'https://via.placeholder.com/800x400.png?text=Upload+your+image',
      });
    }
  }

  public render() {
    const { classes } = this.props;
    const { croppedImageUrl, src, crop } = this.state;

    return (
      <div>
        <div className={classes.inputFile}>
          {!src && (<>

            <div
              className={classes.companyLogo}
            >
              <img
                alt="Company Logo"
                className={classes.companyLogo}
                src={croppedImageUrl} />
              <div>


                <div className={classes.imageButtonsContainer}>
                  <IconButton component="label" onClick={(e: any) => (e.target.value = null)}>
                    <UploadImageIcon className={classes.imageButton} />
                    <input
                      accept="image/*"
                      className={classes.fileInput}
                      type="file"
                      onChange={this.onSelectFile}
                      hidden
                    />
                  </IconButton>        </div>
              </div></div>  </>)}
        </div>

        <div className={classes.inputFile}>
          {src && (<>
            <ReactCrop
              className={classes.originImage}
              src={src}
              crop={crop}
              ruleOfThirds
              keepSelection={true}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </>)}
        </div>
      </div >
    );
  }
}

export default withStyles(styles)(CompanyLogo);
