import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import { logChitChatSystemMessage } from 'actions/chitchat';

export default class NoteService {

  constructor(dispatch, relationship, chitChatRecordName) {
    this.dispatch = dispatch;
    this.entityId = relationship && relationship.entityId;
    this.entityType = relationship && relationship.entityType;
    this.notesApiUrl = `notes/${this.entityType}/${this.entityId}`;
    this.chitChatRecordName = chitChatRecordName;
  }

  get = () => {
    if (this.entityId && this.entityType) {
      return this.dispatch(callApi(this.notesApiUrl))
        .then(response => ParseJsonOnSuccessOrStop(this.dispatch, response));
    } else {
      return Promise.reject('Relationship not detected for Notes fetch');
    }
  }

  add = note =>
    this.dispatch(callApi(this.notesApiUrl, { body: note }))
      .then(this.logChitChat(`Note Added: ${note.subject}`));

  update = note =>
    this.dispatch(callApi(`${this.notesApiUrl}/${note.id}`, { method: 'put', body: note }));

  delete = noteId =>
    this.dispatch(callApi(`${this.notesApiUrl}/${noteId}`, { method: 'delete' }));

  logChitChat = message => {
    if (this.entityId && this.entityType) {
      const relationship = {
        id: this.entityId,
        entityType: this.entityType,
      };

      this.dispatch(logChitChatSystemMessage(message, relationship, this.chitChatRecordName || ''));
    }
  }

}
