import React, { Component } from 'react';
import {
  TextField as MaterialTextField,
  FormLabel as Label,
  InputAdornment,
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MuiPhoneNumber from 'material-ui-phone-number';
import IconButton from 'Components/Buttons/IconButton';
import { ArrowDropUp as AddIcon } from '@material-ui/icons';
import { ArrowDropDown as SubtractIcon } from '@material-ui/icons';

const styles = theme => ({
  flexGrowWithPad: {
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
  },
  flexGrowNoPad: {
    flexGrow: 1,

  },
  label: {
    color: theme.palette.grey[200],
  },
  input: {
    color: theme.palette.grey[700],
  },
  phone: {
    marginTop: 16,
    marginBottom: 8,
  },
  numberButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  numberButton: {
    padding: 0,
    height: '12px',
    color: 'black',
  },
});

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      tabIndex: '-1',
    },
  },
});

class TextField extends Component {

  fieldChanged = event => {
    console.log(event);

    if (!this.props.onFieldChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    this.props.onFieldChange(event.target.value);
  }

  increaseNumber = () => {
    const { value, incrementStep = 1 } = this.props;

    const intValue = parseInt(value) || 0;

    this.props.onFieldChange(intValue + incrementStep);
  }

  decreaseNumber = () => {
    const { value, incrementStep = 1 } = this.props;

    const intValue = parseInt(value) || 0;

    this.props.onFieldChange(intValue - incrementStep);
  }

  onBlur = () => {
    this.props.onBlur && this.props.onBlur();
  }

  render() {
    const {
      classes,
      disabled,
      value,
      fieldName,
      label,
      placeholder,
      error,
      type,
      inputAdornment,
      className,
      inputComponent,
      margin,
      inputRef,
      helperText,
      endAdornment,
      maxLength,
      minValue,
      maxValue,
      autoFocus = false,
      multiline = false,
      noPad,
      inputStyle,
      required,
      rows,
    } = this.props;

    return <> {type === 'phone' &&
      <MuiThemeProvider theme={theme}>
        <MuiPhoneNumber
          defaultCountry={'us'}
          onlyCountries={['us', 'ca']}
          value={value || ''}
          name={fieldName}
          placeholder={placeholder}
          label={<Label className={classes.label} >{label}</Label>}
          disableAreaCodes={true}
          disabled={disabled}
          inputClass={classNames(noPad ? classes.noPad : classes.flexGrowWithPad, classes.phone, className)}
          onChange={this.props.onFieldChange}
          error={error} />
      </MuiThemeProvider>}

      {type === 'number' && <MaterialTextField
        label={label}
        rows={rows}
        multiline={multiline}
        placeholder={placeholder}
        error={error}
        className={classNames(noPad ? classes.noPad : classes.flexGrowWithPad, classes.numberInput, className)}
        onChange={this.fieldChanged}
        name={fieldName}
        margin={margin || "normal"}
        value={value || ''}
        disabled={disabled}
        type='tel' // tel limits entry to only digits
        helperText={helperText}
        required={required}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        InputProps={{
          classes: {
            root: classes.input,
            disabled: classes.input,
          },
          inputComponent: inputComponent,
          onBlur: this.onBlur,
          startAdornment: inputAdornment,
          endAdornment: <InputAdornment position="end">
            <div className={classes.numberButtonsContainer}>
              <IconButton height={15} icon={AddIcon} className={classes.numberButton} onClick={this.increaseNumber} />
              <IconButton height={15} icon={SubtractIcon} className={classes.numberButton} onClick={this.decreaseNumber} />
            </div>
          </InputAdornment>,
        }}
        inputProps={{
          maxLength: maxLength,
          min: minValue,
          max: maxValue,
          style: inputStyle,
        }}
        inputRef={inputRef}
        autoFocus={autoFocus}
        onFocus={event => {
          if (autoFocus && event.target.value) {
            event.target.select();
          }
        }}
      />
      }

      {type !== 'phone' && type !== 'number' && <MaterialTextField
        label={label}
        rows={rows}
        multiline={multiline}
        placeholder={placeholder}
        error={error}
        className={classNames(noPad ? classes.noPad : classes.flexGrowWithPad, className)}
        onChange={this.fieldChanged}
        name={fieldName}
        margin={margin || "normal"}
        value={value || ''}
        disabled={disabled}
        type={type}
        helperText={helperText}
        required={required}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        InputProps={{
          classes: {
            root: classes.input,
            disabled: classes.input,
          },
          inputComponent: inputComponent,
          onBlur: this.onBlur,
          startAdornment: inputAdornment,
          endAdornment: endAdornment,
        }}
        inputProps={{
          maxLength: maxLength,
          min: minValue,
          max: maxValue,
          style: inputStyle,
        }}
        inputRef={inputRef}
        autoFocus={autoFocus}
        onFocus={event => {
          if (autoFocus && event.target.value) {
            event.target.select();
          }
        }}
      />}
    </>;
  }
}

export default withStyles(styles)(TextField);
