import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SortableMenuSelection from 'menu/SortableMenuSelection';
import NewButton from 'menu/NewButton';
import SelectPackagesModal from 'Components/SelectPackagesModal';
import { getTaxConfig } from 'actions/booking';
import { addMenuCategoryToEvent } from 'actions/eventMenu';

const styles = theme => ({
  menuSelection: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.paper,
    maxHeight: 400,
    overflowY: 'auto',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

class WizardMenuSelection extends Component {
  state = {
    taxConfig: {},
  };

  componentDidMount() {
    // this.getDefaultTaxConfig();
    this.addMenuSection();
  }

  addMenuSection = () => {
    const {
      onMenuSectionAdded = () => { },
      eventMenuCategories,
    } = this.props;

    if (eventMenuCategories && eventMenuCategories.length === 0) {
      this.props.addMenuCategoryToEvent().then(onMenuSectionAdded);
    }
  }

  openSelectPackagesModal = () => {
    this.setState({ isSelectPackagesModalOpen: true });
  }

  closeSelectPackagesModal = () => {
    this.setState({ isSelectPackagesModalOpen: false });
  }

  getDefaultTaxConfig = () => {
    // const {
    // getTaxConfig,
    // } = this.props;

    // getTaxConfig(booking.id).then(taxConfig => this.setState({ taxConfig }));
  }

  toolBar = () => {
    return (<NewButton
      openSelectPackagesModal={this.openSelectPackagesModal}
      newCategoryName="Unnamed Category"
    />
    );
  }

  render() {
    const {
      classes,
      focusedEvent,
    } = this.props;

    const {
      isSelectPackagesModalOpen,
      // taxConfig,
    } = this.state;

    return (
      <div className={classes.menuSelection}>
        {focusedEvent &&
          <SortableMenuSelection
            toolBar={this.toolBar()}
            useDefaultColumns={true}
            isEditing={true}
            storeType="wizard"
          />
        }
        {isSelectPackagesModalOpen &&
          <SelectPackagesModal
            isOpened={true}
            onModalClosed={this.closeSelectPackagesModal}
            defaultQuantity={focusedEvent.estimatedGuestCount}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    booking: {
      wizard: {
        storeBooking,
        focusedEventIndex,
        focusedEvent,
      },
    },

  } = state;

  return {
    storeBooking,
    focusedEventIndex,
    focusedEvent,
    eventMenuCategories: focusedEvent.bookingEventMenuCategories,
  };
};

const mapDispatchToProps = {
  getTaxConfig,
  addMenuCategoryToEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WizardMenuSelection));
