import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from 'Components/Grid/Grid';
import { GridColumn } from '@progress/kendo-react-grid';
import Checkbox from 'Components/Checkbox';
import {
  Edit as EditIcon,
} from '@material-ui/icons';

const styles = theme => ({
  table: {
    borderColor: theme.palette.grey[50],
    '& tr': {
      borderTop: 'none',
    },
    '& th': {
      textAlign: 'center',
      maxWidth: 200,
    },
    '& td': {
      borderWidth: 1,
      textAlign: 'center',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
    '& tbody th': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
  },
});

const RevenueTypeRateGrid = ({ classes, revenueTypeRates, onEdit }) => {
  return (
    <Grid
      items={revenueTypeRates}
    >

      <GridColumn field="revenueType.name" title="Name" />
      <GridColumn field="" title="Tax" cell={
        props => {
          console.log(props);

          return (<td>
            {props.dataItem.locationBasedTaxPercentage ? 'Automatic based on location' : `${(props.dataItem.taxPercentage * 100).toFixed(2)}%`}
          </td>);
        }} />
      <GridColumn format="{0:p2}" field="serviceChargePercentage" title="Service" />
      <GridColumn
        format="bool"
        width="100px"
        field="serviceChargePercentage"
        title="Tax Service"
        cell={props => (
          <td style={{ textAlign: 'center', padding: 0 }}>
            <Checkbox
              disabled={true}
              checked={props.dataItem.taxServChg}
            />
          </td>
        )}
      />
      <GridColumn format="{0:p2}" field="gratuityPercentage" title="Gratuity" />
      <GridColumn
        format="bool"
        width="100px"
        field="gratuityPercentage"
        title="Tax Gratuity"
        cell={props => (
          <td style={{ textAlign: 'center', padding: 0 }}>
            <Checkbox
              disabled={true}
              checked={props.dataItem.taxGrat}
            />
          </td>
        )}
      />
      <GridColumn field="" title="" width="100px" cell={props => (
        <td className={classes.crudIcons}>
          <EditIcon
            className={classes.modifyIcon}
            onClick={() => onEdit(props.dataItem)}
            aria-label={`Edit Revenue Type Rate`}
          />
        </td>
      )}
      />
    </Grid>
  );
};

export default withStyles(styles)(RevenueTypeRateGrid);
