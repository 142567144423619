import React, { Component } from 'react';
import IndexSelectionBar from 'Components/IndexSelectionBar';
import { connect } from 'react-redux';
import {
  setFocusedEventIndex,
} from 'actions/booking';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

const eventLabel = [{
  name: 'EVENTS',
  id: 0,
}];

const styles = theme => {
  return {
    eventButton: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
    },
    eventTime: {
      fontSize: '12px',
      fontWeight: '500',
      color: 'grey',
    },
  };
};

class EventBar extends Component {
  onSelectionChange = index => {
    this.props.setFocusedEventIndex(index);
  }

  generateNavigationItems = () => {
    const {
      events,
      classes,
    } = this.props;

    return events.map(event => ({
      name: (<div className={classes.eventButton}>
        <span>{event.name}</span>
        <span className={classes.eventTime}>{`${moment(event.startDateTime).format('l')}`}</span>
        <span className={classes.eventTime}>{`${moment(event.startDateTime).format('LT')} - ${moment(event.endDateTime).format('LT')}`}</span>
      </div>),
    }));
  }

  render() {
    const {
      focusedEventIndex,
    } = this.props;

    return (
      <IndexSelectionBar
        onSelectionChange={this.onSelectionChange}
        leftFixedOptions={eventLabel}
        options={this.generateNavigationItems()}
        selectedOptionIndex={focusedEventIndex}
        showConfig={false}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    booking: {
      wizard: {
        storeBooking,
        focusedEventIndex,
        focusedEvent,
      },
    },
  } = state;

  return {
    storeBooking,
    events: storeBooking.events,
    focusedEventIndex,
    focusedEvent,
  };
};

const mapDispatchToProps = {
  setFocusedEventIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventBar));
