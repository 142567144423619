import React, { Component, useContext } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';
import { withStyles } from '@material-ui/core/styles';
import SectionContext from 'Components/Contexts/SectionContext';
import FilesService from 'actions/FilesService';
import IconBorderButton from 'Components/Buttons/IconBorderButton';
import { connect } from 'react-redux';
import {
  Description,
} from '@material-ui/icons';
import {
  Menu,
  MenuItem,
  Button,
  Tooltip,
} from '@material-ui/core';
import EmailTemplatesModal from 'Components/EmailTemplatesModal';
import {
  addToRecents,
} from 'actions/recents';
import {
  mergeTemplateWithTags,
} from 'actions/emailTemplates';
import './RichTextEmailEditorWithToolbar.css';

const styles = theme => ({
  emailEditor: {
    flexGrow: 1,
    alignItems: 'flex-start',
    '& .fr-toolbar': {
      paddingLeft: 50, //matches SectionHeader look
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.grey[800], //gets used in the fonts in drop down boxes
      border: 0,
      borderRadius: 0,
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    '& .fr-box.fr-basic.fr-top .fr-wrapper': {
      paddingLeft: 12,
      border: 0,
    },
    '& .fr-box .fr-wrapper.show-placeholder .fr-placeholder': {
      paddingLeft: '32px !important',
    },
    '& .second-toolbar': {
      display: 'none',
    },
    '& .fr-popup': {
      // fixes FROALA bug where file-upload-layer appears at the top location on second file upload (where file browser window was last displayed)
      top: '34px !important',
    },
    position: 'relative',
  },
  smallerIcon: {
    fontSize: 14,
  },
  biggerButton: {
    height: 23,
    width: 23,
  },
  noBgOnFocus: {
    '&:focus': {
      backgroundColor: 'transparent !important',
    },
  },
  emailTemplateButton: {
    background: 'transparent',
    color: '#333333',
    outline: 'none',
    border: 0,
    lineHeight: 1,
    margin: '4px 2px',
    padding: 0,
    borderRadius: '4px',
    boxSizing: 'border-box',
    height: 40,
    minWidth: 40,
  },
  emailTemplateIconContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
  },
  emailTemplateIconBar: {
    position: 'absolute',
    paddingLeft: 20,
  },
});

const defaultConfig = {
  charCounterCount: false,
  height: '100%',
  attribution: false,
  toolbarButtons: ['fontFamily', 'fontSize', 'bold', 'italic', 'underline', 'formatOL', 'align', 'insertHR', 'insertImage', 'customFileManager', 'insertLink'],
  pluginsEnabled: ['fontFamily', 'fontSize', 'align', 'insertHR', 'image', 'file', 'customPlugin', 'link'],
  popupButtons: ['insertFile', 'popupClose'],
  width: '100%',
  key: 'zEG4iH4B11B6D7F4A3g1JWSDBCQG1ZGDf1C1d2JXDAAOZWJhE5B4E4C3F2H3C11A4C4D4==', //keep key same as RichTextEditor
  placeholderText: 'Start typing...',
  position: 'relative',
  linkStyles: {
    style1: 'Color and Underline',
    style2: 'Bold',
  },
};

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};
const transformOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

class RichTextEmailEditorWithToolbar extends Component {
  constructor(props) {
    super(props);
    this.config = {
      ...defaultConfig,
      ...props.config,
      imageUploadURL: props.filesService.fileUploadUrl(),
      fileUploadURL: props.filesService.fileUploadUrl(),
      events: {
        'file.uploaded': response => {
          return this.handleUpload(response);
        },
      },
      requestHeaders: { Authorization: props.authorization },
    };
    this.state = {
      templatesModalOpened: false,
    };
  }

  componentDidMount() {
    this.getFiles(true, this.props.autoAttachFileId);
  }

  componentDidUpdate(prevProps) {
    const { autoAttachFileId } = this.props;

    if (autoAttachFileId && autoAttachFileId !== prevProps.autoAttachFileId) {
      this.getFiles(false, autoAttachFileId); //you created a print from the e-mail page
    }
  }

  handleUpload = response => {
    const responseObj = JSON.parse(response);
    const { url, name } = responseObj;

    this.getFiles(false);
    this.insertFile({ url, name });

    return false;
  }

  insertFile = file => {
    const editor = this.initControls.getEditor();
    const insert = () => {
      editor.html.insert('<br/>', true);
      editor.file.insert(file.url, file.name, { link: file.url });
      editor.html.insert('<br/>', true);
      editor.selection.setAtStart(editor.$el.get(0));
      editor.selection.restore();
    };

    if (editor.file && editor.selection) {
      insert();
    } else {
      setTimeout(insert); //onLoad the editor.file is not set, but pausing for a moment lets it initialize okay ¯\_(ツ)_/¯
    }
  }

  openTemplates = event => {
    this.setState({ templateOpenedBy: event.currentTarget });
  }

  closeTemplatesMenu = () => {
    this.setState({ templateOpenedBy: null });
  }

  openTemplatesModal = () => {
    this.setState({ templatesModalOpened: true, templateOpenedBy: null });
  }

  runRecentTemplate = recentTemplate => () => { //TODO
    this.closeTemplatesMenu();
    // this.props.addToRecents(recentTemplate, recentTemplate.url, 'emailTemplate');
    // this.performMerge(recentTemplate.url);
  }

  useTemplate = selectedTemplate => {
    this.setState({ templatesModalOpened: false });
    if (selectedTemplate) {
      this.performMerge(selectedTemplate.id);
      this.props.onTemplateSelected(selectedTemplate);
    }
  }

  performMerge = templateId => {
    const { relationship, mergeTemplateWithTags, onFieldChange } = this.props;

    mergeTemplateWithTags(templateId, relationship).then(mergedTemplate => {
      onFieldChange(mergedTemplate);
      // this.props.addToRecents(selectedTemplate, html, 'emailTemplate'); TODO
    });
  }

  getFiles = (initToolBar, autoAttachFileId) => {
    const { filesService } = this.props;

    filesService.get().then(files => {
      this.files = this.sortFiles(files);

      if (initToolBar) {
        this.initToolBar();
      }

      const editor = this.initControls.getEditor();

      if (!initToolBar) {
        editor.customPlugin.initPopup(files);
      }

      if (autoAttachFileId) {
        const autoAttachFile = files.find(f => f.id === autoAttachFileId);

        if (autoAttachFile) {
          this.insertFile(autoAttachFile);
        }
      }
    });
  }

  _files = () => {
    return this.files;
  }

  sortFiles = files => {
    if (files && files.length) {
      files.sort((a, b) => {
        const aName = a.name && a.name.toUpperCase();
        const bName = b.name && b.name.toUpperCase();

        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }

        return 0;
      });

      return files;
    }
  }

  //should only get called once!
  initToolBar = () => {
    const _files = this._files;

    const initControls = this.initControls;

    initControls.initialize();

    Froalaeditor.POPUP_TEMPLATES["customPlugin.popup"] = '[_BUTTONS_][_CUSTOM_LAYER_]';

    Froalaeditor.PLUGINS.customPlugin = function (editor) {

      function initPopup(files) {
        let popup_buttons = '';

        if (editor.opts.popupButtons.length > 1) {
          popup_buttons += '<div class="fr-buttons">';
          popup_buttons += editor.button.buildList(editor.opts.popupButtons);
          popup_buttons += '</div>';
        }
        const template = {
          buttons: popup_buttons,
          custom_layer: `<div id='dropdown-menu-download-1' class='fr-dropdown-menu' role='listbox' aria-labelledby='download-1' aria-hidden='false'>
            <div class='fr-dropdown-wrapper' role='presentation' style='border: solid 1px #f2f2f2; border-radius: 4px; margin: 10px;'>
              <div class='fr-dropdown-content' role='presentation'>
                <div style='background-color: #f2f2f2;; padding: 10px;'>
                  <strong>Attach File</strong>
                </div>
                ${files === undefined || files.length < 1 ? 'No files available' : ''}
                <ul class='fr-dropdown-list' role='presentation' style='max-height: 120px; overflow-y: scroll;'>
                  ${files && files.length ?
            files.map(f => "<li role='presentation' style='padding: 5px 10px;'><a class='fr-command' tabindex='-1' role='option' data-cmd='attachFile' data-param1=" + f.id + " title='" + f.name + "' aria-selected='false'>" + f.name + "</a></li>").join('')
            : ''}
                </ul>
              </div>
            </div>
          </div>`,
        };
        const $popup = editor.popups.create('customPlugin.popup', template);

        return $popup;
      }

      function showPopup(files) {
        let $popup = editor.popups.get('customPlugin.popup');

        if (!$popup) $popup = initPopup(files);
        editor.popups.setContainer('customPlugin.popup', editor.$tb);
        const $btn = editor.$tb.find('.fr-command[data-cmd="customFileManager"]');
        const left = $btn.offset().left + $btn.outerWidth() / 2;
        const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

        editor.popups.show('customPlugin.popup', left, top, $btn.outerHeight());
      }

      function hidePopup() {
        editor.popups.hide('customPlugin.popup');
      }

      return {
        showPopup: showPopup,
        hidePopup: hidePopup,
        initPopup: initPopup,
      };
    };

    Froalaeditor.DefineIcon('fileAttachIcon', { PATH: 'M13 14c0 2.21-1.79 4-4 4s-4-1.79-4-4V3c0-1.66 1.34-3 3-3s3 1.34 3 3v9c0 1.1-.9 2-2 2s-2-.9-2-2V4h1v8c0 .55.45 1 1 1s1-.45 1-1V3c0-1.1-.9-2-2-2s-2 .9-2 2v11c0 1.66 1.34 3 3 3s3-1.34 3-3V4h1v10z', template: 'svg' });
    Froalaeditor.RegisterCommand('customFileManager', {
      title: 'Attach From Files',
      icon: 'fileAttachIcon',
      plugin: 'customPlugin',
      callback: function () {
        this.customPlugin.showPopup(_files());
      },
    });

    Froalaeditor.DefineIcon('popupClose', { NAME: 'sue', SVG_KEY: 'close' });
    Froalaeditor.RegisterCommand('popupClose', {
      title: 'Close',
      callback: function () {
        this.customPlugin.hidePopup();
      },
    });

    Froalaeditor.RegisterCommand('attachFile', {
      callback: (cmd, val) => {
        const file = _files().find(f => {
          // eslint-disable-next-line eqeqeq
          return f.id == val; // val is string, so can't use === operator
        });

        if (file) {
          console.log(file);

          this.insertFile(file);
        }
      },
    });
  }

  handleManualController = initControls => {
    this.initControls = initControls;
  }

  render() {
    const {
      classes,
      onFieldChange,
      value,
      EmailTemplate,
    } = this.props;

    const {
      templateOpenedBy,
      templatesModalOpened,
    } = this.state;

    return (
      <div className={classes.emailEditor}>
        <div className={classes.emailTemplateIconContainer}>
          <div className={classes.emailTemplateIconBar}>
            <Tooltip title="Email Template">
              <Button className={classes.emailTemplateButton}>
                <IconBorderButton
                  variant="right"
                  className={classes.biggerButton}
                  onClick={this.openTemplates}
                  isActive={Boolean(templateOpenedBy)}
                >
                  <Description className={classes.biggerButton} />
                </IconBorderButton>
              </Button>
            </Tooltip>
            <Menu
              id="template-options"
              anchorEl={templateOpenedBy}
              open={Boolean(templateOpenedBy)}
              onClose={this.closeTemplatesMenu}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
            >
              {EmailTemplate.length && <MenuItem
                className={classes.noBgOnFocus}
              >RECENT TEMPLATES</MenuItem>}
              {EmailTemplate.map(template =>
                <MenuItem
                  onClick={this.runRecentTemplate(template)}
                  key={template.id}
                >{template.name}</MenuItem>
              )}
              <MenuItem onClick={this.openTemplatesModal}>Choose New Template</MenuItem>
            </Menu>
            <EmailTemplatesModal
              isOpened={templatesModalOpened}
              onUseTemplate={this.useTemplate}
            />
          </div>
        </div>
        <FroalaEditor
          config={this.config}
          tag='textarea'
          model={value}
          onModelChange={onFieldChange}
          onManualControllerReady={this.handleManualController}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    oidc,
    recents: { EmailTemplate },
  } = state;

  if (oidc.user) {
    const token = oidc.user.access_token;

    return {
      authorization: `Bearer ${token}`,
      EmailTemplate,
    };
  }

  return {
    EmailTemplate,
  };
};

const mapDispatchToProps = {
  addToRecents,
  mergeTemplateWithTags,
};

const RichTextEmailEditorWithToolbarWithContext = connect()(props => {
  const {
    relationship,
  } = useContext(SectionContext);

  const {
    dispatch,
    ...rest
  } = props;

  return (<RichTextEmailEditorWithToolbar
    {...rest}
    filesService={new FilesService(relationship, dispatch)}
  />);
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RichTextEmailEditorWithToolbarWithContext));
