import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContactSearchResults from './ContactSearchResults';
import MenuItemSearchResults from './MenuItemSearchResults';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    background: 'white',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 85%)',
  },
});

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  skeleton = props => (<ContentLoader
    speed={2}
    width={150}
    height={40}
    viewBox="0 0 150 40"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="47" y="4" rx="3" ry="3" width="88" height="6" />
    <rect x="50" y="16" rx="3" ry="3" width="52" height="6" />
    <circle cx="20" cy="20" r="20" />
    <rect x="45" y="28" rx="3" ry="3" width="88" height="6" />
    <rect x="171" y="156" rx="0" ry="0" width="1" height="0" />
  </ContentLoader>);

  render() {
    const { classes, loading } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="subtitle1" gutterBottom>
          Contacts:
        </Typography>
        {loading ? this.skeleton() : <ContactSearchResults />}
        <Typography variant="subtitle1" gutterBottom>
          Menu Items:
        </Typography>
        {loading ? this.skeleton() : <MenuItemSearchResults />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    search: {
      loading,
    } } = state;

  return {
    loading,
  };
};

export default connect(mapStateToProps, undefined)(withStyles(styles)(SearchResults));
