import { EVENT_TYPE_FILTER_CLICK, UPDATING_CALENDAR_EVENTS, SELECTED_DATE_CHANGED } from 'actions/constants';
import { defaultSelectedEventTypes } from 'constants/eventTypes';

const initialState = {
  selectedEventTypes: defaultSelectedEventTypes,
  updating: false,
  selectedDate: undefined,
};

export function calendar(state = initialState, action) {
  switch (action.type) {
    case EVENT_TYPE_FILTER_CLICK:
      const { eventType, isChecked } = action;
      let selectedEventTypes = { ...state.selectedEventTypes };

      console.log(eventType, isChecked);

      selectedEventTypes[eventType] = isChecked;

      return {
        ...state,
        selectedEventTypes,
      };

    case UPDATING_CALENDAR_EVENTS:
      const { updating } = action;

      return {
        ...state,
        updating,
      };
    case SELECTED_DATE_CHANGED:
      const { selectedDate } = action;

      return {
        ...state,
        selectedDate,
      };
    default:
      return state;
  }
}
