import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import TextField from 'Components/TextField';
import { withRouter } from 'react-router-dom';
import {
  Search,
  Clear,
} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  search,
  clearSearch,
} from 'actions/search';
import classNames from 'classnames';
import SearchResults from './SearchResults';
import _ from 'lodash';
import Grow from '@material-ui/core/Grow';

const styles = theme => ({
  searchBar: {
    [theme.breakpoints.down('small')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
    marginLeft: 12,
    marginRight: 12,
  },
  searchContainer: {
    position: 'relative',
    width: '100%',
    paddingLeft: '100px',
    paddingRight: '100px',
  },
  searchResultsPopOver: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    top: '64px',
    left: 0,
    right: 0,
    paddingLeft: '100px',
    paddingRight: '100px',
  },
  searchInput: {
    width: '100%',
  },
});

class SearchBoxNoStyle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchResults: {
        total: 0,
      },
    };
  }

  onKeyPress = e => {
    if (e.key === 'Enter') {

    }
  }

  searchFocus = () => {
    this.setState({ active: true });
  }

  clearSearch = () => {
    this.setState({ searchText: '' });
    this.props.clearSearch();
  }

  searchChange = value => {
    this.setState({ searchText: value });

    this.search(value);
  }

  search = _.debounce(query => {
    console.log('searching');
    this.props.search(query);
  }, 300);

  render() {
    const { classes } = this.props;
    const { searchText } = this.state;

    const showSearchResults = searchText.length > 0;

    return (
      <div className={classNames(classes.searchContainer, classes.searchBar)}>
        <TextField
          className={classes.searchInput}
          placeholder="Start typing to see results..."
          value={searchText}
          minValue={0}
          noPad={true}
          onFieldChange={this.searchChange}
          inputAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          endAdornment={
            searchText.length > 0 ? <InputAdornment position="end">
              <IconButton onClick={this.clearSearch}>
                <Clear />
              </IconButton>
            </InputAdornment> : <></>
          }
        />
        <Grow in={showSearchResults} style={{ display: showSearchResults ? 'block' : 'none' }}>
          <div className={classNames(classes.searchResultsPopOver)}>
            <SearchResults />
          </div>
        </Grow>
      </div>
    );
  }
}

const mapDispatchToProps = {
  search,
  clearSearch,
};

export const SearchBox = withStyles(styles)(SearchBoxNoStyle);

export default withRouter(connect(undefined, mapDispatchToProps)(SearchBox));
