import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import './CoverTimePicker.css';
import { Datepicker } from '@mobiscroll/react';

const styles = theme => ({
  pickContainer: {
    flexGrow: 1,
    margin: '0px 16px 8px',
  },
});

class CoverTimePicker extends Component {

  state = { timeOptions: [] };

  componentDidMount() {

  }

  onTimeChange = value => {

    if (!this.props.onFieldChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    this.props.onFieldChange(value);

  }

  render() {
    const {
      value,
      className,
      label,
      name,
      classes,
      minTime,
      maxTime,
      minWidth,
      disabled,
      error,
      placeholder,
      ...rest
    } = this.props;

    return (
      <Datepicker
        className={className}
        cssClass={className}
        controls={['time']}
        timeFormat="h:mm A"
        minTime={minTime}
        maxTime={maxTime}
        value={value}
        theme='material'
        disabled={disabled}
        onChange={event => this.onTimeChange(event.valueText)}
        inputProps={{
          label: label,
          labelStyle: 'floating',
          inputStyle: 'underline',
          placeholder: placeholder,
        }}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(CoverTimePicker);
