import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Header from 'Components/Header';
import RoomGrid from './RoomGrid';
import {
  getRevenueTypes,
} from 'actions/item';
import {
  clearCurrentCoupon,
} from 'actions/coupon';

const styles = theme => ({

  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '3%',
    marginTop: '20px',
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
  },
  mainContent: {
    display: 'flex',
    flex: '1 0 auto',
  },
  appBarSpacing: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class Rooms extends Component {
  allCategories = {
    name: 'ALL',
    id: 0,
  };

  state = {
    selectedOption: this.allCategories,
    summary: {
      totalCount: 0,
      totalPrice: 0,
    },
    isEditingCategories: false,
  };

  componentDidMount() {
    this.props.getRevenueTypes();
  }

  closeModal = () => {

  }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <>
        <Header>Rooms</Header>
        <div className={classes.mainContent}>
          <div className={classes.gridContainer}>
            <RoomGrid />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    api,
    coupons: {
      data,
      currentCoupon,
    },
  } = state;

  return {
    revenueTypes: api && api.revenueTypes ? api.revenueTypes : [],
    currentCoupon,
    usedCouponCodes: data,
  };
};

const mapDispatchToProps = {
  getRevenueTypes,
  clearCurrentCoupon,
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Rooms)));
