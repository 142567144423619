import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Popover,
  TextField,
} from '@material-ui/core';
import {
  deleteEventMenuCategory,
  addLibraryItems,
  removeLibraryItem,
  editEventMenuCategoryItem,
  updateEventMenuCategory,
  getSetsOfModifiers,
  updateSetsOfModifiers,
} from 'actions/eventMenu';
import {
  toggleEditMode,
  storeBookingSetIsEditing,
} from 'actions/booking';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import MenuGrid from './MenuGrid';
import BulkModifiersModal from './BulkModifiersModal';
import MenuItemPickerModal from './MenuItemPickerModal';
import MenuCategoryContext from './MenuCategoryContext';
import BookingContext from 'bookings/BookingContext';
import MenuItemSelectionModal from 'portal/admin/menumanagement/MenuItemSelectionModal/MenuItemSelectionModal';
import {
  Clear as DeleteIcon,
  PlayForWork as SelectNewIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import _ from 'lodash';

const styles = theme => ({
  menuExpansionIconButton: {
    left: 0,
  },
  menuExpansionSummary: {
    paddingLeft: 48,
    '&:hover $hoverDelete': {
      visibility: 'visible',
    },
  },
  hoverDelete: {
    color: theme.palette.error.main,
    visibility: 'hidden',
  },
  inFrontOfModal: {
    zIndex: theme.zIndex.modal + 1,
  },
  dragHandle: {
    width: '100%',
    cursor: 'grab',
  },
});

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};
const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

class EventMenuCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuItemModalOpen: false,
      isMenuItemPickerOpen: false,
      isRenamingMenu: false,
      newMenuItems: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventMenuCategory !== this.props.eventMenuCategory) {
      this.setState({ eventMenuCategory: _.cloneDeep(this.props.eventMenuCategory) });
    }

    if (prevProps.showModifiers !== this.props.showModifiers) {
      const eventMenuCategoryItems = this.state.eventMenuCategory.eventMenuCategoryItems.map(item => {
        item.expanded = this.props.showModifiers;

        return item;
      });

      const eventMenuCategory = {
        ...this.state.eventMenuCategory,
        eventMenuCategoryItems,
      };

      this.setState({ eventMenuCategory });
    }
  }

  updateSetsOfModifiers = sets => {
    return this.props.updateSetsOfModifiers(this.state.eventMenuCategory, sets).then(updatedItems => {
      const eventMenuCategoryItems = this.state.eventMenuCategory.eventMenuCategoryItems.map(item => {
        var found = updatedItems.find(u => u.id === item.id);

        if (found) {
          found.expanded = item.expanded;

          return found;
        }

        return item;
      });
      const eventMenuCategory = {
        ...this.state.eventMenuCategory,
        eventMenuCategoryItems,
      };

      this.setState({ eventMenuCategory });

      return updatedItems;
    });
  }

  getSetsOfModifiers = item => {
    return this.props.getSetsOfModifiers(this.state.eventMenuCategory, item);
  }

  onEnterPressed = event => {
    if (event.key === 'Enter') {
      this.saveMenuCategoryName(event);
    }
  }

  saveMenuCategoryName = synthEvent => {
    //called from onBlur so tabbing off text field will save automatically
    //called from onClose because clicking the hidden backdrop was triggering the expansion panel. bug?
    synthEvent.stopPropagation();
    const eventMenuCategory = {
      ...this.props.eventMenuCategory,
      name: this.updatedName,
    };

    this.props.updateEventMenuCategory(eventMenuCategory);
    this.setState({ isRenamingMenu: false });
  }

  noPropagate = synthEvent => {
    synthEvent.stopPropagation();
  }

  openMenuItemModal = synthEvent => {
    synthEvent.stopPropagation();
    this.setState({ isMenuItemModalOpen: true });
  }

  closeMenuItemModal = () => {
    this.setState({ isMenuItemModalOpen: false });
  }

  closeBulkModifierModal = () => {
    const { onAddMenuItem } = this.props;
    const { newMenuItems } = this.state;

    onAddMenuItem(newMenuItems);

    this.setState({
      sets: null,
      newMenuItems: [],
    });
  }

  onSaveModifires = (itemIndex, item) => {
    return new Promise(resolve => {
      const newMenuItems = this.state.newMenuItems.map(mi => { return { ...mi };});
      const sets = this.state.sets.map(si => { return { ...si };});

      sets[itemIndex] = {
        ...sets[itemIndex],
        ...item,
      };
      const newItemsIndex = newMenuItems.findIndex(mi => mi.id === sets[itemIndex].id);

      newMenuItems[newItemsIndex] = { ...sets[itemIndex] };

      this.setState({
        newMenuItems,
        sets,
      }, () => resolve());
    });
  }

  saveMenuItemSelection = newMenuItems => {
    const { onAddMenuItem } = this.props;

    this.closeMenuItemModal();

    if (newMenuItems && newMenuItems.length) {
      const sets = newMenuItems.filter(mci => !!mci.menuItem.modifiers.length);

      if (!!sets && !!sets.length) {
        this.setState({
          sets,
          newMenuItems,
        });
      } else {
        onAddMenuItem(newMenuItems);
      }

      // addLibraryItems(eventMenuCategory, newMenuItems)
      //   .then(sets => {
      //     if (sets.length) {
      //       this.setState({ sets }); //opens the bulk modifiers modal
      //     }
      //   })
      //   .then(() => {
      //     onMenuCategoryChanged(); //refresh the menu, so so drag-and-drop works
      //     onFinancialDetailsChanged();
      //   });
    }
  }

  openItemModal = menuItem => {
    const { eventMenuCategory } = this.props;
    const openedItem = eventMenuCategory.eventMenuCategoryItems.find(m => (menuItem.id && menuItem.id === m.id) || (menuItem.tempId && menuItem.tempId === m.tempId));

    if (openedItem) {
      this.setState({ openedItem });
      this.props.storeBookingSetIsEditing(true);
    } else {
      console.error('Clicked Item was not found in eventMenuCategoryItems for this category');
    }
  }

  //updatedItem will be falsy if canceled
  closeItemModal = updatedItem => {
    this.setState({ openedItem: null });
    if (updatedItem) {
      this.saveMenuItem(updatedItem);
    }
  }

  saveMenuItem = updatedItem => {
    const { isEditing, editEventMenuCategoryItem, toggleEditMode } = this.props;
    const eventMenuCategory = _.cloneDeep(this.props.eventMenuCategory);

    updatedItem.unitPrice = !updatedItem.unitPrice ? 0 : updatedItem.unitPrice;
    updatedItem.quantity = !updatedItem.quantity ? 0 : updatedItem.quantity;
    editEventMenuCategoryItem(eventMenuCategory, updatedItem);
    if (!isEditing) {
      toggleEditMode();
    }
  }

  openRenamePopover = event => {
    event.stopPropagation();
    this.updatedName = this.state.eventMenuCategory.name;
    this.setState({ isRenamingMenu: true });
  }

  renameTitleComponent = title => {
    const {
      isEditing,
      classes,
    } = this.props;

    if (!isEditing) {
      return title;
    }

    return (
      <>
        <div
          onClick={this.openRenamePopover}
          ref={node => { this.anchorEl = node; }}
        >
          { title }
        </div>
        <Popover
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          anchorEl={this.anchorEl}
          className={classes.inFrontOfModal}
          onClose={this.saveMenuCategoryName}
          open={this.state.isRenamingMenu}>
          <TextField
            autoFocus={true}
            defaultValue={title}
            onClick={this.noPropagate}
            onBlur={this.saveMenuCategoryName}
            onChange={this.menuNameUpdated}
            onKeyPress={this.onEnterPressed}
          />
        </Popover>
      </>
    );
  }

  menuNameUpdated = event => {
    this.updatedName = event.target.value;
  }

  deleteMenuCategory = event => {
    const { eventMenuCategory } = this.state;

    event.stopPropagation();
    this.props.deleteEventMenuCategory(eventMenuCategory)
      .then(this.props.onMenuCategoryChanged);
    // onFinancialDetailsChanged TODO
  }

  getDragHandle = () => {
    const { classes } = this.props;

    return <div className={classes.dragHandle}>&nbsp;</div>;
  }

  openProductDetails = selectedBookingItem => {
    this.setState({ selectedBookingItem });
  }

  closeProductDetail = () => {
    this.setState({ selectedBookingItem: null });
  }

  saveBookingItem = updatedBookingItem => {
    this.saveMenuItem(updatedBookingItem);
    this.closeProductDetail();
  }

  render() {
    const {
      eventMenuCategory,
      isEditing,
      classes,
      isLoading,
      useDefaultColumns,
      onDeleteMenuItem,
      onAddMenuItem,
      allMenuItems,
    } = this.props;

    const {
      isMenuItemModalOpen,
      sets,
      openedItem,
      selectedBookingItem,
    } = this.state;

    const eventMenuCategoryContext = {
      getSetsOfModifiers: this.getSetsOfModifiers,
      updateSetsOfModifiers: this.updateSetsOfModifiers,
      allMenuItems,
      openProductDetails: this.openProductDetails,
    };

    return (
      <MenuCategoryContext.Provider value={eventMenuCategoryContext}>
        <CoverExpansionPanel
          title={this.renameTitleComponent(eventMenuCategory.name)}
          hoverTitle={eventMenuCategory.name}
          customstyles={{
            summary: classes.menuExpansionSummary,
            iconButton: classes.menuExpansionIconButton,
          }}
          defaultExpanded={true}
          rightcontent={isEditing &&
            <>
              <div onClick={this.openMenuItemModal}>
                <SelectNewIcon />
              </div>
              <DeleteIcon classes={{ root: classes.hoverDelete }} onClick={this.deleteMenuCategory} />
            </>
          }
          centerContent={isEditing && this.getDragHandle()}
        >
          <MenuGrid
            eventMenuCategoryItems={eventMenuCategory.eventMenuCategoryItems}
            isEditing={isEditing}
            onOpenItemModal={this.openItemModal}
            onMenuItemAdded={onAddMenuItem}
            onItemDeleted={onDeleteMenuItem}
            onCellEditFinished={this.saveMenuItem}
            useDefaultColumns={useDefaultColumns}
            isLoading={isLoading}
            selectedBookingItem={selectedBookingItem}
            closeProductDetail={this.closeProductDetail}
            saveBookingItem={this.saveBookingItem}
          />
        </CoverExpansionPanel>
        {isMenuItemModalOpen &&
          <MenuItemSelectionModal
            isImagesOpenedFirst={true}
            onModalClosed={this.closeMenuItemModal}
            onSave={this.saveMenuItemSelection}
            bookingMenuSelect={true}
          />
        }
        {sets &&
          <BulkModifiersModal
            onModalClosed={this.closeBulkModifierModal}
            sets={sets}
            onSave={this.onSaveModifires}
          />}
        {openedItem && <MenuItemPickerModal item={openedItem} onModalClosed={this.closeItemModal} />}
      </MenuCategoryContext.Provider>
    );
  }
}

EventMenuCategory.propTypes = {
  eventMenuCategory: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

const EventMenuCategoryWithStyles = withStyles(styles)(EventMenuCategory);

const mapStateToProps = state => ({
  allMenuItems: state.menuItem.indexedData,
});

const mapDispatchToProps = {
  addLibraryItems,
  deleteEventMenuCategory,
  updateEventMenuCategory,
  removeLibraryItem,
  getSetsOfModifiers,
  updateSetsOfModifiers,
  editEventMenuCategoryItem,
  storeBookingSetIsEditing,
  toggleEditMode,
};

const EventMenuCategoryWithContext = props => {
  const {
    onFinancialDetailsChanged,
  } = useContext(BookingContext);

  return (<EventMenuCategoryWithStyles
    {...props}
    onFinancialDetailsChanged={onFinancialDetailsChanged}
  />);
};

export default connect(mapStateToProps, mapDispatchToProps)(EventMenuCategoryWithContext);
export { EventMenuCategoryWithStyles };

//<BookingMenuCategory /> used from
//1)orders/wizard/menu
//2)booking/menu
//3)bookings/bookingwizard/wizardMenuSelection
