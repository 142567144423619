import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from 'Components/TextField';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import {
  getPickLists,
} from 'actions/pickList';
import {
  getAccountTypes,
  getReferences,
} from 'actions/pickListOption';
import { connect } from 'react-redux';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';
import ComboBox from 'Components/ComboBox';

const styles = theme => ({
  masonry: {
    display: 'flex',
    padding: '8px 0',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
  },
  fieldWrapper: {
    display: 'flex',
    flex: 1,
  },
  halfWidth: {
    display: 'flex',
    width: '50%',
  },
  fieldIsEdited: {
    background: theme.palette.error.light,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
});

class Details extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accountTypes: [],
      references: [],
      salesReps: [],
    };
  }

  getAccountTypes = () => {
    const { pickListDictionary } = this.props;
    const types =
      pickListDictionary &&
      pickListDictionary['Type'] &&
      pickListDictionary['Type'].pickListOptions;

    this.setState({ accountTypes: setupComboBoxSuggestions(types).comboBoxSuggestions });
  }

  getReferences = () => {
    const { pickListDictionary } = this.props;
    const references =
      pickListDictionary &&
      pickListDictionary['Reference'] &&
      pickListDictionary['Reference'].pickListOptions;

    this.setState({ references: setupComboBoxSuggestions(references).comboBoxSuggestions });
  }

  getSalesReps = () => {
    const { pickListDictionary } = this.props;
    const salesReps =
      pickListDictionary &&
      pickListDictionary['Sales Rep'] &&
      pickListDictionary['Sales Rep'].pickListOptions;

    this.setState({ salesReps });
  }

  handleReferenceChange = referenceModel => {
    if (!referenceModel) {
      this.props.onFieldChange('reference')(null);//clicked clear button

      return;
    }
    this.props.onFieldChange('reference')(referenceModel.label);
  }

  handleTypeChange = accountModel => {
    if (!accountModel) {
      this.props.onFieldChange('type')(null);//clicked clear button

      return;
    }
    this.props.onFieldChange('type')(accountModel.label);
  }

  componentDidMount() {
    this.props.getPickLists().then(() => {
      this.getAccountTypes();
      this.getReferences();
      this.getSalesReps();
    });
  }

  render() {
    const {
      references,
      accountTypes,
      salesReps,
    } = this.state;
    const {
      classes,
      account,
      isEditing,
      isFieldChanged,
      validateField,
      onFieldChange,
      isFieldInvalid,
      isQuickAdd,
    } = this.props;

    return (
      <div className={classes.masonry}>
        <div className={classNames(classes.column)}>
          <div className={classNames(classes.rowContainer)}>
            <div className={classNames(classes.fieldWrapper, isFieldChanged('name') && classes.fieldIsEdited)}>
              <TextField
                label="Account Name"
                value={account.name}
                onFieldChange={onFieldChange('name')}
                disabled={!isEditing}
                error={isFieldInvalid('name')}
                onBlur={() => validateField('name')}
              />
            </div>
            <div className={classNames(classes.fieldWrapper, isFieldChanged('reference') && classes.fieldIsEdited)}>
              <ComboBox
                label="Reference"
                disabled={!isEditing}
                value={account.reference}
                suggestions={references}
                isClearable={true}
                isCreatable={true}
                handleChange={this.handleReferenceChange}
                handleQuickAdd={onFieldChange('reference')}
              />
            </div>
          </div>
          <div className={classNames(classes.rowContainer)}>
            <div className={classNames(classes.fieldWrapper, isFieldChanged('phone') && classes.fieldIsEdited)}>
              <TextField
                type="phone"
                label="Phone Number"
                value={account.phone}
                onFieldChange={onFieldChange('phone')}
                disabled={!isEditing}
              />
            </div>
            <div className={classNames(classes.fieldWrapper, isFieldChanged('type') && classes.fieldIsEdited)}>
              <ComboBox
                label="Type"
                disabled={!isEditing}
                value={account.type}
                suggestions={accountTypes}
                isClearable={true}
                isCreatable={true}
                handleChange={this.handleTypeChange}
                handleQuickAdd={onFieldChange('type')}
              />
            </div>
          </div>
          <div className={classNames(classes.rowContainer)}>
            {!isQuickAdd &&
              <div className={classNames(classes.fieldWrapper, isFieldChanged('phone2') && classes.fieldIsEdited)}>
                <TextField
                  type="phone"
                  label="Phone 2"
                  value={account.phone2}
                  onFieldChange={onFieldChange('phone2')}
                  disabled={!isEditing}
                />
              </div>
            }
            {!isQuickAdd &&
              <div className={classNames(classes.fieldWrapper, isFieldChanged('website') && classes.fieldIsEdited)}>
                <TextField
                  label="Website"
                  value={account.website}
                  onFieldChange={onFieldChange('website')}
                  disabled={!isEditing}
                />
              </div>
            }
          </div>
          <div className={classNames(classes.rowContainer)}>
            <div className={classNames(classes.fieldWrapper, isFieldChanged('email') && classes.fieldIsEdited)}>
              <TextField
                label="Email"
                value={account.email}
                onFieldChange={onFieldChange('email')}
                disabled={!isEditing}
                type="email"
                error={isFieldInvalid('email')}
                onBlur={() => validateField('email')}
              />
            </div>
            <div className={classNames(classes.fieldWrapper, isFieldChanged('salesRepId') && classes.fieldIsEdited)}>
              <Select
                label="Sales Rep"
                disabled={!isEditing}
                value={account.salesRepId}
                onFieldChange={onFieldChange('salesRepId')}
              >
                {salesReps && salesReps.map(salesRep =>
                  <MenuItem key={salesRep.id} value={salesRep.id}>{salesRep.name}</MenuItem>
                )}
              </Select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pickListDictionary: state.pickList.pickListDictionary,
  };
};

const mapDispatchToProps = {
  getPickLists,
  getAccountTypes,
  getReferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Details));
