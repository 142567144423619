import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getRevenueTypes,
} from 'actions/item';
import { deleteRevenueType } from 'actions/RevenueType';
import { withStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import SimpleDialog from 'Components/SimpleDialog';
import RevenueTypeGrid from './RevenueTypeGrid';
import RevenueTypeModal from './RevenueTypeModal';
import DeleteRevenueTypeModal from './DeleteRevenueTypeModal';

const styles = () => ({
  RevenueType: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flex: 1,
  },
  name: {
    display: 'flex',
    width: '65%',
  },
  lower: {
    display: 'flex',
    padding: '24px 16px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 16px',
  },
  topButtons: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    margin: '0 5px',
  },
  addButton: {
    marginBottom: 5,
  },
  booleanOption: {
    padding: '2px 12px',
  },
  primary: {
    marginLeft: -10,
  },
  tableContainer: {
    width: 650,
  },
});

const RevenueTypes = ({
  classes,
  getRevenueTypes,
  deleteRevenueType,
  revenueTypes,
}) => {
  const [RevenueTypes, setRevenueTypes] = useState([]);
  const [RevenueTypeEdit, setRevenueTypeEdit] = useState(null);
  const [RevenueTypeDelete, setRevenueTypeDelete] = useState(null);
  const [usedRevenueTypeCodes, setUsedRevenueTypeCodes] = useState([]);
  const dialog = React.createRef();

  useEffect(() => {
    //getRevenueTypes();
    getRevenueTypesApi();
  }, []);

  async function getRevenueTypesApi() {
    var dbRevenueTypes = await getRevenueTypes();

    setRevenueTypes(dbRevenueTypes);
  }

  function editRevenueType(selectedRevenueType) {
    setRevenueTypeEdit(selectedRevenueType);
    setUsedRevenueTypeCodes(RevenueTypes.filter(RevenueType => RevenueType.code !== selectedRevenueType.code).map(RevenueType => RevenueType.code));
  }

  function closeModal() {
    setRevenueTypeEdit(null);
    setRevenueTypeDelete(null);
    getRevenueTypesApi();
  }

  function onAddClick() {
    editRevenueType({ active: true });
  }

  function onDeleteRevenueType(revenueType) {
    setRevenueTypeDelete(revenueType);
  }

  return (
    <div className={classes.RevenueType}>
      <div className={classes.main}>
        <div className={classes.content}>
          <div className={classes.tableContainer}>
            <RevenueTypeGrid RevenueTypes={RevenueTypes} onEditRevenueType={editRevenueType} onDeleteRevenueType={onDeleteRevenueType} onCloseModal={closeModal} />
          </div>
        </div>
        <div className={classes.buttons}>
          <div className={classes.topButtons}>
            <div className={classes.addButton}>
              <HighlightButton
                onClick={onAddClick}
                aria-label={`Add Revenue Type`}
                className={classes.spaceButtons}
              >
                <AddIcon />
              </HighlightButton>
            </div>
          </div>
        </div>
      </div>
      {RevenueTypeDelete && <DeleteRevenueTypeModal
        RevenueType={RevenueTypeDelete}
        onCloseModal={closeModal}
      />}
      <SimpleDialog innerRef={dialog} />
      {RevenueTypeEdit && <RevenueTypeModal RevenueType={RevenueTypeEdit} usedRevenueTypeCodes={usedRevenueTypeCodes} revenueTypes={revenueTypes} onCloseModal={closeModal} />}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    api,
  } = state;

  return {
    revenueTypes: api && api.revenueTypes ? api.revenueTypes : [],
  };
};

const mapDispatchToProps = {
  getRevenueTypes,
  deleteRevenueType,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RevenueTypes));
