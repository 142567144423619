import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import { CURRENT_LOCATION_SET, RESET_RECENTS } from 'actions/constants';

export const getLocations = () => (dispatch, getState) => {
  const { oidc: { user: { profile: { locations } } } } = getState(); //check if the params already matches what we have

  try {
    var locationsDictionary = JSON.parse(locations);

    return Object.getOwnPropertyNames(locationsDictionary).map(key => ({ id: key, name: locationsDictionary[key] }));
  }
  catch {
    const { oidc: { user: { profile } } } = getState();

    return [{
      id: profile["organization_id"],
      name: profile["organization_name"],
    }];
  }
};

export const getLocation = locationId => dispatch => {
  return dispatch(callApi(`user/location/${locationId}`, { method: 'GET' }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));
};

export const setCurrentLocation = location => dispatch => {
  dispatch({
    type: CURRENT_LOCATION_SET,
    location,
  });
  dispatch({
    type: RESET_RECENTS,
  });
};
