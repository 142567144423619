import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import ODataGrid from 'Components/Grid/ODataGrid';
import { connect } from 'react-redux';
import {
  getCoupons,
  setCurrentCoupon,
} from 'actions/coupon';
import GridToolBar from 'Components/Grid/GridToolBar';
import { buildGridCellModalLink } from 'Components/buildGridCellLink';
import { Add, Close, LibraryAdd } from '@material-ui/icons';
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  newCouponFab: {
    zIndex: 3,
    position: 'relative',
    top: 50,
  },
  backgroundColor: {
    backgroundColor: 'white !important',
  },
});

class CouponGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coupons: [],
    };

    this.codeCell = buildGridCellModalLink({
      onOpenModal: item => this.onCouponSelected(item),
      text: item => item.code,
    });
  }

  customFilters = [
    {
      id: 2, name: 'Active now',
      default: true,
      filters: [],
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'startDate',
            operator: 'lt',
            value: moment().startOf('day').utc().toDate(),
          },
          {
            field: 'active',
            operator: 'eq',
            value: true,
          },
          {
            logic: 'or',
            filters: [
              {
                field: 'endDate',
                operator: 'gt',
                value: moment().startOf('day').utc().toDate(),
              },
              {
                field: 'endDate',
                operator: 'eq',
                value: null,
              },
            ],
          },
        ],
      },
    },
    {
      id: 3, name: 'Expired',
      filters: [],
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'endDate',
            operator: 'lt',
            value: moment().endOf('day').utc().toDate(),
          },
        ],
      },
    },
  ];

  defaultFilter = this.customFilters.find(filter => filter.default);

  componentDidMount() {
    this.props.getCoupons();
  }

  componentDidUpdate(prevProps) {

  }

  createCoupon = () => {
    this.setState({ newCouponMenu: false });
    this.props.setCurrentCoupon({ discountType: 'Flat', couponDiscounts: [] });
  }

  seedCoupons = () => {
    this.setState({ newCouponMenu: false });
    this.props.setCurrentCoupon({ discountType: 'Flat', seedCoupons: true, couponDiscounts: [] });
  }

  onCouponSelected = coupon => {
    this.setState({ newCouponMenu: false });
    this.props.setCurrentCoupon(coupon);
  }

  applyCustomFilter = filterId => {
    const foundFilter = this.customFilters.find(filter => filter.id === filterId);

    this.setState({
      filterId,
      globalFilter: foundFilter,
    });
  }

  newCouponButton = () => {
    const { classes } = this.props;

    return (
      <div className={classes.newCouponFab}>
        {/* <HighlightButton
          aria-label="Create Coupon"
        >
          <AddIcon />
        </HighlightButton> */}
        <FloatingMenu
          slideSpeed={500}
          direction="down"
          spacing={8}
          isOpen={this.state.newCouponMenu}
        >
          <MainButton
            iconResting={<Add style={{ fontSize: 30, backgroundColor: 'white' }} />}
            iconActive={<Close style={{ fontSize: 30, backgroundColor: 'white' }} />}
            backgroundColor="black"
            onClick={() => this.setState({ newCouponMenu: !this.state.newCouponMenu })}
            size={56}
          />
          <Tooltip title="Generate Individual Coupon" placement="top">
            <ChildButton
              icon={<Add style={{ fontSize: 30 }} />}
              size={40}
              onClick={() => this.createCoupon()}
              className={classes.backgroundColor}
            />
          </Tooltip>
          <Tooltip title="Generate Multiple Coupons" placement="top">
            <ChildButton
              icon={<LibraryAdd style={{ fontSize: 30 }} />}
              size={40}
              onClick={() => this.seedCoupons()}
              className={classes.backgroundColor}
            />
          </Tooltip>
        </FloatingMenu>
      </div>
    );
  }

  getToolbar = () => (
    <GridToolBar
      gridContext="Coupons"
      additionalButtons={this.newCouponButton()}
      customFilters={this.customFilters}
      onApplyCustomFilter={this.applyCustomFilter}
      filterId={this.state.filterId}
      activeCustomFilter={this.state.globalFilter}
    />
  )

  render() {
    const {
      globalFilter,
      sort,
    } = this.state;

    const {
      loading,
      coupons,
    } = this.props;

    return (
      <ODataGrid
        getData={this.props.getCoupons}
        items={coupons}
        loading={loading}
        isSortable={true}
        filterable={true}
        isEditable={false}
        pageSize={50}
        toolBar={this.getToolbar()}
        onColumnReorder={this.reorderColumns}
        filter={(globalFilter && globalFilter.filter)}
        sort={sort}
      >
        <GridColumn field='code'
          title='Code' cell={this.codeCell} />
        <GridColumn
          field='discountType'
          title='Discount Type'
        />
        <GridColumn filter="numeric" field='discountAmount' title="Discount Amount" cell={props => (
          <td>{(props.dataItem.discountType === 'Flat' ? '$' : '') + (props.dataItem.discountAmount ? props.dataItem.discountAmount.toFixed(2) : '0') + (props.dataItem.discountType === 'Percentage' ? '%' : '')}</td>
        )} />
        <GridColumn filter="boolean" field='active' title="Active" cell={props => (
          <td>{props.dataItem.active ? 'Yes' : 'No'}</td>
        )} />
        <GridColumn
          field='startDate'
          title='Effective Start Date'
          filter='date'
          cell={props => (
            <td>{props.dataItem.startDate ? moment(props.dataItem.startDate).format("l") : ''}</td>
          )}
        />
        <GridColumn
          filter='date'
          field='endDate'
          title='Effective End Date'
          cell={props => (
            <td>{props.dataItem.endDate ? moment(props.dataItem.endDate).format("l") : ''}</td>
          )}
        />
      </ODataGrid>
    );
  }
}

const mapStateToProps = state => {
  const {
    coupons: {
      data,
      loading,
    },
  } = state;

  return {
    coupons: data,
    loading,
  };
};

const mapDispatchToProps = {
  getCoupons,
  setCurrentCoupon,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CouponGrid));
