import {
  WORKING_ADDRESS_SET_INIT,
  WORKING_ADDRESS_SET_UPDATE,
  WORKING_ADDRESS_SET_ADD_ADDRESS,
  WORKING_ADDRESS_SET_SELECT_ADDRESS,
  WORKING_ADDRESS_SET_RESET,
} from './constants';
import _ from 'lodash';

export const initWorkingAddresses = workingAddresses => dispatch => {
  if (!workingAddresses || !workingAddresses.length) {
    workingAddresses = [{ 'id': -1 }];
  }

  const workingAddressesRef = _.cloneDeep(workingAddresses);

  dispatch({ type: WORKING_ADDRESS_SET_INIT, workingAddresses, workingAddressesRef });

  return Promise.resolve();
};
export const clearWorkingAddresses = () => ({ type: WORKING_ADDRESS_SET_RESET });
export const workingAddressesUpdate = workingAddresses => ({ type: WORKING_ADDRESS_SET_UPDATE, workingAddresses });
export const workingAddressesAddNew = () => ({ type: WORKING_ADDRESS_SET_ADD_ADDRESS });

export const workingAddressesSelectAddress = selectedAddressId => dispatch => {
  if (selectedAddressId) {
    return dispatch({ type: WORKING_ADDRESS_SET_SELECT_ADDRESS, selectedAddressId });
  }
};

export const workingAddressesDelete = selectedAddressId => (dispatch, getState) => {
  const { address: { workingAddresses } } = getState();
  const newAddresses = [...workingAddresses];

  const index = newAddresses.findIndex(t => t.id === selectedAddressId);
  const deletedAddress = newAddresses[index];

  if (deletedAddress.id) {
    deletedAddress.recordStatus = 'Deleted';
  } else {
    newAddresses.splice(index, 1);
  }

  return dispatch(workingAddressesUpdate(newAddresses));
};

export const workingAddressesOnChange = (selectedAddressId, fieldName, value) => (dispatch, getState) => {
  const { address: { workingAddresses } } = getState();
  const originalAddresses = _.cloneDeep(workingAddresses);
  const editedAddress = originalAddresses.find(t => t.id === selectedAddressId);

  editedAddress[fieldName] = value;

  const newAddresses = originalAddresses.map(address => address.id === editedAddress.id ? editedAddress : address);

  return dispatch(workingAddressesUpdate(newAddresses));
};

export const workingAddressesOnPrimaryChange = (addressId, isChecked) => (dispatch, getState) => {
  const { address: { workingAddresses } } = getState();
  const newAddresses = workingAddresses.map(address => {
    address.primary = address.id === addressId && isChecked;

    return address;
  });

  return dispatch(workingAddressesUpdate(newAddresses));
};

export const selectedAddressFieldIsChanged = fieldName => (dispatch, getState) => {
  const { address: { workingAddresses, workingAddressesRef, selectedAddressId } } = getState();
  const selectedAddress = workingAddresses.find(o => o.id === selectedAddressId);
  const selectedAddressRef = workingAddressesRef.find(o => o.id === selectedAddressId);

  return selectedAddress && selectedAddressRef && selectedAddress[fieldName] !== selectedAddressRef[fieldName];
};
