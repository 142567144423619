import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from 'Components/Modal';
import ODataGrid from 'Components/Grid/ODataGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { connect } from 'react-redux';
import { SelectProperty } from 'helpers/select';
import SelectedColumn from 'Components/Grid/SelectedColumn';

const styles = theme => ({
  fullRow: {
    width: '100%',
  },
  row: {
    display: 'flex',
    marginRight: theme.spacing.unit * 5,
    marginLeft: theme.spacing.unit * 5,
    marginTop: theme.spacing.unit * 1.5,
    marginBottom: theme.spacing.unit * 1.5,
  },
  subtitle: {
    backgroundColor: theme.palette.grey[50],
    fontSize: '17px',
    color: theme.common.black,
    width: '100%',
    paddingLeft: theme.spacing.unit * 5,
    paddingTop: theme.spacing.unit * 0.5,
    textAlign: 'left',
    height: theme.spacing.unit * 4,
  },
  overflowIfTooBig: {
    overflow: 'auto',
    marginTop: theme.spacing.unit * 2,
  },
  addButton: {
    textTransform: 'initial',
    paddingLeft: theme.spacing.unit * 6.5,
    paddingRight: theme.spacing.unit * 6.5,
    borderColor: theme.palette.grey[100],
  },
});

export class ModifierTemplateSelectorModal extends Component {

  state = {
    columns: [{
      field: 'selected',
      title: 'Selected',
      isVisible: true,
    }, {
      field: 'name',
      title: 'Name',
      isVisible: true,
    }, {
      field: 'options',
      title: 'Options',
      isVisible: true,
    },
    ],
  };

  componentDidUpdate(prevProps) {
    const { isOpened,
      modifierTemplates,
    } = this.props;

    if (isOpened === true && prevProps.isOpened === false) {
      this.setState({
        ...this.state,
        modifierTemplates: modifierTemplates,
      });
    }
    else if (isOpened === false && prevProps.isOpened === true) {
      // reset the state
      this.setState({
        ...this.state,
        modifierTemplates: modifierTemplates,
      });
    }
  }

  save = () => {
    const { modifierTemplates } = this.state;

    this.props.onSave(modifierTemplates.filter(template => template.selected));
  };

  onAllTemplatesSelected = selected => {
    const { modifierTemplates } = this.state;

    this.setState({
      modifierTemplates: modifierTemplates.map(template => ({ ...template, selected })),
    });
  }

  onTemplateSelected = ({ selected, index }) => {
    let { modifierTemplates } = this.state;

    modifierTemplates[index].selected = selected;

    this.setState({ modifierTemplates: modifierTemplates });
  };

  getColumn = column => {
    const { modifierTemplates } = this.state;

    if (column.isVisible) {
      switch (column.field) {
        case 'selected':
          return SelectedColumn(modifierTemplates);
        case 'name':
          return <GridColumn
            key={column.field}
            field={column.field}
            title={column.title}
            filterable={false}
          />;
        case 'options':
          return <GridColumn filterable={false} key={column.field} field={column.field} title="Options" cell={props => (
            <td>{SelectProperty(props.dataItem.options, 'name').join(', ')}</td>
          )} />;
        default:
          return <GridColumn key={column.field} field={column.field} title={column.title} />;
      }
    }
  }

  render() {
    const {
      classes,
      onModalClosed,
      title,
      isOpened,
      isLoading,
    } = this.props;

    const { modifierTemplates, columns } = this.state;

    return (<Modal
      isOpened={isOpened}
      onCancel={onModalClosed}
      title={title}
      onSave={this.save}
      addTitleBottomBorder={true}
    >
      <div className={classes.overflowIfTooBig}>
        <Typography className={classes.subtitle} variant="body1">
          Modifier Templates
        </Typography>
        <div className={classes.row}>
          <div className={classes.fullRow}>
            <ODataGrid
              getData={this.props.getMenuItemModifierTemplates}
              selectionChanged={this.onTemplateSelected}
              allItemsSelected={this.onAllTemplatesSelected}
              items={modifierTemplates && modifierTemplates.map(
                modifierTemplate => ({ ...modifierTemplate }))}
              isLoading={isLoading}
              onRowClick={this.onModifierTemplateSelected}
            >
              {columns.map(column => this.getColumn(column))}
            </ODataGrid>
          </div>
        </div>
      </div>
    </Modal>);
  }
}

const mapStateToProps = state => {
  const {
    menuItemModifier: {
      modifierTemplates: {
        data,
        isLoading,
      },
    },
  } = state;

  return {
    modifierTemplates: data,
    isLoading,
  };
};

ModifierTemplateSelectorModal.propTypes = {
  onModalClosed: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,

};
const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModifierTemplateSelectorModal));
