import React, { Component, useContext } from "react";
import { connect } from "react-redux";
import MenuCategoryContext from "./MenuCategoryContext";
import { withStyles } from "@material-ui/core/styles";
import Modal from "Components/Modal";
import Checkbox from "Components/Checkbox";
import SectionHeader from "Components/SectionHeader";
import TextField from "Components/TextField";
import { CurrencyField } from "Components/currencyInput";
import RichTextField from "Components/RichTextField";
import Select from "Components/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getRevenueTypes } from "actions/item";

const styles = () => ({
  topSection: {
    padding: "0 14px",
  },
  formSection: {
    padding: "8px 14px",
  },
  formRow: {
    display: "flex",
    flexGrow: 1,
  },
  richTextSection: {
    padding: "12px 30px",
  },
  halfWidth: {
    width: "50%",
    display: "flex",
  },
  main: {
    overflow: "auto",
  },
});

//whole list except insertImage. Change to an input prop if this is too chattly/brittle
const toolbarButtons = ["bold", "italic", "underline", "textColor", "align"];

export class MenuItemPickerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      taxTypes: [],
    };
    this.froalaConfig = {
      zIndex: props.theme.zIndex.modal + 1,
      toolbarButtons,
    };
  }

  componentDidMount() {
    this.props.getRevenueTypes();

    if (this.props.item) {
      this.copyItemPropsToStateAndGetModifiers();
    }
  }

  componentDidUpdate(prevProps) {
    const { item, taxTypes } = this.props;

    if (prevProps.item !== item) {
      this.copyItemPropsToStateAndGetModifiers();
    }
    if (prevProps.taxTypes !== taxTypes) {
      this.setState({ taxTypes });
    }
  }

  copyItemPropsToStateAndGetModifiers = () => {
    this.setState({ item: { ...this.props.item } });
    // this.props.getSetsOfModifiers(this.props.item).then(sets => {
    //   this.setState({ sets: [...sets] });
    // });
  };

  handleFieldChange = fieldName => value => {
    this.setState({
      item: {
        ...this.state.item,
        [fieldName]: value,
      },
    });
  };

  handleIntChange = fieldName => value => {
    if (value) {
      value = parseInt(value);
    }
    this.setState({
      item: {
        ...this.state.item,
        [fieldName]: value,
      },
    });
  };

  handleFloatChange = fieldName => value => {
    if (value) {
      value = parseFloat(value);
    }
    this.setState({
      item: {
        ...this.state.item,
        [fieldName]: value,
      },
    });
  };

  checkChanged = modifier => event => {
    modifier.selected = event.target.checked;
    this.forceUpdate(); //okay to force update because modifers are loaded into this.state
  };

  fieldChecked = fieldName => checked => {
    this.setState({
      item: {
        ...this.state.item,
        [fieldName]: checked,
      },
    });
  };

  onSave = () => {
    const { onModalClosed } = this.props;

    const item = this.state.item;

    // item.sets = this.state.sets;
    onModalClosed(item);
  };

  onCancel = () => {
    this.props.onModalClosed();
  };

  render() {
    const { classes } = this.props;

    const {
      item,
      // sets,
      taxTypes,
    } = this.state;

    return (
      <Modal
        isOpened={true}
        onCancel={this.onCancel}
        onSave={this.onSave}
        title={"Edit Menu Item"}
        addTitleBottomBorder={true}
        dimensions={{
          width: "unset",
          height: "unset",
          maxWidth: "823px",
          maxHeight: "750px",
        }}
      >
        <div className={classes.main}>
          <SectionHeader>Item Information</SectionHeader>
          <div className={classes.topSection}>
            <div className={classes.formRow}>
              <TextField
                label="Item"
                fieldName="name"
                value={item.name}
                className={classes.halfWidth}
                onFieldChange={this.handleFieldChange("name")}
              />
              <div className={classes.halfWidth}>
                <TextField
                  label="Quantity"
                  fieldName="quantity"
                  value={item.quantity}
                  onFieldChange={this.handleIntChange("quantity")}
                  type="number"
                />
                <CurrencyField
                  label="Unit Price"
                  fieldName="unitPrice"
                  value={item.unitPrice}
                  onFieldChange={this.handleFloatChange("unitPrice")}
                />
                <TextField
                  label="Calories"
                  fieldName="calories"
                  value={item.calories}
                  onFieldChange={this.handleIntChange("calories")}
                  type="number"
                />
              </div>
            </div>
            <div className={classes.formRow}>
              <TextField
                label="Allergens"
                fieldName="allergens"
                value={item.allergens}
                className={classes.halfWidth}
                onFieldChange={this.handleFieldChange("allergens")}
              />
              <div className={classes.halfWidth}>
                <TextField
                  label="Prep Area"
                  fieldName="prepArea"
                  value={item.prepArea}
                  onFieldChange={this.handleFieldChange("prepArea")}
                  className={classes.halfWidth}
                />
                <Select
                  label="Tax Type"
                  value={item.revenueTypeId || ""}
                  onFieldChange={this.handleFieldChange("revenueTypeId")}
                  name="revenueTypeId"
                  className={classes.halfWidth}
                >
                  {taxTypes.map(taxType => (
                    <MenuItem key={taxType.id} value={taxType.id}>
                      {taxType.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <SectionHeader>Description</SectionHeader>
          <div className={classes.richTextSection}>
            <RichTextField
              value={item.description}
              config={this.froalaConfig}
              onFieldChange={this.handleFieldChange("description")}
            />
          </div>
          {/* {sets.map(set =>
            <Fragment key={set.id}>
              <SectionHeader>
                {set.label}
              </SectionHeader>
              <div className={classes.formSection}>
                <div className={classes.formRow}>
                  {set.modifiers.map(modifier => (
                    <Checkbox
                      label={modifier.name}
                      checked={modifier.selected}
                      onChange={this.checkChanged(modifier)}
                      className={classes.checkboxWidth}
                      key={modifier.id}
                    />
                  ))}
                </div>
              </div>
            </Fragment>
          )} */}
          <SectionHeader>Additional Information</SectionHeader>
          <div className={classes.formSection}>
            <div className={classes.formRow}>
              <Checkbox
                label="Tax"
                checked={item.hasTax}
                onFieldChange={this.fieldChecked("hasTax")}
                className={classes.checkboxWidth}
              />
              <Checkbox
                label="Service Charge"
                checked={item.hasServiceCharge}
                onFieldChange={this.fieldChecked("hasServiceCharge")}
                className={classes.checkboxWidth}
              />
              <Checkbox
                label="Gratuity"
                checked={item.hasGratuity}
                onFieldChange={this.fieldChecked("hasGratuity")}
                className={classes.checkboxWidth}
              />
              {/* Disabling due to bugs with linked menu items */}
              {/* <Checkbox
                label="Linked"
                checked={item.isLinked}
                onFieldChange={this.fieldChecked('isLinked')}
                className={classes.checkboxWidth}
              /> */}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const MenuItemPickerModalContainer = props => {
  const { getSetsOfModifiers } = useContext(MenuCategoryContext);

  return (
    <MenuItemPickerModal {...props} getSetsOfModifiers={getSetsOfModifiers} />
  );
};

const mapStateToProps = state => {
  const {
    api: { revenueTypes },
  } = state;

  return {
    taxTypes: revenueTypes,
  };
};

const mapDispatchToProps = {
  getRevenueTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MenuItemPickerModalContainer));
