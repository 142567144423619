import React, { Component } from 'react';
import { Grid as KendoGrid } from '@progress/kendo-react-grid';
import DataLoader from '../DataLoader';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaveBar from '../SaveBar';
import { toODataString } from '@progress/kendo-data-query';
import _ from 'lodash';
import './DropdownList.scss';

const noFilter = {
  logic: 'and',
  filters: [],
};
const styles = theme => ({
  grid: {
    '& .k-grid': {
      fontSize: 13,
      color: theme.palette.primary.main,
      border: 'none',
      '& td': {
        borderWidth: '0 1px 1px 0',
        borderColor: theme.palette.grey[100],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap !important',

        '&:first-child': { // for "Selected" column
          borderRightWidth: 0,
        },
        '&:last-child': {
          borderRightWidth: 0,
        },
      },
      '& .k-grid-content & tr:last-child td': {
        borderBottomWidth: 1,
      },
      '& .k-grid-norecords': {
        position: 'absolute',
        top: 18,
        left: 12,
      },
      '& ::selection': {
        backgroundColor: theme.palette.action.inputHighlight + '!important',
        color: theme.palette.grey[900] + '!important',
      },
      '& .k-grid-header': {
        color: theme.palette.grey[600],
        paddingLeft: 16,
        paddingRight: 16,
        '& th': {
          borderLeftWidth: 0,
          borderColor: theme.palette.grey[100],
          verticalAlign: 'top',
        },
      },
      '& .k-grid-header-wrap': {
        border: 'none !important',
        zIndex: 1,
      },
      '& .k-icon': {
        color: theme.palette.grey[600],
      },
      '& .k-checkbox:checked+.k-checkbox-label::before': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
      '& .k-filtercell-wrapper': {
        flexWrap: 'wrap-reverse',
        justifyContent: 'end',
        '& > span, input': {
          minWidth: 60,
          width: '63%',
        },
        '& .k-filtercell-operator': {
          '& span.k-widget.k-dropdown-operator': {
            zIndex: 99999,
          },
          '& button.k-button': {
            visibility: 'visible',
            zIndex: 99999,
          },
        },
      },
    },
  },
  iconCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  iconButton: {
    padding: 15,
    color: theme.palette.primary.main,
  },
  toolbar: {
    backgroundColor: theme.palette.background.paper,
  },
  selectedRow: {
    backgroundColor: 'rgb(74 144 226)',
    color: 'white !important',
    '&:hover': {
      backgroundColor: 'rgb(74 144 226) !important',
      color: 'white !important',
      '& a': {
        color: 'white !important',
      },
    },
  },
  unselectedRow: {
    '&:hover': {
      backgroundColor: 'rgb(209, 227, 247) !important',
      color: '#fff',
    },
  },
});

class ODataGrid extends Component {
  static defaultPageSize = 30;

  getItemsCopy = items => items.map(item => item.isQuickAdd ? item : Object.assign({ isEditable: this.props.isEditable }, item));

  state = {
    sort: this.props.sort || [],
    skip: 0,
    take: this.props.pageSize ? this.props.pageSize : ODataGrid.defaultPageSize, //we could pull page size from props, but currently we don't have need to pass it in.
    pageSize: this.props.pageSize ? this.props.pageSize : ODataGrid.defaultPageSize, //we could pull page size from props, but currently we don't have need to pass it in.
    filter: this.props.filter || noFilter,
    items: this.getItemsCopy(this.props.items),
  };

  componentDidMount() {
    if (this.props.items.length === 0 && this.props.getData) {
      const { sort } = this.props;

      this.setState({ ...this.state, sort: sort || [] }, this.getGridData);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      items: currentItems,
      total: currentTotal,
      isEditable: currentEditable,
      filter,
      sort,
    } = this.props;

    if (prevProps.sort !== sort) {
      this.setState({ ...this.state, sort: sort || [] }, this.getGridData);
    }

    if (prevProps.filter !== filter) {
      this.setState({ filter: filter || noFilter, skip: 0 }, this.getGridData);
    }

    if (currentTotal !== prevProps.total && this.state.skip !== 0) {
      this.setState({ skip: 0 }, this.getGridData);
    } else if (currentEditable !== prevProps.isEditable || currentItems !== prevProps.items || currentItems.length !== this.state.items.length) {
      if (currentItems.length) {
        this.setState({
          items: this.getItemsCopy(currentItems),
        });
      } else {
        this.setState({
          items: [],
        });
      }
    }
  }

  getGridData = _.debounce(append => {
    const odataRequest = this.state;

    console.log(odataRequest);

    if (append) {
      odataRequest.skip = this.state.items.length;
    }
    odataRequest.take = odataRequest.pageSize;
    this.props.getData(toODataString(odataRequest), append);
  }, 300);  // TODO: why are we doing this?

  getFieldAndDirection = sort => {
    if (sort.length === 0)
      return { sortField: '', sortDirection: '' };

    const { field: sortField, dir: sortDirection } = sort[0];

    return { sortField, sortDirection };
  }

  static rowProps(props, className) {
    return {
      ...props,
      className, //remove alt css and selected css
    };
  }

  rowRender = (trElement, element) => {
    const { dataItem } = element;

    return React.cloneElement(
      trElement,
      ODataGrid.rowProps(trElement.props, dataItem.selected ? this.props.classes.selectedRow : this.props.classes.unselectedRow),
      trElement.props.children);
  }

  rowRenderDraggable = (trElement, element) => {
    const { dataItem } = element;

    const props = ODataGrid.rowProps(trElement.props, dataItem.selected ? this.props.classes.selectedRow : this.props.classes.unselectedRow);

    props.draggable = true;
    props.onDragStart = this.onDragStart(dataItem);
    props.onDragOver = this.onReorder(dataItem, this.rowCounter);
    props.onDrop = this.onDrop;

    this.rowCounter++;

    return React.cloneElement(
      trElement,
      props,
      trElement.props.children);
  }

  onSortChange = event => {
    const { sort } = this.props;

    this.setState({
      sort: event.sort.length === 0 && sort
        ? sort
        : event.sort,
    }, this.getGridData);
  }

  onReorder = (dataItem, destinationIndex) => e => {
    e.preventDefault(); //onDrop doesn't fire without out e.preventDefault()
    if (this.activeItem === dataItem) {
      //hovering over yourself
      return;
    }

    const reorderedData = [];
    let foundDraggedItem = false;
    const items = this.state.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i] === this.activeItem) {
        foundDraggedItem = true;
        continue;
      }
      if (destinationIndex === i) {
        if (foundDraggedItem) {
          //if we already found the activeItem, then we should put it after the item at item[i]
          reorderedData.push(items[i]);
          reorderedData.push(this.activeItem);
        } else {
          reorderedData.push(this.activeItem);
          reorderedData.push(items[i]);
        }
      } else {
        reorderedData.push(items[i]);
      }
    }

    this.setState({ items: reorderedData });
  }

  onDragStart = dataItem => () => {
    this.activeItem = dataItem;
    this.setState({ isReordering: true });
  }

  onDrop = () => {
    this.props.onReordered(this.state.items)
      .then(() => this.setState({ items: this.props.items, isReordering: false }));
  }

  onSelectionChange = event => {
    const { id, selected } = event.dataItem;
    const index = this.state.items.indexOf(event.dataItem);

    this.props.selectionChanged({ id, selected: !selected, index });
  }

  onHeaderSelectionChange = event => {
    const checked = event.syntheticEvent.target.checked;

    this.props.allItemsSelected({ selected: checked });
  }

  onFilterChange = e => {
    const filter = e.filter || noFilter;

    this.setState({ filter, skip: 0 }, this.getGridData);
  }

  onScroll = e => {
    if (this.props.pageable) return;

    const isScrolledToBottom = e.target.className.indexOf('k-grid-content') >= 0 && e.target.scrollTop + 100 >= e.target.scrollHeight - e.target.clientHeight;

    if (!this.props.isEditable && isScrolledToBottom && !this.props.isLoading) {
      this.setState({ skip: this.state.items.length });
      this.getGridData(true);
    }
  };

  onItemChange = event => {
    const { dataItem = {}, field, value } = event;
    const index = this.state.items.indexOf(dataItem);

    if (index >= 0) {
      const items = this.state.items;

      items[index][field] = value;
      this.setState({ items });
    }
  }

  onSave = () => {
    if (!this.props.onSave) return;

    this.props.onSave(this.state.items);
  }

  onPageChange = event => {
    const { skip, take } = event.page;

    this.setState({ skip, pageSize: take }, this.getGridData);
  }

  render() {
    this.rowCounter = 0;
    const {
      classes,
      pageable,
      toolBar,
      total,
      children,
      isEditable,
      onColumnReorder,
      isLoading,
      filterable,
      onSave,
      onCancel,
      isSortable,
      onReordered,
      isQuickAdding,
      minHeight,
      height,
      onRowClick,
    } = this.props;
    const { pageSize, skip, sort, filter, items } = this.state;
    const isDraggable = filter.filters && filter.filters.length === 0 && onReordered && sort.length === 0 && !isEditable && !isQuickAdding;

    return (
      <>
        {toolBar && (<div className={classes.toolbar}>
          {toolBar}
        </div>)}
        {/* For some reason height HAS to be specified with a px value to display properly */}
        <div onScroll={this.onScroll} style={{ flexGrow: 1, minHeight: minHeight ? minHeight : '250px', height: height ? height : '0px' }} className={classes.grid}>
          <KendoGrid
            reorderable={onColumnReorder ? true : false}
            onColumnReorder={onColumnReorder}
            rowHeight={40}
            selectedField="selected"
            onSelectionChange={this.onSelectionChange}
            onHeaderSelectionChange={this.onHeaderSelectionChange}
            onSortChange={this.onSortChange}
            style={{ minHeight: minHeight ? minHeight : '250px', height: '100%' }}
            data={items}
            editField='isEditable'
            total={total}
            skip={skip}
            filterable={filterable}
            pageable={pageable}
            pageSize={pageSize}
            rowRender={isDraggable ? this.rowRenderDraggable : this.rowRender}
            onPageChange={this.onPageChange}
            onFilterChange={this.onFilterChange}
            onItemChange={this.onItemChange}
            filter={filter}
            sortable={isSortable && {
              mode: 'single',
            }}
            onRowClick={onRowClick}
            sort={sort}
          >
            {children}
          </KendoGrid>
        </div>

        {(onSave || onCancel) && (isEditable || isQuickAdding) && (
          <SaveBar onSave={this.onSave} onCancel={onCancel} />
        )}
        <DataLoader isLoading={isLoading} />
      </>
    );
  }
}
ODataGrid.propTypes = {
  selectionChanged: PropTypes.func,
  allItemsSelected: PropTypes.func,
  items: PropTypes.array.isRequired,
  total: PropTypes.number,
  onColumnReorder: PropTypes.func,
  isLoading: PropTypes.bool,
  filterable: PropTypes.bool,
  getData: PropTypes.func,
  pageSize: PropTypes.number,
  onReordered: PropTypes.func,
  isQuickAdding: PropTypes.bool,
  isEditable: PropTypes.bool,
  isSortable: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  toolBar: PropTypes.object,
  filter: PropTypes.object,
  sort: PropTypes.array,
};

ODataGrid.defaultProps = {
  selectionChanged: () => { },
  items: [],
  filterable: false,
};

export default withStyles(styles)(ODataGrid);
