import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Header from 'Components/Header';
import ModifierTemplatesGrid from './ModifierTemplatesGrid.js';
import ModifierEditor from './ModifierEditor';
import {
  pushModifierTemplateToStack,
  removeModifierTemplatesFromStack,
  saveMenuItemModifierTemplate,
  deleteMenuItemModifierTemplate,
  updateModifierTemplateInStack,
} from 'actions/menuItemModifier';
import { SteppedLineTo } from 'react-lineto';

const styles = theme => {
  return {
    managementeContainer: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'auto',
      marginTop: '20px',
      border: `1px solid ${theme.palette.grey[50]}`,
      borderRadius: 4,
    },
    horizontalScroll: {
      overflowX: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      minWidth: '1160px',
    },
  };
};

class ModifierTemplateManagement extends Component {
  state = {};

  componentDidMount() {
    window.addEventListener('resize', this.windowSizeOrScrollChanged);
    window.addEventListener('scroll', this.windowSizeOrScrollChanged, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowSizeOrScrollChanged);
    window.removeEventListener('scroll', this.windowSizeOrScrollChanged);
  }

  windowSizeOrScrollChanged = () => {
    this.setState({
      ...this.state,
      windowSize: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
      scroll: {
        y: window.scrollY,
        x: window.scrollX,
      },
    });

    return Promise.resolve(); // in case something needs to adjust to this new width (ie: columns)
  }

  onDelete = () => {
    this.props.deleteMenuItemModifierTemplate(this.props.modifierTemplatesStack[0], 0);
  }

  onSave = () => {
    this.props.saveMenuItemModifierTemplate(this.props.modifierTemplatesStack[0], 0);
  }

  render() {
    const { classes, modifierTemplatesStack } = this.props;

    return (
      <>
        <Header>Modifier Templates</Header>
        <div className={classes.managementeContainer}>

          <ModifierTemplatesGrid />
          <div className={classes.horizontalScroll}>
            {
              modifierTemplatesStack && modifierTemplatesStack.map((modifierTemplate, index) => {
                return (
                  <>
                    <ModifierEditor
                      modifierTemplate={modifierTemplate}
                      index={index}
                      key={index}
                      showSaveBar={true}
                      onDelete={this.onDelete}
                      onSave={this.onSave}
                      pushModifierTemplateToStack={this.props.pushModifierTemplateToStack}
                      removeModifierTemplatesFromStack={this.props.removeModifierTemplatesFromStack}
                      deleteMenuItemModifierTemplate={this.props.deleteMenuItemModifierTemplate}
                      updateModifierTemplateInStack={this.props.updateModifierTemplateInStack}
                    />
                    <SteppedLineTo delay={20} borderColor={index === 1 ? '#4A90E2' : '#470a66'} borderWidth={3} fromAnchor="100% 50%" toAnchor="0 50%" from={`modifierEditor-${index - 1}`} to={`modifierEditor-${index}`} orientation="h" />
                  </>
                );
              })
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    menuItemModifier: {
      modifierTemplates: {
        modifierTemplatesStack,
      },
    },
  } = state;

  return {
    modifierTemplatesStack,
  };
};

const mapDispatchToProps = {
  pushModifierTemplateToStack,
  removeModifierTemplatesFromStack,
  saveMenuItemModifierTemplate,
  deleteMenuItemModifierTemplate,
  updateModifierTemplateInStack,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModifierTemplateManagement));
