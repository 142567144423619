import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import ODataGrid from 'Components/Grid/ODataGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { Add, Done, Block, Delete } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import CheckBox from 'Components/Checkbox';
import TextField from 'Components/TextField';
import { Tooltip } from '@material-ui/core';
import SimpleDialog from 'Components/SimpleDialog';

const styles = theme => ({
  addSetupStyleButton: {
    position: 'absolute',
    right: 10,
  },
  doneButton: {
    verticalAlign: 'bottom',
    marginBottom: 8,
    marginRight: 8,
  },
  disabled: {
    color: theme.palette.grey[200],
    cursor: 'default',
  },
});

const SetupStylesModal = ({
  classes,
  setupStylesModalOpen,
  onSetupStyleCancel,
  roomSetupStyles,
  setupStyles,
  usedSetupStyles,
  getAllSetupStyles,
  onSetupStyleSave,
  onDeleteSetupStyle,
}) => {
  const [selectedSetupStyles, setSelectedSetupStyles] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [addNewStyle, setaddNewStyle] = useState(false);
  const [newSetupStyle, setNewSetupStyle] = useState('');
  const [editSetupStyle, setEditSetupStyle] = useState(null);

  const confirmDeleteSetupStyle = React.createRef();

  useEffect(() => {
    if (updated) {
      return;
    }
    const selectedKeyValue = roomSetupStyles.reduce((r, e) => {
      if (e.setupStyle) {
        r[e.setupStyle.id] = e.setupStyle.name;
      }

      return r;
    }, {});

    setSelectedSetupStyles(
      setupStyles.map(setupStyle => {
        return {
          ...setupStyle,
          selected: selectedKeyValue[setupStyle.id] ? true : false,
        };
      })
    );
  }, [setupStyles, roomSetupStyles, updated]);

  const addNewSetupStyle = () => {
    const updatedSelectedSetupStyles = [ ...selectedSetupStyles ];

    updatedSelectedSetupStyles.unshift(
      { id: newSetupStyle, name: newSetupStyle, selected: true },
    );
    setUpdated(true);
    setaddNewStyle(false);
    setNewSetupStyle('');
    setSelectedSetupStyles(updatedSelectedSetupStyles);
  };

  const onEditSetupStyle = () => {
    const updatedSelectedSetupStyles = [ ...selectedSetupStyles ];
    const updatedStyle = updatedSelectedSetupStyles.find(ss => ss.id === editSetupStyle.id);

    updatedStyle.updated = true;
    updatedStyle.name = editSetupStyle.name;
    setUpdated(true);
    setEditSetupStyle(null);
    setSelectedSetupStyles(updatedSelectedSetupStyles);
  };

  const onSave = () => {
    setUpdated(false);
    setaddNewStyle(false);
    setNewSetupStyle('');
    setEditSetupStyle(null);
    onSetupStyleSave(selectedSetupStyles);
  };

  const onCancel = () => {
    setUpdated(false);
    setaddNewStyle(false);
    setNewSetupStyle('');
    setEditSetupStyle(null);
    onSetupStyleCancel();
  };

  const handleCheckbox = setupStyleId => event => {
    const updatedSetupStyles = selectedSetupStyles.map(setupStyle => {
      return { ...setupStyle };
    });
    const selectedStyle = updatedSetupStyles.find(ss => ss.id === setupStyleId);

    selectedStyle.selected = !selectedStyle.selected;
    setSelectedSetupStyles(updatedSetupStyles);
    setUpdated(true);
  };

  const nameOnClick = dataIndex => {
    const editSetupStyle = selectedSetupStyles[dataIndex];

    setEditSetupStyle(editSetupStyle);
  };

  return (<Modal
    isOpened={setupStylesModalOpen}
    dimensions={{
      width: 400,
      height: 'auto',
      maxHeight: '90%',
      overflow: 'auto',
    }}
    onSave={onSave}
    onCancel={onCancel}
    isSaveDisabled={!updated}
    title={
      <div>
        Add Setup Style
        <HighlightButton
          aria-label="Add A New Setup Style"
          className={classes.addSetupStyleButton}
          onClick={() => setaddNewStyle(true)}
        >
          <Add/>
        </HighlightButton>
      </div>
    }
    addTitleBottomBorder={true}
  >
    <div>
      {(addNewStyle || editSetupStyle) && <>
        <div>
          <TextField
            label={editSetupStyle ? 'Edit Setup Style' : 'New Setup Style'}
            value={editSetupStyle ? editSetupStyle.name : newSetupStyle}
            onFieldChange={value => editSetupStyle
              ? setEditSetupStyle({
                ...editSetupStyle,
                name: value,
              })
              : setNewSetupStyle(value)
            }
          />
          <HighlightButton
            className={classes.doneButton}
            onClick={() => {
              setNewSetupStyle('');
              setEditSetupStyle(null);
              setaddNewStyle(false);
            }}
          >
            <Block/>
          </HighlightButton>
          <HighlightButton
            className={classes.doneButton}
            disabled={
              (!newSetupStyle && (!editSetupStyle || !editSetupStyle.name)) ||
              selectedSetupStyles
                .filter(ss => editSetupStyle
                  ? ss.name.toLowerCase() === editSetupStyle.name.toLowerCase()
                  : ss.name.toLowerCase() === newSetupStyle.toLowerCase()
                ).length > 0
            }
            onClick={editSetupStyle ? onEditSetupStyle : addNewSetupStyle}
          >
            <Done/>
          </HighlightButton>
        </div>
      </>}
      <ODataGrid
        height='auto'
        getData={getAllSetupStyles}
        items={newSetupStyle
          ? selectedSetupStyles.filter(ss => ss.name.toLowerCase().includes(newSetupStyle.toLowerCase()))
          : selectedSetupStyles
        }
        isSortable={false}
        filterable={false}
        isEditable={false}
        pageSize={50}
      >
        <GridColumn
          key='name'
          field='name'
          title='Name'
          cell={props => <td
            onClick={() => nameOnClick(props.dataIndex)}
            style={{ cursor: 'pointer' }}
          >
            {props.dataItem.name}
          </td>}
        />
        <GridColumn
          key='selected'
          width={60}
          cell={props => <td style={{ padding: 0 }}>
            <CheckBox
              checked={props.dataItem.selected}
              onFieldChange={handleCheckbox(props.dataItem.id)}
            />
          </td>}
        />
        <GridColumn
          key='delete'
          width={65}
          cell={props => <td style={{ padding: 0 }}>
            <Tooltip title={props.dataItem.selected || usedSetupStyles[props.dataItem.id] ? 'Style in use' : ''}>
              <Button
                className={props.dataItem.selected || usedSetupStyles[props.dataItem.id] ? classes.disabled : null}
                onClick={() => {
                  if (!props.dataItem.selected && !usedSetupStyles[props.dataItem.id]) {
                    confirmDeleteSetupStyle.current.open().then(() => {
                      onDeleteSetupStyle(props.dataItem);
                    });
                  }
                }}
              >
                <Delete/>
              </Button>
            </Tooltip>
          </td>}
        />
      </ODataGrid>
      <SimpleDialog
        message="Are you sure you want to delete this setup style?"
        innerRef={confirmDeleteSetupStyle}
      />
    </div>
  </Modal>
  );
};

const mapDispatchToProps = {
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(SetupStylesModal));
