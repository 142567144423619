import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import { fetchCalendarEventsBegin, calendarEventsReceived, fetchAgendaEventsBegin, agendaEventsReceived } from './api';
import { EVENT_TYPE_FILTER_CLICK, TASK_COMPLETED, UPDATING_CALENDAR_EVENTS, UPSERT_CALENDAR_EVENT, DELETE_CALENDAR_EVENT, SELECTED_DATE_CHANGED } from './constants';
import ICalendarEvent from 'models/ICalendarEvent';
import EventType from 'models/EntityType';

const calendarApiUrl: string = 'calendar';

export const selectDate = (date: any) => async (dispatch: any, getState: any) => {
  const { calendar: { selectedDate } } = getState();

  if (selectedDate !== date) {
    dispatch(eventSelectedDateChanged(date));
  }
};

const eventSelectedDateChanged = (selectedDate: any) => ({
  type: SELECTED_DATE_CHANGED,
  selectedDate,
});

export const getCalendarEventsByDates = (requestParams: GetCalendarEventsRequest) => async (dispatch: any, getState: any) => {
  const { api: { calendarEvents: { json, params } }, calendar: { selectedEventTypes } } = getState();

  if ((selectedEventTypes.Proposal || selectedEventTypes.Booking || selectedEventTypes.Task || selectedEventTypes.Personal) && requestParams.StartDate && requestParams.EndDate) {
    dispatch(fetchCalendarEventsBegin());


    const parameters = `StartDate=${requestParams.StartDate}&EndDate=${requestParams.EndDate}&Switches.Proposal=${selectedEventTypes.Proposal}&Switches.Personal=${selectedEventTypes.Personal}&Switches.Booking=${selectedEventTypes.Booking}&Switches.Task=${selectedEventTypes.Task}`;

    if (params === parameters) {
      return json;
    }

    const url = `${calendarApiUrl}?${parameters}`;

    const calendarEvents: GetCalendarEventsResponse = await dispatch(callApi(url))
      .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response));

    dispatch(calendarEventsReceived(calendarEvents, parameters, requestParams));

    return calendarEvents;


  }
}

export const getCalendarEventsForAgendaByDates = (requestParams: GetCalendarEventsRequest) => async (dispatch: any, getState: any) => {

  const { api: { agendaEvents: { json, params } }, calendar: { selectedEventTypes } } = getState();

  if ((selectedEventTypes.Proposal || selectedEventTypes.Booking || selectedEventTypes.Task || selectedEventTypes.Personal) && requestParams.StartDate && requestParams.EndDate) {
    dispatch(fetchAgendaEventsBegin());

    const parameters = `StartDate=${requestParams.StartDate}&EndDate=${requestParams.EndDate}&Switches.Proposal=${selectedEventTypes.Proposal}&Switches.Personal=${selectedEventTypes.Personal}&Switches.Booking=${selectedEventTypes.Booking}&Switches.Task=${selectedEventTypes.Task}`;
    if (params === parameters) {
      return json;
    }

    const url = `${calendarApiUrl}?${parameters}`;

    const calendarEvents: GetCalendarEventsResponse = await dispatch(callApi(url))
      .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response));

    dispatch(agendaEventsReceived(calendarEvents, parameters, requestParams));

    return calendarEvents;
  }
}

export const getCalendarEventsByDatesByResource = (requestParams: GetCalendarEventsRequest) => async (dispatch: any, getState: any) => {
  const { api: { calendarEvents: { json, params } }, calendar: { selectedEventTypes } } = getState();

  if ((selectedEventTypes.Proposal || selectedEventTypes.Booking || selectedEventTypes.Task || selectedEventTypes.Personal) && requestParams.StartDate && requestParams.EndDate) {
    dispatch(fetchCalendarEventsBegin());


    const parameters = `StartDate=${requestParams.StartDate}&EndDate=${requestParams.EndDate}&Switches.Proposal=${selectedEventTypes.Proposal}&Switches.Personal=${selectedEventTypes.Personal}&Switches.Booking=${selectedEventTypes.Booking}&Switches.Task=${selectedEventTypes.Task}`;

    if (params === parameters) {
      return json;
    }

    const url = `${calendarApiUrl}/Event/ByResource/Room?${parameters}`;

    const calendarEvents: GetCalendarEventsResponse = await dispatch(callApi(url))
      .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response));

    dispatch(calendarEventsReceived(calendarEvents, parameters, requestParams));

    return calendarEvents;


  }
}



export const getTasks = (skip = 0, pageSize = 30) => { }

export interface GetCalendarEventsResponse {
  events: ICalendarEvent[]
}

export interface GetCalendarEventsRequest {
  StartDate: string,
  EndDate: string,
}


export const updatingCalendarEvent = (updating: boolean) => ({
  type: UPDATING_CALENDAR_EVENTS,
  updating,
});

export const handleEventTypeFilterClick = (eventType: EventType, isChecked: boolean) => (dispatch: any, getState: any) => {
  dispatch(eventTypeFilterClickEvent(eventType, isChecked));

  const agendaDates = getState().api.agendaEvents.dates;
  const calendarDates = getState().api.calendarEvents.dates;

  dispatch(getCalendarEventsForAgendaByDates(agendaDates));
  dispatch(getCalendarEventsByDates(calendarDates));

};

const eventTypeFilterClickEvent = (eventType: EventType, isChecked: boolean) => ({
  type: EVENT_TYPE_FILTER_CLICK,
  eventType,
  isChecked,
});

export const taskCompleted = (response: ICalendarEvent) => ({
  type: TASK_COMPLETED,
  response,
});

export const upsertedCalendarEvent = (response: any) => ({
  type: UPSERT_CALENDAR_EVENT,
  response
});

export const deletedCalendarEvent = (id: number) => ({
  type: DELETE_CALENDAR_EVENT,
  id
});

// export const getAgenda = ({ startDate, skip, top }: any) => (dispatch: any) => {
//   const NextMonthDate = moment(startDate).clone().add(1, 'month');
//   const eventTypes = [
//     CalendarEventType.Task,
//     CalendarEventType.Personal,
//     CalendarEventType.Booking,
//     CalendarEventType.Proposal
//   ]

//   return getCalendarAndBookingEventsByDates({ firstDate: startDate, lastDate: NextMonthDate, eventTypes, skip, top })(dispatch);
// }

// export const getWeekAgenda = ({ startDate, eventTypes }: any) => (dispatch: any) => {
//   const NextWeekDate = moment(startDate).clone().add(7, 'days');

//   return getCalendarAndBookingEventsByDates({ firstDate: startDate, lastDate: NextWeekDate, eventTypes })(dispatch);
// }

// export const getCalendarEventsByDates = (params: any) => async (dispatch: any) => {
//   dispatch(fetchCalendarEventsBegin());
//   const { firstDate, lastDate, eventTypes, skip, top } = params;

//   const oDataSkip = `$skip=${skip ? skip : 0}&`;
//   const oDataTop = top ? `$top=${top}&` : '';
//   const oDataOrderBy = '$orderBy=StartDateTime&';

//   const endDateFilter = lastDate ? ` and EndDateTime le ${moment(lastDate).format()}` : '';
//   let eventTypesFilter = '';
//   if (eventTypes && eventTypes.length) {
//     const calendarEventType = 'Cover.Domain.Models.Enums.CalendarEventType';
//     eventTypesFilter = ` and (CalendarEventType eq ${calendarEventType}'${eventTypes.join(`' or CalendarEventType eq ${calendarEventType}'`)}')`
//   }
//   const oDataFilter = `$filter=StartDateTime ge ${moment(firstDate).format()}${endDateFilter}${eventTypesFilter}`;

//   const calendarEvents: ICalendarEvent[] = await dispatch(callApi(
//     `${calendarEventApiUrl}?${oDataSkip}${oDataTop}${oDataOrderBy}${oDataFilter}`))
//     .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response));

//   const calendarEventsWithFusionProps = addFusionProps(calendarEvents);

//   dispatch(calendarEventsReceived(calendarEventsWithFusionProps, params));

//   return calendarEventsWithFusionProps;
// };

// export const getCalendarAndBookingEventsByDates = (params: any) => async (dispatch: any) => {
//   dispatch(fetchCalendarEventsBegin());
//   const { firstDate, lastDate, eventTypes, skip, top } = params;

//   if (!eventTypes || !eventTypes.length) {
//     return [];
//   }

//   const eventTypesString = eventTypes.join('&eventTypes=');

//   const calendarAndBookingEvents: ICalendarEvent[] = await dispatch(callApi(
//     `${calendarEventApiUrl}/withBookings?firstDate=${moment(firstDate).format()}&lastDate=${moment(lastDate).format()}&eventTypes=${eventTypesString}&skip=${skip ? skip : 0}&top=${top ? top : 0}`))
//     .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response));

//   const calendarEventsWithFusionProps = addFusionProps(calendarAndBookingEvents);

//   dispatch(calendarEventsReceived(calendarEventsWithFusionProps, params));

//   return calendarEventsWithFusionProps;
// };

// const flipToExludedEventTypes = (eventTypes: string[] = []) => {
//   const excludedEventTypes: string[] = [];

//   defaultSelectedEventTypes.forEach(eventType => {
//     if (eventTypes.indexOf(eventType) === -1) {
//       excludedEventTypes.push(eventType);
//     }
//   });

//   return encodeURIComponent(JSON.stringify(excludedEventTypes));
// };

// export const getTasks = (skip = 0, pageSize = 30) => {
//   const eventTypes = ['Task'];

//   return (dispatch: any) => dispatch(callApi(`Events/calendarEvent?excludedEventTypes=${flipToExludedEventTypes(eventTypes)}&excludeCompletedTasks=true&skip=${skip}&pageSize=${pageSize}`))
//     .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response));
// };

export const deleteCalendarEvent = (calendarEventId: number) => (dispatch: any) => {
  dispatch(updatingCalendarEvent(true));
  return dispatch(callApi(`${calendarApiUrl}/Event/${calendarEventId}`, { method: 'delete' }))
    .then(() => { dispatch(updatingCalendarEvent(false)) });
}

export const saveCalendarEvent = (calendarEvent: ICalendarEvent) => (dispatch: any) => {
  return upsert(calendarEvent)(dispatch)
    .then((response: any) => { dispatch(upsertedCalendarEvent(response)) });
};

export const markTaskCompleted = (eventId: number) => { }
//   return (dispatch: any) => dispatch(callApi(`Events/calendarEvent/complete/${eventId}`, { method: 'put' }))
//     .then((response: any) => dispatch(taskCompleted(response)));
// };

// export const onSchedulerDaySelected = (selectedDate: any) => {
//   return (dispatch: any) => dispatch(disSchedulerDaySelected(selectedDate));
// };

// const add = (calendarEvent: ICalendarEvent) => (dispatch: any) => {
//   return dispatch(callApi(calendarEventApiUrl, { body: calendarEvent }))
//     .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response));
// }

const upsert = (calendarEvent: ICalendarEvent) => (dispatch: any) => {
  return dispatch(callApi(`${calendarApiUrl}/Event`, { method: 'post', body: calendarEvent }))
    .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response));
}

// const addFusionProps = (calendarEvents: ICalendarEvent[]) => {
//   return calendarEvents.map((calendarEvent: ICalendarEvent) => {
//     return {
//       ...calendarEvent,
//       syncFusionStart: new Date(calendarEvent.startDateTime),
//       syncFusionEnd: new Date(calendarEvent.endDateTime),
//     };
//   });
// };
