import React, { Component } from 'react';
import CoverExpansionPanel from 'Components/CoverExpansionPanel';
import TextField from 'Components/TextField';
import ComboBox from 'Components/ComboBox';
import SaveBar from 'Components/SaveBar';
import Select from 'Components/Select';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';
import { getAccountNameAndIds } from 'actions/account';
import LeadContext from './LeadContext';
import AddressSearch from 'Components/AddressSearch';
import { handleAddressSearchDone } from 'shared/GoogleApi';
import AddressFields from 'Components/AddressFields';
import { getStatusesByType } from 'actions/settings';

const styles = {
  fieldsContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: 0,
  },
  addressSearch: {
    display: 'flex',
  },
  fullWidth: {
    display: 'flex',
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
};

class GeneralFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountSuggestions: [],
      accountsMap: {},
      addressParts: {
        streetAddress1Number: null,
        streetAddress1Route: null,
      },
    };
    this.fieldMap = {
      street_number: 'streetAddress1Number',
      route: 'streetAddress1Route',
      locality: 'city',
      administrative_area_level_1: 'state',
      postal_code: 'zipCode',
    };
  }

  componentDidMount() {
    this.props.getAccountNameAndIds();
    this.setupAccountSuggestions(this.props.accounts);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accounts !== this.props.accounts) {
      this.setupAccountSuggestions(this.props.accounts);
    }
  }

  setupAccountSuggestions = accounts => {
    if (accounts) {
      const { comboBoxSuggestions, comboBoxMap } = setupComboBoxSuggestions(accounts);

      this.setState({ accountSuggestions: comboBoxSuggestions, accountsMap: comboBoxMap });
    }
  }

  editField = (fieldName, updateField) => newValue => {
    updateField(fieldName, newValue);

    return Promise.resolve();
  }

  // If an existing Account is picked from the list, send AccountId AND Company
  handleSelectedAccount = stashLead => pick => {
    const { accountsMap } = this.state;
    const accountId = pick && pick.value && pick.value.id;

    this.editField('company', stashLead)(accountsMap[accountId])
      .then(() => this.editField('accountId', stashLead)(accountId));
  }

  // If typing a one-off, set Company name, null out accountId link (if any)
  handleOneOffCompany = stashLead => value => {
    this.editField('company', stashLead)(value)
      .then(() => this.editField('accountId', stashLead)(null));
  }

  onAddressSearchDone = onAddressFieldChange => selection =>  {
    handleAddressSearchDone(selection, onAddressFieldChange); // Google Service will use local onFieldChange after parsing results
  }

  render() {
    const {
      classes,
      leadStatuses,
    } = this.props;
    const {
      accountSuggestions,
    } = this.state;

    return (
      <LeadContext.Consumer>
        {({ cancelEditMode, onLaunchEditMode, stashLead, saveLead, lead, isEditing, validateField, errors, onAddressFieldChange }) => (
          <>
            <CoverExpansionPanel title="General" defaultExpanded={true}>
              <div className={classes.fieldsContent} onDoubleClick={onLaunchEditMode}>
                <div className={classes.fullWidth}>
                  <div className={classes.halfWidth}>
                    <TextField
                      disabled={!isEditing}
                      label={'First Name'}
                      value={lead.firstName}
                      error={errors.firstName}
                      onBlur={validateField('firstName')}
                      onFieldChange={this.editField('firstName', stashLead)}
                    />
                    <TextField
                      disabled={!isEditing}
                      label={'Last Name'}
                      value={lead.lastName}
                      error={errors.lastName}
                      onBlur={validateField('lastName')}
                      onFieldChange={this.editField('lastName', stashLead)}
                    />
                  </div>
                </div>
                <ComboBox
                  label="Company"
                  disabled={!isEditing}
                  value={lead.company}
                  suggestions={accountSuggestions}
                  isClearable={true}
                  isCreatable={true}
                  handleChange={this.handleSelectedAccount(stashLead)}
                  handleQuickAdd={this.handleOneOffCompany(stashLead)}
                />
                <TextField
                  disabled={!isEditing}
                  label={'Title'}
                  value={lead.title}
                  onFieldChange={this.editField('title', stashLead)}
                />
                <Select
                  label="Lead Status"
                  value={lead.statusId}
                  onFieldChange={this.editField('statusId', stashLead)}
                  disabled={!isEditing}
                >
                  {leadStatuses && leadStatuses.map((status, index) =>
                    <MenuItem key={index} value={status.id}>{status.name}</MenuItem>
                  )}
                </Select>
                <TextField
                  type="phone"
                  disabled={!isEditing}
                  label={'Phone'}
                  value={lead.phone}
                  onFieldChange={this.editField('phone', stashLead)}
                />
                <TextField
                  disabled={!isEditing}
                  label={'Email'}
                  value={lead.email}
                  error={errors.email}
                  onBlur={validateField('email')}
                  onFieldChange={this.editField('email', stashLead)}
                />
                <TextField
                  disabled={!isEditing}
                  label={'Category'}
                  value={lead.category}
                  onFieldChange={this.editField('category', stashLead)}
                />
              </div>
            </CoverExpansionPanel>
            <CoverExpansionPanel title="Address Information" defaultExpanded={true}>
              <div className={classes.fieldsContent} onDoubleClick={onLaunchEditMode}>
                <div className={classes.addressSearch}>
                  {isEditing &&
                  <AddressSearch
                    onSelection={this.onAddressSearchDone(onAddressFieldChange)}
                  />
                  }
                </div>
                <AddressFields
                  address={lead.leadAddress || {}}
                  onFieldChange={onAddressFieldChange}
                  isEditing={isEditing}
                />
              </div>
            </CoverExpansionPanel>

            {isEditing && <SaveBar
              isSticky={true}
              onSave={saveLead}
              onCancel={cancelEditMode} />}
          </>
        )}
      </LeadContext.Consumer>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      accounts,
    },
    settings: {
      statuses,
    },
  } = state;

  return {
    accounts: accounts.nameAndIds,
    leadStatuses: getStatusesByType(statuses, 'Lead'),
  };
};

const mapDispatchToProps = {
  getAccountNameAndIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GeneralFields));
