import React, { Component } from 'react';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import {
  IconButton,
} from '@material-ui/core';
import Modal from 'Components/Modal';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getCardPaymentUrl } from 'actions/orderPayment';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisLabels,
  ChartValueAxisLabels,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';

import moment from 'moment';

const styles = () => ({
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 1,
    right: 5,
    top: 5,
    padding: 0,
    color: '#177db8',
  },
  container: {
    height: 660,
  },
});

class SalesWidgetModal extends Component {
  state = {

  };

  render() {
    const { classes, data, field, graphColor, title, onClose, valueFormat } = this.props;

    const renderSalesTooltip = ({ point, dataItem }) => {
      const date = dataItem ? dataItem.date : point.dataItem.date;
      const value = dataItem ? dataItem.totalSold : point.dataItem.totalSold;

      return `${moment(date).format("l")} : $${value}`;
    };

    return (
      <Modal
        className={classes.paymentModal}
        open={true}
        dimensions={{ width: 1000, height: 800 }}

        isOpened={true}

        onSave={onClose}
        saveText="Close"
        showCancel={false}
        title={title}
        addTitleBottomBorder={true}
      >
        <div className={classes.container}>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>

          {data && <Chart style={{ height: '100%', width: '100%' }}>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                line={{ visible: true }}
                majorGridLines={{ visible: true }}
                visible={true}
                categories={data.totals.map(item => moment(item.date).format('L'))}
                rotation={90}
              >
                <ChartCategoryAxisLabels rotation={45} />
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                line={{ visible: true }}
                majorGridLines={{ visible: true }}
                visible={true}
              >
                <ChartValueAxisLabels format={valueFormat} />
              </ChartValueAxisItem>
            </ChartValueAxis>
            <ChartSeries >
              <ChartSeriesItem type="area" data={data.totals} field={field} line={{ style: 'normal' }} markers={{ visible: false }} color={graphColor} opacity={0.1} />
              <ChartSeriesItem type="line" data={data.totals} field={field} line={{ style: 'normal' }} markers={{ visible: false }} color={graphColor} opacity={1} />
            </ChartSeries>
            <ChartTooltip render={renderSalesTooltip} />
          </Chart>}

        </div>
      </Modal>
    );
  }
}

SalesWidgetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  valueFormat: PropTypes.string.isRequired,
  graphColor: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  storeOrder: state.storeReducer.storeOrder,
});

const mapDispatchToProps = {
  getCardPaymentUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SalesWidgetModal));
