import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getTaxSchedules } from 'actions/taxSchedules';
import { getMerchantAccounts } from 'actions/merchantAccount';
import PickBranch from 'Components/PickBranch';
import FinancialsContext from './FinancialsContext';
import RevenueTypeRatesManager from './RevenueTypeRates/RevenueTypeRatesManager';
import DeliveryZones from './DeliveryZones';
// import PaymentProcessors from './PaymentProcessors';
import RevenueTypesManager from './RevenueTypes/RevenueTypesManager';

const styles = theme => ({
  financials: {
    display: 'flex',
    flex: 1,
  },
  flex: {
    flexDirection: 'column',
    width: 230,
    display: 'flex',
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  financialSetting: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  financialSetup: {
    fontSize: 16,
    padding: 3,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[50],
  },
  placeholderMessage: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
  },
});

class Financials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedNodes: {},
      selectedNode: null,
      selectedSettingId: null,
      taxSchedules: [],
      financialMenus: [
        // {
        //   name: 'Payment Options', id: 1, pickLists: [ TODO: Hidden for MVP
        //     { name: 'Merchant Accounts', id: 1 },
        //   ],
        // },
        {
          name: 'Revenue Configuration', id: 2, pickLists: [
            { name: 'Revenue Types', id: 1 },
            { name: 'Revenue Type Rates', id: 2 },
          ],
        },
        {
          name: 'Delivery Zones', id: 3, pickLists: [
            { name: 'Postal Code', id: 1 },
            { name: 'Distance', id: 2 },
            { name: 'Travel Time', id: 3 },
            { name: 'Polygon', id: 4 },
          ],
        },
        // { name: 'Gateway', id: 3, pickLists: [{ name: 'three', id: 3 }] }, TODO: Hidden for Beta
      ],
    };
  }

  componentDidMount() {
    const { getMerchantAccounts } = this.props;

    getMerchantAccounts();
  }

  // getTaxScheduleList = () => {
  //   this.props.getTaxSchedules().then(taxSchedules => {
  //     const menus = [...this.state.financialMenus];
  //     const indexOfTaxNode = menus.findIndex(n => n.name === 'Tax Schedule');

  //     menus.splice(indexOfTaxNode, 1, { name: 'Tax Schedule', id: 2, pickLists: taxSchedules });

  //     this.setState({ financialMenus: menus });
  //   });
  // }

  onSettingPicked = (selectedNode, selectedSettingId) => {
    this.setState({ selectedSettingId, selectedNode });
  }

  onToggleExpand = category => () => {
    const newExpanded = !this.state.expandedNodes[category.name];
    const expandedNodes = {
      ...this.state.expandedNodes,
      [category.name]: newExpanded,
    };

    this.setState({ expandedNodes });
  }

  getSetting = () => {
    const { selectedSettingId, selectedNode } = this.state;

    switch (selectedNode) {
    // case 'Payment Options':
    //   return <PaymentProcessors />;
      case 'Delivery Zones':
        return <DeliveryZones selectedSettingId={selectedSettingId} />;
      case 'Revenue Configuration':
        return selectedSettingId === 1 ? <RevenueTypesManager /> : <RevenueTypeRatesManager />;
      default:
        return `${selectedNode}`;
    }
  }

  render() {
    const {
      classes,
      locationId,
    } = this.props;

    const {
      selectedSettingId,
      expandedNodes,
      financialMenus,
    } = this.state;

    const financialsContext = {
      locationId,
    };

    return (
      <FinancialsContext.Provider value={financialsContext}>
        <div className={classes.financials}>
          <div className={classes.flex}>
            <div className={classes.financialSetup}>Financial Setup</div>
            <div className={classes.options}>
              {financialMenus.map(category => (
                <PickBranch
                  key={category.name}
                  expanded={expandedNodes[category.name]}
                  onToggleExpand={this.onToggleExpand(category)}
                  onPickListPicked={setting => this.onSettingPicked(category.name, setting.id)}
                  pickLists={category.pickLists}
                  selectedListId={selectedSettingId}
                >
                  {category.name}
                </PickBranch>
              ))}
            </div>
          </div>

          <div className={classes.financialSetting}>
            {selectedSettingId ? this.getSetting() : <div className={classes.placeholderMessage}>Select an option to manage your settings.</div>}
          </div>

        </div>
      </FinancialsContext.Provider>
    );
  }
}

const mapDispatchToProps = {
  getTaxSchedules,
  getMerchantAccounts,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Financials));
