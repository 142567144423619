import React, { Component } from 'react';
import './ErrorPage.css';
import logo from 'loginLogo.png';

class ErrorPage extends Component {
  render() {
    return <>
      <img src={logo} class="logo" alt="logo" width="192" />

      <div class="browser">
        <div class="controls">
          <i></i>
          <i></i>
          <i></i>
        </div>

        <div class="eye"></div>
        <div class="eye"></div>
        <div class="mouth">
          <div class="lips"></div>
          <div class="lips"></div>
          <div class="lips"></div>
          <div class="lips"></div>
          <div class="lips"></div>
          <div class="lips"></div>
          <div class="lips"></div>
          <div class="lips"></div>
        </div>
      </div>

      <h1 style={{ textAlign: 'center' }}>Unfortunately, something has gone wrong.</h1>
      <p style={{ color: '#070E4F',fontWeight: '300',maxWidth: '600px',textShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',letterSpacing: '0.5px',margin: '0 auto 24px',textAlign: 'center',padding: '0 20px' }}>We're unable to fulfill your request. Rest assured we have been notified and are looking into the issue. Please refresh your browser. If the error continues, please contact our <a href="http://mcause.us/supportticket">support team</a>.</p>
    </>;
  }
}

export default ErrorPage;
