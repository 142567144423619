import React from 'react';
//import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

const styles = theme => ({
  button: {
    borderRadius: 3,
    marginLeft: -1,
  },
  active: {
    color: theme.palette.secondary.dark,
  },
  notActive: {
    color: theme.palette.grey[800],
  },
  middleButton: {
    borderLeftColor: `${theme.palette.grey[100]} !important`,
    borderRightColor: `${theme.palette.grey[100]} !important`,
    borderRadius: '0px 0px 0px 0px',
  },
  leftButton: {
    borderRadius: '3px 0px 0px 3px',
    borderRightColor: `${theme.palette.grey[100]} !important`,
  },
  rightButton: {
    borderRadius: '0px 3px 3px 0px',
    borderLeftColor: `${theme.palette.grey[100]} !important`,
  },
});

function IconBorderButton(props) {
  const {
    children,
    classes,
    className: classNameProp,
    isActive,
    variant,
    ...rest
  } = props;

  let variantClass;

  if (variant === 'left') {
    variantClass = classes.leftButton;
  } else if (variant === 'right') {
    variantClass = classes.rightButton;
  } else if (variant === 'middle') {
    variantClass = classes.middleButton;
  }

  const className = classNames(classNameProp, classes.button, isActive ? classes.active : classes.notActive, variantClass);

  return (
    <ButtonBase
      className={className}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
}

export default withStyles(styles)(IconBorderButton);
