import {
  RECEIVE_COMPANY,
  RECEIVE_LOCATIONS,
  ADD_LOCATION,
  RECEIVE_LOCATION,
  REQUEST_LOCATION,
  LOCATIONS_REQUEST_BEGIN,
  ITEMS_REQUEST_BEGIN,
  RECEIVE_ITEMS,
  LOCATION_SELECTED,
  RECEIVE_CATEGORIES,
  LOCATION_NOT_RECEIVED,
  EDIT_LOCATION_BEGIN,
  FETCH_LIBRARY_ITEM_IMAGE_BEGIN,
  FETCH_LIBRARY_ITEM_IMAGE_SUCCESS,
  FETCH_LIBRARY_ITEM_IMAGE_FAILURE,
  ITEMS_REQUEST_END,
  FETCH_TAX_TYPES_BEGIN,
  FETCH_ALL_TAX_TYPES_SUCCESS,
  FETCH_DEFAULT_TAX_TYPES_SUCCESS,
  RESET_RECENTS,
  ITEM_TAGS_RECEIVED,
  ITEM_COLUMNS_RECEIVED,
} from './constants';

import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

export const resetAndReseed = () =>
  dispatch => {
    dispatch(resetRecents());
    dispatch(callApi(`data/reset`, { method: 'post' })).then(response => {
      if (response.status !== 200) {
        alert(`Failed to reset database. Status Code: ${response.status}`);
      }
      else {
        alert("Database reset was completed successfully.");
        window.location.reload(false);
      }
    });
  };

export const checkAccess = currentLocation => {
  const url = currentLocation ?
    'admin/access?checkAccessToLocationId=' + currentLocation.id :
    'admin/access';

  return dispatch =>
    dispatch(callApi(url))
      .then(response => {
        if (response.status === 403) {
          throw Error('no access');
        }

        return ParseJsonOnSuccessOrStop(dispatch, response);
      });
};

export const fetchItemsBegin = params => ({
  type: ITEMS_REQUEST_BEGIN,
  params,
});

export const fetchItemsSuccess = (response, params, skipParamCheck = false) => ({
  type: RECEIVE_ITEMS,
  response,
  params,
  skipParamCheck,
});

export const fetchItemsEnded = () => ({
  type: ITEMS_REQUEST_END,
});

export const fetchCategoriesSuccess = categories => ({
  type: RECEIVE_CATEGORIES,
  categories,
});

export const companyReceived = company => ({
  type: RECEIVE_COMPANY,
  company: company,
});

export const fetchLocationsBegin = params => ({
  type: LOCATIONS_REQUEST_BEGIN,
  params,
});

export const fetchLocationsSuccess = (response, params) => ({
  type: RECEIVE_LOCATIONS,
  response,
  params,
});

export const locationRequested = () => ({
  type: REQUEST_LOCATION,
});

export const locationDetailsReceived = locationDetails => ({
  type: RECEIVE_LOCATION,
  locationDetails,
});

export const locationDetailsNotReceived = error => ({
  type: LOCATION_NOT_RECEIVED,
  error,
});

export const locationSelected = ({ id, isSelected, isAll }) => ({
  type: LOCATION_SELECTED,
  id,
  isSelected,
  isAll,
});

export const locationAdded = locationDetails => ({
  type: ADD_LOCATION,
  locationDetails,
});

export const editLocationBegin = () => ({
  type: EDIT_LOCATION_BEGIN,
});

export const fetchLibraryItemImageBegin = () => ({
  type: FETCH_LIBRARY_ITEM_IMAGE_BEGIN,
});

export const fetchLibraryItemImageSuccess = itemImage => ({
  type: FETCH_LIBRARY_ITEM_IMAGE_SUCCESS,
  itemImage,
});

export const fetchLibraryItemImageFailure = error => ({
  type: FETCH_LIBRARY_ITEM_IMAGE_FAILURE,
  error,
});

export const fetchTaxTypesBegin = () => ({
  type: FETCH_TAX_TYPES_BEGIN,
});

export const fetchAllTaxTypesSuccess = allTaxTypes => ({
  type: FETCH_ALL_TAX_TYPES_SUCCESS,
  allTaxTypes,
});

export const fetchDefaultTaxTypesSuccess = defaultTaxTypes => ({
  type: FETCH_DEFAULT_TAX_TYPES_SUCCESS,
  defaultTaxTypes,
});

export const resetRecents = () => ({
  type: RESET_RECENTS,
});

export const itemTagsReceived = itemTags => ({
  type: ITEM_TAGS_RECEIVED,
  itemTags,
});

export const itemColumnsReceived = columns => ({
  type: ITEM_COLUMNS_RECEIVED,
  columns,
});

