import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import OrderContext from './OrderContext';
import OrderHeader from './OrderHeader';
import SectionContext from 'Components/Contexts/SectionContext';
import MainTab from './MainTab';
import Clone from './Clone';
import { Paper } from '@material-ui/core';
import ChitChat from 'Components/ChitChat';
import OrdersSideTab from './OrdersSideTab';
import Email from 'Components/Email';
import SimpleDialog from 'Components/SimpleDialog';
import Toast from 'Components/Toast';
import ValidationBanner from 'Components/ValidationBanner';
import { withRouter } from 'react-router-dom';
import OrderStatusBar from './OrderStatusBar';
import {
  getLocations,
  getLocation,
} from 'actions/location';
import {
  getSettingAreas,
} from 'actions/settings';
import {
  getOrder,
  revertStoreOrder,
  updateStoreOrder,
  updateStoreOrderAPI,
  replaceStoreOrderAPI,
  clearStoreOrder,
  mapAddressToOrderDelivery,
  deleteWorkingStoreOrder,
  updateContactAddressesAndStoreOrderAPI,
  orderChitChatSystemMessage,
  getStatusId,
} from 'actions/order';
import {
  getOrderPayments,
  addOrderPayment,
} from 'actions/orderPayment';
import {
  getContact,
  currentContactReset,
} from 'actions/contact';
import { addToRecents, removeRecent } from 'actions/recents';
import {
  initWorkingAddresses,
  clearWorkingAddresses,
  workingAddressesAddNew,
  workingAddressesDelete,
  workingAddressesOnChange,
  workingAddressesSelectAddress,
  workingAddressesOnPrimaryChange,
} from 'actions/address';
import {
  fetchChitChat,
} from 'actions/chitchat';
import {
  getStatusHistory,
  addStatusHistory,
} from 'actions/statusHistory';
import moment from 'moment';
import validate from 'validate.js';
import { ENTITY_TYPES } from 'constants/entityTypes';
import { getStoreMenu } from 'actions/storemenu';
import { MenuType } from 'models/MenuType';

const styles = () => ({
  orderContent: {
    display: 'flex',
    flexGrow: 1,
    padding: '18px 24px 0',
    height: 'calc(100% - 120px - 82px)',
  },
  statusBar: {
    marginTop: 16,
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    borderRadius: 4,
    marginRight: 24,
    height: '100%',
  },
  side: {
    display: 'flex',
    borderRadius: 4,
    height: '100%',
  },
  flexGrow: {
    display: 'flex',
    flexGrow: 1,
  },
});

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      invalidFields: [],
      isValidationBannerVisible: false,
      viewState: 'orderDetails',
      locations: [],
      orderTimes: [],
      orderLocation: null,
      statusHistory: [],
    };
    this.orderDialog = React.createRef();
    this.okDialog = React.createRef();
    this.cloneModal = React.createRef();
  }

  componentDidMount() {
    this.getOrderData();
    this.props.getStoreMenu(MenuType.QuickOrder);
    this.props.getSettingAreas();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.getOrderData();
      this.props.clearWorkingAddresses();
    }
  }

  componentWillUnmount() {
    this.props.clearStoreOrder();
    this.props.clearWorkingAddresses();
    this.props.currentContactReset();
  }

  setView = viewState => {
    this.setState({ viewState });
  }

  getOrderData = () => {
    const { getOrder, match, addToRecents, getLocations, initWorkingAddresses, getStatusHistory } = this.props;

    this.setState({ locations: getLocations() });
    getOrder(match.params.id).then(order => {
      this.refreshLocationDetails(order.locationId);

      if (order.orderDelivery) {
        initWorkingAddresses([order.orderDelivery]);
      }

      addToRecents(order, match.url, ENTITY_TYPES.order);
    }).catch(error => {
      console.error(error);
      this.orderDialog && this.orderDialog.current && this.orderDialog.current.open('That order was not found. Go back to Orders?').then(this.goToOrdersGrid);
    });
    getStatusHistory('Order', match.params.id).then(statusHistory => {
      this.setState({ statusHistory });
    });
  }

  toggleEditMode = () => {
    if (this.state.isEditing) {
      this.cancelEditMode();
    } else {
      this.launchEditMode();
    }
  }

  launchEditMode = () => {
    this.setState({ isEditing: true });
  }

  cancelEditMode = () => {
    this.orderDialog.current.open('Are you sure you want to lose your changes?').then(() => {
      this.props.revertStoreOrder();
      this.setState({ isEditing: false });
      this.getOrderData();

      return;
    });
  }

  cloneOrder = () => {
    const { storeOrder, currentLocation } = this.props;

    if (currentLocation) {
      this.cloneModal.current.open(storeOrder.id);
    } else {
      this.okDialog.current.open('Please set your location before cloning this order');
    }
  }

  handleCloneSuccess = newOrderId => {
    this.orderDialog.current.open('Successfully cloned! Would you like to go to the newly created order?')
      .then(() => this.props.history.push(`/orders/${newOrderId}`))
      .catch(this.refreshChitChat);
  }

  goToOrdersGrid = () => {
    this.props.history.push('/orders');
  }

  deleteOrder = () => {
    const { storeOrder, removeRecent, deleteWorkingStoreOrder } = this.props;

    this.orderDialog.current.open('Are you sure you want to delete this order?').then(() => {
      deleteWorkingStoreOrder().then(() => {
        this.goToOrdersGrid();
        removeRecent(storeOrder.id, ENTITY_TYPES.order);
      });
    });
  }

  save = () => {
    if (this.isFormValid() === true) {
      this.saveOrder();
    } else {
      this.setState({ isValidationBannerVisible: true });
    }
  }

  saveOrder = () => {
    this.setState({ isSaving: true });
    this.props.updateContactAddressesAndStoreOrderAPI().then(() => {
      this.setState({ isEditing: false, isSaving: false });
    });
  }

  handleFieldChange = fieldName => value => {
    const { updateStoreOrder, storeOrder } = this.props;
    const order = {
      ...storeOrder,
      [fieldName]: value,

    };

    if (fieldName === 'timeNeeded' || fieldName === 'dateNeeded') {
      if (value === 'ASAP' || (fieldName === 'dateNeeded' && moment(order.dateNeeded).isSame(moment(), 'day'))) {
        order.requestedDateTime = moment().format();
        order.asap = true;
      } else {
        const timeNeeded = moment.isDate(order.timeNeeded) ? moment(order.timeNeeded).clone().format('LT') : order.timeNeeded;

        if (timeNeeded) {
          order.requestedDateTime = moment(moment(order.dateNeeded).format('YYYY/MM/DD') + ' ' + timeNeeded).format();
        } else {
          order.requestedDateTime = moment(order.dateNeeded).format();
        }
        order.asap = false;
      }
    }
    updateStoreOrder(order);
  }

  handleOrderTypeChange = value => {
    const { storeOrder, workingAddresses, selectedAddressId, updateStoreOrder, mapAddressToOrderDelivery } = this.props;

    const updatedStoreOrder = {
      ...storeOrder,
      type: value,
    };

    if (value === 'Pickup') {
      updatedStoreOrder.orderDelivery = null;
    } else if (value === 'Delivery') {
      const selectedAddress = workingAddresses && workingAddresses.length
        ? workingAddresses.find(a => a.id === selectedAddressId) || workingAddresses.find(a => a.primary)
        : workingAddresses[0];

      if (selectedAddress) updatedStoreOrder.orderDelivery = mapAddressToOrderDelivery(selectedAddress, updatedStoreOrder);
    }

    updateStoreOrder(updatedStoreOrder);
  }

  isFieldChanged = fieldName => {
    const { storeOrder, storeOriginalOrder } = this.props;
    const { isEditing } = this.state;

    return isEditing && storeOrder[fieldName] !== storeOriginalOrder[fieldName];
  }

  isFieldInvalid = fieldName => {
    return this.state.invalidFields.indexOf(fieldName) !== -1;
  }

  validateField = fieldName => {
    var isValid = true;
    var value = this.props.storeOrder[fieldName];

    switch (fieldName) {
      case 'email':
        const emailResult = validate({ email: value }, { email: { email: true } });

        isValid = !emailResult;
        break;
      case 'name':
        const nameResult = validate({ name: value }, { name: { presence: { allowEmpty: false } } });

        isValid = !nameResult;
        break;
      default:
        return;
    }

    let invalidFields = [...this.state.invalidFields];
    const indexOfField = invalidFields.indexOf(fieldName);

    if (!isValid) {
      if (indexOfField === -1) {
        invalidFields.push(fieldName);
      }
    } else {
      if (indexOfField !== -1) {
        invalidFields.splice(indexOfField, 1);
      }
    }

    if (this.state.isValidationBannerVisible && invalidFields.length === 0) {
      this.setState({ invalidFields, isValidationBannerVisible: false });
    } else {
      this.setState({ invalidFields });
    }

  }

  isFormValid = () => {
    return this.state.invalidFields.length === 0;
  }

  handleSelectAddress = selectedAddress => {
    const { isEditing } = this.state;
    const { storeOrder, workingAddressesSelectAddress, mapAddressToOrderDelivery } = this.props;

    if (isEditing) {
      storeOrder.orderDelivery = mapAddressToOrderDelivery(selectedAddress, storeOrder);
      this.setState({ storeOrder });

      if (selectedAddress) workingAddressesSelectAddress(selectedAddress.id);
    }
  }

  handleSelectLocation = selectedLocationId => {
    const updatedStoreOrder = {
      ...this.props.storeOrder,
      locationId: selectedLocationId,
    };

    this.refreshLocationDetails(selectedLocationId);
    this.props.updateStoreOrder(updatedStoreOrder);
  }

  refreshLocationDetails = locationId => {
    this.props.getLocation(locationId).then(location => {
      this.setState({ orderLocation: location });
    });
  }

  handleAddressChange = (selectedAddressId, fieldName, value) => {
    this.props.workingAddressesOnChange(selectedAddressId, fieldName, value);
  }

  addAddress = () => {
    this.setState({ isEditing: true });
    this.props.workingAddressesAddNew();
  }

  deleteAddress = selectedAddressId => {
    this.setState({ isEditing: true });
    this.props.workingAddressesDelete(selectedAddressId);
  }

  handlePrimaryAddress = (addressId, isChecked) => {
    this.props.workingAddressesOnPrimaryChange(addressId, isChecked);
  }

  getOrderTimes = storeOrder => {
    let minTime = moment(storeOrder.requestedDateTime).clone();

    const maxTime = moment({ hour: 21, minute: 0, seconds: 0 }); // Closing time is hardcoded to 9 PM until we pull real data
    const timesArray = [];

    if (!storeOrder.requestedDateTime || moment(storeOrder.requestedDateTime).clone().isSame(moment(), 'day')) { // If order date is today, add ASAP option and filter start time to current time +30
      timesArray.push({ label: 'ASAP', value: 'ASAP' }); //TODO: need to start from current time

      minTime.seconds(0);
      if (minTime.minutes() < 15)
        minTime.minutes(30);
      else if (minTime.minutes() < 30)
        minTime.minutes(45);
      else if (minTime.minutes() < 45)
        minTime.minutes(0);
      else {
        minTime.minutes(15);
        minTime.hour(minTime.hour() + 1);
      }
    }
    else { // if this is a future order, allow order placed for any time
      minTime = moment({ hour: 9, minute: 0, seconds: 0 }); // Opening time is hardcoded to 9 AM until we pull real data
    }

    while (minTime.isBefore(maxTime)) {
      timesArray.push({ label: minTime.format('LT'), value: minTime.format('LT') });
      minTime.add(15, 'minute');
    }

    this.setState({ orderTimes: timesArray });
  }

  reloadPayments = () => {
    const {
      getOrderPayments,
      storeOrder: { id },
    } = this.props;

    return getOrderPayments(id);
  }

  addPayment = payment => {
    const { addOrderPayment, orderChitChatSystemMessage } = this.props;

    return addOrderPayment(payment).then(() => {
      orderChitChatSystemMessage(`Payment Added`).then(this.refreshChitChat);
    });
  }

  editPayment = payment => {
    const { updateOrderPayment, orderChitChatSystemMessage } = this.props;

    return updateOrderPayment(payment.id, payment).then(() => {
      orderChitChatSystemMessage(`Payment Changed`).then(this.refreshChitChat);
    });
  }

  deletePayment = paymentId => {
    const { deleteOrderPayment, orderChitChatSystemMessage } = this.props;

    return deleteOrderPayment(paymentId).then(() => {
      orderChitChatSystemMessage(`Payment Deleted`).then(this.refreshChitChat);
    });
  }

  refreshChitChat = () => {
    const { viewState } = this.state;
    const { storeOrder, fetchChitChat } = this.props;

    if (viewState === 'chitChat') {
      fetchChitChat(ENTITY_TYPES.order, storeOrder.id);
    }
  }

  afterFileUpload = file => {
    this.props.orderChitChatSystemMessage(`File Added: ${file.name}`).then(this.refreshChitChat);
  }

  openOrderDetails = () => {
    this.setView('orderDetails');
  }

  filesUpdated = () => {
    this.setState({ filesLastUpdated: new Date() });
  }

  openEmailWithFile = file => {
    this.setState({ viewState: 'email', autoAttachFileId: file.id });
  }

  onEmailDone = () => {
    this.setState({
      viewState: 'orderDetails',
      autoAttachFileId: null,
      successMessageOpen: true,
      successMessage: 'Email sent successfully',
    });
  }

  onEmailCancel = () => {
    this.setState({ viewState: 'orderDetails' });
  }

  handleSuccessMessageClose = () => {
    this.setState({
      successMessageOpen: false,
      successMessage: null,
    });
  }

  setRecordName = storeOrder => {
    let chitChatRecordName = 'Order ' + storeOrder.id;

    if (storeOrder.customerName) chitChatRecordName += ` - ${storeOrder.customerName}`;
    this.setState({ chitChatRecordName });
  }

  updateOrderStatus = statusId => {
    const { storeOrder, statuses } = this.props;

    const placedStatusId = getStatusId('Placed', storeOrder.type, statuses);

    storeOrder.orderStatusId = statusId;
    storeOrder.orderStatus = undefined;

    if (placedStatusId && statusId === placedStatusId) {
      storeOrder.placedDateTime = moment().format();
    }

    return this.props.replaceStoreOrderAPI(storeOrder).then(() => {
      this.props.addStatusHistory('Order', storeOrder.id, storeOrder.orderStatusId).then(statusHistory => {
        this.refreshChitChat();
        this.setState({ statusHistory });
      });
    });
  };

  completeOrder = () => {
    const { storeOrder, statuses } = this.props;
    const completedStatusId = getStatusId('Completed', storeOrder.type, statuses);

    return this.updateOrderStatus(completedStatusId);
  }

  render() {

    const {
      classes,
      workingAddresses,
      selectedAddressId,
      storeOrder,
      contact,
    } = this.props;
    const {
      isEditing,
      isValidationBannerVisible,
      viewState,
      locations,
      orderTimes,
      filesLastUpdated,
      autoAttachFileId,
      chitChatRecordName,
      successMessage,
      successMessageOpen,
      isSaving,
      orderLocation,
      statusHistory,
    } = this.state;

    if (storeOrder && storeOrder.id) {
      const sectionContext = {
        relationship: {
          entityId: storeOrder.id,
          entityType: ENTITY_TYPES.order,
        },
        contact,
        autoAttachFileId,
        chitChatRecordName,
        onEmailDone: this.onEmailDone,
        onEmailCancel: this.onEmailCancel,
        defaultEmail: storeOrder.customerEmail ? storeOrder.customerEmail : contact && contact.email ? contact.email : '',
      };

      const orderContext = {
        storeOrder,
        contactAddresses: workingAddresses,
        locations,
        isEditing,
        selectedAddressId,
        orderTimes,
        filesLastUpdated,
        isSaving,
        orderLocation,
        statusHistory,
        onEdit: this.toggleEditMode,
        onSave: this.save,
        onCancelEdit: this.cancelEditMode,
        onLaunchEditMode: this.launchEditMode,
        onFieldChange: this.handleFieldChange,
        onOrderTypeChange: this.handleOrderTypeChange,
        onAddressChange: this.handleAddressChange,
        onNewAddress: this.addAddress,
        onDeleteAddress: this.deleteAddress,
        onHandlePrimaryAddress: this.handlePrimaryAddress,
        onSelectAddress: this.handleSelectAddress,
        onSelectLocation: this.handleSelectLocation,
        isFieldChanged: this.isFieldChanged,
        validateField: this.validateField,
        isFieldInvalid: this.isFieldInvalid,
        onChitChatUpdated: this.refreshChitChat,
        onFinancialDetailsChanged: this.getOrderData,
        onFileAddSuccess: this.afterFileUpload,
        onUpdateOrderStatus: this.updateOrderStatus,
        onCompleteOrder: this.completeOrder,
      };

      return (
        <OrderContext.Provider value={orderContext}>
          <SectionContext.Provider value={sectionContext}>
            <OrderHeader
              label="Order"
              order={storeOrder}
              isEditing={isEditing}
              onEdit={this.toggleEditMode}
              onDelete={this.deleteOrder}
              onClone={this.cloneOrder}
              onSetView={this.setView}
              onGetPayments={this.reloadPayments}
              onUpdateOrderStatus={this.updateOrderStatus}
              viewState={viewState}
              filesUpdated={this.filesUpdated}
              openEmailWithFile={this.openEmailWithFile}
            />
            <div className={classes.statusBar}>
              <OrderStatusBar />
            </div>

            <div className={classes.orderContent}>
              <div className={classes.main}>
                {viewState === 'orderDetails' &&
                  <MainTab />
                }
                {viewState === 'chitChat' &&
                  <Paper className={classes.flexGrow}>
                    <ChitChat
                      relationshipId={storeOrder.id}
                      chitChatRelationship={ENTITY_TYPES.order}
                      recordName={chitChatRecordName}
                    />
                  </Paper>
                }
                {viewState === 'email' &&
                  <Email />
                }
              </div>
              <div className={classes.side}>
                <OrdersSideTab />
              </div>
            </div>
            <SimpleDialog innerRef={this.orderDialog} />
            <SimpleDialog innerRef={this.okDialog} onlyOkayButton={true} />
            <Toast message={successMessage} variant="success" open={successMessageOpen} onClose={this.handleSuccessMessageClose} />
            {isEditing && <ValidationBanner isOpen={isValidationBannerVisible} />}
            <Clone innerRef={this.cloneModal} onCloneSuccess={this.handleCloneSuccess} />
          </SectionContext.Provider>
        </OrderContext.Provider>
      );
    } else {
      return (<div>
        loading...
        <SimpleDialog innerRef={this.orderDialog} />
      </div>);
    }
  }
}

const mapStateToProps = state => {
  const {
    api: {
      currentLocation,
    },
    address: {
      workingAddresses,
      selectedAddressId,
    },
    contact: {
      currentContact,
    },
    storeReducer: {
      storeOrder,
      storeOriginalOrder,
    },
    settings: {
      statuses,
    },
  } = state;

  return {
    currentLocation,
    workingAddresses,
    selectedAddressId,
    storeOrder,
    storeOriginalOrder,
    contact: currentContact,
    statuses,
  };
};

const mapDispatchToProps = {
  getOrder,
  revertStoreOrder,
  updateStoreOrder,
  updateStoreOrderAPI,
  replaceStoreOrderAPI,
  addToRecents,
  removeRecent,
  getContact,
  currentContactReset,
  getLocations,
  getLocation,
  initWorkingAddresses,
  clearWorkingAddresses,
  workingAddressesAddNew,
  workingAddressesDelete,
  workingAddressesOnChange,
  workingAddressesSelectAddress,
  workingAddressesOnPrimaryChange,
  getOrderPayments,
  addOrderPayment,
  fetchChitChat,
  clearStoreOrder,
  getStoreMenu,
  mapAddressToOrderDelivery,
  deleteWorkingStoreOrder,
  updateContactAddressesAndStoreOrderAPI,
  orderChitChatSystemMessage,
  getStatusHistory,
  addStatusHistory,
  getSettingAreas,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Order)));
