import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";

const styles = theme => ({
  main: {
    height: '100%',
    display: 'flex',
  },
  logo: {
    paddingTop: '48px',
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: '36px',
    paddingTop: '43px',
    paddingBottom: '41px',
    //textAlign: 'center',
  },
  loginForm: {
    width: '518px',
    margin: '28px',
    textAlign: 'center',
  },
  imageArea: {
    backgroundImage: 'url("/images/login.jpeg")',
    flexGrow: 1,
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  grazemain: {
    height: '100vh',
    display: 'flex',
    overflow: 'hidden',
  },
  grazelogo: {
    paddingTop: '48px',
    height: '180px',
  },
  grazeheader: {
    color: theme.palette.common.black,
    fontSize: '35px',
    padding: '43px 0px',
    textShadow: '0 0 black',
  },
  grazeloginForm: {
    width: '518px',
    margin: '28px',
    textAlign: 'center',
    flex: 1,
  },
  grazeimageArea: {
    backgroundImage: 'url("/images/loginGraze.png")',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    flex: '2 1 auto',
  },

});

class Master extends Component {
  state = { showUsername: true };

  render() {
    const { classes, title, children } = this.props;
    const grazeLogin = window.location.hostname.toLowerCase().includes('graze');

    return (
      <>
        {!grazeLogin ?
          <>
            <Helmet>
              <title>Cover</title>
              <link id="favicon" rel="icon" href="/favicon.ico" type="image/x-icon" />
            </Helmet>
            <div className={classes.main}>
              <div className={classes.loginForm}>
                <img src="/images/loginLogo.png" alt="logo" className={classes.logo}></img>
                <h1 className={classes.header}>{title}</h1>
                {children}
              </div>
              <div className={classes.imageArea}>
              </div>
            </div></> :
          <>
            <Helmet>
              <title>Graze</title>
              <link id="favicon" rel="icon" href="/grazefavicon.ico" type="image/x-icon" />
            </Helmet>
            <div className={classes.grazemain}>
              <div className={classes.grazeloginForm}>
                <img src="/images/loginLogoGraze.png" alt="logo" className={classes.grazelogo}></img>
                <h2 className={classes.grazeheader}>Sign in to Graze</h2>
                {children}
              </div><div className={classes.grazeimageArea}>
              </div>
            </div>
          </>}
      </>
    );
  }
}

export default withStyles(styles)(Master);
