import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Link,
} from 'react-router-dom';
import {
  MenuItem,
  MenuList,
  ClickAwayListener,
  Typography,
  Popper,
  Paper,
} from '@material-ui/core';

const styles = theme => ({
  popper: {
    zIndex: theme.zIndex.modal,
  },
  menuItem: {
    width: 180,
    fontSize: 13,
    padding: '6px 16px',
  },
  menuLink: {
    color: theme.common.black,
    '&:hover': {
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
  },
  recentsSubtitle: {
    fontSize: 14,
    fontWeight: 600,
    cursor: 'default',
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  addNew: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
});

const Recents = ({ name, classes, isOpen, recents, onAddNew, anchorEl, onHoverMenuClose, onMenuEnter, onMenuLeave }) => {
  const id = isOpen ? `recents-menu-${name}` : undefined;
  const trimmedName = name && name.replace(/s+$/, '');

  return (
    <ClickAwayListener onClickAway={onHoverMenuClose}>
      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        placement="right-start"
        id={id}
        onClose={onHoverMenuClose}
        className={classes.popper}
      >
        <Paper square onMouseOver={onMenuEnter} onMouseLeave={onMenuLeave}>
          <MenuList>
            {onAddNew &&
              <MenuItem className={classNames(classes.menuItem, classes.addNew)} onClick={onAddNew} >
                <Typography noWrap>
                  + Add New {trimmedName}
                </Typography>
              </MenuItem>
            }
            <MenuItem className={classNames(classes.menuItem, classes.recentsSubtitle)} disableRipple onClick={event => event.stopPropagation()}>
              <span>Recent Records{!recents || !recents.length ? ' (none)' : null}</span>
            </MenuItem>
            {recents && recents.map((recent, index) => (
              <Link key={index} className={classes.menuLink} to={recent.url}>
                <MenuItem className={classes.menuItem} onClick={onHoverMenuClose} >
                  <Typography noWrap>
                    {`${recent.name}`}
                  </Typography>
                </MenuItem>
              </Link>
            ))}
          </MenuList>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default withStyles(styles)(Recents);
