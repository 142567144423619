import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import { setupStylesReceived, roomsReceived, parentRoomsReceived, unavailableRoomsReceived } from './api';
import moment from 'moment';

export const getRooms = (params, append = false) => dispatch =>
  dispatch(callApi(`Room?${params}`))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .then(rooms => {
      dispatch(roomsReceived(rooms, append));

      return rooms;
    })
    .catch(console.error);

export const updateRoom = room => async dispatch =>
  dispatch(callApi(`room/${room.id}`, { body: room, method: 'PUT' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .then(result => {
      return result;
    })
    .catch(console.error);

export const createRoom = room => async dispatch =>
  dispatch(callApi('room', { body: room, method: 'POST' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .then(result => {
      return result;
    })
    .catch(console.error);

export const deleteRoom = room => async dispatch =>
  dispatch(callApi(`room/${room.id}`, { body: room, method: 'DELETE' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

export const getParentRooms = () => dispatch =>
  dispatch(callApi('Room?$filter=ParentRoomId%20eq%20null'))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .then(rooms => {
      dispatch(parentRoomsReceived(rooms));

      return rooms;
    })
    .catch(console.error);

export const getAllSetupStyles = () =>
  dispatch =>
    dispatch(callApi('SetupStyle'))
      .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
      .then(results => {
        dispatch(setupStylesReceived(results));

        return results;
      })
      .catch(console.error);

export const createSetupStyle = setupStyle => async dispatch =>
  dispatch(callApi('SetupStyle', { body: setupStyle, method: 'POST' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .then(result => {
      return result;
    })
    .catch(console.error);

export const updateSetupStyle = setupStyle => async dispatch =>
  dispatch(callApi(`SetupStyle/${setupStyle.id}`, { body: setupStyle, method: 'PUT' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .then(result => {
      return result;
    })
    .catch(console.error);

export const deleteSetupStyle = setupStyle => async dispatch =>
  dispatch(callApi(`SetupStyle/${setupStyle.id}`, { body: setupStyle, method: 'DELETE' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

/*.estimatedGuestCount, event.venueSetupStyleId,
      moment(event.startDateTime).add(-event.venueSetupTimeInMinutes, 'minutes').format(),
      moment(event.endDateTime).add(event.venueTearDownTimeInMinutes, 'minutes').format() */

export const getUnavailableRooms = event =>
  dispatch =>
    dispatch(callApi(`Room/Availability?GuestCount=${event.estimatedGuestCount}&SetupStyleId=${event.venueSetupStyleId}&StartDateTime=${moment(event.startDateTime).add(-event.venueSetupTimeInMinutes, 'minutes').format()}&EndDateTime=${moment(event.endDateTime).add(event.venueTearDownTimeInMinutes, 'minutes').format()}`))
      .then(result => {
        if (result.ok) { return ParseJsonOnSuccessOrStop(dispatch, result); }

        return null;
      })
      .then(results => {
        dispatch(unavailableRoomsReceived(results));

        return results;
      })
      .catch(console.error);
