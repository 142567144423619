import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Paper,
  MenuItem,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';
import Select from 'Components/Select';
import SaveBar from 'Components/SaveBar';
import Checkbox from 'Components/Checkbox';
import { CurrencyField } from 'Components/currencyInput';
import classNames from 'classnames';
import TextField from 'Components/TextField';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ModifierSortableList } from './ModifierSortableList';
import IconButton from 'Components/Buttons/IconButton';
import _ from 'lodash';
import Modifier from 'Components/Menu/Modifier';

const styles = theme => ({
  editor: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modifierTemplate: {
    display: 'flex',
    width: 350,
    height: 700,
    flexDirection: 'column',
    margin: 5,
    marginLeft: 30,
    overflowY: 'hidden',
  },
  cellContents: {
    display: 'flex',
    flexDirection: 'column',
  },
  twoColumns: {
    gridColumn: '2 / 4',
    maxHeight: '252px',
  },
  contents2x1: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  halfWidth: {
    display: 'flex',
    width: '40%',
  },
  fullWidth: {
    display: 'flex',
    width: '100%',
  },
  toolbarButton: {
    width: 20,
  },
  buttonsBar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexGrow: 1,
    padding: 10,
    maxHeight: 50,
  },
  bottomBar: {
    margin: 'auto',
    marginBottom: 0,
    width: '100%',
  },
  stretchVertical: {
    height: '100%',
    overflowY: 'auto',
  },
  contentHeader: {
    width: '95%',
  },
  selectedRow: {
    backgroundColor: fade(theme.palette.secondary.dark, .05),
  },
  cardBorderColorLevel1: {
    height: 640,
    marginTop: 65,
    borderColor: '#4A90E2 !important',
    border: '3px solid !important',
    borderRadius: '5px !important',
  },
  cardBorderColorLevel2: {
    height: 590,
    marginTop: 115,
    borderColor: '#470a66 !important',
    border: '3px solid !important',
    borderRadius: '5px !important',
  },
  rowBorderColorLevel1: {
    outline: '3px solid #4A90E2',
    outlineOffset: '-3px',
  },
  rowBorderColorLevel2: {
    outline: '3px solid #470a66',
    outlineOffset: '-3px',
  },
  closeButton: {
    height: 30,
    width: 30,
    margin: 'auto',
    marginRight: 10,
    marginTop: 5,
    marginBottom: 0,
  },
  options: {
    overflowY: 'auto',
    height: 'auto',
  },
  optionsShort: {
    overflowY: 'auto',
    height: 'auto',
    maxHeight: 120,
  },
  errorName: {
    color: 'red',
    paddingLeft: '30px',
    marginTop: '-5px',
  },
});

class ModifierEditor extends Component {

  constructor(props) {
    super(props);
    this.state = { modifierTemplate: props.modifierTemplate };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.modifierTemplate !== this.props.modifierTemplate) {

      this.setState({
        ...this.state,
        modifierTemplate: this.props.modifierTemplate,
      });
    }
  }

  static rowProps(props, className) {
    return {
      ...props,
      className, //remove alt css and selected css
    };
  }

  fieldChange = (fieldName, parseFunct) => value => {
    value = parseFunct ? parseFunct(value) : value;
    let modifierTemplate = {
      ...this.state.modifierTemplate
      , [fieldName]: value,
    };

    this.setState({ modifierTemplate });
    this.props.updateModifierTemplateInStack(modifierTemplate, this.props.index);
  }

  nameChange = parseFunct => value => {
    value = parseFunct ? parseFunct(value) : value;

    let modifierTemplate = {
      ...this.state.modifierTemplate
      , name: value,
    };

    this.setState({ modifierTemplate });
    this.props.updateModifierTemplateInStack(modifierTemplate, this.props.index);
  }

  onCancel = () => {
    this.props.removeModifierTemplatesFromStack(this.props.index);
  }

  onModifierEdit = (item, index) => {

    item.selected = true;

    this.props.pushModifierTemplateToStack(item, this.props.index);
    this.props.updateModifierTemplateInStack(item, this.props.index + 1);
  };

  onModifierDelete = (item, index) => {
    const modifierTemplate = this.state.modifierTemplate;

    item.recordStatus = "Deleted";

    if (item.id) {
      modifierTemplate.options = modifierTemplate.options.map(option => option.id === item.id ? item : option);
    }
    else {
      modifierTemplate.options = modifierTemplate.options.filter((option, optionIndex) => optionIndex !== index);
    }

    this.props.updateModifierTemplateInStack(modifierTemplate, this.props.index);
    this.setState({
      ...this.state,
      modifierTemplate,
    });
  }

  onOptionsSorted = options => {
    const modifierTemplate = {
      ...this.state.modifierTemplate,
      options: options.map((option, index) => ({ ...option, sortOrder: index * 100 })),
    };

    this.props.updateModifierTemplateInStack(modifierTemplate, this.props.index);
  }

  addNewOption = () => {
    const nextIndex = this.props.index + 1;
    const modifierTemplate =
    {
      name: `${this.state.modifierTemplate.name} level ${nextIndex}`,
      rule: 'PickOne',
      style: 'Button',
      selected: true,
      newOption: true,
      recordStatus: 'Active',
    };

    this.props.pushModifierTemplateToStack(modifierTemplate, this.props.index);

    this.props.updateModifierTemplateInStack(modifierTemplate, nextIndex);
  }

  onModifierClone = (item, index) => {

    const newOption = _.cloneDeep(item);

    newOption.name = `${newOption.name} Copy`;

    this.resetOptionsIdRecurse(newOption);

    const modifierTemplate = _.cloneDeep(this.state.modifierTemplate);

    modifierTemplate.options = modifierTemplate.options.concat(newOption);

    newOption.index = modifierTemplate.options.length - 1;

    this.props.updateModifierTemplateInStack(_.cloneDeep(modifierTemplate), this.props.index);
    this.props.pushModifierTemplateToStack(newOption, this.props.index);
    this.setState({
      ...this.state,
      modifierTemplate,
    });
  }

  resetOptionsIdRecurse = modifier => {
    modifier.id = undefined;
    if (modifier.options) {
      modifier.options.forEach(option => {
        this.resetOptionsIdRecurse(option);
      });
    }
  }

  updateModifierState = modifiersState => {
    this.setState({
      ...this.state,
      modifiersState: modifiersState,
    });
  }

  renderOptions = () => {
    const { modifierTemplatesStack, index } = this.props;
    const options = modifierTemplatesStack[index].options;

    if (!options) {
      return null;
    }

    const { classes } = this.props;

    return <ModifierSortableList
      items={
        options
          .sort((a, b) => parseInt(a.sortOrder, 10) - parseInt(b.sortOrder, 10))
          .filter(m => m.recordStatus === 'Active')
          .map((item, index) => ({
            ...item,
            selected: item.selected,
            index,
            additionalClasses: item.selected
              ? this.props.index === 0
                ? classes.rowBorderColorLevel1
                : classes.rowBorderColorLevel2
              : '',
          })
          ) || []
      }
      onEdit={this.onModifierEdit}
      onSorted={this.onOptionsSorted}
      onDelete={this.onModifierDelete}
      onClone={this.onModifierClone}
    />;
  }

  render() {
    const { modifierTemplate, modifiersState } = this.state;
    const { classes, taxTypes, index = 0 } = this.props;

    return (modifierTemplate && (<Paper elevation={1} className={classNames(`modifierEditor-${index}`, classes.modifierTemplate, this.props.index === 1 ? classes.cardBorderColorLevel1 : '', this.props.index === 2 ? classes.cardBorderColorLevel2 : '')}>
      {(!this.props.minimumCancelButtonIndex || this.props.minimumCancelButtonIndex <= index) && <IconButton className={classes.closeButton} icon={CancelIcon} onClick={this.onCancel} />}
      {index === 0 && <Modifier
        modifier={modifierTemplate}
        modifiersState={modifiersState || {}}
        updateModifierState={this.updateModifierState}
      />}
      <div className={classNames(classes.fullWidth, classes.cellContents)}>
        <TextField
          label="Name"
          name="name"
          maxLength={80}
          onFieldChange={this.nameChange()}
          value={modifierTemplate.name || ''}
          autoFocus={modifierTemplate.newOption || false}
        />
        {index > 0 && <>
          <Checkbox
            label="Default"
            name="default"
            checked={modifierTemplate.default}
            onFieldChange={this.fieldChange('default')} />
          <Checkbox
            label="Disabled"
            name="disabled"
            checked={modifierTemplate.disabled}
            onFieldChange={this.fieldChange('disabled')} />
        </>}
        {index > 0 && <CurrencyField
          label="Price"
          onFieldChange={this.fieldChange('price', parseFloat)}
          name="price"
          minValue={0}
          maxValue={99999}
          value={modifierTemplate.price || ''}
        />}
        {modifierTemplate.price > 0 &&
          <Select
            label="Tax Type"
            value={modifierTemplate.taxTypeId || (taxTypes && taxTypes[0].id)}
            onFieldChange={this.fieldChange('taxTypeId')}
            name="taxTypeId"
          >
            {taxTypes && taxTypes.map(taxType =>
              <MenuItem key={taxType.id} value={taxType.id}>{taxType.name}</MenuItem>
            )}
          </Select>}
        {index < 2 && modifierTemplate.options && modifierTemplate.options.length > 0 && <Select label="Rule"
          value={modifierTemplate.rule || 'PickOne'}
          onFieldChange={this.fieldChange('rule')}
          name="rule">
          <MenuItem value='PickOne'>Pick One</MenuItem>
          <MenuItem value='PickMany'>Pick Many</MenuItem>
        </Select>}
        {modifierTemplate.rule === 'PickMany' &&
          <div className={classes.contents2x1}>
            <TextField
              label="Min Pick Quantity"
              name="minimumQuantity"
              type="number"
              className={classes.halfWidth}
              onFieldChange={this.fieldChange('minimumQuantity', parseInt)}
              value={modifierTemplate.minimumQuantity || ''}
            />
            <TextField
              label="Max Pick Quantity"
              name="maximumQuantity"
              type="number"
              className={classes.halfWidth}
              onFieldChange={this.fieldChange('maximumQuantity', parseInt)}
              value={modifierTemplate.maximumQuantity || ''}
            />
          </div>}
        {modifierTemplate.rule === 'PickOne' &&
          <div className={classes.contents2x1}>
            <TextField
              label="Min Pick Quantity"
              name="minimumQuantity"
              type="number"
              className={classes.halfWidth}
              onFieldChange={this.fieldChange('minimumQuantity', parseInt)}
              value={modifierTemplate.minimumQuantity || ''}
              minValue={0}
              maxValue={1}
            />
            <TextField
              label="Max Pick Quantity"
              name="maximumQuantity"
              type="number"
              className={classes.halfWidth}
              onFieldChange={this.fieldChange('maximumQuantity', parseInt)}
              value={modifierTemplate.maximumQuantity || '1'}
              minValue={1}
              maxValue={1}
            />
          </div>}
        {index < 2 && modifierTemplate.options && modifierTemplate.options.length > 0 && modifierTemplate.rule === 'PickOne' && <Select label="Style"
          value={modifierTemplate.style || 'Button'}
          onFieldChange={this.fieldChange('style')}
          name="style">
          <MenuItem value='Button'>Button</MenuItem>
          <MenuItem value='Dropdown'>Dropdown</MenuItem>
        </Select>}
      </div>
      {index < 2 && <div className={classNames(classes.fullWidth, classes.cellContents, classes.stretchVertical)}>
        <div className={classes.buttonsBar}>
          <Typography variant="subtitle1" className={classes.contentHeader}>Options</Typography>
          <HighlightButton
            onClick={this.addNewOption}
            className={classes.toolbarButton}
          >
            <AddIcon />
          </HighlightButton>
        </div>
        <div className={classNames(classes.options)}>
          {this.renderOptions()}
        </div>
      </div>}
      {index === 0 && this.props.showSaveBar && <div className={classes.bottomBar}>
        <SaveBar
          compact={true}
          showCancel={false}
          onSave={this.props.onSave}
          onDelete={this.props.onDelete}
        />
      </div>}
    </Paper>)) || <></>;
  }
}

const mapStateToProps = state => {
  const {
    api: {
      revenueTypes,
    },
    menuItemModifier: {
      modifierTemplates: {
        modifierTemplatesStack,
      },
    },
  } = state;

  return {
    taxTypes: revenueTypes,
    modifierTemplatesStack: modifierTemplatesStack,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModifierEditor));
