export const WARNING_TOAST_SHOW = 'WARNING_TOAST_SHOW';
export const WARNING_TOAST_HIDE = 'WARNING_TOAST_HIDE';
export const SET_CURRENT_VERSION = 'SET_CURRENT_VERSION';
export const APPLICATION_UPDATED = 'APPLICATION_UPDATED';

export const showWarningToast = warningDetails => ({
  type: WARNING_TOAST_SHOW,
  warningDetails,
});

export const hideWarningToast = () => ({
  type: WARNING_TOAST_HIDE,
});

export const applicationUpdated = () => ({
  type: APPLICATION_UPDATED,
});
