import { callApiFileUpload, callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import {
  UPLOAD_FILE_BEGIN,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
} from './constants';

export function uploadFile(entityType, entityId, accessType, formData) {
  return async dispatch => {
    dispatch(uploadFileBegin());

    return uploadFileApi(dispatch, entityType, entityId, accessType, formData)
      .then(result => {
        dispatch(uploadFileSuccess(result));

        return result;
      }).catch(error => {
        dispatch(uploadFileFailure(error.message));
        throw Error('Upload File Failed', error.message);
      });
  };
}

function uploadFileApi(dispatch, entityType, entityId, accessType, formData) {
  dispatch(uploadFileBegin);

  return dispatch(callApiFileUpload(`file/${accessType}/${entityType}/${entityId}`, { body: formData, method: 'POST' }))
    .then(response => {
      if (!response.ok || response.status !== 200) {
        throw Error(response.title || response.status || 'Upload File Failed');
      }

      return ParseJsonOnSuccessOrStop(dispatch, response);
    });
}

export const saveReportToFiles = (entityType, entityId, report) =>
  dispatch => dispatch(callApi(`file/private/${entityType}/${entityId}`, { method: 'POST', body: report }))
    .then(result => {
      if (!result.ok || result.status !== 200) {
        throw Error('Save Report Failed', result);
      }

      return ParseJsonOnSuccessOrStop(dispatch, result);
    });

export const uploadFileBegin = () => ({
  type: UPLOAD_FILE_BEGIN,
});

export const uploadFileSuccess = response => ({
  type: UPLOAD_FILE_SUCCESS,
  response,
});

export const uploadFileFailure = error => ({
  type: UPLOAD_FILE_FAILURE,
  error,
});
