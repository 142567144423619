import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { getCalendarEventsForAgendaByDates, selectDate } from 'actions/calendar';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Eventcalendar } from '@mobiscroll/react';

const styles = theme => ({
  backgroundColor: theme.palette.grey[50],
  eventLabel: {
    flexShrink: 0,
    fontSize: '17px',
    paddingBottom: '3px',
    paddingLeft: '28px',
    paddingTop: '3px',
  },
  dayContainer: {
    textAlign: 'center',
  },
  horizontalLine: {
    borderBottom: '2px solid',
    color: theme.palette.grey[200],
    height: '10px',
  },
  footerLabel: {
    flexGrow: 1,
    fontSize: '17px',
    lineHeight: '17px',
    paddingBottom: '7px',
    paddingTop: '7px',
    textAlign: 'center',
  },
  footerButton: {
    minHeight: '31px',
    minWidth: '56px',
    padding: 0,
  },
  dayLabel: {
    backgroundColor: theme.palette.background.paper,
    display: 'inline-block',
    fontSize: '13px',
    paddingLeft: '10px',
    paddingRight: '10px',
    textTransform: 'uppercase',
  },
  agendaFooter: {
    display: 'flex',
    flexShrink: 0,
    height: '31px',
  },
  eventContainer: {
    display: 'flex',
  },
  row: {
    fontSize: '13px',
    minHeight: '27px',
    paddingBottom: '4px',
    paddingTop: '4px',
  },
  eventText: {
    flexGrow: '1',
    maxWidth: '155px',
    whiteSpace: 'normal',
    marginLeft: '18px',
  },
  eventTime: {
    color: theme.palette.grey[200],
    width: 57,
    minWidth: 57,
    textAlign: 'right',
  },
  agenda: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    overflowX: 'hidden',
    paddingLeft: '17px',
    paddingRight: '17px',
    position: 'relative',
  },
});

const eventTypeToPaletteName = {
  Booking: 'bookingEvent',
  Proposal: 'proposalEvent',
  Task: 'taskEvent',
  Personal: 'personalEvent',
  '0': 'bookingEvent',
  '1': 'proposalEvent',
  '2': 'taskEvent',
  '3': 'personalEvent',
};

export const eventTypeToColor = (theme, event) => {
  return theme.palette[eventTypeToPaletteName[event.calendarEventType]];
};

class Agenda extends Component {
  pageSize = 0;

  state = {
    today: moment({ hour: 0 }),
    skip: 0,
    hasFutureEvents: true,
    isLoading: true,
  };

  constructor(props) {
    super(props);

    this.agendaRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.height >= 0) {

      const params = { StartDate: new Date().toISOString(), EndDate: new Date().lastDay.toISOString(), Switches: { Proposal: true, Booking: true, Personal: true, Task: true } };

      this.props.getCalendarEventsForAgendaByDates(params);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.height !== this.props.height) {
      this.pageSize = Agenda.calculatePageSize(this.props.height);
      this.getAgenda();
    } else if (prevProps.calendarUpdating !== this.props.calendarUpdating && this.props.calendarUpdating === false) {
      this.getAgenda();
    }

    if (this.props.selectedDate !== prevProps.selectedDate) {
      this.agendaRef.current.navigate(this.props.selectedDate);
    }
  }

  static calculatePageSize(height) {
    const pageSize = Math.ceil((height - 31 - 31) / 27);

    if (pageSize <= 0) {
      return 1;
    }

    return pageSize;
  }

  getAgenda = () => {
    const { getAgenda } = this.props;
    const { skip, today } = this.state;
    const startDate = today.format('YYYY-M-D');

    this.setState({ isLoading: true });

    return getAgenda({ startDate, skip, top: this.pageSize })
      .then(calendarEvents => {
        const updatedState = {
          ...this.state,
          isLoading: false,
          calendarEvents,
          hasFutureEvents: calendarEvents.length === this.pageSize,
        };

        this.setState(updatedState);
      });
  }

  agendaLabel = calendarEvent => {
    const agendaDate = moment(calendarEvent.startDateTime);

    return agendaDate.isSame(this.state.today, 'day') ? 'today' : agendaDate.format('ddd M-D');
  }

  nextAgenda = () => {
    const { hasFutureEvents, skip, calendarEvents } = this.state;

    if (!hasFutureEvents) {
      return;
    }

    this.setState(
      { skip: skip + calendarEvents.length },
      this.getAgenda
    );
  }

  previousAgenda = () => {
    const { skip } = this.state;

    if (!skip) {
      return;
    }

    this.setState(
      { skip: this.pageSize >= skip ? 0 : skip - this.pageSize },
      this.getAgenda
    );
  }

  groupByDay = calendarEvents => {
    const dayGroups = _.groupBy(calendarEvents, calendarEvent => {
      return moment(calendarEvent.startDateTime).startOf('day').format();
    });

    return _.values(dayGroups);
  }

  render() {
    const { agenda } = this.props;

    const onPageLoaded = (event, instance) => {
      const params = { StartDate: event.firstDay.toISOString(), EndDate: event.lastDay.toISOString(), Switches: { Proposal: true, Booking: true, Personal: true, Task: true } };

      if (this.props.params !== params) {
        this.props.getCalendarEventsForAgendaByDates(params);
      }
    };

    const dateChange = (event, inst) => {
      this.props.selectDate(event.date);
    };

    return (
      <>
        <Eventcalendar
          theme="ios"
          themeVariant="light"
          dataTimezone='utc'
          data={agenda.events}
          view={{
            calendar: { type: 'month', size: 1 },
            agenda: { type: 'day' },
          }}
          onPageLoaded={onPageLoaded}
          onSelectedDateChange={dateChange}
          ref={this.agendaRef}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      agendaEvents: {
        json,
        params,
      },
    },
    calendar: {
      selectedEventTypes,
      selectedDate,
    },
  } = state;

  return {
    selectedEventTypes,
    agenda: json,
    params,
    selectedDate,
  };
};

const mapDispatchToProps = {
  getCalendarEventsForAgendaByDates,
  selectDate,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Agenda));
