import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import userManager from './userManager';

const styles = theme => ({
  loginButton: {
    width: '100%',
    textTransform: 'none',
    minHeight: '32px',
    height: '32px',
    fontSize: '12px',
  },
  grazeloginButton: {
    width: '65%',
    textTransform: 'none',
    minHeight: '50px',
    height: '50px',
    fontSize: '20px',
    backgroundColor: '#EF716D',
    marginBottom: '25px',
    '&:hover': {
      backgroundColor: '#EF716D',
      boxShadow: `rgb(239 113 109 / 30%) 0px 6px 10px 0px, rgb(239 113 109 / 40%) 0px 2px 4px 0px`,
    },
  },
});

class OauthLogin extends Component {

  login = () => {
    userManager.signinRedirect();
  }

  render() {
    const { classes } = this.props;
    const grazeLogin = window.location.hostname.toLowerCase().includes('graze');

    return (
      <Button variant="contained" color="secondary" className={!grazeLogin ? classes.loginButton : classes.grazeloginButton} onClick={this.login}>
        Login
      </Button>
    );
  }
}

export default withStyles(styles)(OauthLogin);
