import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  DeviceHub as TriForce,
} from '@material-ui/icons';
import Table from 'Components/Table';
import {
  SortableContainer,
  arrayMove,
} from 'react-sortable-hoc';
import SetupStyleRow from './SetupStyleRow';

const SortableList = SortableContainer(({ setupStyles }) => {
  return (
    <tbody>
      {setupStyles
        .filter(ss => !!ss.setupStyle && ss.recordStatus !== 'Deleted')
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((item, index) =>
          <SetupStyleRow
            key={`setupstyleoption-${item.setupStyleId}`}
            index={index}
            item={{ ...item.setupStyle, index }}
          />
        )
      }
    </tbody>
  );
});

const styles = theme => ({
  table: {
    borderColor: theme.palette.grey[50],
    '& th': {
      textAlign: 'center',
    },
    '& td': {
      borderWidth: 1,
      textAlign: 'center',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
    '& tbody th': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[50],
    },
  },
});
const smallColumn = { width: 60 };

class SetupStylesTable extends Component {
  state = {};

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onSort(arrayMove(this.props.setupStyles, oldIndex, newIndex));
  };

  render() {
    const {
      classes,
      setupStyles,
    } = this.props;

    return (
      <Table
        className={classes.table}
        wrapWithTbody={false}
        header={
          <tr className={classes.headerRow}>
            <th scope="col" style={smallColumn}>Order</th>
            <th scope="col">Name</th>
            <th scope="col" style={smallColumn}><TriForce /></th>
            <th scope="col" style={smallColumn}></th>
          </tr>
        }
      >
        {setupStyles && <SortableList
          setupStyles={setupStyles}
          onSortEnd={this.onSortEnd}
          lockAxis="y"
          useDragHandle={true}
        />}

      </Table >);
  }
}

export default withStyles(styles)(SetupStylesTable);

