import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from 'Components/Table';
import DecimalField from 'Components/DecimalField';
import Checkbox from 'Components/Checkbox';

const styles = theme => ({
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  table: {
    fontSize: 12,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    '& th': {
      padding: '6px 13px',
    },
    '& tr': {
      borderTop: 0,
      borderBottom: 0,
    },
    '& td': {
      borderLeft: `1px solid ${theme.palette.grey[50]}`,
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
      paddingLeft: 8,
    },
  },
  rowLabel: {
    color: theme.palette.grey[300],
    paddingTop: 11,
    paddingBottom: 11,
  },
  percentField: {
    width: 70,
    marginTop: 5,
  },
  taxChackbox: {
    marginLeft: -1,
    marginRight: 0,
  },
});

class TaxRatesTableComponent extends Component {

  state = {
    rates: [],
    errors: [],
  }

  getRate = revenueTypeId => {
    const { revenueConfiguration, rateOverwrites } = this.props;
    const rate = revenueConfiguration.revenueTypeRates.find(rate => rate.revenueTypeId === revenueTypeId);

    if (rateOverwrites && rateOverwrites.length > 0) {
      const overwrite = rateOverwrites.find(rate => rate.revenueTypeId === revenueTypeId);

      if (overwrite) {
        return overwrite;
      }
    }

    return rate;
  };

  onChange = (index, valueIndex) => value => {
    const { rates } = this.state;

    if (!rates[index]) {
      rates[index] = {};
    }

    rates[index][valueIndex] = value;
    this.setState({ rates });
  }

  onBoolChange = (index, valueIndex, revenueType) => value => {
    this.onChange(index, valueIndex)(value);
    const rate = this.getRate(revenueType.revenueTypeId);

    this.props.onRateUpdated({ ...rate, [valueIndex]: value });
  }

  onBlur = (index, rateType, revenueType) => () => {
    const { rates, errors } = this.state;
    const { onRateUpdated } = this.props;

    if (rates[index] && rates[index][rateType]) {

      const value = rates[index][rateType];

      if (!isNaN(value) && value <= 100) {
        const rate = this.getRate(revenueType.revenueTypeId);

        if (errors[index]) {
          errors[index][rateType] = false;
        }
        onRateUpdated({ ...rate, [rateType]: parseFloat(value) / 100 });
        this.setState({ errors });
      } else {
        if (!errors[index]) {
          errors[index] = {};
        }
        errors[index][rateType] = true;
        this.setState({ errors });
      }
    }
  }

  isErrorTax = error => {
    return error ? true : false;
  }

  render() {
    const { classes, revenueConfiguration } = this.props;
    const { rates, errors } = this.state;

    return (

      <Table
        className={classes.table}
        header={
          <tr className={classes.header}>
            <th scope="col">Type</th>
            <th scope="col" style={{ textAlign: 'center' }}>Tax</th>
            <th scope="col" style={{ textAlign: 'center' }}>Auto</th>
            <th scope="col" style={{ textAlign: 'center' }}>Service</th>
            <th scope="col" style={{ textAlign: 'center' }}>+Tax</th>
            <th scope="col" style={{ textAlign: 'center' }}>Gratuity</th>
            <th scope="col" style={{ textAlign: 'center' }}>+Tax</th>
          </tr>
        }
      >
        {revenueConfiguration && revenueConfiguration.revenueTypeRates && revenueConfiguration.revenueTypeRates.map((revenueTypeRate, index) => {
          const autoTax = rates[index] && rates[index].locationBasedTaxPercentage !== undefined
            ? rates[index].locationBasedTaxPercentage
            : this.getRate(revenueTypeRate.revenueTypeId).locationBasedTaxPercentage;

          return (
            <tr key={revenueTypeRate.revenueTypeId}>
              <th scope="row" className={classes.rowLabel}>
                {revenueTypeRate.revenueType.name}
              </th>
              <td style={{ backgroundColor: this.isErrorTax(errors[index] && errors[index]['taxPercentage']) ? 'red' : 'white' }}>
                <DecimalField
                  suffix="%"
                  minValue={0}
                  className={classes.percentField}
                  value={autoTax
                    ? 'auto'
                    : rates[index] && rates[index]['taxPercentage']
                      ? rates[index]['taxPercentage']
                      : this.getRate(revenueTypeRate.revenueTypeId).taxPercentage * 100
                  }
                  disabled={autoTax}
                  onFieldChange={this.onChange(index, 'taxPercentage')}
                  onBlur={this.onBlur(index, 'taxPercentage', revenueTypeRate)}
                  maxLength={20}
                />
              </td>
              <td>
                <Checkbox
                  className={classes.taxChackbox}
                  checked={autoTax}
                  onFieldChange={this.onBoolChange(index, 'locationBasedTaxPercentage', revenueTypeRate)}
                />
              </td>
              <td style={{ backgroundColor: this.isErrorTax(errors[index] && errors[index]['serviceChargePercentage']) ? 'red' : 'white' }}>
                <DecimalField
                  suffix="%"
                  minValue={0}
                  className={classes.percentField}
                  value={rates[index] && rates[index]['serviceChargePercentage'] ? rates[index]['serviceChargePercentage'] : this.getRate(revenueTypeRate.revenueTypeId).serviceChargePercentage * 100}
                  onFieldChange={this.onChange(index, 'serviceChargePercentage')}
                  onBlur={this.onBlur(index, 'serviceChargePercentage', revenueTypeRate)}
                  maxLength={20}
                />
              </td>
              <td>
                <Checkbox
                  className={classes.taxChackbox}
                  checked={rates[index] && rates[index].taxServChg !== undefined ? rates[index].taxServChg  : this.getRate(revenueTypeRate.revenueTypeId).taxServChg}
                  onFieldChange={this.onBoolChange(index, 'taxServChg', revenueTypeRate)}
                />
                {rates[index] && rates[index]['taxServChg']}
              </td>
              <td style={{ backgroundColor: this.isErrorTax(errors[index] && errors[index]['gratuityPercentage']) ? 'red' : 'white' }}>
                <DecimalField
                  suffix="%"
                  minValue={0}
                  className={classes.percentField}
                  value={rates[index] && rates[index]['gratuityPercentage'] ? rates[index]['gratuityPercentage'] : this.getRate(revenueTypeRate.revenueTypeId).gratuityPercentage * 100}
                  onFieldChange={this.onChange(index, 'gratuityPercentage')}
                  onBlur={this.onBlur(index, 'gratuityPercentage', revenueTypeRate)}
                  maxLength={20}
                />
              </td>
              <td>
                <Checkbox
                  className={classes.taxChackbox}
                  checked={rates[index] && rates[index].taxGrat !== undefined ? rates[index].taxGrat : this.getRate(revenueTypeRate.revenueTypeId).taxGrat}
                  onFieldChange={this.onBoolChange(index, 'taxGrat', revenueTypeRate)}
                />
              </td>
            </tr>
          );}
        )}
      </Table>
    );

  }
}

export default withStyles(styles)(TaxRatesTableComponent);

// function toFixed(param, precisionpadding) {
//   let val = param;

//   if (isNaN(param))
//     val = 0;

//   var precision = precisionpadding || 0,
//     neg = val < 0,
//     power = Math.pow(10, precision),
//     value = Math.round(val * power),
//     integral = String((neg ? Math.ceil : Math.floor)(value / power)),
//     fraction = String((neg ? -value : value) % power),
//     padding = new Array(Math.max(precision - fraction.length, 0) + 1).join('0');

//   if (integral < 10)
//     integral = '0' + integral;

//   return precision ? integral + '.' + padding + fraction : integral;
// }
