import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  handleAddCategory,
  collapseCategories,
  expandCategories,
  handleMenuFieldChange,
} from 'actions/masterMenuManagement';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import MenuBuilder from './MenuBuilder';
import Header from 'Components/Header';
import MenuAssigment from './MenuAssignment';
import MenuSettings from './MenuSettings';

const styles = theme => ({
  menuManagement: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    minWidth: 0,
  },
  tabContainer: {
    height: 40,
    minHeight: 40,
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingBottom: 24,
  },
  content: {
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const MenuManagement = ({
  classes,
  formatter,
  isWorkingMasterMenuChanged,
}) => {
  const tabs = ['Build', 'Assign', 'Settings'];
  const [tabIndex, setTabIndex] = useState(0);

  return (<>
    <div className={classes.header}>
      <Header>Menu Management</Header>
    </div>
    <div className={classes.menuManagement}>

      <div className={classes.tabContainer}>
        <BlueTextTab
          onTabChange={setTabIndex}
          tabs={tabs}
          tabIndex={tabIndex}
          disableInactive={isWorkingMasterMenuChanged}
        />
      </div>
      <div className={classes.content}>
        {tabIndex === 0 &&
          <MenuBuilder formatter={formatter} />
        }
        {tabIndex === 1 &&
          <MenuAssigment />
        }
        {tabIndex === 2 &&
          <MenuSettings />
        }
      </div>
    </div>
  </>);
};

const mapStateToProps = state => {
  const {
    masterMenuManagement: {
      workingMasterMenu,
      isWorkingMasterMenuChanged,
      isLoading,
    },
  } = state;

  return {
    menu: workingMasterMenu,
    isWorkingMasterMenuChanged,
    isLoading,
  };
};

const mapDispatchToProps = {
  handleAddCategory,
  collapseCategories,
  expandCategories,
  handleMenuFieldChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuManagement));
