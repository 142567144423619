import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import DropdownCell from 'Components/Grid/DropdownCell';
import QuickAdd from 'Components/QuickAdd';
import Checkbox from 'Components/Checkbox';
import { provideIntlService } from '@progress/kendo-react-intl';
import {
  GridColumn as Column,
} from '@progress/kendo-react-grid';
import {
  Clear as DeleteIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import popoverCellEdit from 'Components/popoverCellEdit';
import SmallGrid from 'Components/Grid/SmallGrid';
import { ExpandoCell, ModifiersDetails } from './ExpandoCell';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import ProductDetailsModal from 'orders/wizard/ModifierModal/ProductDetailsModal';

const styles = theme => ({
  menuGrid: {
    minWidth: 0,
    '& .k-grid': {
      '& thead.k-grid-header th': {
        backgroundColor: theme.palette.background.paper,
      },
      '& table': {
        width: '100% !important',
      },
      '& td': {
        paddingLeft: 10,
        paddingRight: 10,
        '&:first-child': { //Modifier Expando cell
          paddingLeft: 4,
          paddingRight: 4,
          borderRightWidth: 0,
        },
      },
    },
  },
  deleteIcon: {
    color: theme.common.softPink,
    cursor: 'pointer',
  },
  indentQuickAdd: {
    paddingLeft: 20,
  },
  italic: {
    fontStyle: 'italic',
  },
  description: {
    maxHeight: 20,
  },
  textTruncate: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  linkCell: {
    '&:hover': {
      color: theme.palette.primary.dark,
      textDecoration: 'underline !important',
      cursor: 'pointer',
    },
  },
  checkbox: {
    margin: 'auto',
  },
});

// Dynamic column widths inspired by:
// https://www.telerik.com/kendo-react-ui/components/grid/columns/width/#toc-setting-a-minimum-column-width
const ADJUST_PADDING = 24;
const COLUMN_MIN = 30;
const EXPANDO_COL_WIDTH = 33;
const DELETE_COL_WIDTH = 36;

class MenuGrid extends Component {
  constructor(props) {
    super(props);
    this.modalLinkOptions = {
      onOpenModal: menuItem => props.onOpenItemModal(menuItem),
      text: this.displayItemName,
    };
    this.state = {
      isEditMenuItemModalOpen: false,
      columns: [],
      taxTypesMap: {},
      gridCurrent: 0,
    };
    this.formatter = provideIntlService(this);
  }

  componentDidMount() {
    const { taxTypes } = this.props;

    window.addEventListener('resize', this.setupGridWidth);
    this.mapTaxTypes(taxTypes);
    this.setupGridWidth();
  }

  componentDidUpdate(prevProps) {
    const { columns, isEditing, taxTypes, eventMenuCategoryItems } = this.props;

    if (prevProps.eventMenuCategoryItems !== eventMenuCategoryItems) {
      this.setupGridWidth();
    }
    if (prevProps.columns !== columns) {
      this.setupGridWidth();
    }
    if (isEditing && !prevProps.isEditing) {
      this.setupGridWidth();
    }
    if (prevProps.taxTypes !== taxTypes) {
      this.mapTaxTypes(taxTypes);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setupGridWidth);
  }

  mapTaxTypes = taxTypes => {
    let taxTypesMap = {};

    if (taxTypes) {
      taxTypes.forEach(t => {
        taxTypesMap[t.id] = t.name;
      });
      this.setState({ taxTypesMap });
    }
  }

  openBulkModifierModal = () => {
    this.setState({ isBulkModifierModalOpen: true });
  }

  closeBulkModifierModal = () => {
    this.setState({ isBulkModifierModalOpen: false });
  }

  inCellEditDone = menuItem => {
    this.props.onCellEditFinished(menuItem);
  }

  expandChange = event => {
    event.dataItem.expanded = !event.dataItem.expanded;
    this.forceUpdate();
  }

  cellRender = allMenuItems => (tdElement, cellProps) => {

    return cellProps && cellProps.field === 'expanded' ?
      <ExpandoCell
        {...cellProps}
        menuSection={this.props.menuSection}
        allMenuItems={allMenuItems}
      /> : tdElement;
  }

  rowRenderDraggable = (trElement, gridRowProps) => {
    const { isEditing } = this.props;
    const { dataItem } = gridRowProps;
    let props = { ...trElement.props };
    const rowIndex = trElement._owner.return.index;
    const dragKey = dataItem.id ? dataItem.id.toString() : dataItem.tempId;

    return (
      <Draggable
        key={dragKey}
        draggableId={dragKey}
        index={rowIndex}
        isDragDisabled={!isEditing}
      >
        {provided => {
          props = {
            ...props,
            ref: provided.innerRef,
            ...provided.draggableProps,
            ...provided.dragHandleProps,
          };

          const clonedElement = React.cloneElement(
            trElement,
            props,
            trElement.props.children);

          return clonedElement;
        }}
      </Draggable>
    );
  }

  onDropdownCellChange = props => selection => {
    const { dataItem, field } = props;

    dataItem[field] = selection.value;
    this.inCellEditDone(dataItem);
  }

  displayItemName = menuItem => {
    return menuItem.name === menuItem.nameOriginal ? menuItem.name : <span className={this.props.classes.italic}>{menuItem.name}</span>;
  }

  // Needed for dynamic column resizing and this.setWidth()
  setupGridWidth = () => {
    const { isEditing, isLoading, isLoadingColumns  } = this.props;

    if (!isLoading && !isLoadingColumns) {
      const unhideableWidth = EXPANDO_COL_WIDTH + (isEditing ? DELETE_COL_WIDTH : 0);
      const grid = document.querySelector('.k-grid');

      this.setState({ gridCurrent: grid.offsetWidth - unhideableWidth });
    }

    return Promise.resolve(); // in case something needs to adjust to this new width (ie: columns)
  }

  setWidth = (minWidth = COLUMN_MIN, maxWidth) => {
    const { gridCurrent } = this.state;
    const { columns } = this.props;

    const visibleColumns = columns.filter(c => c.isVisible);
    let width = minWidth + gridCurrent / visibleColumns.length;

    width = width < COLUMN_MIN ? width : width - ADJUST_PADDING;

    width = width > maxWidth ? maxWidth : width;

    return width;
  }

  nameModalCell = ({ dataItem }) => {
    const { onOpenItemModal, classes, isEditing } = this.props;
    const maxWidth = this.getBigCellMaxWidth();

    return (
      <td>
        <div
          className={classNames(classes.textTruncate, isEditing && classes.linkCell)}
          onClick={() => onOpenItemModal(dataItem)}
          style={{ width: this.setWidth(100, maxWidth) }}
        >
          {dataItem.name}
        </div>
      </td>
    );
  };

  htmlDescription = ({ dataItem }) => {
    const { classes } = this.props;
    const maxWidth = this.getBigCellMaxWidth();

    return (
      <td>
        <div
          dangerouslySetInnerHTML={{ __html: dataItem.description }}
          className={classNames(classes.textTruncate, classes.description)}
          style={{ width: this.setWidth(100, maxWidth) }}
        />
      </td>
    );
  }

  htmlAllergens = ({ dataItem }) => {
    const { classes } = this.props;

    return (
      <td>
        <div
          dangerouslySetInnerHTML={{ __html: dataItem.allergens }}
          className={classNames(classes.textTruncate, classes.description)}
          style={{ width: this.setWidth(50, 90) }}
        />
      </td>
    );
  }

  getBigCellMaxWidth = () => {
    const { useDefaultColumns, columns } = this.props;
    const { gridCurrent } = this.state;
    const isWizard = useDefaultColumns;
    const visibleColumns = columns.filter(c => c.isVisible);
    const visibleColumnsLength = visibleColumns.length;
    let maxWidth = (gridCurrent / visibleColumnsLength) + 80;

    if (visibleColumnsLength > 8) {
      maxWidth = 120;
    } else if (visibleColumnsLength >= 9) {
      maxWidth = 80;
    }

    maxWidth = isWizard ? 150 : maxWidth;

    return maxWidth;
  }

  revenueTypeIdCell = props => {
    const { isEditing, taxTypes } = this.props;
    const { taxTypesMap } = this.state;
    const maxWidth = this.setWidth(null, 100);

    return isEditing ?
      <DropdownCell options={taxTypes} onChange={this.onDropdownCellChange(props)} value={props.dataItem.revenueTypeId} name="revenueTypeId" maxWidth={maxWidth} />
      :
      <td style={{ maxWidth }}>{taxTypesMap[props.dataItem.revenueTypeId]}</td>;
  }

  checkbox = field => ({ dataItem }) => {
    const { classes, isEditing } = this.props;

    return (
      <td align="center">
        <Checkbox
          className={classes.checkbox}
          disabled={!isEditing}
          onChange={this.onCheckboxChange({ dataItem, field })}
          checked={dataItem[field]}
        />
      </td>
    );
  }

  onCheckboxChange = props => event => {
    const { dataItem, field } = props;

    dataItem[field] = event.target.checked;
    this.inCellEditDone(dataItem);
  }

  filterItems = menuCategoryItems => {
    return menuCategoryItems.filter(item => item.recordStatus === 'Active');
  }

  getColumn = column => {
    const { isEditing } = this.props;

    if (column.isVisible) {
      switch (column.field) {
        case 'name':
          return <Column
            key={column.field}
            field={column.field}
            title="Item"
            width={this.setWidth(90, 150)}
            cell={this.nameModalCell}
          />;
        case 'quantity':
          return <Column key={column.field} field={column.field} title="Qty" cell={popoverCellEdit(isEditing, this.inCellEditDone, 60)} />;
        case 'unitPrice':
          return <Column key={column.field} field={column.field} title="Unit Price" format="c2" cell={popoverCellEdit(isEditing, this.inCellEditDone, 80)} />;
        case 'itemCost':
          return <Column key={column.field} field={column.field} title="Cost" format="c2" cell={popoverCellEdit(isEditing, this.inCellEditDone, 80)} />;
        case 'total':
          return <Column key={column.field} field={column.field} title={column.title} format="c2" cell={props => (
            <td>{
              props.dataItem.unitPrice === 0 ? this.formatter.formatNumber(0, 'c2') :
                !props.dataItem.unitPrice ? '' ://price is falsy
                  this.formatter.formatNumber(props.dataItem.quantity * props.dataItem.unitPrice, 'c2')
            }</td>
          )}
          />;
        case 'calories':
          return <Column key={column.field} field={column.field} title="Cal" />;
        case 'allergens':
          return <Column key={column.field} field={column.field} title={column.title} cell={this.htmlAllergens}/>;
        case 'revenueTypeId':
          return <Column key={column.field} field="revenueTypeId" title="Tax Type" width={this.setWidth(85)} cell={this.revenueTypeIdCell} />;
        case 'description':
          return <Column key={column.field} field={column.field} title={column.title} width={this.setWidth(100, 150)} cell={this.htmlDescription} />;
        case 'hasTax':
          return <Column key={column.field} field={column.field} title="Tax" width={this.setWidth()} cell={this.checkbox(column.field)} />;
        case 'hasServiceCharge':
          return <Column key={column.field} field={column.field} title="Serv Chg" width={this.setWidth()} cell={this.checkbox(column.field)} />;
        case 'isLinked':
          return <Column key={column.field} field={column.field} title="Linked" width={this.setWidth()} cell={this.checkbox(column.field)} />;
        case 'hasGratuity':
          return <Column key={column.field} field={column.field} title="Grat" width={this.setWidth()} cell={this.checkbox(column.field)} />;
        default:
          return <Column key={column.field} field={column.field} title={column.title} width={this.setWidth()} />;
      }
    }
  }

  render() {
    const {
      classes,
      isEditing,
      onMenuItemAdded,
      isLoading,
      isLoadingColumns,
      columns,
      onItemDeleted,
      eventMenuCategoryItems,
      allMenuItems,
      selectedBookingItem,
      closeProductDetail,
      saveBookingItem,
    } = this.props;

    const isLoadingSomething = isLoadingColumns || isLoading;

    return (<>
      <div className={classes.menuGrid}>
        {eventMenuCategoryItems &&
          <SmallGrid
            data={this.filterItems(eventMenuCategoryItems)}
            detail={ModifiersDetails}
            expandField="expanded"
            cellRender={this.cellRender(allMenuItems)}
            onExpandChange={this.expandChange}
            rowRender={this.rowRenderDraggable}
            isLoading={isLoadingSomething}
            scrollable="none"
          >
            {!isLoadingColumns && columns.map(column => this.getColumn(column))}
            {isEditing && <Column
              field=""
              width="35px"
              title=""
              cell={props => {
                return (
                  <td style={{ borderLeft: 'none', padding: '6px 4px' }}>
                    <DeleteIcon classes={{ root: classes.deleteIcon }} onClick={() => onItemDeleted(props.dataItem)} />
                  </td>
                );
              }}
            />}
          </SmallGrid>
        }
        {isEditing && (
          <div className={classes.indentQuickAdd}>
            <QuickAdd
              onItemSelected={onMenuItemAdded}
            />
          </div>)}
      </div>
      {selectedBookingItem &&
        <ProductDetailsModal
          onModalClosed={closeProductDetail}
          isSaveDisabled={false}
          onSave={saveBookingItem}
          item={selectedBookingItem}
          modifiersFromOrder={() => {}}
        />
      }
    </>);
  }
}

MenuGrid.propTypes = {
  menuCategoryItems: PropTypes.array.isRequired,
  isEditing: PropTypes.bool,
  onMenuItemAdded: PropTypes.func.isRequired,
  onOpenItemModal: PropTypes.func.isRequired,
  onItemDeleted: PropTypes.func.isRequired,
  taxTypes: PropTypes.array,
};

const mapStateToProps = state => {
  const {
    settings: {
      menu: {
        bookingMenuColumns,
        isFetchingBookingMenuColumns,
      },
    },
    api: {
      revenueTypes,
    },
    menuItem: {
      indexedData,
    },
  } = state;

  return {
    columns: bookingMenuColumns,
    isLoadingColumns: isFetchingBookingMenuColumns,
    taxTypes: revenueTypes,
    allMenuItems: indexedData,
  };
};

export default connect(mapStateToProps, undefined)(withStyles(styles)(MenuGrid));
