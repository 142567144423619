import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

export const getStatusHistory = (entityType, entityId) => dispatch => {
  if (entityType && entityId) {
    return dispatch(callApi(`statushistory/${entityType}/${entityId}`))
      .then(result => {
        if (!result.ok || result.status !== 200) {
          throw Error('Problem in status history fetch');
        }

        return ParseJsonOnSuccessOrStop(dispatch, result);
      });
  } else {
    return Promise.reject('Relationship not detected for Status History fetch');
  }
};

export const addStatusHistory = (entityType, entityId, statusId) => dispatch => {
  return dispatch(callApi(`statushistory/${entityType}/${entityId}`, { method: 'PUT', body: statusId }))
    .then(result => {
      if (!result.ok || result.status !== 200) {
        throw Error('Problem in status history update');
      }

      return ParseJsonOnSuccessOrStop(dispatch, result);
    });
};
