import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import StoreMenuSelection from './StoreMenuSelection';
import { userIsInRole, adminRole } from 'helpers/userHelpers';

const styles = theme => ({
  menuSelection: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.paper,
    maxHeight: 558,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  noMenu: {
    textAlign: 'center',
    fontSize: '20px',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
  },
});

class Menu extends Component {
  state = {
    nextPersonId: 2,
    taxConfig: {},
    isMenuItemModalOpen: true,
  };

  componentDidMount() {
    this.getOrderTaxConfig();
  }

  onMenuSectionAdded = () => {
    this.setState({ nextPersonId: this.state.nextPersonId + 1 });
  }

  saveMenuItemSelection = newMenuItems => {

    this.closeMenuItemModal();
    const { storeOrder } = this.props;
    const tmp = [...storeOrder, ...newMenuItems];

    console.log(tmp);
  }

  closeMenuItemModal = () => {
    this.setState({ isMenuItemModalOpen: false });
  }

  getOrderTaxConfig = () => {

    this.setState({ taxConfig: {} });
  }

  render() {
    const { storeMenus, classes, user } = this.props;
    const userIsCoverAdmin = userIsInRole(user, adminRole);

    return storeMenus && storeMenus.length ?
      <StoreMenuSelection
        defaultQuantity={1}
        isImagesOpenedFirst={true}
        onModalClosed={this.closeMenuItemModal}
        onSave={this.saveMenuItemSelection}
      />
      :
      <div className={classes.noMenu}>
        {userIsCoverAdmin
          ? <span>No Quick Order Menu Assigned <Link to="/admin/menu">Menu Management</Link></span>
          : <span>No Quick Order Menu Assigned</span>
        }
      </div>;
  }
}
class WizardMenu extends Component {
  render() {

    const {
      storeOrder,
    } = this.props;

    return (<Menu
      {...this.props}
      menuId={storeOrder.menuId}
      orderId={storeOrder.id}
    />);
  }
};

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeOrder,
      storeMenus,
    },
    oidc: {
      user,
    },
  } = state;

  return {
    storeOrder,
    storeMenus,
    user,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WizardMenu));
