import React, { Component } from 'react';
import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';

// Note: Most of this is adapted from the Demo: https://material-ui.com/demos/autocomplete/#react-select
const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
  },
  input: {
    display: 'flex',
    padding: 0,
    '& div[aria-hidden="true"]': { //Decrease padding around icons to make input size match (height: 32px)
      padding: '6px',
    },
  },
  valueContainer: {
    color: theme.palette.grey[300],
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 4}px ${theme.spacing.unit / 4}px ${theme.spacing.unit / 2}px`,
    height: theme.spacing.unit * 3,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[400],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    color: theme.palette.grey[200],
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    color: theme.palette.grey[200],
  },
  menuItem: {
    color: theme.palette.grey[300],
  },
  menu: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    borderRadius: '4px',
    '& .css-1fv7cpv-MenuList': {
      maxHeight: 150,
    },
  },
  label: {
    color: theme.palette.grey[200],
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,

          ...props.innerProps,
        },
      }}
      label={props.selectProps.label}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: props.selectProps.classes.label,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      key={props.value.id}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.selectProps.classes.menuItem}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer} key={props.selectProps.values}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      key={props.data}
      tabIndex={-1}
      label={props.data}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.menu} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer,
};

class MultiSelect extends Component {
  state = {
    suggestions: [],
    map: {},
  };

  componentDidMount() {
    this.setUpSuggestions(this.props.dataSource);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.setUpSuggestions(this.props.dataSource);
    }
  }

  setUpSuggestions = dataSource => {
    if (dataSource && dataSource.length) {
      const { comboBoxSuggestions, comboBoxMap } = setupComboBoxSuggestions(dataSource);

      this.setState({ suggestions: comboBoxSuggestions, map: comboBoxMap });
    }
  }

  getValues = values => {
    const { valueType } = this.props;

    if (valueType === 'string') {
      return this.getValuesFromString(values);
    }

    return values || [];
  }

  getValuesFromString = commaDelimitedString => {
    const { suggestions, map } = this.state;

    if (!!commaDelimitedString && typeof commaDelimitedString === 'string') {
      return commaDelimitedString.split(', ').map(stringItem => {
        const found = suggestions.find(sugg => sugg.value.name === stringItem);

        if (found) {
          return map[found.value.id];
        }

        return stringItem;
      });
    } else {
      return [];
    }
  }

  handleSelection = (selections, { action, removedValue }) => {
    if (action === 'remove-value') {
      this.handleRemoveValue(removedValue);
    } else {
      this.handleAddValue(selections);
    }
  }

  handleRemoveValue = selection => {
    const { valueType, values, onFieldChange } = this.props;
    const valueArray = this.getValues([...values]);
    const indexOfRemovedValue = valueArray.indexOf(selection);

    if (indexOfRemovedValue !== -1) {
      valueArray.splice(indexOfRemovedValue, 1);
    }
    const newSelections = valueType === 'string' ? valueArray.join(', ') : valueArray;

    onFieldChange(newSelections);
  }

  handleAddValue = selections => {
    const { valueType, onFieldChange } = this.props;

    const values = selections.map(selection => {
      if (selection.hasOwnProperty('value')) {
        return selection.value.name;
      } else {
        return selection;
      }
    });

    const newSelections = valueType === 'string' ? values.join(', ') : values;

    onFieldChange(newSelections);
  }

  render() {
    const {
      classes,
      values,
      label,
      theme,
      onQuickAdd,
    } = this.props;
    const {
      suggestions,
    } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.grey[300],
        '& input': {
          font: 'inherit',
        },
      }),
      menuPortal: base => ({
        ...base,
        zIndex: 9999,
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          {onQuickAdd ?
            <CreatableSelect
              {...this.props}
              classes={classes}
              styles={selectStyles}
              options={suggestions || []}
              components={components}
              value={this.getValues(values)}
              textFieldProps={{
                label: label,
              }}
              onChange={this.handleSelection}
              onCreateOption={onQuickAdd}
              isMulti={true}
              menuPortalTarget={document.body}
              hideSelectedOptions={true}
            />
            :
            <Select
              {...this.props}
              classes={classes}
              styles={selectStyles}
              options={suggestions || []}
              components={components}
              value={this.getValues(values)}
              textFieldProps={{
                label: label,
              }}
              onChange={this.handleSelection}
              isMulti={true}
              menuPortalTarget={document.body}
            />
          }
        </NoSsr>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MultiSelect);
