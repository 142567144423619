export const defaultSelectedEventTypes = {
  Personal: true,
  Proposal: true,
  Booking: true,
  Task: true,
};

export const EVENT_TYPES = {
  Booking: 'Booking',
  Proposal: 'Proposal',
  Task: 'Task',
  Personal: 'Personal',
};
