import React, { Component } from 'react';
import SimpleDialog from 'Components/SimpleDialog';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SectionHeader from 'Components/SectionHeader';
import drivericon from 'shared/icons/wedding-reception.svg';
import hostessicon from 'shared/icons/air-hostess.svg';
import drinksicon from 'shared/icons/beverages.svg';
import plateicon from 'shared/icons/dinner.svg';
import captainicon from 'shared/icons/rudder.png';
import ShiftGrid from './ShiftGrid';
import {
  updateStoreBooking,
  deleteShiftFromFocusedEvent,
} from 'actions/booking';
import {
  getShiftTypes,
} from 'actions/shiftType';
import ShiftPickerModal from './ShiftPickerModal';
import IconButton from 'Components/Buttons/IconButton';
import { Add as AddIcon } from '@material-ui/icons';
import moment from 'moment';

const styles = theme => ({
  listPanel: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    justifyContent: 'space-evenly',
    padding: 16,
  },
  sectionHeader: {
    paddingRight: 32,
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 120,
    height: 120,
    padding: 16,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 4,
    fontSize: 13,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  imageContainer: {
    width: 90,
    textAlign: 'center',
  },
  image: {
    width: 51,
  },
  shiftGrid: {
    paddingTop: 8,
  },
  maxHeight: {
    maxHeight: 185,
  },
  iconButton: {
    padding: 0,
    color: theme.palette.grey[800],
  },
  fallbackMessage: {
    padding: 16,
  },
});

class Shifts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShiftPickerOpen: false,
      shifts: [],
      existingShiftIndex: null, // when editing, we use the index to update the store
    };
    this.customShiftDialog = React.createRef();
    this.savedCardsModal = React.createRef();
  }

  componentDidMount() {
    this.props.getShiftTypes();
  }

  openCustomShiftModal = () => {
    const { focusedEvent } = this.props;

    const shiftEdit = {
      timeIn: moment(focusedEvent.startDateTime).clone().format(),
      timeOut: moment(focusedEvent.endDateTime).clone().format(),
      recordStatus: 'Active',
    };

    this.setState({ shiftEdit, existingShiftIndex: null });
  }

  openAddShiftModal = shiftType => () => {
    const { focusedEvent } = this.props;

    const shiftEdit = {
      shiftTypeId: shiftType.id,
      numberOfEmployees: 5,
      rate: shiftType.defaultRate,
      rateType: shiftType.defaultRateType, //TODO: add to ShiftPicker
      timeIn: moment(focusedEvent.startDateTime).clone().format(),
      timeOut: moment(focusedEvent.endDateTime).clone().format(),
      recordStatus: 'Active',
    };

    this.setState({ shiftEdit, existingShiftIndex: null });
  }

  closeShiftPicker = () => {
    this.setState({ shiftEdit: null, existingShiftIndex: null });
  }

  shiftButton = (shiftType, name, image) => {
    const classes = this.props.classes;

    return (<div className={classes.card} onClick={this.openAddShiftModal(shiftType)} >
      <div className={classes.imageContainer}>
        <img src={image} alt={name} className={classes.image} />
      </div>
      {name}
    </div>
    );
  }

  //editing an existing shift
  onEdit = (clickedShift, shiftIndex) => {
    this.setState({ shiftEdit: { ...clickedShift }, existingShiftIndex: shiftIndex });
  }

  onDelete = shiftIndex => {
    this.props.deleteShiftFromFocusedEvent(shiftIndex);
  }

  handleOpenCustomShiftModal = () => {
    this.customShiftDialog.current.open().then(this.openCustomShiftModal);
  }

  render() {
    const { classes, storeDefaultShiftTypes, focusedEvent } = this.props;
    const { shiftEdit, existingShiftIndex } = this.state;

    return (
      <>
        <SectionHeader className={classes.sectionHeader}>
          Shift Picker
        </SectionHeader>
        {storeDefaultShiftTypes && (
          <div className={classes.listPanel}>
            {this.shiftButton(storeDefaultShiftTypes.server, 'Server', drivericon)}
            {this.shiftButton(storeDefaultShiftTypes.bartender, 'Bartender', drinksicon)}
            {this.shiftButton(storeDefaultShiftTypes.busser, 'Busser', plateicon)}
            {this.shiftButton(storeDefaultShiftTypes.hostess, 'Hostess', hostessicon)}
            {this.shiftButton(storeDefaultShiftTypes.captain, 'Captain', captainicon)}
          </div>
        )}
        <SectionHeader className={classes.sectionHeader}>
          Shifts
          <IconButton
            height={24}
            icon={AddIcon}
            onClick={this.handleOpenCustomShiftModal}
            className={classes.iconButton}
          />
        </SectionHeader>
        <div className={classes.shiftGrid}>
          {focusedEvent && focusedEvent.bookingEventShifts ?
            <ShiftGrid
              className={classes.maxHeight}
              shifts={focusedEvent.bookingEventShifts || []}
              isEditing={true}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
            />
            :
            <div className={classes.fallbackMessage}>Select an event above to edit Shifts for that event!</div>
          }
        </div>
        {shiftEdit &&
          <ShiftPickerModal
            onModalClosed={this.closeShiftPicker}
            shift={shiftEdit}
            existingShiftIndex={existingShiftIndex}
          />}
        <SimpleDialog
          message="Would you like to add a custom shift for this event?"
          innerRef={this.customShiftDialog}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    booking: {
      [ownProps.storeType]: {
        storeBooking,
        focusedEventIndex,
        focusedEvent,
      },
      storeDefaultShiftTypes,
    },
  } = state;

  return {
    storeBooking,
    events: storeBooking.events,
    focusedEventIndex,
    focusedEvent,
    defaultStartTime: focusedEvent ? focusedEvent.startDateTime : null,
    defaultEndTime: focusedEvent ? focusedEvent.endDateTime : null,
    storeDefaultShiftTypes,
  };
};

const mapDispatchToProps = {
  getShiftTypes,
  updateStoreBooking,
  deleteShiftFromFocusedEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Shifts));
