import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import {
  PICKLISTS_RECEIVED,
  PICKLISTS_GROUPS_RECEIVED,
} from 'actions/constants';

const picklistsReceived = picklists => ({
  type: PICKLISTS_RECEIVED,
  picklists,
});

const picklistGroupsReceived = picklistGroups => ({
  type: PICKLISTS_GROUPS_RECEIVED,
  picklistGroups,
});

export const getPickLists = () => {
  return dispatch => {

    return getPickListsApi(dispatch).then(result => {
      dispatch(picklistsReceived(result));

      return result;
    });
  };
};

export const getGroupedPickLists = () => {
  return dispatch => {

    return getGroupedPickListsApi(dispatch).then(result => {
      dispatch(picklistGroupsReceived(result));
    });
  };
};

const getPickListsApi = dispatch => {
  return dispatch(callApi(`PickList`)).then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

const getGroupedPickListsApi = dispatch => {
  return dispatch(callApi(`PickList/Grouped`)).then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

export const savePicklist = picklist => (dispatch, getState) => {

  return savePicklistApi(dispatch, picklist).then(result => {
    const { picklistGroups } = getState().pickList;

    const updatedPicklistGroups = [...picklistGroups];

    updatedPicklistGroups.forEach((group, groupIndex) => {
      group.items.forEach((groupItem, itemIndex) => {
        if (groupItem.id === result.id) {
          updatedPicklistGroups[groupIndex].items[itemIndex] = result;
        }
      });
    });

    dispatch(picklistGroupsReceived(updatedPicklistGroups));

    return result;
  });
};

export const deletePicklistOption = deletedOption => (dispatch, getState) => {

  return deletePicklistOptionApi(dispatch, deletedOption).then(result => {
    if (!result.ok) {
      return result;
    }

    const { picklistGroups } = getState().pickList;
    const updatedPicklistGroups = [...picklistGroups];

    updatedPicklistGroups.forEach(group => {
      group.items.forEach(item => {
        item.pickListOptions = item.pickListOptions
          .filter(option => option.id !== deletedOption.id);
      });
    });

    dispatch(picklistGroupsReceived(updatedPicklistGroups));
  });
};

const deletePicklistOptionApi = (dispatch, option) => {
  return dispatch(
    callApi(`PickListOption/${option.id}`, { method: 'delete' }))
    .catch(console.error);
};

const savePicklistApi = (dispatch, picklist) => {
  if (picklist.id) {
    return dispatch(
      callApi(`PickList/${picklist.id}`, { method: 'put', body: picklist }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);
  } else {
    return dispatch(
      callApi(`PickList`, { method: 'post', body: picklist }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);
  }
};
