import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import "@yaireo/tagify/dist/tagify.css"; // Tagify CSS
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { getAllTags } from 'actions/menu';

const styles = theme => ({
  tagIcon: {
    fill: '#060f4e',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '16px',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    userSelect: 'none',
    flexShrink: '0',
    marginLeft: '5px',
  },
  tagsContainer: {
    '& tags': {
      fontSize: '16px',
      border: '0',
      margin: '0',
      marginTop: '6px',
      flex: 1,
      width: '100%',
    },
    '& div': {
      width: '100%',
    },
    '&:hover': {
      borderBottom: '2px solid rgb(100,100,100)',
      paddingBottom: 0,
    },
    '& .searchIcon': {
      marginTop: '12px',
      marginLeft: '5px',
      fill: '#bcbcbc',
      fontSize: '25px',
      width: 30,
    },
    '& .tagify__tag__removeBtn': {
      font: '17px Serif',
      width: 22,
    },
    borderBottom: '1px solid rgb(97,97,97)',
    display: 'flex',
    border: '0',
    flexDirection: 'row',
    marginTop: '6px',
    paddingBottom: 1,
  },
});

class MenuItemFilterField extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.tags = React.createRef();
  }

  componentDidMount() {
    this.props.getAllTags();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.itemTags !== prevProps.itemTags) {
      const suggestions = this.props.itemTags.map(tag => {
        return {
          value: tag.name,
          type: 'tag',
          id: tag.id,
        };
      });

      this.setState({
        tagSuggestions: suggestions,
      });

      if (this.tags.current) {
        this.tags.current.whitelist = null;
        this.tags.current.whitelist = suggestions;
      }
    }
  }

  tagRemoved = event => {
    if (this.tags.current) {
      this.tags.current.DOM.input.click();
      this.tags.current.DOM.input.focus();
    }
  }

  fieldChanged = event => {
    if (!this.props.onChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    if (!event.detail.value) {
      this.props.onChange(null);

      return;
    }

    this.props.onChange(JSON.parse(event.detail.value));
  }

  render() {

    const {
      classes,
      className,
    } = this.props;

    const { tagSuggestions = [] } = this.state;

    const tagifySettings = {
      whitelist: tagSuggestions,
      placeholder: " try 'Burger' or 'Breakfast'",
      dropdown: {
        enabled: 0,
      },
    };

    return <div className={className || classes.tagsContainer}>
      <SearchIcon className="searchIcon" />
      <Tags
        settings={tagifySettings}
        onChange={this.fieldChanged}
        onRemove={this.tagRemoved}
        tagifyRef={this.tags}
      />
    </div>;
  }
}

const mapStateToProps = state => {
  const {
    menuItem: {
      tags: {
        data,
      },
    },
  } = state;

  return {
    itemTags: data,
  };
};

const mapDispatchToProps = {
  getAllTags,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MenuItemFilterField));
