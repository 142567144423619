import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import AccountContext from './AccountContext';
import AccountHeader from './AccountHeader';
import AccountTabs from './AccountTabs';
import AccountSideTabs from './AccountSideTabs';
import ValidationBanner from 'Components/ValidationBanner';
import SimpleDialog from 'Components/SimpleDialog';
import validate from 'validate.js';
import { ENTITY_TYPES } from 'constants/entityTypes';
import { withRouter } from 'react-router-dom';
import SectionContext from 'Components/Contexts/SectionContext';
import ChitChat from 'Components/ChitChat';
import Email from 'Components/Email';
import { getSalesRepsIfNeeded } from 'actions/contact';
import {
  addAccount,
  getAccount,
  saveAccount,
  deleteAccount,
  cloneAccount,
  clearCurrentAccount,
} from 'actions/account';
import {
  addToRecents,
  removeRecent,
} from 'actions/recents';
import { logChitChatSystemMessage } from 'actions/chitchat';

const styles = theme => ({
  body: {
    display: 'flex',
    flexGrow: 1,
    padding: '24px 16px 0',
    height: 'calc(100% - 120px)',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: '75%',
    borderRadius: 4,
    marginRight: 24,
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: '25%',
    height: '100%',
  },
  flexGrow: {
    display: 'flex',
    flexGrow: 1,
  },
});

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isFirstEdit: false,
      account: {},
      accountRef: {},
      invalidFields: [],
      salesReps: [],
      isValidationBannerVisible: false,
      viewState: 'accountDetails',
    };

    this.notFoundDialog = React.createRef();
    this.accountDialog = React.createRef();
  }

  refreshChitChat = () => {
    const { viewState, account } = this.state;
    const { fetchChitChat } = this.props;

    if (viewState === 'chitChat') {
      fetchChitChat(ENTITY_TYPES.account, account.id);
    }
  }

  chitChatSystemMessage = message => {
    const { account } = this.state;
    const { logChitChatSystemMessage } = this.props;
    const relationship = {
      id: account.id,
      entityType: ENTITY_TYPES.account,
    };
    const chitChatRecordName = `Account - ${account.firstName || ''} ${account.lastName || ''}`;

    return logChitChatSystemMessage(message, relationship, chitChatRecordName).then(this.refreshChitChat);
  }

  checkIfFirstEditAsync = () => {
    const { account } = this.state;
    const isFirstEdit = !!account && account.name === 'New' && !account.lastModifiedUtcDateTime;
    const isEditing = this.state.isEditing || (!this.state.isFirstEdit && isFirstEdit);

    return new Promise((resolve, reject) => {
      if (this.state.isFirstEdit !== isFirstEdit) {
        this.setState({ isFirstEdit, isEditing }, () => resolve());
      } else {
        resolve();
      }
    });
  }

  async componentDidMount() {
    await this.checkIfFirstEditAsync();
    this.updateAccountReference();
    // this.props.getSalesRepsIfNeeded(); TODO
  }

  async componentDidUpdate(prevProps) {
    await this.checkIfFirstEditAsync();
    if (prevProps.location !== this.props.location) {
      this.updateAccountReference();
    }
  }

  componentWillUnmount() {
    // this.props.clearCurrentAccount();
  }

  updateAccountReference = () => {
    return new Promise((resolve, reject) => {
      this.props.getAccount(this.props.match.params.id).then(account => {
        this.setState({
          account,
          sectionContext: {
            relationship: {
              entityId: account.id,
              entityType: ENTITY_TYPES.account,
            },
          },
          accountRef: { ...account },
          isValidationBannerVisible: false,
        }, () => { resolve(account); });
        this.props.addToRecents(account, this.props.match.url, ENTITY_TYPES.account);
      }).catch(error => {
        console.error('Error in Account lookup: ', error);
        this.showNotFoundMessage();
        reject(error);
      });
    });
  }

  showNotFoundMessage = () => {
    const { notFoundDialog } = this;

    notFoundDialog && notFoundDialog.current && notFoundDialog.current.open().then(this.goToAccountsGrid);
  }

  onEdit = () => {
    if (this.state.isEditing) {
      this.cancelEditMode();
    } else {
      this.launchEditMode();
    }
  }

  launchEditMode = () => {
    // TURN ON: set initial form in case changes are not saved
    this.setState({ isEditing: true, accountRef: this.state.account, viewState: 'accountDetails' });
  }

  cancelEditMode = () => {
    const { accountRef, isFirstEdit } = this.state;

    if (isFirstEdit) {
      this.deleteAccount(); // User quick-adds an Account, then decides not to Save it

      return;
    }
    if (this.isFormChanged()) {
      this.accountDialog.current.open('Are you sure you want to lose your changes?').then(() => {
        this.setState({ isEditing: false, account: accountRef, invalidFields: [], isValidationBannerVisible: false });
      });
    } else {
      this.setState({ isEditing: false });
    }
  }

  cloneAccount = () => {
    const { cloneAccount, saveAccount, history, addToRecents } = this.props;
    const { account } = this.state;

    this.accountDialog.current.open('Are you sure you want to clone this account?').then(() => {
      cloneAccount(account).then(response => {
        const newUrl = `/accounts/${response.id}`;

        addToRecents(response, newUrl, ENTITY_TYPES.account);
        this.chitChatSystemMessage(`Account cloned from ${account.firstName || ''} ${account.lastName || ''}`);
        this.accountDialog.current.open('Successfully cloned! Would you like to go to the newly created account?')
          .then(() => history.push(newUrl))
          .catch(() => saveAccount(response)); // save new clone again, so isNew is removed;
      }).catch(error => window.alert(error));
    });
  }

  goToAccountsGrid = () => {
    this.props.history.push('/accounts/');
  }

  deleteAccount = () => {
    const accountId = this.state.account.id;

    this.accountDialog.current.open('Are you sure you want to delete this account?').then(() => {
      this.props.deleteAccount(accountId).then(() => {
        this.goToAccountsGrid();
        this.props.removeRecent(accountId, 'account');
      });
    });
  }

  checkAccountAddress = () => {
    const { account, accountRef } = this.state;
    const addressDeleted =
      (!!accountRef.accountAddress && !!accountRef.accountAddress.address1)
      && (!account.accountAddress || !account.accountAddress.address1);

    if (addressDeleted) {
      this.accountDialog.current.open('Are you sure you want to save without address?')
        .then(this.saveAccount)
        .catch();
    } else {
      this.saveAccount();
    }
  }

  saveAccount = () => {
    const { account, isFirstEdit } = this.state;

    return new Promise((resolve, reject) => {
      if (this.isFormValid()) {
        if (this.isFormChanged() || isFirstEdit) {
          this.props.saveAccount(account).then(async () => {
            await this.updateAccountReference();
            this.chitChatSystemMessage('Account Details updated');
            this.cancelEditMode();
            resolve();
          });
        } else {
          this.cancelEditMode();
          resolve();
        }
      } else {
        this.setState({ isValidationBannerVisible: true }, resolve);
      }
    });
  }

  handleFieldChange = fieldName => value => {
    let account = {
      ...this.state.account,
      [fieldName]: value,
    };

    this.setState({ account });
  }

  handleAddressChange = fieldName => value => {
    let account = {
      ...this.state.account,
      accountAddress: {
        ...this.state.account.accountAddress,
        [fieldName]: value,
      },
    };

    this.setState({ account });
  }

  isFormChanged = () => {
    const { account, accountRef } = this.state;

    return Object.keys(accountRef).some(key => accountRef[key] !== account[key]) ||
      (accountRef.accountAddress && Object.keys(accountRef.accountAddress).some(key => accountRef.accountAddress[key] !== account.accountAddress[key]));
  }

  isFieldChanged = fieldName => {
    return this.state.accountRef[fieldName] !== this.state.account[fieldName];
  }

  isAddressFieldChanged = fieldName => {
    const { account, accountRef } = this.state;

    return accountRef.accountAddress && accountRef.accountAddress[fieldName] !== account.accountAddress[fieldName];
  }

  isFieldInvalid = fieldName => {
    return this.state.invalidFields.indexOf(fieldName) !== -1;
  }

  validateField = fieldName => {
    var isValid = true;
    var value = this.state.account[fieldName];

    switch (fieldName) {
      case 'email':
        const emailResult = validate({ email: value }, { email: { email: true } });

        isValid = !emailResult;
        break;
      case 'name':
        const nameResult = validate({ name: value }, { name: { presence: { allowEmpty: false } } });

        isValid = !nameResult;
        break;
      default:
        return;
    }

    let invalidFields = [...this.state.invalidFields];
    const indexOfField = invalidFields.indexOf(fieldName);

    if (!isValid) {
      if (indexOfField === -1) {
        invalidFields.push(fieldName);
      }
    } else {
      if (indexOfField !== -1) {
        invalidFields.splice(indexOfField, 1);
      }
    }

    if (this.state.isValidationBannerVisible && invalidFields.length === 0) {
      this.setState({ invalidFields, isValidationBannerVisible: false });
    } else {
      this.setState({ invalidFields });
    }
  }

  isFormValid = () => {
    return this.state.invalidFields.length === 0;
  }

  mockData = {
    customFields: [
      { id: 1, name: 'Site Name', value: 'Winterfell Castle' },
      { id: 2, name: 'Transportation', value: 'Iron Fleet' },
    ],
  };

  primaryContactUpdated = newPrimaryContact => {
    const account = {
      ...this.state.account,
      primaryContact: newPrimaryContact,
    };

    this.setState({ account });
  }

  setView = viewState => {
    this.setState({ viewState });
  }

  onEmailCancel = () => {
    this.setState({ viewState: 'accountDetails' });
  }

  createAccountAndGo = () => {
    const { addAccount, history } = this.props;

    addAccount({ name: 'New' }).then(account => {
      history.push(`/accounts/${account.id}`);
    });
  }

  onAddAccount = () => {
    if (this.isFormChanged() === true) {
      this.accountDialog.current.open('Save your changes before creating a new account?').then(() => {
        this.saveAccount().then(this.createAccountAndGo);
      }).catch(this.createAccountAndGo);
    } else {
      this.saveAccount().then(this.createAccountAndGo);
    }
  };

  render() {
    const {
      classes,
      salesRepsData,
    } = this.props;

    const {
      isEditing,
      account,
      isValidationBannerVisible,
      viewState,
    } = this.state;

    if (!account || !account.id) {
      return <SimpleDialog
        message="Account Not Found. Return to Accounts Grid?"
        innerRef={this.notFoundDialog}
      />;
    }

    const sectionContext = {
      relationship: {
        entityId: account.id,
        entityType: ENTITY_TYPES.account,
      },
      onEmailCancel: this.onEmailCancel,
      chitChatRecordName: account ? account.name : '',
    };

    // TODO: API Custom Fields
    account.customFields = this.mockData.customFields;

    const accountContext = {
      account: account,
      salesReps: salesRepsData,
      isEditing: isEditing,
      onSave: this.checkAccountAddress,
      onCancelEdit: this.cancelEditMode,
      onLaunchEditMode: this.launchEditMode,
      onFieldChange: this.handleFieldChange,
      onAddressFieldChange: this.handleAddressChange,
      isFieldChanged: this.isFieldChanged,
      isAddressFieldChanged: this.isAddressFieldChanged,
      validateField: this.validateField,
      isFieldInvalid: this.isFieldInvalid,
    };

    return (
      <AccountContext.Provider value={accountContext}>
        <SectionContext.Provider value={sectionContext}>
          <AccountHeader
            label="Account"
            account={account}
            salesReps={salesRepsData}
            isEditing={isEditing}
            onEdit={this.onEdit}
            onDelete={this.deleteAccount}
            onClone={this.cloneAccount}
            onSetView={this.setView}
            onAddAccount={this.onAddAccount}
            viewState={viewState}
          />
          <div className={classes.body}>
            <div className={classes.main}>
              {viewState === 'accountDetails' &&
              <AccountTabs
                onSave={this.saveAccount}
                onCancelEdit={this.cancelEditMode}
              />
              }
              {viewState === 'chitChat' &&
              <Paper className={classes.flexGrow}>
                <ChitChat
                  relationshipId={account.id}
                  chitChatRelationship={ENTITY_TYPES.account}
                  recordName={account.name}
                />
              </Paper>
              }

              {viewState === 'email' &&
              <Email />
              }
            </div>
            <div className={classes.sidebar}>
              <AccountSideTabs
                primaryContactUpdated={this.primaryContactUpdated}
              />
            </div>
          </div>

          {isEditing && <ValidationBanner isOpen={isValidationBannerVisible} />}
          <SimpleDialog innerRef={this.accountDialog} />
        </SectionContext.Provider>
      </AccountContext.Provider>
    );
  }
}

const mapDispatchToProps = {
  addAccount,
  getAccount,
  saveAccount,
  getSalesRepsIfNeeded,
  deleteAccount,
  clearCurrentAccount,
  cloneAccount,
  addToRecents,
  removeRecent,
  logChitChatSystemMessage,
};

const mapStateToProps = state => {
  const {
    api: {
      salesReps: {
        salesRepsData,
      },
    },
  } = state;

  return {
    salesRepsData,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Account)));
