import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import TextField from 'Components/TextField';
import {
  updateRevenueType,
  createRevenueType,
} from 'actions/RevenueType';

const styles = theme => ({
  container: {
    padding: '20px 26px',
  },
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  table: {
    fontSize: 12,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    '& th': {
      padding: '6px 13px',
    },
    '& tr': {
      borderTop: 0,
      borderBottom: 0,
    },
  },
  borderedCell: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
  },
  rowLabel: {
    color: theme.palette.grey[300],
    paddingTop: 11,
    paddingBottom: 11,
  },
  centerText: {
    textAlign: 'center',
  },
  sectionLabel: {
    height: 16,
    display: 'flex',
    paddingLeft: 13,
    marginTop: 8,
    alignItems: 'center',
  },
  moveDown: {
    marginTop: 13,
    marginRight: 0,
  },
  exemptContents: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftColumn: {
    minWidth: 126,
  },
  rightColumn: {
    flexGrow: 1,
  },
  flex: {
    display: 'flex',
  },
  switchTextContainer: {
    textAlign: 'center',
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  switchText: {
    fontSize: 11,
    color: '#9d9d9d',
  },
  switch: {
    marginTop: -5,
  },
  codeExists: {
    margin: '0 16px',
    color: theme.palette.error.dark,
  },
});

const RevenueTypeModal = ({ classes, RevenueType, createRevenueType, updateRevenueType, onCloseModal, discountMode }) => {
  const [RevenueTypeEdit, setRevenueTypeEdit] = useState(RevenueType);

  const onSave = () => {
    const newRevenueType = !RevenueTypeEdit.id;

    if (newRevenueType) {
      return createRevenueType(RevenueTypeEdit).then(onCloseModal);
    }

    return updateRevenueType(RevenueTypeEdit).then(onCloseModal);
  };

  const onCancel = () => {
    onCloseModal();
  };

  const onFieldChange = fieldName => value => {
    if (value && value.length > 20) {
      return;
    }

    const updatedRevenueType = {
      ...RevenueTypeEdit,
      [fieldName]: value,
    };

    setRevenueTypeEdit(updatedRevenueType);
  };

  return (<Modal
    isOpened={true}
    dimensions={{
      width: 340,
      height: 250,
    }}
    onCancel={onCancel}
    onSave={onSave}
    isSaveDisabled={(!RevenueTypeEdit.name)}
    title='Revenue Type'
    addTitleBottomBorder={true}
  >
    <div className={classes.container}>
      <div className={classes.exemptContents}>
        <TextField
          label="Name"
          value={RevenueTypeEdit.name}
          onFieldChange={onFieldChange('name')}
          maxLength={20}
        />
      </div>

    </div>
  </Modal>
  );
};

const mapDispatchToProps = {
  updateRevenueType,
  createRevenueType,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(RevenueTypeModal));
