import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from 'Components/Modal';
import CoverDateTimePicker from 'Components/CoverDateTimePicker';
import ComboBox from 'Components/ComboBox';
import TextField from 'Components/TextField';
import RichTextField from 'Components/RichTextField';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import moment from 'moment';
import { saveCalendarEvent } from 'actions/calendar';
import { connect } from 'react-redux';
import { getContacts } from 'actions/contact';
import ValidationBanner from 'Components/ValidationBanner';
import { REMINDERS } from 'constants/reminders';
import SectionContext from 'Components/Contexts/SectionContext';
import CheckBox from 'Components/Checkbox';

const styles = theme => ({
  row: {
    display: 'flex',
    height: 72,
    margin: '0 8px 24px',
  },
  overflowIfTooBig: {
    overflow: 'auto',
    marginTop: 16,
  },
  flexGrow: {
    flexGrow: 1,
  },
  flexGrowWithPad: {
    flexGrow: 1,
    margin: '16px 16px 8px',
  },
  multilineHeight: {
    height: 'unset',
    minHeight: 72,
  },
  width50: {
    display: 'flex',
    flex: 1,
    width: '50%',
  },
  subtitle: {
    paddingTop: 4,
    paddingLeft: 32,
    height: 32,
    width: '100%',
    fontSize: 17,
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.common.black,
    textAlign: 'left',
  },
  grey200: {
    color: theme.palette.grey[200],
  },
  grey300: {
    color: theme.palette.grey[300],
  },
  black: {
    color: theme.palette.common.black,
  },
  calendarEventType: {
    width: '81%',
  },
  allDayCheckbox: {
    paddingTop: 10,
    margin: 0,
  },
  addOthersSection: {
    paddingBottom: 24,
  },
  addOthersContainer: {
    display: 'flex',
    margin: '20px 0px 8px',
  },
  addOthersButton: {
    width: 20,
    height: 20,
    alignSelf: 'center',
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 2,
    padding: '0',
    marginRight: theme.spacing.unit,
    fontSize: 18,
  },
  contactsComboBox: {
    width: '50%',
  },
  sharedContacts: {
    color: theme.palette.primary.dark,
    marginLeft: 18,
    display: 'list-item',
  },
  richText: {
    paddingTop: 16,
  },
});

const comma = ", ";
const and = " and ";

export class AddEditEventModal extends Component {
  constructor(props) {
    super(props);
    this.validationBanner = React.createRef();
    this.froalaConfig = {
      zIndex: props.theme.zIndex.modal + 1,
      heightMin: 34,
      heightMax: 290,
      toolbarButtons: ['bold', 'italic', 'underline', 'textColor', 'align'], // delete this line to restore default
    };

    this.state = {
      calendarEvent: {
        name: '',
        startDateTime: '',
        endDateTime: '',
        calendarEventType: '',
      },
      isAddingContact: false,
      isValidationBannerVisible: false,
      contacts: [],
      invalidFields: [],
    };
  }

  // TODO: This will be a QuickPick list
  locations = [
    { label: 'Office' },
    { label: 'Conference Room' },
    { label: 'Cafe' },
    { label: 'Auditorium' },
  ].map(suggestion => ({
    value: suggestion.label,
    label: suggestion.label,
  }));

  eventTypes = [
    { id: 2, name: 'Task/ToDo', type: 'Task' },
    { id: 3, name: 'Personal', type: 'Personal' },
  ];

  componentDidMount() {
    if (!!this.props.appointment) {
      this.setupData();
    }
    else {
      this.setupDefault();
    }
  }

  setupData = () => {
    const { appointment } = this.props;
    const calendarEvent = {
      ...appointment,
      startDateTime: moment(appointment.startDateTime).toDate(),
      endDateTime: moment(appointment.endDateTime).toDate(),
      calendarEventType: appointment.calendarEventType ? appointment.calendarEventType : 'Task',
    };

    this.setState({ calendarEvent });
  }

  setupDefault = () => {
    const { selectedDate } = this.props;

    const calendarEvent = {
      startDateTime: moment(selectedDate).toDate(),
      endDateTime: moment(selectedDate).toDate(),
      calendarEventType: 'Task',
    };

    this.setState({ calendarEvent });
  }

  getNearestHalfHour = () => {
    const minutesToNextHalfHour = 30 - (moment().minute() % 30);

    return moment().add(minutesToNextHalfHour, 'minutes').toDate();
  }

  handleDateTimeChange = fieldName => changedEventArgs => {
    if (!changedEventArgs) {
      return;
    }

    if (changedEventArgs.value) {
      this.handleNewDateSelection(fieldName, changedEventArgs.value);
    } else {
      const nearestHalfHour = this.getNearestHalfHour();
      const halfHourLater = moment(nearestHalfHour).add(30, 'minutes').toDate();

      this.handleNewDateSelection(fieldName, halfHourLater);
    }
  };

  handleNewDateSelection = (fieldName, value) => {
    const calendarEvent = { ...this.state.calendarEvent };
    const newDate = value;

    calendarEvent[fieldName] = newDate;

    if (fieldName === 'startDateTime') {
      if (!calendarEvent.endDateTime
        || calendarEvent.startDateTime > calendarEvent.endDateTime) {
        calendarEvent.endDateTime = moment(newDate).add(1, 'hours').toDate();
      }
    } else if (fieldName === 'endDateTime') {
      if (calendarEvent.startDateTime > calendarEvent.endDateTime) {
        calendarEvent.startDateTime = moment(newDate).subtract(1, 'hours').toDate();
      }
    }

    this.setState({ calendarEvent });
  }

  handleQuickPick = fieldName => pick => {
    const calendarEvent = {
      ...this.state.calendarEvent,
      [fieldName]: pick ? pick.value : '',
    };

    this.setState({ calendarEvent });
  }

  handleMultiSelect = fieldName => selections => {
    const newSelections = selections.map(selection => {
      if (selection.hasOwnProperty('value')) {
        return selection.value;
      } else {
        return selection;
      }
    });

    const calendarEvent = {
      ...this.state.calendarEvent,
      [fieldName]: newSelections,
    };

    this.setState({ calendarEvent });
  }

  handleFieldChange = fieldName => value => {
    const calendarEvent = {
      ...this.state.calendarEvent,
      [fieldName]: value,
    };

    if (fieldName === 'allDay' && value) {
      const allDay = calendarEvent.startDateTime || calendarEvent.endDateTime || new Date();

      calendarEvent.startDateTime = moment(allDay).startOf('day').toDate();
      calendarEvent.endDateTime = moment(allDay).endOf('day').toDate();
    }

    this.setState({ calendarEvent });
  }

  handleCheckbox = fieldName => event => {
    const calendarEvent = {
      ...this.state.calendarEvent,
      [fieldName]: event.target.checked,
    };

    this.setState({ calendarEvent });
  }

  // toggleAddOthers = () => {
  //   if (!this.state.isAddingContact) {
  //     const params = {
  //       filters: [],
  //       pageSize: '',
  //       skip: 0,
  //       sortField: '',
  //       sortDirection: '',
  //       search: '',
  //     };

  //     this.props.getContacts(params);
  //   }
  //   this.setState({ isAddingContact: !this.state.isAddingContact });
  // }

  isFieldValid = fieldName => {
    var value = this.state.calendarEvent[fieldName];

    switch (fieldName) {
      case 'name':
        return this.isTextValid(value);
      case 'startDateTime':
        return this.isDateValid(value);
      case 'endDateTime':
        return this.isDateValid(value);
      case 'calendarEventType':
        return this.isTextValid(value);
      default:
        return true;
    }
  }

  isDateValid = date => {
    return !!date && moment(date).isValid();
  }

  isTextValid = val => {
    if (val === undefined || val == null || val.length < 1) {
      return false;
    }

    return true;
  }

  validateForm = () => {
    const { calendarEvent } = this.state;
    const invalidFields = [];

    Object.keys(calendarEvent).forEach(fieldName => {
      if (!this.isFieldValid(fieldName)) {
        invalidFields.push(fieldName);
      };
    });

    return invalidFields;
  };

  addError(errors, newError) {
    if (errors === undefined || errors === null || errors.length === 0) {
      return newError;
    } else {
      return errors.includes(newError) ? errors : errors + comma + newError;
    }
  }

  formatErrorWithAnd(errors) {
    if (errors && errors.length > 0) {
      const lastComma = errors.lastIndexOf(comma);

      if (lastComma >= 0) {
        const endOfLastComma = lastComma + comma.length;
        let newErrors = errors.substring(0, lastComma) + and + errors.substring(endOfLastComma);

        return newErrors;
      } else {
        return errors;
      }
    } else {
      return errors;
    }
  }

  getErrorMessages = invalidFields => {
    let validationErrors = '';

    invalidFields.forEach(error => {
      if (error === 'name') {
        validationErrors = this.addError(validationErrors, 'Name');
      }
      if (error === 'startDateTime' || error === 'endDateTime') {
        validationErrors = this.addError(validationErrors, 'Start/End dates');
      }
      if (error === 'calendarEventType') {
        validationErrors = this.addError(validationErrors, 'Event Type');
      }
    });

    validationErrors = validationErrors ? validationErrors + ' must be provided.' : undefined;

    return this.formatErrorWithAnd(validationErrors);
  }

  formatDates = () => {
    const { calendarEvent } = this.state;

    calendarEvent.startDateTime = moment(calendarEvent.startDateTime).clone().format();
    calendarEvent.endDateTime = moment(calendarEvent.endDateTime).clone().format();

    return calendarEvent;
  }

  handleSave = () => {
    const { saveCalendarEvent, onModalClosed, onRefresh } = this.props;

    const invalidFields = this.validateForm();

    if (invalidFields && invalidFields.length > 0) {
      const bannerMessage = this.getErrorMessages(invalidFields);

      this.validationBanner.current.open(bannerMessage);
    } else {
      const formattedEvent = this.formatDates(); //make sure dates are formatted before sending to API

      saveCalendarEvent(formattedEvent)
        .then(() => {
          onModalClosed(true);
        })
        .then(onRefresh)
        .catch(error => {
          if (error.message) {
            this.validationBanner.current.open(error.message);
          } else {
            this.validationBanner.current.open('Error occured');
          }
        });
    }
  }

  // Provides context for RichTextField
  getSectionContext = () => {
    const { calendarEvent } = this.state;
    const sectionContext = {
      relationship: {
        entityId: calendarEvent.id || null,
        entityType: calendarEvent.calendarEventType, // TODO: add Task and Personal if wiring up images here.
      },
    };

    return sectionContext;
  }

  render() {
    const {
      classes,
      onModalClosed,
      hasRelationship,
    } = this.props;
    const {
      calendarEvent,
      isAddingContact,
    } = this.state;

    if (!calendarEvent) {
      return null;
    }

    const modalTitle = calendarEvent.id ? 'Edit Calendar Event' : 'New Calendar Event';
    const isTask = calendarEvent.calendarEventType === "Task";
    const isLinkedTask = isTask && hasRelationship;

    return (
      <Modal
        title={modalTitle}
        isOpened={true}
        onCancel={onModalClosed}
        onSave={this.handleSave}
        isSaveDisabled={isAddingContact}
        addTitleBottomBorder={true}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '735px' }}
      >
        <SectionContext.Provider value={this.getSectionContext()}>
          <div className={classes.overflowIfTooBig}>
            <Typography className={classes.subtitle} variant="body1">
              Event Details
            </Typography>

            <div className={classes.row}>
              <div className={classes.width50}>
                <TextField
                  label="Event Name"
                  onFieldChange={this.handleFieldChange('name')}
                  name="name"
                  value={calendarEvent.name}
                />
              </div>

              <div className={classNames(classes.width50)}>
                <ComboBox
                  value={calendarEvent.location || ''}
                  suggestions={this.locations}
                  handleChange={this.handleQuickPick('location')}
                  label="Location"
                  isClearable={true}
                  placeholder={"Select or Add..."}
                />
              </div>
            </div>

            <div className={classes.row}>
              <div className={classNames(classes.flexGrowWithPad, classes.width50)}>
                <CoverDateTimePicker
                  label="Start"
                  name="startDateTime"
                  value={calendarEvent.startDateTime}
                  onChange={this.handleDateTimeChange('startDateTime')}
                  disabled={calendarEvent.allDay}
                />
              </div>
              <div className={classNames(classes.flexGrowWithPad, classes.width50)}>
                <CoverDateTimePicker
                  label="End"
                  name="endDateTime"
                  value={calendarEvent.endDateTime}
                  onChange={this.handleDateTimeChange('endDateTime')}
                  disabled={calendarEvent.allDay}
                />
                <CheckBox
                  className={classes.allDayCheckbox}
                  label="All Day"
                  checked={calendarEvent.allDay}
                  onFieldChange={this.handleFieldChange('allDay')}
                />
              </div>
            </div>

            <Typography className={classes.subtitle} variant="body1">
              Additional Details
            </Typography>

            <div className={classes.row}>
              <div className={classes.width50}>
                <Select
                  value={calendarEvent.reminder || ''}
                  onFieldChange={this.handleFieldChange('reminder')}
                  name="reminder"
                  label="Reminder"
                >
                  {REMINDERS && REMINDERS.map(reminder =>
                    <MenuItem key={reminder.id} value={reminder.id} className={classes.grey300}>{reminder.name}</MenuItem>
                  )}
                </Select>
              </div>
              <div className={classes.width50}>
                <Select
                  value={calendarEvent.calendarEventType}
                  onFieldChange={this.handleFieldChange('calendarEventType')}
                  name="calendarEventType"
                  label="Event Type"
                  disabled={isLinkedTask}
                >
                  {this.eventTypes && this.eventTypes.map(calendarEventType =>
                    <MenuItem key={calendarEventType.id} value={calendarEventType.type} className={classes.grey300}>{calendarEventType.name}</MenuItem>
                  )}
                </Select>
              </div>
            </div>

            <Typography className={classes.subtitle} variant="body1">
              Description
            </Typography>

            <div className={classNames(classes.row, classes.multilineHeight)}>
              <div className={classNames(classes.flexGrowWithPad, !isTask && classes.width50)}>
                <div className={classNames(classes.flexGrow, classes.richText)}>
                  <RichTextField
                    value={calendarEvent.description}
                    onFieldChange={this.handleFieldChange('description')}
                    config={this.froalaConfig}
                  />
                </div>
              </div>
            </div>
            {/* TODO: turning this feature off until requirements are well-defined
            {showContacts &&
              <div className={classes.addOthersSection}>
                <Typography className={classes.subtitle} variant="body1">
                Add Others
                </Typography>
                <div className={classes.row}>
                  <div className={classNames(classes.flexGrowWithPad, classes.width50)}>
                    {isAddingContact
                      ?
                      <div className={classes.flexGrow}>
                        <MultiSelect
                          values={calendarEvent.sharedContacts}
                          suggestions={contactSuggestions}
                          onSelection={this.handleMultiSelect('sharedContacts')}
                          onBlur={this.toggleAddOthers}
                          placeholder="Add Teammate or Client"
                          isClearable={true}
                          defaultMenuIsOpen={true}
                          closeMenuOnSelect={false}
                          maxMenuHeight={180}
                          classes={{ root: classes.contactsComboBox }}
                        />
                      </div>
                      :
                      <FormControl>
                        <div className={classes.addOthersContainer}>
                          <IconButton classes={{ root: classes.addOthersButton }} aria-label="Add Others" onClick={this.toggleAddOthers}>
                            <AddIcon fontSize="inherit" />
                          </IconButton>
                          <Typography variant="body1" classes={{ root: classes.grey300 }} onClick={this.toggleAddOthers}>Add Teammate or Client</Typography>
                        </div>
                        {calendarEvent.sharedContacts && calendarEvent.sharedContacts.map(contact => {
                          return <Typography variant="body2" classes={{ root: classes.sharedContacts }} key={contact.id}>{contact.name} ({contact.email})</Typography>;
                        })}
                      </FormControl>
                    }
                  </div>
                </div>
              </div>
            } */}

          </div>
          <ValidationBanner
            innerRef={this.validationBanner}
          />
        </SectionContext.Provider>
      </Modal>);
  }
}

AddEditEventModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onModalClosed: PropTypes.func.isRequired,
  hasRelationship: PropTypes.bool, // tells modal whether to allow certain edits
};

const mapStateToProps = state => {
  const {
    api: {
      contacts: {
        data,
      },
    },
    calendar: {
      selectedDate,
    },
  } = state;

  return {
    contacts: data,
    selectedDate,
  };
};

const mapDispatchToProps = {
  saveCalendarEvent,
  getContacts,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(AddEditEventModal));
