import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import {
  fetchBookingsSuccess,
  bookingColumnsReceived,
  fetchBookingsBegin,
  cloneBookingPreferencesReceived,
} from './api';
import { defaultBookingsGrid } from 'constants/apiParamDefaults';
import { defaultBookingsGridColumns } from 'constants/gridColumnDefaults';
import {
  BOOKING_CREATE,
  BOOKING_UPDATE,
  BOOKING_RECEIVED,
  SET_FOCUSED_EVENT_INDEX,
  UPDATE_FOCUSED_EVENT,
  EVENT_SHIFT_ADDED,
  EVENT_SHIFT_DELETED,
  EVENT_SHIFT_UPDATED,
  STORE_BOOKING_SET_IS_EDITING,
  DELETE_BOOKING,
  BOOKING_EVENT_DELETED,
  BOOKING_EVENT_SAVED,
  BOOKING_EVENT_CLONED,
  BOOKING_SAVED,
  BOOKING_ACTIVITIES_RECEIVED,
  BOOKING_ACTIVITY_ADDED,
  BOOKING_ACTIVITY_UPDATED,
  BOOKING_ACTIVITY_DELETED,
  BOOKING_ACTIVITY_COMPLETED,
  BOOKING_EVENT_RECEIVED,
  SET_NEXT_TEMP_ID,
  STORE_BOOKING_REVERT_CHANGES,
  STORE_BOOKING_SET_STORE_TYPE,
  CLEAR_STORE_BOOKING,
  FOCUSED_EVENT_DELETE_SECTION,
  FOCUSED_EVENT_ADD_SECTION,
} from './constants';
import {
  addToRecents,
  removeRecent,
} from './recents';
import {
  addStatusHistory,
} from './statusHistory';
import {
  getStatuses,
} from './settings';
import moment from 'moment';
import { ENTITY_TYPES } from 'constants/entityTypes';

export const bookingReceived = booking => ({
  type: BOOKING_RECEIVED,
  booking,
});

export const bookingDeleted = response => ({
  type: DELETE_BOOKING,
  response,
});

export const bookingEventDeleted = eventName => ({
  type: BOOKING_EVENT_DELETED,
  eventName,
});

export const bookingEventSaved = response => ({
  type: BOOKING_EVENT_SAVED,
  response,
});

export const bookingEventCloned = eventName => ({
  type: BOOKING_EVENT_CLONED,
  eventName,
});

export const bookingEventReceived = response => ({
  type: BOOKING_EVENT_RECEIVED,
  response,
});

export const bookingSaved = response => ({
  type: BOOKING_SAVED,
  response,
});

export const activitiesReceived = response => ({
  type: BOOKING_ACTIVITIES_RECEIVED,
  response,
});

export const activityAdded = response => ({
  type: BOOKING_ACTIVITY_ADDED,
  response,
});

export const activityUpdated = response => ({
  type: BOOKING_ACTIVITY_UPDATED,
  response,
});

export const activityDeleted = response => ({
  type: BOOKING_ACTIVITY_DELETED,
  response,
});

export const activityCompleted = response => ({
  type: BOOKING_ACTIVITY_COMPLETED,
  response,
});

export const getActivities = bookingId => dispatch =>
  dispatch(callApi(`booking/${bookingId}/activities`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(activities => {
      dispatch(activitiesReceived(activities));

      return activities;
    });

export const addActivity = (bookingId, activity) => {
  return dispatch => dispatch(callApi(`booking/${bookingId}/activity`, { body: activity }))
    .then(response => dispatch(activityAdded(response)));
};

export const updateActivity = (bookingId, activity) => {
  return dispatch => dispatch(callApi(`booking/${bookingId}/activity/${activity.id}`, { method: 'put', body: activity }))
    .then(response => dispatch(activityUpdated(response)));
};

export const deleteActivity = (bookingId, activity) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/activity/${activity.id}`, { method: 'delete', body: activity }))
    .then(response => dispatch(activityDeleted(response)));

export const completeActivity = (bookingId, activity) => {
  return dispatch => dispatch(callApi(`booking/${bookingId}/activity/complete/${activity.id}`, { method: 'put', body: activity }))
    .then(response => dispatch(activityCompleted(response)));
};

export const getPayments = bookingId => dispatch =>
  dispatch(callApi(`booking/${bookingId}/payments`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const addPayment = (bookingId, payment) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/payment`, { body: payment }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const updatePayment = (bookingId, payment) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/payment/${payment.id}`, { method: 'put', body: payment }));

export const deletePayment = (bookingId, paymentId) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/payment/${paymentId}`, { method: 'delete' }));

export const getDeposits = bookingId => dispatch =>
  dispatch(callApi(`booking/${bookingId}/deposit`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const addDeposit = (bookingId, deposit) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/deposit`, { body: deposit }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const updateDeposit = (bookingId, deposit) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/deposit/${deposit.id}`, { method: 'put', body: deposit }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const deleteDeposit = (bookingId, depositId) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/deposit/${depositId}`, { method: 'delete' }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const getEventTimes = (bookingId, eventId, sortData) => dispatch => {
  const sortField = sortData && sortData.field ? sortData.field : '';
  const sortDirection = sortData && sortData.dir ? sortData.dir : '';

  return dispatch(callApi(`booking/${bookingId}/event/${eventId}/times?orderBy=${sortField}&direction=${sortDirection}`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(times => {
      return times;
    });
};

export const saveEventTime = (bookingId, eventId, time) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/event/${eventId}/times`, { method: 'post', body: time }));

// export const deleteEventTime = (bookingId, eventId, isSaved, timeId) => dispatch => {
//   const setSavePointPromise = isSaved ? dispatch(setSavePoint(bookingId, eventId)) : new Promise(resolve => resolve());

//   return setSavePointPromise
//     .then(() =>
//       dispatch(callApi(`booking/${bookingId}/event/${eventId}/times/${timeId}`, { method: 'delete' }))
//     )
//     .then(response => ParseJsonOnSuccessOrStop(dispatch, response));
// };

/* Returns a link to Customer Portal page for this booking */
export const share = (bookingId, shareDto) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/share`, { method: 'put', body: shareDto }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const getBooking = id => dispatch =>
  dispatch(callApi(`booking/${id}`))
    .then(result => {
      if (!result.ok || result.status !== 200) {
        throw Error('Booking not found', result);
      }

      return ParseJsonOnSuccessOrStop(dispatch, result);
    })
    .then(booking => {
      dispatch(bookingReceived(booking));
      dispatch(createStoreBooking('manager', booking)); // Should only be called by Booking Manager (not wizard)

      return booking;
    });

export const getBookingsIfNeeded = (newParams = defaultBookingsGrid, append) => (dispatch, getState) => {
  dispatch(fetchBookingsBegin(newParams));

  return getBookingsApi(dispatch, newParams)
    .then(result => {
      dispatch(fetchBookingsSuccess(result, append));

      return result;
    });
};

export const getBookingsByContact = contactId => {
  const filterByContact = { filter: { filters: [{ field: 'contactId', operator: 'eq', value: contactId }] } };

  return getBookingsForSmallGrid(filterByContact);
};

export const getBookingsByAccount = accountId => {
  const filterByAccount = { filter: { filters: [{ field: 'accountId', operator: 'eq', value: accountId }] } };

  return getBookingsForSmallGrid(filterByAccount);
};

const getBookingsForSmallGrid = newParams => {
  const params = {
    ...defaultBookingsGrid,
    ...newParams,
  };

  return dispatch => {
    dispatch(fetchBookingsBegin(params));

    return getBookingsApi(dispatch, params)
      .then(result => result.data);
  };
};

const getBookingsApi = (dispatch, params) => {
  return dispatch(callApi(`booking?${params}`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

export const saveBooking = booking => dispatch => {
  const bookingApi = booking.id ?
    {
      url: `booking/${booking.id}`,
      options: {
        body: booking,
        method: 'PUT',
      },
    }
    :
    {
      url: `booking`,
      options: {
        body: booking,
        method: 'POST',
      },
    };

  return dispatch(callApi(bookingApi.url, bookingApi.options));
};

export const saveStoreBooking = () => (dispatch, getState) => {
  const { booking } = getState();
  const { currentStoreType } = booking;
  const storeBooking = { ...booking[currentStoreType].storeBooking };

  return saveBooking(storeBooking)(dispatch)
    .then(result => {
      if (!result.ok || result.status !== 200) {
        throw Error('Problem in booking update');
      }

      return ParseJsonOnSuccessOrStop(dispatch, result);
    })
    .then(booking => {
      dispatch(bookingSaved(booking));
      dispatch(updateStoreBooking(booking));
      dispatch(storeBookingSetIsEditing(false));
      dispatch(setNextTempId(1));

      return booking;
    });;
};

export const closeBooking = () => (dispatch, getState) => {
  const { settings: { statuses } } = getState();

  if (statuses) {
    const closedStatus = statuses.find(statusGroup => statusGroup.type === 'Booking')
      .statuses.find(status => status.name === 'Closed'); //TODO: change to point to a systemStatusCode?

    return dispatch(saveBookingStatus(closedStatus.id));
  } else {
    return dispatch(getStatuses());
  }
};

export const deleteBooking = bookingId =>
  dispatch => dispatch(callApi(`booking/${bookingId}`, { method: 'DELETE' }))
    .then(response => {
      dispatch(bookingDeleted(response));
      dispatch(removeRecent(bookingId, 'booking'));
    });

function setEventsStartTimeEndTime(bookingEvent) {
  const startTime = getStartTime(bookingEvent);
  const endTime = getEndTime(bookingEvent, startTime);

  return {
    ...bookingEvent,
    startTime,
    endTime,
  };
}

function getStartTime(bookingEvent) {
  if (!bookingEvent.startDate) {
    return bookingEvent.startTime;
  }
  var copiedDate = new Date(bookingEvent.startDate.getTime());

  if (copiedDate.getHours() !== 0) {
    console.error(`event startDate should be at 00 hours! Not ${copiedDate.getHours()}`);
  }

  copiedDate.setHours(bookingEvent.startTime.getHours());
  copiedDate.setMinutes(bookingEvent.startTime.getMinutes());

  return copiedDate;
}

function getEndTime(bookingEvent, startDate) {
  if (!moment.isDate(bookingEvent.endTime)) {
    return bookingEvent.endTime; //don't do this logic if the endTime is not a Javascript date object
  }

  var copiedDate = new Date(bookingEvent.startDate.getTime());

  if (startDate.getHours() > bookingEvent.endTime.getHours() ||
    (startDate.getHours() === bookingEvent.endTime.getHours() && startDate.getMinutes() > bookingEvent.endTime.getMinutes())) {
    //if the start "time"" if after the end "time", assume it ends the next day. So bump the day by 1.

    //javascript is funny in that adding past the end of the month bumps the month and sets the day as 1
    //which is the behavior we want
    copiedDate.setDate(copiedDate.getDate() + 1);
  }

  copiedDate.setHours(bookingEvent.endTime.getHours());
  copiedDate.setMinutes(bookingEvent.endTime.getMinutes());

  return copiedDate;
}

export const updateEvent = (bookingId, bookingEvent) => {
  const body = setEventsStartTimeEndTime(bookingEvent);

  return dispatch => dispatch(callApi(`booking/${bookingId}/event/${bookingEvent.id}`, { method: 'PUT', body }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(event => {
      dispatch(bookingEventSaved(event.id));

      return event;
    });
};

export const hasChanges = (bookingId, eventId) => {
  return false; //TODO
};

export const getEventDetails = (bookingId, eventId) =>
  dispatch => dispatch(callApi(`booking/${bookingId}/event/${eventId}`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(event => {
      dispatch(bookingEventReceived(event));

      return event;
    });

export const toggleEditMode = () => (dispatch, getState) => {
  const { booking: { manager: { isEditing } } } = getState();

  return dispatch(storeBookingSetIsEditing(!isEditing));
};

export const setIsIncludedInBookingTotal = (bookingId, eventId, isIncludedInBookingTotal) =>
  (dispatch, getState) => dispatch(callApi(`booking/${bookingId}/event/${eventId}/isIncludedInBookingTotal`, { method: 'PUT', body: isIncludedInBookingTotal }))
    .then(() => {
      const { api: { bookingEvent } } = getState();

      if (bookingEvent.id !== eventId) {
        return;
      }
      const isEditingEvent = {
        ...bookingEvent,
        isIncludedInBookingTotal,
        isSaved: false,
      };

      dispatch(bookingEventReceived(isEditingEvent));
    });

export const getBookingColumns = () =>
  dispatch => dispatch(callApi('userSetting/booking_columns'))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(columnString => {
      const columns = columnString ? columnString.split(',') : [];

      dispatch(bookingColumnsReceived(columns));

      return columns;
    })
    .catch(error => {
      console.error(error);
    });

export const saveBookingColumns = columns =>
  dispatch => {
    const setting = columns.length ? columns.join() : defaultBookingsGridColumns.default.join();

    dispatch(callApi('userSetting/booking_columns', { method: 'PUT', body: setting }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .then(dispatch(bookingColumnsReceived(columns)));
  };

export const reorderBookingColumns = columns => {
  return dispatch => dispatch(
    callApi('userSetting/booking_columns', { method: 'PUT', body: columns.join() }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));
};

export const getClonePreferences = () =>
  dispatch => dispatch(callApi('userSetting/clone_booking_preferences'))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(preferenceString => {
      const preferences = preferenceString ? preferenceString.split(',') : [];

      dispatch(cloneBookingPreferencesReceived(preferences));

      return preferences;
    })
    .catch(error => {
      console.error(error);
    });

export const saveClonePreferences = newPreferencesArray =>
  (dispatch, getState) => {
    const { api: { bookings: { clonePreferences } } } = getState();
    const newPreferences = newPreferencesArray.join();

    if (newPreferences === clonePreferences) return;

    dispatch(
      callApi('userSetting/clone_booking_preferences', { method: 'PUT', body: newPreferences }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .then(dispatch(savedPreferences => cloneBookingPreferencesReceived(savedPreferences)));
  };

export const cloneBooking = (id, name, startTime, selectedSections) =>
  dispatch => {
    const payload = selectedSections.reduce((result, item) => {
      result[item] = true;

      return result;
    }, {});

    payload.id = id;
    payload.name = name;
    payload.bookingStartTime = startTime;

    return dispatch(
      callApi(`booking/${id}/clone`, {
        method: 'PUT',
        body: payload,
      }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .then(response => {
        dispatch(bookingSaved(response.id));
        dispatch(addToRecents({ name: name, id: response.id }, `/bookings/${response.id}`, ENTITY_TYPES.booking));

        return response.id;
      });
  };

export const getTaxConfig = bookingId => dispatch =>
  dispatch(callApi(`booking/${bookingId}/taxes`))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

export const updateTaxConfig = (bookingId, taxAndServiceConfig) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/taxes`, { body: taxAndServiceConfig }));

export const getDiscountsConfig = bookingId => dispatch =>
  dispatch(callApi(`booking/${bookingId}/discounts`))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results));

export const updateDiscountsConfig = (bookingId, discountConfig) => dispatch =>
  dispatch(callApi(`booking/${bookingId}/discounts`, { body: discountConfig }));

// export const getVenueSelectionErrors = bookingId => dispatch =>
//   dispatch(callApi(`booking/${bookingId}/venueErrors`))
//     .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

/// STORE BOOKING ACTIONS ///

export const addStoreBookingEvent = () => (dispatch, getState) => {
  const { booking, api: { currentLocation } } = getState();
  const { storeBooking, nextTempId } = booking[booking.currentStoreType];

  const updatedStoreBooking = {
    ...storeBooking,
    events: [
      ...storeBooking.events.filter(event => event.recordStatus === 'Active'),
      {
        name: storeBooking.name,
        startDateTime: moment().format(), //TODO
        endDateTime: moment().add(2, 'hours').format(), //TODO
        locationId: currentLocation.id,
        includedInBookingTotal: true,
        recordStatus: 'Active',
        eventMenuName: 'New Menu',
        bookingEventMenuCategories: [],
        bookingEventRooms: [],
        bookingEventSites: [],
        bookingEventShifts: [],
      },
    ],
  };

  dispatch(updateStoreBooking(updatedStoreBooking));
  dispatch(setFocusedEventIndex(updatedStoreBooking.events.length - 1));
  dispatch(setNextTempId(nextTempId + 1));

  return updatedStoreBooking;
};

export const updateStoreFocusedEvent = updatedEvent => dispatch => {
  return dispatch(focusedEventUpdated(updatedEvent));
};

export const deleteStoreBookingEvent = eventIndex => (dispatch, getState) => {
  const { booking } = getState();
  const { storeBooking } = booking[booking.currentStoreType];

  let bookingEvents = storeBooking.events.filter(event => event.recordStatus === 'Active');
  const eventToDelete = bookingEvents[eventIndex];

  if (eventToDelete.id) {
    bookingEvents = bookingEvents.map((event, index) => {
      if (index === eventIndex) event.recordStatus = 'Deleted';

      return event;
    });
  } else {
    bookingEvents.splice(eventIndex, 1);
  }

  const updatedStoreBooking = {
    ...storeBooking,
    events: bookingEvents,
  };

  dispatch(bookingEventDeleted(eventToDelete.name));
  dispatch(updateStoreBooking(updatedStoreBooking));
  dispatch(setFocusedEventIndex(updatedStoreBooking.events.filter(event => event.recordStatus === 'Active').length - 1));
};

export const cloneStoreBookingEvent = eventIndex => (dispatch, getState) => {
  const { booking } = getState();
  const { storeBooking } = booking[booking.currentStoreType];

  let bookingEvents = storeBooking.events.filter(event => event.recordStatus === 'Active');
  const eventToClone = bookingEvents[eventIndex];
  // TODO: deep copy bookingEventMenuCategories, shifts, etc.
  const newEvent = {
    ...eventToClone,
    name: `${eventToClone.name} (copy)`,
  };

  if (newEvent.id) delete newEvent.id; //Need a new Id from API

  const updatedStoreBooking = {
    ...storeBooking,
    events: [
      ...bookingEvents,
      newEvent,
    ],
  };

  dispatch(bookingEventCloned(newEvent.name));
  dispatch(updateStoreBooking(updatedStoreBooking));
  dispatch(setFocusedEventIndex(updatedStoreBooking.events.length - 1));
};

export const addShiftToFocusedEvent = shift => (dispatch, getState) => {
  const { booking } = getState();
  const { storeBooking, focusedEventIndex } = booking[booking.currentStoreType];

  let bookingEvents = storeBooking.events.filter(event => event.recordStatus === 'Active');
  const event = bookingEvents[focusedEventIndex];

  if (!event.bookingEventShifts) event.bookingEventShifts = [];

  const updatedEvent = {
    ...event,
    bookingEventShifts: [
      ...event.bookingEventShifts,
      shift,
    ],
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
  dispatch(bookingEventShiftAdded(shift));

  return Promise.resolve();
};

export const deleteShiftFromFocusedEvent = shiftIndex => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];
  let eventShifts = focusedEvent.bookingEventShifts.filter(shift => shift.recordStatus === 'Active');
  const shiftToDelete = eventShifts.splice(shiftIndex, 1);

  const updatedEvent = {
    ...focusedEvent,
    bookingEventShifts: eventShifts,
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
  dispatch(bookingEventShiftDeleted(shiftToDelete));

  return Promise.resolve();
};

export const updateShiftOnFocusedEvent = (shiftIndex, shift) => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];
  let eventShifts = [...focusedEvent.bookingEventShifts.filter(shift => shift.recordStatus === 'Active')];

  eventShifts.splice(shiftIndex, 1, shift);

  const updatedEvent = {
    ...focusedEvent,
    bookingEventShifts: eventShifts,
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
  dispatch(bookingEventShiftUpdated(shift));

  return Promise.resolve();
};

export const resetAllEventShifts = () => (dispatch, getState) => {
  const { booking } = getState();
  const { storeBooking } = booking[booking.currentStoreType];
  const savedEvents = storeBooking.events.filter(e => !!e.id);

  for (let i = 0; i < savedEvents.length; i++) {
    const savedShifts = savedEvents[i].bookingEventShifts.filter(s => !!s.id);

    for (let j = 0; j < savedShifts.length; j++) {
      savedShifts[j].recordStatus = 'Deleted';
    }
    savedEvents[i].bookingEventShifts = savedShifts;
  }

  const updatedStoreBooking = {
    ...storeBooking,
    eventSections: storeBooking.eventSections.filter(s => s !== 'Shifts'),
    events: savedEvents,
  };

  dispatch(updateStoreBooking(updatedStoreBooking));
};

export const deleteSiteFromFocusedEvent = siteIndex => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];
  const bookingEventSites = focusedEvent.bookingEventSites.filter(shift => shift.recordStatus === 'Active')
    .map((site, index) => {
      if (index === siteIndex) site.recordStatus = 'Deleted';

      return site;
    });

  const updatedEvent = {
    ...focusedEvent,
    bookingEventSites,
  };

  dispatch(updateStoreFocusedEvent(updatedEvent));
};

export const resetAllEventSites = () => (dispatch, getState) => {
  const { booking } = getState();
  const { storeBooking } = booking[booking.currentStoreType];
  const savedEvents = storeBooking.events.filter(e => !!e.id);

  for (let i = 0; i < savedEvents.length; i++) {
    const savedSites = savedEvents[i].bookingEventSites.filter(s => !!s.id);

    for (let j = 0; j < savedSites.length; j++) {
      savedSites[j].recordStatus = 'Deleted';
    }
    savedEvents[i].bookingEventSites = savedSites;
  }

  const updatedStoreBooking = {
    ...storeBooking,
    eventSections: storeBooking.eventSections.filter(s => s !== 'Sites'),
    events: savedEvents,
  };

  dispatch(updateStoreBooking(updatedStoreBooking));
};

export const resetAllEventRooms = () => (dispatch, getState) => {
  const { booking } = getState();
  const { storeBooking } = booking[booking.currentStoreType];
  const savedEvents = storeBooking.events.filter(e => !!e.id);

  for (let i = 0; i < savedEvents.length; i++) {
    const savedRooms = savedEvents[i].bookingEventRooms.filter(s => !!s.id);

    for (let j = 0; j < savedRooms.length; j++) {
      savedRooms[j].recordStatus = 'Deleted';
    }
    savedEvents[i].bookingEventRooms = savedRooms;
  }

  const updatedStoreBooking = {
    ...storeBooking,
    eventSections: storeBooking.eventSections.filter(s => s !== 'Venues'),
    events: savedEvents,
  };

  dispatch(updateStoreBooking(updatedStoreBooking));
};

export const toggleIncludeBookingEvent = eventIndex => (dispatch, getState) => {
  const { booking } = getState();
  const { storeBooking } = booking[booking.currentStoreType];

  const bookingEvents = storeBooking.events.filter(event => event.recordStatus === 'Active')
    .map((event, index) => {
      if (index === eventIndex) event.includedInBookingTotal = !event.includedInBookingTotal;

      return event;
    });

  const updatedStoreBooking = {
    ...storeBooking,
    events: bookingEvents,
  };

  dispatch(updateStoreBooking(updatedStoreBooking));
  dispatch(saveStoreBooking());
};

export const addEventSection = section => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];
  let updatedFocusedEvent = { ...focusedEvent };

  switch (section) {
    case 'Venues':
      updatedFocusedEvent.bookingEventRooms = [];
      updatedFocusedEvent.hasVenues = true;
      break;
    case 'Shifts':
      updatedFocusedEvent.bookingEventShifts = [];
      updatedFocusedEvent.hasShifts = true;
      break;
    case 'Sites':
      updatedFocusedEvent.bookingEventSites = [];
      updatedFocusedEvent.hasSites = true;
      break;

    default:
      break;
  }

  dispatch(updateStoreFocusedEvent(updatedFocusedEvent));
  dispatch(storeFocusedEventSectionAdded(section));
};

export const deleteEventSection = section => (dispatch, getState) => {
  const { booking } = getState();
  const { focusedEvent } = booking[booking.currentStoreType];
  let updatedFocusedEvent = { ...focusedEvent };

  switch (section) {
    case 'Venues':
      const savedRooms = updatedFocusedEvent.bookingEventRooms.filter(s => !!s.id);

      for (let i = 0; i < savedRooms.length; i++) {
        savedRooms[i].recordStatus = 'Deleted';
      }
      updatedFocusedEvent.bookingEventRooms = savedRooms;
      updatedFocusedEvent.hasVenues = false;
      break;
    case 'Shifts':
      const savedShifts = updatedFocusedEvent.bookingEventShifts.filter(s => !!s.id);

      for (let i = 0; i < savedShifts.length; i++) {
        savedShifts[i].recordStatus = 'Deleted';
      }
      updatedFocusedEvent.bookingEventShifts = savedShifts;
      updatedFocusedEvent.hasShifts = false;
      break;
    case 'Sites':
      const savedSites = updatedFocusedEvent.bookingEventSites.filter(s => !!s.id);

      for (let i = 0; i < savedSites.length; i++) {
        savedSites[i].recordStatus = 'Deleted';
      }
      updatedFocusedEvent.bookingEventSites = savedSites;
      updatedFocusedEvent.hasSites = false;
      break;

    default:
      break;
  }

  dispatch(updateStoreFocusedEvent(updatedFocusedEvent));
  dispatch(storeFocusedEventSectionDeleted(section));
};

export const saveBookingStatus = statusId => (dispatch, getState) => {
  const { booking } = getState();
  const { storeBooking } = booking[booking.currentStoreType];

  storeBooking.statusId = statusId;
  storeBooking.bookingStatus = null;

  dispatch(updateStoreBooking(storeBooking));

  return dispatch(saveBooking(storeBooking))
    .then(result => {
      if (!result.ok || result.status !== 200) {
        throw Error('Problem in booking status update');
      }

      return ParseJsonOnSuccessOrStop(dispatch, result);
    })
    .then(updatedBooking => {
      dispatch(updateStoreBooking(updatedBooking));

      return dispatch(addStatusHistory('Booking', updatedBooking.id, updatedBooking.statusId)).then(statusHistory => {

        return statusHistory;
      });
    });
};

export const createStoreBooking = (currentStoreType, storeBooking) => ({
  type: BOOKING_CREATE,
  currentStoreType,
  storeBooking,
});

export const updateStoreBooking = updatedBooking => ({
  type: BOOKING_UPDATE,
  updatedBooking,
});

export const setFocusedEventIndex = focusedEventIndex => ({
  type: SET_FOCUSED_EVENT_INDEX,
  focusedEventIndex,
});

export const focusedEventUpdated = focusedEvent => ({
  type: UPDATE_FOCUSED_EVENT,
  focusedEvent,
});

export const bookingEventShiftAdded = shift => ({
  type: EVENT_SHIFT_ADDED,
  shift,
});

export const bookingEventShiftUpdated = shift => ({
  type: EVENT_SHIFT_UPDATED,
  shift,
});

export const bookingEventShiftDeleted = shift => ({
  type: EVENT_SHIFT_DELETED,
  shift,
});

export const setNextTempId = nextTempId => ({
  type: SET_NEXT_TEMP_ID,
  nextTempId,
});

export const storeBookingSetIsEditing = isEditing => ({
  type: STORE_BOOKING_SET_IS_EDITING,
  isEditing,
});

export const revertStoreBookingChanges = () => ({
  type: STORE_BOOKING_REVERT_CHANGES,
});

export const storeBookingSetStoreType = storeType => ({
  type: STORE_BOOKING_SET_STORE_TYPE,
  storeType,
});

export const clearStoreBooking = () => ({
  type: CLEAR_STORE_BOOKING,
});

export const storeFocusedEventSectionAdded = section => ({
  type: FOCUSED_EVENT_ADD_SECTION,
  section,
});

export const storeFocusedEventSectionDeleted = section => ({
  type: FOCUSED_EVENT_DELETE_SECTION,
  section,
});

