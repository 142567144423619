import {
  PAYMENT_ACTION,
} from './constants';
import { callPaymentApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

export const paymentAction = actionUrl => ({
  type: PAYMENT_ACTION,
  actionUrl,
});

export const setPaymentAction = actionUrl =>
  dispatch => dispatch(paymentAction(actionUrl));

export const paymentAuthorize = authorizeRequest =>
  dispatch =>
    dispatch(callPaymentApi('payment/authorize', {
      body: authorizeRequest,
      method: 'POST',
    }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .then(payment => {
        dispatch(paymentAction(payment.actionUrl));
      });
