import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import SimpleModal from 'Components/SimpleModal';
import StepFooter from 'Components/StepFooter';
import StepHeader from 'Components/StepHeader';
import WizardVenuesSelection from './WizardVenuesSelection';
import WizardEvents from './WizardEvents';
import BookingsWizardGeneral from './BookingsWizardGeneral';
import WizardContext from './WizardContext';
import WizardMenuSelection from './WizardMenuSelection';
import BookingWizardSites from './BookingWizardSites';
import SectionContext from 'Components/Contexts/SectionContext';
import {
  createStoreBooking,
  deleteBooking,
  updateStoreBooking,
  saveStoreBooking,
  setIsIncludedInBookingTotal,
  saveBookingStatus,
  // getVenueSelectionErrors,
  storeBookingSetStoreType,
  clearStoreBooking,
  resetAllEventShifts,
  resetAllEventSites,
  resetAllEventRooms,
} from 'actions/booking';
import {
  getStoreMenu,
} from 'actions/storemenu';
import {
  resetAllEventMenus,
} from 'actions/eventMenu';
import {
  addStatusHistory,
} from 'actions/statusHistory';
import { addToRecents } from 'actions/recents';
import { connect } from 'react-redux';
import EventBar from './EventBar';
import NotesWizard from 'Components/NotesWizard';
import Shifts from 'bookings/Shifts';
import moment from 'moment';
import PropTypes from 'prop-types';
import SimpleDialog from 'Components/SimpleDialog';
import ProposalWizardGeneral from './ProposalWizardGeneral';
import { EVENT_TYPES } from 'constants/eventTypes';
import { ENTITY_TYPES } from 'constants/entityTypes';
import { MenuType } from 'models/MenuType';

// This is also the Proposal Wizard. A Proposal is a Booking with proposal = true;
export class BookingWizard extends Component {
  constructor(props) {
    super(props);
    const {
      startTime: selectedStartTime,
      endTime: selectedEndTime,
      roomId: initialRoomId,
    } = props.selection;

    const dateBooked = (selectedStartTime ? moment(selectedStartTime) : moment()).clone().startOf('day').format();

    this.bookingInit = {
      salesRep: props.user.name,
      eventManager: props.user.name,
      dateBooked,
      selectedStartTime: selectedStartTime,
      selectedEndTime: selectedEndTime,
      eventSections: [],
      events: [],
      initialRoomId: initialRoomId,
      proposal: props.proposal,
      entityType: props.entityType,
      entityId: props.entityId,
      accountId: props.accountId,
      contactId: props.contactId,
      siteContactId: props.contactId,
      id: props.selectedProposalId ? props.selectedProposalId : 0, // existing Proposals open wizard, but not Bookings
      locationId: props.currentLocation.id,
      revenueConfigurationId: 1, //TODO: Get Default revenueConfigurationId - should be in a reducer somewhere
    };

    this.state = {
      steps: ['General', 'Events'], //list of all steps
      activeStep: 0,
    };
    this.deleteSectionDialog = React.createRef();
    this.errorModal = React.createRef();
  }

  componentDidMount() {
    this.props.createStoreBooking('wizard', this.bookingInit);
    this.props.getStoreMenu(MenuType.Booking);
  }

  componentWillUnmount() {
    this.props.clearStoreBooking();
    this.props.storeBookingSetStoreType('manager');
  }

  generalInfoErrors = () => {
    const { storeBooking } = this.props;
    const dateBookedHasError = storeBooking.dateBooked ? false : true;
    const bookingNameHasError = storeBooking.name ? false : true;
    const accountHasError = storeBooking.accountId ? false : true;

    const generalInfoErrors = dateBookedHasError || bookingNameHasError || (!this.bookingInit.proposal && accountHasError) ?
      {
        name: bookingNameHasError,
        dateBooked: dateBookedHasError,
        account: accountHasError,
      } : undefined;

    this.setState({ generalInfoErrors });

    return generalInfoErrors;
  }

  getSteps = () => {
    const steps = ['General', 'Events'];

    if (this.selectedCards.venues) {
      steps.push('Venues');
    }
    if (this.selectedCards.sites) {
      steps.push('Sites');
    }
    if (this.selectedCards.shifts) {
      steps.push('Shifts');
    }
    if (this.selectedCards.menus) {
      steps.push('Menus');
    }
    if (this.selectedCards.notes) {
      steps.push('Notes');
    }

    return steps;
  }

  saveGeneralInfo = () => {
    const { storeBooking, currentLocation, proposal, statuses } = this.props;
    let booking = { ...storeBooking };

    if (!booking.id && (!booking.events || booking.events.length === 0)) {
      const createdStatus = statuses ?
        statuses.find(statusGroup => statusGroup.type === 'Booking')
          .statuses.find(status => status.name === 'Proposed') //TODO: change to point to a systemStatusCode?
        : null;

      const firstEvent = {
        name: booking.name,
        startDateTime: moment().format(), //TODO
        endDateTime: moment().add(2, 'hours').format(), //TODO
        locationId: currentLocation.id,
        includedInBookingTotal: true,
        eventType: proposal ? EVENT_TYPES.Proposal : EVENT_TYPES.Booking, //TODO calendarEventType?
        bookingEventShifts: this.selectedCards.shifts ? [] : null,
        bookingEventSites: this.selectedCards.sites ? [] : null,
        bookingEventRooms: this.selectedCards.venues ? [] : null,
        bookingEventMenuCategories: [],
        eventMenuName: 'New Menu',
      };

      if (createdStatus) {
        booking.statusId = createdStatus.id;
      }

      booking.events.push(firstEvent);
    }
    this.props.updateStoreBooking(booking);

    return this.props.saveStoreBooking().then(apiBooking => {
      if (apiBooking.statusId) this.props.addStatusHistory('Booking', apiBooking.id, apiBooking.statusId);

      return apiBooking;
    });
  }

  handleNext = () => {
    //what was checked???
    const {
      activeStep,
      notesWizard,
      steps,
    } = this.state;
    const isLastStep = activeStep === steps.length - 1;

    if (activeStep === 0) {
      const generalInfoErrors = this.generalInfoErrors();

      if (generalInfoErrors) {
        return;
      }

      this.saveGeneralInfo().then(booking => {
        this.setState({
          sectionContext: {
            relationship: {
              entityId: booking.id,
              entityType: ENTITY_TYPES.booking,
            },
          },
          steps: this.getSteps(),
          activeStep: 1,
        });
      });

      return;
    }

    if (notesWizard) {
      this.setState({ notesWizard: false });

      return;
    }

    // FINISH = Last Step
    if (isLastStep) {
      // check that Venue Setup Styles are provided for all events
      // this.props.getVenueSelectionErrors(storeBooking.id).then(evtNames => {
      //   if (evtNames && evtNames.length > 0) {
      //     this.errorModal.current.open(`For venues, the following events require Setup Style before finishing: ${evtNames.join(' ')}`);
      //   } else {
      //     this.finish();
      //   }
      // });

      this.finish();

      return;
    }

    this.setState({ activeStep: activeStep + 1 });
  };

  finish = () => {
    const { storeBooking } = this.props;

    this.props.saveStoreBooking().then(() => {
      this.props.onClose();
      if (!storeBooking.proposal) {
        this.props.history.push(`/bookings/${storeBooking.id}`);
      };
    });
  }

  onClose = () => {
    const { storeBooking, deleteBooking } = this.props;

    if (storeBooking.id) {
      deleteBooking(storeBooking.id).then(() => {
        this.props.onClose();
      });
    } else {
      this.props.onClose();
    }
  }

  handleBack = () => {
    const {
      activeStep,
      notesWizard,
    } = this.state;

    if (notesWizard) {
      this.setState({ notesWizard: false });

      return;
    }

    this.setState({
      activeStep: activeStep - 1,
    });
  };

  openDeleteSectionDialog = () => {
    this.deleteSectionDialog.current.open().then(this.deleteSection);
  }

  deleteSection = () => {
    // const { storeBooking } = this.props;
    const {
      activeStep,
      steps,
    } = this.state;

    const stepToDelete = steps[activeStep];

    if (stepToDelete === 'Venues') {
      this.selectedCards.venues = false;
      this.props.resetAllEventRooms();
    }

    if (stepToDelete === 'Sites') {
      this.selectedCards.sites = false;
      this.props.resetAllEventSites();
    }

    if (stepToDelete === 'Shifts') {
      this.selectedCards.shifts = false;
      this.props.resetAllEventShifts();
    }

    if (stepToDelete === 'Menus') {
      this.selectedCards.menus = false;
      this.props.resetAllEventMenus();
    }

    this.setState({
      steps: this.getSteps(), // Go to Next step: activeStep index stays the same, but there is 1 less step.
    });
  }

  getStepContent = () => {
    const {
      activeStep,
      steps,
      notesWizard,
    } = this.state;

    const step = steps[activeStep];

    if (notesWizard || step === 'Notes') {
      return <NotesWizard maxHeight={479} />;
    }

    if (activeStep === 0) {
      return this.props.proposal ? <ProposalWizardGeneral /> : <BookingsWizardGeneral />;
    }

    if (activeStep === 1) {
      return <WizardEvents />;
    }

    if (step === 'Venues') {
      return <WizardVenuesSelection wizardstep={3} />;
    }

    if (step === 'Menus') {
      return <WizardMenuSelection />;
    }

    if (step === 'Sites') {
      return <BookingWizardSites />;
    }

    if (step === 'Shifts') {
      return <Shifts storeType="wizard" />;
    }

    return <h3>{step} Something went wrong. Please click Wizard Back button.</h3>;
  }

  onClickNotes = () => {
    const {
      activeStep,
      steps,
      notesWizard,
    } = this.state;
    //handle the current step is the notes wizard

    if (notesWizard) {
      this.setState({ notesWizard: false });

      return;
    }

    if (steps[activeStep] === 'Notes') {
      this.setState({ activeStep: activeStep - 1 });//clicking the note icon on the last step of wizard goes back i guess?

      return;
    }

    this.setState({ notesWizard: true });
  }

  handleStepClick = stepIndex => {
    this.setState({ activeStep: stepIndex, notesWizard: false });
  }

  //the TemplatePanel calls this
  selectedCardsChanged = selectedCards => {
    const booking = { ...this.props.storeBooking };
    let eventSections = [];

    if (selectedCards) {
      this.selectedCards = selectedCards;
    }

    if (selectedCards.venues) eventSections.push('Venues');
    if (selectedCards.sites) eventSections.push('Sites');
    if (selectedCards.shifts) eventSections.push('Shifts');
    booking.eventSections = eventSections;

    this.props.updateStoreBooking(booking);
  }

  setNextIsDisabled = nextIsDisabled => {
    this.setState({ nextIsDisabled });
  }

  render() {
    const {
      proposal,
      theme,
    } = this.props;

    const {
      steps,
      activeStep,
      generalInfoErrors,
      notesWizard,
      nextIsDisabled,
      sectionContext,
    } = this.state;

    const wizardContextValue = {
      selectedCardsChanged: this.selectedCardsChanged,
      selectedCards: this.selectedCards,
      generalInfoErrors,
      setNextIsDisabled: this.setNextIsDisabled,
    };

    const isFirst = activeStep === 0;
    const isLast = activeStep === steps.length - 1 && !notesWizard;
    const isDeleteHidden = isFirst || activeStep === 1 || isLast || notesWizard; // hide Delete button for General, Events, and Notes
    const wizardThemeColor = proposal ? theme.palette.proposalEvent : theme.palette.bookingEvent;

    return (
      <WizardContext.Provider value={wizardContextValue}>
        <SectionContext.Provider value={sectionContext}>
          <SimpleModal
            isOpened={true}
            height={730}
            title={
              <StepHeader
                notesWizard={notesWizard}
                activeStep={activeStep}
                steps={steps}
                bar={EventBar}
                onClickNotes={this.onClickNotes}
                onStepClick={this.handleStepClick}
                title={proposal ? 'Proposal' : 'Booking'}
                wizardThemeColor={wizardThemeColor}
              />
            }
            compFooter={
              <StepFooter
                handleNext={this.handleNext}
                handleBack={this.handleBack}
                handleDeleteSection={this.openDeleteSectionDialog}
                isFirst={isFirst}
                isLast={isLast}
                isDeleteHidden={isDeleteHidden}
                isNextDisabled={nextIsDisabled}
              />}
            onClose={this.onClose}
            closeAlert={true}
            borderColor={wizardThemeColor}
            outlineColor={wizardThemeColor}
          >
            {this.getStepContent()}
          </SimpleModal>
          <SimpleDialog message="Are you sure you want to delete this section?" innerRef={this.deleteSectionDialog} />
          <SimpleDialog onlyOkayButton={true} innerRef={this.errorModal} />
        </SectionContext.Provider>
      </WizardContext.Provider>
    );
  }
}

BookingWizard.propTypes = {
  selection: PropTypes.object,
  proposal: PropTypes.bool,
  contactId: PropTypes.number, // to pre-populate 'Contact' field
  accountId: PropTypes.number, // to pre-populate 'Account' field
};

BookingWizard.defaultProps = {
  selection: {},
  proposal: false,
};

const mapStateToProps = state => {
  const {
    booking: {
      wizard: {
        storeBooking,
        focusedEventIndex,
        nextTempId,
      },
    },
    api: {
      currentLocation,
    },
    oidc: {
      user,
    },
    settings: {
      statuses,
    },
  } = state;

  return {
    storeBooking,
    focusedEventIndex,
    currentLocation,
    user: user.profile,
    nextTempId,
    statuses,
  };
};

const mapDispatchToProps = {
  createStoreBooking,
  deleteBooking,
  updateStoreBooking,
  saveStoreBooking,
  setIsIncludedInBookingTotal,
  saveBookingStatus,
  addToRecents,
  // getVenueSelectionErrors,
  getStoreMenu,
  storeBookingSetStoreType,
  clearStoreBooking,
  resetAllEventMenus,
  resetAllEventShifts,
  resetAllEventSites,
  resetAllEventRooms,
  addStatusHistory,
};

export default withTheme()(connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingWizard)));
