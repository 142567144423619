import React, { useContext, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Select from 'Components/Select';
import CoverDatePicker from 'Components/CoverDatePicker';
import { MenuItem } from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';
import OrderAddressManager from './wizard/OrderAddressManager';
import SectionHeader from 'Components/SectionHeader';
import TextField from 'Components/TextField';
import OrderContext from './OrderContext';

const styles = theme => ({
  bottomContainer: {
    display: 'flex',
    marginTop: 20,
  },
  flexGrow: {
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    flexGrow: 1,
  },
  mainFieldsContainer: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 25,
  },
  locationPickerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 24,
    marginTop: 24,
    width: 314,
  },
  locationPicker: {
    display: 'flex',
    height: 88,
  },
  locationDetails: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '0 16px 16px',
    justifyContent: 'space-between',
    fontSize: 14,
  },
  locationInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.secondary.dark,
    marginBottom: 4,
  },
  field: {
    display: 'flex',
    flex: 1,
  },
  fieldIsEdited: {
    background: theme.palette.error.light,
  },
});

const Details = ({ classes }) => {
  const {
    storeOrder,
    onFieldChange,
    onOrderTypeChange,
    contactAddresses,
    isEditing,
    selectedAddressId,
    locations,
    onAddressChange,
    onHandlePrimaryAddress,
    onNewAddress,
    onDeleteAddress,
    onSelectLocation,
    onSelectAddress,
    orderTimes,
    isFieldChanged,
    orderLocation,
  } = useContext(OrderContext);
  const [locationAddress, setLocationAddress] = useState(null);

  useEffect(() => {
    getLocationAddress(orderLocation);
  }, [orderLocation]);

  const getTimeNeeded = () => {
    if (storeOrder.asap) return 'ASAP';
    const timeNeededValue = moment(storeOrder.requestedDateTime).clone().format('h:mm A').toString();
    const neededTimeSelection = { label: timeNeededValue, value: timeNeededValue };

    if (!orderTimes.find(selection => selection.value === timeNeededValue)) {
      orderTimes.push(neededTimeSelection);
    }
    const timeNeeded = moment(storeOrder.requestedDateTime).clone().format('h:mm A').toString();

    return timeNeeded;
  };

  const getLocationAddress = orderLocation => {
    if (orderLocation && orderLocation.accountAddress) {
      const locationAddress = { ...orderLocation.accountAddress };

      locationAddress.cityStatePostalCode = `${locationAddress.city || ''}${locationAddress.city && locationAddress.state && ','} ${locationAddress.state || ''} ${locationAddress.postalCode || ''}`;

      setLocationAddress(locationAddress);
    }
  };

  return (
    <>
      <div className={classes.mainFieldsContainer}>
        <div className={classNames(classes.field, isFieldChanged('type') && classes.fieldIsEdited)}>
          <Select
            label="Type"
            disabled={!isEditing}
            value={storeOrder.type}
            onFieldChange={onOrderTypeChange}
          >
            <MenuItem value="Delivery">Delivery</MenuItem>
            <MenuItem value="Pickup">Pickup</MenuItem>
          </Select>
        </div>
        <div className={classNames(classes.field, isFieldChanged('customerName') && classes.fieldIsEdited)}>
          <TextField
            label="Customer Name"
            value={storeOrder.customerName}
            disabled={!isEditing}
            onFieldChange={onFieldChange('customerName')}
          />
        </div>
        <div className={classNames(classes.field, isFieldChanged('customerPhone') && classes.fieldIsEdited)}>
          <TextField
            type="phone"
            label="Phone Number"
            disabled={!isEditing}
            value={storeOrder.customerPhone}
            onFieldChange={onFieldChange('customerPhone')}
          />
        </div>
        <div className={classNames(classes.field, isFieldChanged('dateNeeded') && classes.fieldIsEdited)}>
          <CoverDatePicker
            label="Date Needed"
            disabled={!isEditing}
            value={storeOrder.requestedDateTime}
            onFieldChange={onFieldChange('dateNeeded')}
          />
        </div>
        <div className={classNames(classes.field, isFieldChanged('requestedDateTime') && classes.fieldIsEdited)}>
          <Select
            label="Time Needed"
            disabled={!isEditing}
            value={getTimeNeeded()}
            onFieldChange={onFieldChange('timeNeeded')}
          >
            {orderTimes.map(time => <MenuItem value={time.value} key={time.value}>{time.label}</MenuItem>)}
          </Select>
        </div>
      </div>
      <SectionHeader>
        Address Information
      </SectionHeader>
      <div className={classes.bottomContainer}>
        <OrderAddressManager
          order={storeOrder}
          contactId={storeOrder.contactId}
          contactAddresses={contactAddresses}
          locations={locations}
          isEditing={isEditing}
          onAddressChange={onAddressChange}
          onHandlePrimaryAddress={onHandlePrimaryAddress}
          onNewAddress={onNewAddress}
          onDeleteAddress={onDeleteAddress}
          onSelectAddress={onSelectAddress}
          selectedAddressId={selectedAddressId}
          onSelectLocation={onSelectLocation}
        />
        <div className={classes.locationPickerContainer}>
          <div className={classNames(classes.locationPicker, isFieldChanged && isFieldChanged('locationId') && classes.fieldIsEdited)}>
            <Select
              label="Location"
              disabled={!isEditing}
              value={storeOrder.locationId}
              onFieldChange={onSelectLocation}
            >
              {locations.map(location => (
                <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.locationDetails}>

            <div className={classes.locationInfo}>
              <p className={classes.title}>LOCATION INFORMATION</p>
              {orderLocation && locationAddress &&
                <div className={classes.locationInfo}>
                  <p>{orderLocation.name}</p>
                  <p>{locationAddress.address}</p>
                  <p>{locationAddress.cityStatePostalCode}</p>
                  <p>{orderLocation.phone}</p>
                  <p>{orderLocation.email}</p>
                </div>
              }
            </div>

            <div className={classes.deliveryTime}>
              {storeOrder.orderDelivery && storeOrder.orderDelivery.estimatedDriveDuration
                ? <>
                <p className={classes.title}>DELIVERY TIME</p>
                <p>{moment.duration(storeOrder.orderDelivery.estimatedDriveDuration, 'seconds').clone().humanize()}</p>
                </>
                : ''}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeOrder,
    },
  } = state;

  return {
    storeOrder,
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Details));
