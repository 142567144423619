import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import {
  EMAIL_ACCOUNTS_REQUEST_BEGIN,
  EMAIL_ACCOUNTS_REQUEST_END,
  RECEIVE_EMAIL_ACCOUNTS,
  DELETE_EMAIL_ACCOUNT_SUCCESS,
  SAVE_EMAIL_ACCOUNT,
} from './constants';

export const getEmailAccounts = (params = '') =>
  (dispatch, getState) => {
    dispatch(fetchEmailAccountsBegin());

    return getEmailAccountsApi(dispatch, params).then(result => {
      dispatch(fetchEmailAccountsSuccess(result));

      return result;
    }).finally(() => dispatch(fetchEmailAccountsEnded()));
  };

export const getEmailAccount = emailAccountId =>
  dispatch => {
    return dispatch(getEmailAccountApi(emailAccountId)).then(result => {
      return result;
    }).finally(() => dispatch(fetchEmailAccountsEnded()));
  };

export const createEmailAccount = emailAccount => dispatch => {
  const apiSafeEmailAccount = removeTemporaryIds(emailAccount);

  return dispatch(createEmailAccountApi(apiSafeEmailAccount))
    .then(result => {
      dispatch(saveEmailAccountSuccess(result));

      return result;
    });
};

export const saveEmailAccount = emailAccount => dispatch => {
  return dispatch(updateEmailAccountApi(emailAccount)).then(result => {
    dispatch(saveEmailAccountSuccess(result));

    return result;
  });
};

export const deleteEmailAccount = emailAccount =>
  dispatch => {
    return dispatch(deleteEmailAccountApi(emailAccount)).then(result => {
      dispatch(deleteEmailAccountSuccess(result));
      dispatch(getEmailAccounts());
    });
  };

const getEmailAccountsApi = dispatch => {
  return dispatch(
    callApi(`Email/Account`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

const getEmailAccountApi = emailAccountId =>
  dispatch => {
    return dispatch(callApi(`Email/Account/${emailAccountId}`))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);
  };

const deleteEmailAccountApi = emailAccount =>
  dispatch => {
    return dispatch(callApi(`Email/Account/${emailAccount.id}`, { method: 'DELETE' }))
      .then(emailAccount)
      .catch(console.error);
  };

const updateEmailAccountApi = emailAccount =>
  dispatch => {
    return dispatch(callApi(`Email/Account/${emailAccount.id}`, { method: 'PUT', body: emailAccount }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);
  };

const createEmailAccountApi = emailAccount => dispatch => {
  return dispatch(callApi(`Email/Account`, { method: 'POST', body: emailAccount }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

const removeTemporaryIds = emailAccount => {
  if (emailAccount.id < 0) delete emailAccount.id;

  return emailAccount;
};

export const fetchEmailAccountsBegin = params => ({
  type: EMAIL_ACCOUNTS_REQUEST_BEGIN,
});

export const fetchEmailAccountsSuccess = response => ({
  type: RECEIVE_EMAIL_ACCOUNTS,
  response,
});

export const fetchEmailAccountsEnded = () => ({
  type: EMAIL_ACCOUNTS_REQUEST_END,
});

export const deleteEmailAccountSuccess = emailAccount => ({
  type: DELETE_EMAIL_ACCOUNT_SUCCESS,
  emailAccount,
});

export const saveEmailAccountSuccess = emailAccount => ({
  type: SAVE_EMAIL_ACCOUNT,
  emailAccount,
});

