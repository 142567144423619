import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import ContactContext from './ContactContext';
import ContactHeader from './ContactHeader';
import ContactTabs from './ContactTabs';
import ContactSideTabs from './ContactSideTabs';
import ValidationBanner from 'Components/ValidationBanner';
import SimpleDialog from 'Components/SimpleDialog';
import ChitChat from 'Components/ChitChat';
import Email from 'Components/Email';
import Toast from 'Components/Toast';
import validate from 'validate.js';
import _ from 'lodash';
import { ENTITY_TYPES } from 'constants/entityTypes';
import { withRouter } from 'react-router-dom';
import { setupComboBoxSuggestions } from 'Components/setupComboBoxSuggestions';
import {
  getContact,
  saveContact,
  deleteContact,
  cloneContact,
  clearCurrentContact,
  addContact,
  inactiveContact,
} from 'actions/contact';
import {
  getPickLists,
} from 'actions/pickList';
import {
  fetchChitChat,
  logChitChatSystemMessage,
} from 'actions/chitchat';
import {
  initWorkingAddresses,
  workingAddressesAddNew,
  workingAddressesDelete,
  workingAddressesOnChange,
  workingAddressesSelectAddress,
  workingAddressesOnPrimaryChange,
} from 'actions/address';
import { addToRecents, removeRecent } from 'actions/recents';
import SectionContext from 'Components/Contexts/SectionContext';
import { userHasFullCoverLicense } from 'helpers/userHelpers';

const styles = () => ({
  body: {
    display: 'flex',
    flexGrow: 1,
    padding: '24px 16px 0',
    height: 'calc(100% - 120px)',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: '75%',
    borderRadius: 4,
    marginRight: 24,
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: '25%',
    height: '100%',
  },
  flexGrow: {
    display: 'flex',
    flexGrow: 1,
  },
});

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      contact: {},
      contactRef: {},
      invalidFields: [],
      salesReps: [],
      salutations: [],
      isValidationBannerVisible: false,
      viewState: 'contactDetails',
      isNewContact: false,
      successMessageOpen: false,
      successMessage: null,
      primaryAddressRef: {},
      inactiveContact: false,
    };

    this.userHasFullCover = userHasFullCoverLicense(this.props.user);// TODO: will be subscriptionOwnerRole
    this.contactDialog = React.createRef();
  }

  componentDidMount() {
    this.getContact();
    this.getPickLists();
    this.checkInactiveContact();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      this.getContact();
    }
    if (prevState.contact !== this.state.contact) {
      this.checkInactiveContact();
    }
  }

  componentWillUnmount() {
    this.props.clearCurrentContact();
  }

  checkInactiveContact = () => {
    const { contact } = this.state;

    if (contact && contact.id) {
      this.props.inactiveContact(contact.id)
        .then(inactiveContact => this.setState({ inactiveContact }));
    }
  }

  getContact = () => {
    const {
      getContact,
      match,
    } = this.props;

    getContact(match.params.id).then(this.setContact);
  }

  setContact = contact => {
    const {
      match,
      addToRecents,
      initWorkingAddresses,
      workingAddressesSelectAddress,
    } = this.props;

    if (!!contact && !!contact.id) {
      const isFirstEdit = contact.firstName === 'New' && !contact.lastModifiedUtcDateTime;
      const noAddresses = contact.contactAddresses.length === 0;
      let primaryAddress = noAddresses ? {} : contact.contactAddresses.find(a => a.primary);

      if (!primaryAddress && !noAddresses) {
        primaryAddress = contact.contactAddresses[0];
      }

      this.setState({
        contact,
        sectionContext: {
          relationship: {
            entityId: contact.id,
            entityType: ENTITY_TYPES.contact,
          },
          contact,
          onEmailDone: this.onEmailDone,
          chitChatRecordName: `Contact - ${contact.firstName || ''} ${contact.lastName || ''}`,
        },
        contactRef: _.cloneDeep(contact),
        isValidationBannerVisible: false,
        isNewContact: isFirstEdit,
        isEditing: isFirstEdit, // launch edit mode if arriving from quick-add
        primaryAddressRef: primaryAddress,
      });
      addToRecents(contact, match.url, ENTITY_TYPES.contact);

      if (!noAddresses) {
        initWorkingAddresses(contact.contactAddresses).then(() => {
          workingAddressesSelectAddress(primaryAddress.id);
        });
      }
      else {
        initWorkingAddresses(contact.contactAddresses);
      }

    } else {
      this.goToContactsGrid(); // redirect if ID doesn't exist
    }
  }

  getPickLists = () => {
    this.props.getPickLists().then(picklists => {
      const salutations = setupComboBoxSuggestions(picklists.find(picklist => picklist.name === 'Salutations').pickListOptions).comboBoxSuggestions;

      this.setState({ salutations });
    });
  }

  toggleEditMode = () => {
    if (this.state.isEditing) {
      this.cancelEditMode();
    } else {
      this.launchEditMode();
    }
  }

  launchEditMode = () => {
    // TURN ON: set initial form in case changes are not saved
    this.setState({ isEditing: true, contactRef: this.state.contact, viewState: 'contactDetails' });
  }

  cancelEditMode = () => {
    if (this.state.isNewContact) {
      this.deleteContact(); // User quick-adds a Contact, then decides not to Save it

      return;
    }

    if (this.isFormChanged() === true) {
      this.contactDialog.current.open('Are you sure you want to lose your changes?').then(() => {
        this.getContact();
      });
    } else {
      this.getContact();
    }
  }

  cloneContact = () => {
    const { cloneContact, saveContact, history, addToRecents, logChitChatSystemMessage } = this.props;
    const { contact } = this.state;

    this.contactDialog.current.open('Are you sure you want to clone this contact?').then(() => {
      cloneContact(contact).then(response => {
        const newUrl = `/contacts/${response.id}`;
        const relationship = {
          id: response.id,
          entityType: ENTITY_TYPES.contact,
        };
        const chitChatRecordName = `Contact - ${response.firstName || ''} ${response.lastName || ''}`;

        logChitChatSystemMessage(`Contact cloned from ${contact.firstName || ''} ${contact.lastName || ''}`, relationship, chitChatRecordName);
        addToRecents(response, newUrl, ENTITY_TYPES.contact);
        this.contactDialog.current.open('Successfully cloned! Would you like to go to the newly created contact?')
          .then(() => history.push(newUrl))
          .catch(() => saveContact(response));
      }).catch(error => window.alert(error));
    });
  }

  goToContactsGrid = () => {
    this.props.history.push('/contacts');
  }

  deleteContact = () => {
    const contactId = this.state.contact.id;

    this.contactDialog.current.open('Are you sure you want to delete this contact?').then(() => {
      this.props.deleteContact(contactId).then(() => {
        this.goToContactsGrid();
        this.props.removeRecent(contactId, ENTITY_TYPES.contact);
      });
    });
  }

  saveContact = () => {
    const { workingAddresses, saveContact } = this.props;
    const { contact } = this.state;

    if (this.isFormValid() === true) {
      const updatedContact = {
        ...contact,
        contactAddresses: workingAddresses,
      };

      return saveContact(updatedContact)
        .then(response => {
          this.chitChatSystemMessage('Contact Details updated');
          this.logAddressChange(response);
          this.setContact(response);
        })
        .catch(console.error);
    } else {
      this.setState({ isValidationBannerVisible: true });
    }
  }

  logAddressChange = updates => {
    const { contactRef, primaryAddressRef } = this.state;
    const previousAddresses = [...contactRef.contactAddresses];
    const updatedAddresses = [...updates.contactAddresses];
    const newPrimary = updatedAddresses.find(b => b.primary);

    if ((primaryAddressRef && newPrimary && newPrimary.address1 !== primaryAddressRef.address1) || (!primaryAddressRef && newPrimary)) {
      this.chitChatSystemMessage(`Primary address changed to ${newPrimary.address1}`);
    }
    if (updatedAddresses.length > previousAddresses.length) {
      const newAddresses = updatedAddresses.filter(a => previousAddresses.findIndex(p => p.address1 === a.address1) === -1);
      const newAddressString = newAddresses.map(a => a.address1).join('<br>');

      this.chitChatSystemMessage(`Addresses added:<br>${newAddressString}<br>`);
    }
    if (updatedAddresses.length < previousAddresses.length) {
      const deletedAddresses = previousAddresses.filter(a => updatedAddresses.findIndex(p => p.address1 === a.address1) === -1);
      const deletedAddressString = deletedAddresses.map(a => a.address1).join('<br>');

      this.chitChatSystemMessage(`Addresses deleted:<br>${deletedAddressString}<br>`);
    }
  }

  handleFieldChange = fieldName => value => {
    const contact = {
      ...this.state.contact,
      [fieldName]: value,
    };

    this.setState({ contact });
  }

  handleAddressChange = (addressId, fieldName, value) => {
    this.props.workingAddressesOnChange(addressId, fieldName, value);
  }

  onNewAddress = () => {
    this.setState({ isEditing: true });
    this.props.workingAddressesAddNew();
  }

  onDeleteAddress = addressId => {
    this.setState({ isEditing: true });
    this.props.workingAddressesDelete(addressId);
  }

  onHandlePrimaryAddress = (addressId, isChecked) => {
    this.props.workingAddressesOnPrimaryChange(addressId, isChecked);
  }

  handleSelectAddress = address => {
    const { workingAddressesSelectAddress } = this.props;

    if (address) workingAddressesSelectAddress(address.id);
  }

  isFormChanged = () => {
    const { contact, contactRef } = this.state;

    return JSON.stringify(contact) !== JSON.stringify(contactRef);
  }

  isFieldChanged = fieldName => {
    const {
      isEditing,
      contactRef,
      contact,
    } = this.state;

    if (!isEditing) {
      return false;
    }

    return contactRef[fieldName] !== contact[fieldName];
  }

  isFieldInvalid = fieldName => {
    return this.state.invalidFields.indexOf(fieldName) !== -1;
  }

  validateField = fieldName => {
    var isValid = true;
    var value = this.state.contact[fieldName];

    switch (fieldName) {
      case 'email':
        const emailResult = validate({ email: value }, { email: { email: true } });

        isValid = !emailResult;
        break;
      case 'name':
        const nameResult = validate({ name: value }, { name: { presence: { allowEmpty: false } } });

        isValid = !nameResult;
        break;
      default:
        return;
    }

    let invalidFields = [...this.state.invalidFields];
    const indexOfField = invalidFields.indexOf(fieldName);

    if (!isValid) {
      if (indexOfField === -1) {
        invalidFields.push(fieldName);
      }
    } else {
      if (indexOfField !== -1) {
        invalidFields.splice(indexOfField, 1);
      }
    }

    if (this.state.isValidationBannerVisible && invalidFields.length === 0) {
      this.setState({ invalidFields, isValidationBannerVisible: false });
    } else {
      this.setState({ invalidFields });
    }

  }

  isFormValid = () => {
    return this.state.invalidFields.length === 0;
  }

  setView = viewState => {
    this.setState({ viewState });
  }

  primaryAccountUpdated = newPrimaryAccount => {
    const contact = {
      ...this.state.contact,
      primaryAccount: newPrimaryAccount,
    };

    this.setState({ contact });
  }

  openEmailWithFile = file => {
    this.setState({ viewState: 'email', autoAttachFileId: file.id });
  }

  onEmailDone = () => {
    this.setState({
      viewState: 'contactDetails',
      autoAttachFileId: null,
      successMessageOpen: true,
      successMessage: 'Email sent successfully',
    });
  }

  onEmailCancel = () => {
    this.setState({ viewState: 'contactDetails' });
  }

  handleSuccessMessageClose = () => {
    this.setState({
      successMessageOpen: false,
      successMessage: null,
    });
  }

  refreshChitChat = () => {
    const { viewState, contact } = this.state;
    const { fetchChitChat } = this.props;

    if (viewState === 'chitChat') {
      fetchChitChat(ENTITY_TYPES.contact, contact.id);
    }
  }

  chitChatSystemMessage = message => {
    const { contact } = this.state;
    const { logChitChatSystemMessage } = this.props;
    const relationship = {
      id: contact.id,
      entityType: ENTITY_TYPES.contact,
    };
    const chitChatRecordName = `Contact - ${contact.firstName || ''} ${contact.lastName || ''}`;

    return logChitChatSystemMessage(message, relationship, chitChatRecordName).then(this.refreshChitChat);
  }

  afterFileUpload = file => {
    this.chitChatSystemMessage(`File Added: ${file.name}`);
  }

  addContactAndShowSuccess = () => {
    const { addContact, currentLocation, history } = this.props;

    if (currentLocation) {
      const newContact = {
        firstName: 'New',
        locationId: currentLocation.id,
      };

      addContact(newContact).then(newEntity => {
        history.push(`/contacts/${newEntity.id}`);
        this.setState({
          successMessageOpen: true,
          successMessage: 'New Contact Created',
        });
      });
    } else {
      console.error('Current Location is not set.');
    }
  }

  createNewContact = () => {
    if (this.isFormChanged() === true) {
      this.contactDialog.current.open('Save your changes before creating a new contact?').then(() => {
        this.saveContact().then(this.addContactAndShowSuccess);
      }).catch(this.addContactAndShowSuccess);
    } else {
      this.saveContact().then(this.addContactAndShowSuccess);
    }
  };

  render() {
    const {
      classes,
    } = this.props;
    const {
      isEditing,
      contact,
      isValidationBannerVisible,
      viewState,
      salutations,
      isNewContact,
      successMessageOpen,
      successMessage,
      autoAttachFileId,
      inactiveContact,
    } = this.state;

    const sectionContext = {
      relationship: {
        entityId: contact.id,
        entityType: ENTITY_TYPES.contact,
      },
      autoAttachFileId,
      onEmailDone: this.onEmailDone,
      onEmailCancel: this.onEmailCancel,
      chitChatRecordName: contact ? contact.name : '',
      defaultEmail: contact && contact.email ? contact.email : '',
    };

    const contactContext = {
      contact,
      isEditing,
      salutations,
      userHasFullCover: this.userHasFullCover,
      isNewContact,
      onSave: this.saveContact,
      onCancelEdit: this.cancelEditMode,
      onLaunchEditMode: this.launchEditMode,
      onFieldChange: this.handleFieldChange,
      onAddressChange: this.handleAddressChange,
      onNewAddress: this.onNewAddress,
      onDeleteAddress: this.onDeleteAddress,
      onHandlePrimaryAddress: this.onHandlePrimaryAddress,
      isFieldChanged: this.isFieldChanged,
      validateField: this.validateField,
      isFieldInvalid: this.isFieldInvalid,
      onChitChatUpdated: this.refreshChitChat,
      onFileAddSuccess: this.afterFileUpload,
    };

    if (!contact || !contact.id) {
      return null;
    }

    return (
      <ContactContext.Provider value={contactContext}>
        <SectionContext.Provider value={sectionContext}>
          <ContactHeader
            label="Contact"
            contact={contact}
            isEditing={isEditing}
            onEdit={this.toggleEditMode}
            onDelete={this.deleteContact}
            disableDeleteMessage={inactiveContact ? '' : 'This contact has associated orders and cannot be deleted'}
            onClone={this.cloneContact}
            onAddContact={this.createNewContact}
            onSetView={this.setView}
            viewState={viewState}
            userHasFullCover={this.userHasFullCover}
            openEmailWithFile={this.openEmailWithFile}
          />
          <div className={classes.body}>
            <div className={classes.main}>
              {viewState === 'contactDetails' &&
                <ContactTabs />
              }
              {viewState === 'chitChat' &&
                <Paper className={classes.flexGrow}>
                  <ChitChat
                    relationshipId={contact.id}
                    chitChatRelationship={ENTITY_TYPES.contact}
                    recordName={sectionContext.chitChatRecordName}
                  />
                </Paper>
              }
              {viewState === 'email' &&
                <Email />
              }
            </div>
            <div className={classes.sidebar}>
              <ContactSideTabs
                primaryAccountUpdated={this.primaryAccountUpdated}
                userHasFullCover={this.userHasFullCover}
              />
            </div>
          </div>

          {isEditing && <ValidationBanner isOpen={isValidationBannerVisible} />}
          <SimpleDialog innerRef={this.contactDialog} />
          <Toast message={successMessage} variant="success" open={successMessageOpen} onClose={this.handleSuccessMessageClose} />
        </SectionContext.Provider>
      </ContactContext.Provider>
    );
  }
}

const mapDispatchToProps = {
  getContact,
  saveContact,
  deleteContact,
  clearCurrentContact,
  cloneContact,
  addToRecents,
  removeRecent,
  getPickLists,
  logChitChatSystemMessage,
  fetchChitChat,
  addContact,
  initWorkingAddresses,
  workingAddressesAddNew,
  workingAddressesDelete,
  workingAddressesOnChange,
  workingAddressesSelectAddress,
  workingAddressesOnPrimaryChange,
  inactiveContact,
};

const mapStateToProps = state => {
  const {
    oidc: { user },
    api: { currentLocation },
    address: {
      workingAddresses,
      selectedAddressId,
    },
  } = state;

  return {
    user,
    currentLocation,
    workingAddresses,
    selectedAddressId,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Contact)));
