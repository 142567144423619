import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import PicklistManager from './Picklists/PicklistManager';
import Financials from './Financials/Financials';
import StatusManager from './Statuses/StatusManager';

import { Card } from '@material-ui/core';

const styles = theme => ({
  subContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 0, // for scroll
  },
  appBar: {
    backgroundColor: theme.palette.grey[50],
    height: 40,
  },
  background: {
    // height: 'calc(100% - 454px)', // TODO
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const tabs = [
  'Financials',
  // 'Custom Names', TODO: hidden for BETA
  'Pick List',
  'Statuses',
];

class BottomSubContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
    this.newId = -1;
  }

  handleChange = value => {
    this.setState({ value, pickListItems: undefined });
  };

  addOption = () => {
    this.setState(prevState => {
      const newItems = [...prevState.pickListItems, { name: '', id: this.newId }];

      this.newId--;

      return {
        pickListItems: newItems,
      };
    });
  }

  updateItems = items => {
    this.setState({ pickListItems: items });
  }

  getTabContents(value) {
    const { locationId } = this.props;

    switch (value) {
      case 0:
        return <Financials locationId={locationId} />;
      case 1:
        return <PicklistManager locationId={locationId} />;
      case 2:
        return <StatusManager />;
      default:
        return `Item ${value}`;
    }
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const contents = this.getTabContents(value);

    return (
      <div className={classes.subContent}>
        <div className={classes.appBar}>
          <BlueTextTab
            onTabChange={this.handleChange}
            tabs={tabs}
            tabIndex={value} />
        </div>
        <div className={classes.background}>
          <Card>
            {contents}
          </Card>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BottomSubContent);
