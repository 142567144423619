import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import {
  EMAIL_TRIGGERS_REQUEST_BEGIN,
  EMAIL_TRIGGERS_REQUEST_END,
  RECEIVE_EMAIL_TRIGGERS,
  DELETE_EMAIL_TRIGGER_SUCCESS,
  SAVE_EMAIL_TRIGGER,
} from './constants';

export const getEmailTriggers = (params = '') =>
  (dispatch, getState) => {
    dispatch(fetchEmailTriggersBegin());

    return getEmailTriggersApi(dispatch, params).then(result => {
      dispatch(fetchEmailTriggersSuccess(result));

      return result;
    }).finally(() => dispatch(fetchEmailTriggersEnded()));
  };

export const getEmailTrigger = emailTriggerId =>
  dispatch => {
    return dispatch(getEmailTriggerApi(emailTriggerId)).then(result => {
      return result;
    }).finally(() => dispatch(fetchEmailTriggersEnded()));
  };

export const createEmailTrigger = emailTrigger => dispatch => {
  const apiSafeEmailTrigger = removeTemporaryIds(emailTrigger);

  return dispatch(createEmailTriggerApi(apiSafeEmailTrigger))
    .then(result => {
      dispatch(saveEmailTriggerSuccess(result));

      return result;
    });
};

export const saveEmailTrigger = emailTrigger => dispatch => {
  const apiSafeEmailTrigger = removeTemporaryIds(emailTrigger);

  return dispatch(updateEmailTriggerApi(apiSafeEmailTrigger)).then(result => {
    dispatch(saveEmailTriggerSuccess(result));

    return result;
  });
};

export const deleteEmailTrigger = emailTrigger =>
  dispatch => {
    return dispatch(deleteEmailTriggerApi(emailTrigger)).then(result => {
      dispatch(deleteEmailTriggerSuccess(result));
      dispatch(getEmailTriggers());
    });
  };

const getEmailTriggersApi = dispatch => {
  return dispatch(
    callApi(`Email/Trigger`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

const getEmailTriggerApi = emailTriggerId =>
  dispatch => {
    return dispatch(callApi(`Email/Trigger/${emailTriggerId}`))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);
  };

const deleteEmailTriggerApi = emailTrigger =>
  dispatch => {
    return dispatch(callApi(`Email/Trigger/${emailTrigger.id}`, { method: 'DELETE' }))
      .then(emailTrigger)
      .catch(console.error);
  };

const updateEmailTriggerApi = emailTrigger =>
  dispatch => {
    return dispatch(callApi(`Email/Trigger/${emailTrigger.id}`, { method: 'PUT', body: emailTrigger }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);
  };

const createEmailTriggerApi = emailTrigger => dispatch => {
  return dispatch(callApi(`Email/Trigger`, { method: 'POST', body: emailTrigger }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

const removeTemporaryIds = emailTrigger => {
  if (emailTrigger.id < 0) delete emailTrigger.id;
  emailTrigger.conditions.forEach(condition => {
    if (condition.id < 0) delete condition.id;
  });

  return emailTrigger;
};

export const fetchEmailTriggersBegin = params => ({
  type: EMAIL_TRIGGERS_REQUEST_BEGIN,
});

export const fetchEmailTriggersSuccess = response => ({
  type: RECEIVE_EMAIL_TRIGGERS,
  response,
});

export const fetchEmailTriggersEnded = () => ({
  type: EMAIL_TRIGGERS_REQUEST_END,
});

export const deleteEmailTriggerSuccess = emailTrigger => ({
  type: DELETE_EMAIL_TRIGGER_SUCCESS,
  emailTrigger,
});

export const saveEmailTriggerSuccess = emailTrigger => ({
  type: SAVE_EMAIL_TRIGGER,
  emailTrigger,
});

