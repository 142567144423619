export const defaultsModifierGrid = {
  categoryId: 0,
  filters: [],
  sets: [],
  pageSize: 30,
  skip: 0,
  sortField: '',
  sortDirection: '',
};

export const defaultContactsGrid = {
  filters: [],
  pageSize: 30,
  skip: 0,
  sortField: '',
  sortDirection: '',
  search: '',
};

export const defaultLeadsGrid = {
  filters: [],
  pageSize: 30,
  skip: 0,
  sortField: '',
  sortDirection: '',
  search: '',
};

export const defaultAccountsGrid = {
  filters: [],
  pageSize: 30,
  skip: 0,
  sortField: '',
  sortDirection: '',
  search: '',
};

export const defaultBookingsGrid = {
  filter: {
    logic: 'and',
    filters: [
      { field: 'confirmed', operator: 'eq', value: true },
      { field: 'proposal', operator: 'eq', value: false },
    ] },
  take: 30,
  skip: 0,
  orderBy: [{ field: 'createdUtcDateTime', dir: 'desc' }],
};

export const defaultItemLibraryGrid = {
  filters: [],
  tags: [],
  pageSize: 30,
  skip: 0,
  sortField: '',
  sortDirection: '',
  categoryId: 0,
  includeImages: false,
  includeTagSummary: false,
};

export const defaultLocationsGrid = {
  filter: '',
  pageSize: 30,
  skip: 0,
  sortField: '',
  sortDirection: '',
};

export const defaultOrdersGrid = {
  filters: [
    { field: 'isDelivery', operator: 'eq', value: "false" },
  ],
  pageSize: 30,
  skip: 0,
  sortField: 'order',
  sortDirection: '',
  search: '',
};

export const defaultDriversGrid = {
  filters: [],
  pageSize: 30,
  skip: 0,
  sortField: 'driver',
  sortDirection: '',
  search: '',
};

export const defaultPackagesGrid = {
  filters: [],
  pageSize: 30,
  skip: 0,
  sortField: 'item',
  sortDirection: '',
  search: '',
};
