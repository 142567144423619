import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import ICalendarEvent from 'models/ICalendarEvent';
import EntityType from 'models/EntityType';

export default class CalendarEventService {

  constructor(dispatch: any, relationship: any) {
    this.dispatch = dispatch;
    this.entityId = relationship.entityId;
    this.entityType = relationship.entityType;
    this.calendarEventApiUrl = 'calendarEvent';
  }

  dispatch: any;
  entityId: any;
  entityType: any;
  calendarEventApiUrl: string;

  get = (): ICalendarEvent[] => {
    return this.dispatch(callApi(
      `${this.calendarEventApiUrl}?$filter=EntityId%20eq%20${this.entityId}%20and%20EntityType%20eq%20Cover.Domain.Models.Enums.EntityType%27${this.entityType}%27`))
      .then((response: any) => ParseJsonOnSuccessOrStop(this.dispatch, response));
  }

  add = (calendarEvent: ICalendarEvent) => {
    calendarEvent.entityId = this.entityId;
    calendarEvent.entityType = EntityType[this.entityType as keyof typeof EntityType];
    return this.dispatch(callApi(this.calendarEventApiUrl, { body: calendarEvent }))
      .then((response: any) => ParseJsonOnSuccessOrStop(this.dispatch, response));
  }

  update = (calendarEvent: ICalendarEvent) => {
    return this.dispatch(callApi(`${this.calendarEventApiUrl}/${calendarEvent.id}`, { method: 'put', body: calendarEvent }))
      .then((response: any) => ParseJsonOnSuccessOrStop(this.dispatch, response));
  }

  delete = (calendarEventId: number) => {
    return this.dispatch(callApi(`${this.calendarEventApiUrl}/${calendarEventId}`, { method: 'delete' }));
  }
}
