import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import DecimalField from 'Components/DecimalField';
import {
  updateRevenueTypeRate,
} from 'actions/RevenueTypeRates';
import Checkbox from 'Components/Checkbox';

const styles = theme => ({
  container: {
    padding: '20px 26px',
  },
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  table: {
    fontSize: 12,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    '& th': {
      padding: '6px 13px',
    },
    '& tr': {
      borderTop: 0,
      borderBottom: 0,
    },
  },
  borderedCell: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
  },
  rowLabel: {
    color: theme.palette.grey[300],
    paddingTop: 11,
    paddingBottom: 11,
  },
  centerText: {
    textAlign: 'center',
  },
  sectionLabel: {
    height: 16,
    display: 'flex',
    paddingLeft: 13,
    marginTop: 8,
    alignItems: 'center',
  },
  moveDown: {
    marginTop: 13,
    marginRight: 0,
  },
  exemptContents: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftColumn: {
    minWidth: 126,
  },
  rightColumn: {
    flexGrow: 1,
  },
  flex: {
    display: 'flex',
  },
  switchTextContainer: {
    textAlign: 'center',
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  switchText: {
    fontSize: 11,
    color: '#9d9d9d',
  },
  switch: {
    marginTop: -5,
  },
  codeExists: {
    margin: '0 16px',
    color: theme.palette.error.dark,
  },
});

const RevenueTypeRateRateModal = ({ classes, revenueTypeRate, updateRevenueTypeRate, onCloseModal, discountMode }) => {
  const toPercent = value => {
    return value * 100;
  };

  const fromPercent = value => {
    return (value / 100).toFixed(4);
  };

  const [revenueTypeRateEdit, setRevenueTypeRateEdit] = useState({
    ...revenueTypeRate,
    taxPercentage: toPercent(revenueTypeRate.taxPercentage),
    serviceChargePercentage: toPercent(revenueTypeRate.serviceChargePercentage),
    gratuityPercentage: toPercent(revenueTypeRate.gratuityPercentage),
    locationBasedTaxPercentage: revenueTypeRate.locationBasedTaxPercentage,
  });

  const onSave = () => {
    return updateRevenueTypeRate({
      id: revenueTypeRateEdit.id,
      revenueTypeId: revenueTypeRateEdit.revenueTypeId,
      revenueConfigurationId: revenueTypeRateEdit.revenueConfigurationId,
      taxPercentage: fromPercent(revenueTypeRateEdit.taxPercentage),
      serviceChargePercentage: fromPercent(revenueTypeRateEdit.serviceChargePercentage),
      taxServChg: revenueTypeRateEdit.taxServChg,
      gratuityPercentage: fromPercent(revenueTypeRateEdit.gratuityPercentage),
      taxGrat: revenueTypeRateEdit.taxGrat,
      locationBasedTaxPercentage: revenueTypeRateEdit.locationBasedTaxPercentage,
    }
    ).then(onCloseModal);
  };

  const onCancel = () => {
    onCloseModal();
  };

  const onFieldChange = fieldName => value => {
    if (value && value.length > 20) {
      return;
    }

    const updatedRevenueTypeRate = {
      ...revenueTypeRateEdit,
      [fieldName]: value,
    };

    setRevenueTypeRateEdit(updatedRevenueTypeRate);
  };

  return (<Modal
    isOpened={true}
    dimensions={{
      width: 340,
      height: 'auto',
    }}
    onCancel={onCancel}
    onSave={onSave}
    title={revenueTypeRateEdit.revenueType.name}
    addTitleBottomBorder={true}
  >
    <div className={classes.container}>
      <div className={classes.exemptContents}>
        <Checkbox
          // className={classes.undeliverableCheckbox}
          // classes={{ label: classes.undeliverableCheckboxLabel }}
          label="Use Automatic Tax % based on Location"
          checked={revenueTypeRateEdit.locationBasedTaxPercentage}
          onFieldChange={onFieldChange('locationBasedTaxPercentage')}
        />
        {!revenueTypeRateEdit.locationBasedTaxPercentage ?
          <DecimalField
            suffix="%"
            minValue={0}
            label="Tax Percentage"
            value={revenueTypeRateEdit.locationBasedTaxPercentage ? '' : revenueTypeRateEdit.taxPercentage}
            onFieldChange={onFieldChange('taxPercentage')}
            maxLength={20}
            disabled={revenueTypeRateEdit.locationBasedTaxPercentage}
          /> : ''}
        <DecimalField
          suffix="%"
          label="Service Percentage"
          value={revenueTypeRateEdit.serviceChargePercentage}
          onFieldChange={onFieldChange('serviceChargePercentage')}
          maxLength={20}
        />
        <Checkbox
          label="Tax Service Charge"
          checked={revenueTypeRateEdit.taxServChg}
          onFieldChange={onFieldChange('taxServChg')}
        />
        <DecimalField
          suffix="%"
          label="Gratuity Percentage"
          value={revenueTypeRateEdit.gratuityPercentage}
          onFieldChange={onFieldChange('gratuityPercentage')}
          maxLength={20}
        />
        <Checkbox
          label="Tax Gratuity"
          checked={revenueTypeRateEdit.taxGrat}
          onFieldChange={onFieldChange('taxGrat')}
        />
      </div>

    </div>
  </Modal>
  );
};

const mapDispatchToProps = {
  updateRevenueTypeRate,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(RevenueTypeRateRateModal));
