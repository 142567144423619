import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AppBar,
  Paper,
  Fade,
  Menu,
  MenuItem,
} from '@material-ui/core';
import DataLoader from 'Components/DataLoader';
import {
  CardGiftcard as LeadIcon,
  Forum as ChitChatIcon,
  Email as EmailIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as CloneIcon,
} from '@material-ui/icons';
import LeadStatusBar from './LeadStatusBar';
import HighlightButton from 'Components/Buttons/HighlightButton';
import HeaderButton from 'Components/Buttons/HeaderButton';
import MiniButtonSet from 'Components/Buttons/MiniButtonSet';
import LeadContext from './LeadContext';
import SimpleDialog from 'Components/SimpleDialog';
import { addLead } from 'actions/lead';
import { addStatusHistory } from 'actions/statusHistory';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';

const styles = theme => ({
  appBarOverrides: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    fontSize: 13,
    height: 'unset',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 14px',
    backgroundColor: theme.palette.lead,
    color: theme.palette.common.white,
  },
  topLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  topRight: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 32,
    width: 32,
    marginRight: 16,
  },
  leadIcon: {
    fontSize: '48px',
    color: theme.palette.common.white,
  },
  rightIcon: {
    fontSize: '20px',
  },
  smallerFont: {
    fontSize: 17,
  },
  verticalAlign: {
    verticalAlign: 'middle',
  },
  leadName: {
    fontSize: 22,
    lineHeight: 1,
  },
  bottomBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 32px 12px 16px',
    backgroundColor: theme.common.blueGrey,
  },
  glanceLabel: {
    color: theme.palette.grey[200],
    fontSize: 10,
  },
  glanceData: {
    color: theme.palette.grey[300],
    fontSize: 12,
  },
  linkColor: {
    color: theme.palette.primary.dark,
  },
  bottomBarFieldContainer: {
    display: 'flex',
    width: 700,
    justifyContent: 'space-between',
    paddingRight: 100,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  statusBar: {
    display: 'flex',
    flex: '0 0 auto',
    margin: '16px 0',
  },
});

const okDialog = React.createRef();

class LeadHeader extends Component {
  state = {
    optionsOpenedBy: null,
  }

  openToolsMenu = event => {
    this.setState({ optionsOpenedBy: event.currentTarget });
  }

  closeToolsMenu = () => {
    this.setState({ optionsOpenedBy: null });
  }

  createLeadAndGo = () => {
    const { addLead, addStatusHistory, currentLocation, history } = this.props;

    this.closeToolsMenu();
    addLead({ firstName: 'New', locationId: currentLocation.id }).then(lead => {
      history.push(`/leads/${lead.id}`);
      if (lead.statusId) addStatusHistory('Lead', lead.id, lead.statusId);
    });
  };

  createLead = () => {
    const { currentLocation } = this.props;

    if (currentLocation && currentLocation.id) {
      this.createLeadAndGo();
    } else {
      okDialog.current.open('Please select a location before creating a lead')
        .then(() => this.createLeadAndGo());
    }
  };

  onSetView = (synthEvent, viewState) => {
    synthEvent.stopPropagation();
    this.props.onSetView(viewState);
  }

  render() {
    const {
      classes,
      isEditing,
      label,
      onEdit,
      onDelete,
      onClone,
      viewState,
      leadStatuses,
      onConvertClick,
      isLoading,
    } = this.props;
    const {
      optionsOpenedBy,
    } = this.state;

    return (<>
      <LeadContext.Consumer>
        {({ lead }) => {
          const currentStatus = leadStatuses.length && lead.statusId ? leadStatuses.find(status => status.id === lead.statusId) : null;
          const converted = currentStatus ? currentStatus.name === 'Converted' : false;

          return (
          <>
          <AppBar position="static" classes={{ root: classes.appBarOverrides }}>
            <div className={classes.topBar}>
              <div className={classes.topLeft}>
                <div className={classes.iconContainer}>
                  <LeadIcon className={classes.leadIcon} />
                </div>
                <div className={classes.verticalAlign}>
                  <div className={classes.smallerFont}>{label}</div>
                  <p className={classes.leadName}>
                    {lead.name}
                  </p>
                </div>
              </div>
              <div className={classes.topRight}>
                <MiniButtonSet>
                  <HeaderButton
                    onClick={synthEvent => this.onSetView(synthEvent, 'leadDetails')}
                    isActive={viewState === 'leadDetails'}
                    name="Lead"
                    icon={<LeadIcon className={classes.rightIcon} />}>
                  </HeaderButton>
                  <HeaderButton
                    onClick={synthEvent => this.onSetView(synthEvent, 'chitChat')}
                    isActive={viewState === 'chitChat'}
                    name="Chit Chat"
                    icon={<ChitChatIcon className={classes.rightIcon} />}>
                  </HeaderButton>
                  <HeaderButton
                    onClick={synthEvent => this.onSetView(synthEvent, 'email')}
                    isActive={viewState === 'email'}
                    name="Email"
                    icon={<EmailIcon className={classes.rightIcon} />}>
                  </HeaderButton>
                </MiniButtonSet>
                <MiniButtonSet>
                  <HeaderButton onClick={onEdit} isActive={isEditing} name="Edit" icon={<EditIcon className={classes.rightIcon} />}></HeaderButton>
                  <HeaderButton onClick={onDelete} name="Delete" icon={<DeleteIcon className={classes.rightIcon} />}></HeaderButton>
                  <HeaderButton onClick={onClone} name="Clone" icon={<CloneIcon className={classes.rightIcon} />}></HeaderButton>
                  <ArrowDownButton onClick={this.openToolsMenu} />
                  <Menu
                    id="options-menu"
                    anchorEl={optionsOpenedBy}
                    open={Boolean(optionsOpenedBy)}
                    onClose={() => this.closeToolsMenu()}
                  >
                    <MenuItem onClick={this.createLead}>Add New Lead</MenuItem>
                  </Menu>
                </MiniButtonSet>
              </div>
            </div>
            <Paper elevation={1} square className={classes.bottomBar}>
              <div className={classes.bottomBarFieldContainer}>
                <div>
                  <p className={classes.glanceLabel}>Title</p>
                  <p className={classes.glanceData}>{lead.title}</p>
                </div>
                <div>
                  <p className={classes.glanceLabel}>Company</p>
                  <p className={classes.glanceData}>
                    {lead.accountId ? <a href={`/accounts/${lead.accountId}`}>{lead.company}</a>
                      : lead.company}
                  </p>
                </div>
                <div>
                  <p className={classes.glanceLabel}>Phone</p>
                  <p className={classes.glanceData}>{lead.phone}</p>
                </div>
                <div>
                  <p className={classes.glanceLabel}>Email</p>
                  <a href={`mailto:${lead.email}`}><p className={classNames(classes.glanceData, classes.linkColor)}>{lead.email}</p></a>
                </div>
              </div>
              { !converted &&
                <HighlightButton onClick={() => onConvertClick()}>
                  Convert
                </HighlightButton>
              }
            </Paper>
            {isLoading && <div className={classes.loader}>
              <Fade
                in={isLoading}
                style={{
                  transitionDelay: isLoading ? '800ms' : '0ms',
                }}
                unmountOnExit
              >
                <DataLoader />
              </Fade>
            </div>}
          </AppBar>
          <div className={classes.statusBar}>
            <LeadStatusBar onLastStep={this.onConvertClick} />
          </div>
          </>
          );
        }}
      </LeadContext.Consumer>
      <SimpleDialog innerRef={okDialog} onlyOkayButton={true} />
    </>);
  }
}

LeadHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  leadStatuses: state.settings.statuses && state.settings.statuses.length
    ? state.settings.statuses.find(status => status.type === 'Lead').statuses
    : [],
  currentLocation: state.api.currentLocation,
});

const mapDispatchToProps = {
  addLead,
  addStatusHistory,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeadHeader)));
