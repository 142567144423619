import React, { Component, useContext } from 'react';
import {
  Add as ExpandIcon,
  Remove as MinimizeIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { GridDetailRow } from '@progress/kendo-react-grid';
import MenuCategoryContext from './MenuCategoryContext';
import { Tooltip } from '@material-ui/core';

const expandoStyles = theme => ({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  iconError: {
    color: theme.palette.error.main,
  },
  iconNormal: {
    color: theme.palette.primary.main,
  },
  noModifiers: {
    color: theme.palette.grey['A200'],
    opacity: '0.4',
    pointerEvents: 'none',
  },
});

const addModifireOptions = (options, allSelectedItemModifiers) => {
  if (options) {
    options.forEach(option => {
      allSelectedItemModifiers[option.id] = option;
      addModifireOptions(option.options, allSelectedItemModifiers);
    });
  }
};

class ExpandoCell extends Component {

  onClick = e => {
    e.preventDefault();
    const {
      dataItem,
      field,
    } = this.props;

    if (this.props.onChange) {
      //onChange is Grid.expandChange
      //copied from GridHiearchyCell.js in the Kendo Library
      this.props.onChange({
        dataItem: dataItem,
        syntheticEvent: e,
        field: field,
        value: !dataItem[field],
      });
    }
  }

  getSelectedModifireOptions = (bookingItemModifiers, modifier) => {
    const selectedModifireIds = Object
      .values(bookingItemModifiers)
      .filter(bookingModifier => bookingModifier.recordStatus === 'Active')
      .map(bookingModifier => bookingModifier.menuItemModifierId);

    return modifier.options
      .filter(option => selectedModifireIds.includes(option.id));
  }

  needSelectSize = (menuItem, bookingItemModifiers) =>
    !menuItem.price
        && !!menuItem.modifiers.length
        && !Object.keys(
          bookingItemModifiers.filter(modifier => modifier.recordStatus === "Active")
        ).length;

  validateAllModifiers = (menuItem, bookingItemModifiers) => {
    const allSelectedItemModifiers = {};

    addModifireOptions(menuItem.modifiers, allSelectedItemModifiers);

    if (this.needSelectSize(menuItem, bookingItemModifiers)) {
      return 'Please select a modifiers';
    }

    for (const modifier of Object.values(allSelectedItemModifiers)) {
      if (modifier.rule === 'PickOne' || (!modifier.minimumQuantity && !modifier.maximumQuantity)) {
        continue;
      }

      const selectedOptions = this.getSelectedModifireOptions(bookingItemModifiers, modifier);
      const parentModifire = !modifier.parentMenuItemModifierId ? '' : `${allSelectedItemModifiers[modifier.parentMenuItemModifierId].name} - `;

      if (!!modifier.minimumQuantity && selectedOptions.length < modifier.minimumQuantity) {
        return `${parentModifire}${modifier.name} min limit is ${modifier.minimumQuantity}`;
      }
      if (!!modifier.maximumQuantity && selectedOptions.length > modifier.maximumQuantity) {
        return `${parentModifire}${modifier.name} max limit is ${modifier.maximumQuantity}`;
      }
    }

    return '';
  }

  render() {
    const {
      expanded,
      classes,
      dataItem,
      allMenuItems,
    } = this.props;

    const menuItem = allMenuItems[dataItem.menuCategoryItem.menuItemId];
    const menuItemModifiers = !!menuItem.modifiers ? menuItem.modifiers : [];
    const bookingItemModifiers = !!dataItem.bookingEventMenuCategoryItemModifiers ? dataItem.bookingEventMenuCategoryItemModifiers : [];
    const noModifiers = !menuItemModifiers.length && !bookingItemModifiers.length;
    const ruleViolation = this.validateAllModifiers(menuItem, bookingItemModifiers);

    return (
      <td className={noModifiers
        ? classes.noModifiers
        : ruleViolation
          ? classes.iconError
          : classes.iconNormal}>
        <div className={classes.icon}>
          <Tooltip title={ruleViolation ? ruleViolation.toString() : 'Modifiers'} placement="top">
            {expanded ? <MinimizeIcon onClick={this.onClick} /> : <ExpandIcon onClick={this.onClick} />}
          </Tooltip>
        </div>
      </td>);
  }
}

const detailsStyles = theme => ({
  openModifierEdit: {
    color: theme.palette.action.active,
    cursor: 'pointer',
  },
});

class ModifiersDetailsComponent extends GridDetailRow {
  state = { sets: [] };

  closeModifierEdit = () => {
    this.setState({ sets: null }); //closes the modal
  }

  content = () => {
    const {
      classes,
      dataItem,
      dataItem: {
        bookingEventMenuCategoryItemModifiers,
        menuCategoryItem,
      },
      allMenuItems,
      openProductDetails,
    } = this.props;

    const menuItem = allMenuItems[menuCategoryItem.menuItemId];
    const allMenuItemModifiers = {};

    addModifireOptions(menuItem.modifiers, allMenuItemModifiers);

    dataItem.menuCategoryItem.menuItem = menuItem;

    if (!!bookingEventMenuCategoryItemModifiers && !!bookingEventMenuCategoryItemModifiers.length) {
      const allModifiers = bookingEventMenuCategoryItemModifiers
        .filter(modifier => modifier.recordStatus === 'Active')
        .map(modifier => allMenuItemModifiers[modifier.menuItemModifierId].name);

      if (allModifiers.length) {
        return <p className={classes.openModifierEdit} onClick={() => openProductDetails(dataItem)}>{allModifiers.join(", ")}</p>;
      }
    }

    return (
      <p className={classes.openModifierEdit} onClick={() => openProductDetails(dataItem)}>No selected modifiers</p>
    );
  }

  render() {
    return (
      <>
        {this.content()}
      </>);
  }
}

const ExpandoCellWithStyles = withStyles(expandoStyles)(ExpandoCell);

const ModifiersDetailsComponentContainer = props => {
  const {
    getSetsOfModifiers,
    allMenuItems,
    openProductDetails,
  } = useContext(MenuCategoryContext);

  return (<ModifiersDetailsComponent
    {...props}
    getSetsOfModifiers={getSetsOfModifiers}
    allMenuItems={allMenuItems}
    openProductDetails={openProductDetails}
  />);
};

const ModifiersDetails = withStyles(detailsStyles)(ModifiersDetailsComponentContainer);

export { ExpandoCellWithStyles as ExpandoCell, ModifiersDetails };
