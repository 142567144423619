import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import ISetting from 'models/ISetting';
import { FormControlLabel, 
  Grid, 
  Checkbox, 
  Radio, 
  Switch } from '@material-ui/core';

const styles = createStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  gridHeader: {
    marginBottom: 3,
  },
  checkItem: {
    display: 'flex',
    alignItems: 'center',
  },
  columns: {
    display: 'flex',
    width: 675,
  },
  formGroupColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioButton: {
    marginLeft: 15,
  },
  switchContainer: {
    display: 'flex',
    marginTop: 15,
    width: 640,
  },
  switchCenter: {
    alignItems: 'center',
  },
  switchTextLeft: {
    textAlign: 'left',
  },
  switchTextRight: {
    textAlign: 'right',
  },
}));

interface IProps extends WithStyles {
  setting: ISetting,
  settingOnChange: (settingKey: string, settingValue: any, optionIndex?: number) => void,
}

interface IState {
  enabledOrderType: {
    pickup: boolean,
    delivery: boolean,
    event: boolean,
  },
  defaultOrderType: string,
  preserveUserChoice: boolean,
}

const defaultState: IState = {
  enabledOrderType: {
    pickup: true,
    delivery: true,
    event: false,
  },
  defaultOrderType: 'pickup',
  preserveUserChoice: false,
}


class grazeOrderTypes extends Component<IProps, IState> {
  state = defaultState;

  mapSettingToState = () => {
    const { setting } = this.props;

    this.setState({
      enabledOrderType: {
        pickup: setting.value.enabledOrderType.pickup,
        delivery: setting.value.enabledOrderType.delivery,
        event: setting.value.enabledOrderType.event,
      },
      defaultOrderType: setting.value.defaultOrderType,
      preserveUserChoice: setting.value.preserveUserChoice,
    });
  }

  checkDefault = () => {
    const { defaultOrderType, enabledOrderType } = this.state;
    const { settingOnChange, setting } = this.props;
    const updatedState = { ...this.state };

    if (!enabledOrderType[defaultOrderType]) {
      updatedState.defaultOrderType = Object.keys(enabledOrderType).find(type => enabledOrderType[type]);
    }

    settingOnChange(setting.key, updatedState);
  }

  updateState = (state: any) => {
    this.setState(state, () => {
      this.checkDefault();
    });
  }

  handleDefaultOrderType = (event: any) => {
    this.updateState({ defaultOrderType: event.target.value });
  };

  handleUserChoice = (event: any) => {
    this.updateState({ preserveUserChoice: event.target.checked });
  };

  public componentDidMount() {
    this.mapSettingToState();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { setting } = this.props;

    if (setting != prevProps.setting) {
      this.mapSettingToState();
    }
  }
  
  public render() {
    const { classes } = this.props;
    const { enabledOrderType, defaultOrderType, preserveUserChoice } = this.state;

    return (
      <div>
        <div className={classes.container}>
          <div className={classes.columns}>
            <Grid container className={classes.gridHeader}>
              <Grid item xs={3}>Order Types</Grid>
              <Grid item xs={3}>Default</Grid>
              <Grid item xs={3}>Enabled</Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.columns}>
            <Grid container className={classes.formGroupColumn}>
              <div className={classes.checkItem}>
                <Grid item xs={3}>
                  Pickup
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    value='pickup'
                    checked={defaultOrderType === 'pickup'}
                    onChange={this.handleDefaultOrderType}
                    control={<Radio className={classes.radioButton} />}
                    disabled={!enabledOrderType.pickup}
                    label=""
                  />
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    checked={enabledOrderType.pickup}
                    value='disablePickup'
                    onChange={e => {
                      this.updateState({
                        enabledOrderType: {
                          ...enabledOrderType,
                          pickup: e.target.checked,
                        },
                      });
                    }}
                    disabled={!enabledOrderType.delivery && !enabledOrderType.event}
                  />
                </Grid>
              </div>
              <div className={classes.checkItem} >
                <Grid item xs={3}>
                  Delivery
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    value='delivery'
                    checked={defaultOrderType === 'delivery'}
                    onChange={this.handleDefaultOrderType}
                    control={<Radio className={classes.radioButton} />}
                    disabled={!enabledOrderType.delivery}
                    label=""
                  />
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    checked={enabledOrderType.delivery}
                    value='disableDelivery'
                    onChange={e => {
                      this.updateState({
                        enabledOrderType: {
                          ...enabledOrderType,
                          delivery: e.target.checked,
                        },
                      });
                    }}
                    disabled={!enabledOrderType.pickup && !enabledOrderType.event}
                  />
                </Grid>
              </div>
              <div className={classes.checkItem}>
                <Grid item xs={3}>
                  Event
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    value='event'
                    checked={defaultOrderType === 'event'}
                    onChange={this.handleDefaultOrderType}
                    control={<Radio className={classes.radioButton} />}
                    disabled={!enabledOrderType.event}
                    label=""
                  />
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    checked={enabledOrderType.event}
                    value='disableEvent'
                    onChange={e => {
                      this.updateState({
                        enabledOrderType: {
                          ...enabledOrderType,
                          event: e.target.checked,
                        },
                      });
                    }}
                    disabled={!enabledOrderType.delivery && !enabledOrderType.pickup}
                  />
                </Grid>
              </div>
            </Grid>
          </div>
          <div className={classes.switchContainer}>
            <Grid container className={classes.switchCenter}>
              <Grid item xs={3} className={classes.switchTextRight}>System Enforced</Grid>
              <Switch
                checked={preserveUserChoice}
                onChange={this.handleUserChoice}
              />
              <Grid item xs={3} className={classes.switchTextLeft}>Preserve User Choice</Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(grazeOrderTypes);
