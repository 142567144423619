import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { GridColumn } from '@progress/kendo-react-grid';
import ODataGrid from 'Components/Grid/ODataGrid';
import { connect } from 'react-redux';
import {
  getRooms,
  createRoom,
  updateRoom,
  getAllSetupStyles,
  createSetupStyle,
  updateSetupStyle,
  deleteSetupStyle,
} from 'actions/rooms';
import GridToolBar from 'Components/Grid/GridToolBar';
import { buildGridCellLink } from 'Components/buildGridCellLink';
import { Exposure } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import SaveBar from 'Components/SaveBar';
import TextField from 'Components/TextField';
import Select from 'Components/Select';
import { CurrencyField } from 'Components/currencyInput';
import MenuItem from '@material-ui/core/MenuItem';
import SetupStylesTable from './SetupStylesTable';
import HighlightButton from 'Components/Buttons/HighlightButton';
import SetupStylesModal from './SetupStylesModal';
import SimpleDialog from 'Components/SimpleDialog';

const styles = theme => ({
  roomContainer: {
    display: 'flex',
  },
  roomField: {
    width: 200,
  },
  setupStyle: {
    padding: 10,
    marginBottom: 20,
    width: 460,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  setupStyleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0 10px 5px',
  },
});

const roomTypes = [
  'Banquet Room',
  'Classroom',
  'Outdoor Space',
  'Pub',
  'Theater',
];

const rateTypes = [
  'Flat',
  // 'Hour',
  // 'Day',
  // 'Week',
  // 'Month',
];

const defaultRoom = {
  roomSetupStyles: [],
  rateType: 'Flat',
};

class CouponGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rooms: [],
      selectedRoom: defaultRoom,
      setupStylesModalOpen: false,
      selectedRoomUpdated: false,
      selectedStylesKeyValue: {},
    };
  }

  dialog = React.createRef();

  customFilters = [];

  defaultFilter = this.customFilters.find(filter => filter.default);

  linkToRoom = buildGridCellLink({
    url: booking => `/admin/rooms/${booking.id}`,
    text: booking => booking.name,
  });

  isSaveDisabled = () => {
    return !this.state.selectedRoom.name || !this.state.selectedRoomUpdated;
  }

  switchRoom = currentId => {
    if (!this.isSaveDisabled()) {
      this.dialog.current.open('Save your changes to this room?')
        .then(async () => {
          await this.saveRoom(true);
          this.setRoom(currentId);
        })
        .catch(() => {
          this.setRoom(currentId);
        });
    } else {
      this.setRoom(currentId);
    }
  }

  setRoom = currentId => {
    const rooms = this.props.rooms.map(room => {
      room.selected = false;

      return room;
    });

    if (currentId === '0') {
      rooms.push({ name: 'New room', selected: true });
    }
    const selectedRoom = !!currentId && currentId !== '0' && !!this.props.rooms
      ? rooms.find(room => room.id == currentId)
      : defaultRoom;

    selectedRoom.selected = true;

    this.setState({
      rooms,
      selectedRoom,
      selectedRoomUpdated: false,
    });
  }

  setUsedSetupStyles = () => {
    const { rooms, setupStyles } = this.props;

    const usedSetupStyles = setupStyles.reduce((r, e) => {
      var inuse = false;

      rooms.every(room => {
        const setupSytleInUse = room.roomSetupStyles
          .find(setupStyle =>
            setupStyle.recordStatus === 'Active' &&
            setupStyle.setupStyleId === e.id
          );

        if (setupSytleInUse) {
          inuse = true;

          return false;
        } else {
          return true;
        }
      });

      r[e.id] = inuse;

      return r;
    }, {});

    this.setState({ usedSetupStyles });
  }

  onDeleteSetupStyle = async deletedSetupStyle => {
    const { getAllSetupStyles, deleteSetupStyle } = this.props;

    await deleteSetupStyle(deletedSetupStyle);
    getAllSetupStyles();
  }

  componentDidMount() {
    this.props.getAllSetupStyles();
    this.props.getRooms();
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.match.params.id;
    const currentId = this.props.match.params.id;

    if (prevId !== currentId) {
      this.switchRoom(currentId);
    } else if (prevProps.rooms !== this.props.rooms) {
      this.setRoom(currentId);
    }

    if (prevProps.setupStyles !== this.props.setupStyles || prevProps.rooms !== this.props.rooms) {
      this.setUsedSetupStyles();
    }
  }

  onSort = setupStyles => {
    const { selectedRoom } = this.state;
    const updatedSetupStyles = setupStyles.map((option, index) => ({ ...option, sortOrder: index * 100 }));

    this.setState({
      selectedRoom: {
        ...selectedRoom,
        roomSetupStyles: updatedSetupStyles,
      },
      selectedRoomUpdated: true,
    });
  }

  onSetupStyleSave = async selectedSetupStyles => {
    const { selectedRoom } = this.state;
    const updatedSelectedRoom = {
      ...selectedRoom,
      roomSetupStyles: [
        ...selectedRoom.roomSetupStyles,
      ],
    };

    await Promise.all(selectedSetupStyles.map(async sss => {
      const addNewSetupStyle = sss.id === sss.name;

      if (addNewSetupStyle) {
        sss.id = 0;
        delete sss.updated;
        const newSetupStyle = await this.props.createSetupStyle(sss);

        sss.id = newSetupStyle.id;
      }

      const updatedRoomSetupStyle = updatedSelectedRoom.roomSetupStyles.find(rss => !!rss.setupStyle && rss.setupStyle.id === sss.id);

      if (sss.updated) {
        const updatedSetupStyle = await this.props.updateSetupStyle({
          id: sss.id,
          name: sss.name,
        });

        if (updatedRoomSetupStyle) {
          updatedRoomSetupStyle.setupStyle = updatedSetupStyle;
        }
      }

      if (!sss.selected) {
        if (!!updatedRoomSetupStyle) {
          updatedRoomSetupStyle.recordStatus = 'Deleted';
        }

        return;
      }

      if (!!updatedRoomSetupStyle && updatedRoomSetupStyle.recordStatus === 'Deleted') {
        updatedRoomSetupStyle.recordStatus = 'Active';

        return;
      }

      if (!updatedRoomSetupStyle) {
        updatedSelectedRoom.roomSetupStyles = [
          ...updatedSelectedRoom.roomSetupStyles,
          {
            setupStyleId: sss.id,
            sortOrder: 100,
            setupStyle: {
              name: sss.name,
              id: sss.id,
            },
          },
        ];
      }
    }));

    this.setState({
      ...this.state,
      selectedRoom: updatedSelectedRoom,
      setupStylesModalOpen: false,
      selectedRoomUpdated: true,
    }, () => { this.props.getAllSetupStyles(); });
  }

  onSetupStyleCancel = () => {
    this.setState({ setupStylesModalOpen: false });
  }

  applyCustomFilter = filterId => {
    const foundFilter = this.customFilters.find(filter => filter.id === filterId);

    this.setState({
      filterId,
      globalFilter: foundFilter,
    });
  }

  handleFieldChange = (fieldName, parser) => value => {
    const selectedRoom = {
      ...this.state.selectedRoom,
      [fieldName]: parser ? parser(value) : value,
    };

    this.setState({
      selectedRoom,
      selectedRoomUpdated: fieldName === 'rate' && this.state.selectedRoom.rate == value
        ? this.state.selectedRoomUpdated
        : true,
    });
  }

  onAddRooms = () => {
    this.props.history.push('/admin/rooms/0');
  }

  saveRoom = async (autosave = false) => {
    const { selectedRoom } = this.state;
    const addNewRoom = !selectedRoom.id;

    if (addNewRoom) {
      const newRoomSetupStyles = [ ...selectedRoom.roomSetupStyles ];

      selectedRoom.roomSetupStyles = [];
      const updatedRoom = await this.props.createRoom(selectedRoom);

      updatedRoom.roomSetupStyles = newRoomSetupStyles;
      await this.props.updateRoom(updatedRoom);
    } else {
      await this.props.updateRoom(selectedRoom);
    }
    await this.props.getRooms();
    if (!autosave) {
      this.props.history.push('/admin/rooms');
    }
  }

  cancel = () => {
    this.props.history.push('/admin/rooms');
  }

  getToolbar = () => (
    <GridToolBar
      gridContext="Rooms"
      onAddClick={() => this.onAddRooms()}
    />
  )

  render() {
    const {
      globalFilter,
      sort,
      rooms,
      selectedRoom,
      setupStylesModalOpen,
      usedSetupStyles,
    } = this.state;

    const {
      classes,
      loading,
      match,
      getAllSetupStyles,
      setupStyles,
    } = this.props;

    return <>
      <div className={classes.roomContainer}>
        <div>
          <ODataGrid
            height='auto'
            getData={this.props.getRooms}
            items={rooms}
            loading={loading}
            isSortable={false}
            filterable={false}
            isEditable={false}
            pageSize={50}
            toolBar={this.getToolbar()}
            onColumnReorder={this.reorderColumns}
            filter={(globalFilter && globalFilter.filter)}
            sort={sort}
          >
            <GridColumn
              key='name'
              title='Name'
              cell={this.linkToRoom}
            />
            <GridColumn
              key='type'
              field='type'
              title='Type'
            />
            <GridColumn
              key='rateType'
              field='rateType'
              title='Rate Type'
            />
            <GridColumn
              key='rate'
              field='rate'
              title='Rate'
              format='{0:C}'
            />
          </ODataGrid>
        </div>
        <div style={match.params.id ? null : { display: 'none' }}>
          <div>
            <TextField
              className={classes.roomField}
              label="Room Name"
              value={selectedRoom.name}
              onFieldChange={this.handleFieldChange('name')}
            />
            <CurrencyField
              className={classes.roomField}
              label="Room Price"
              value={selectedRoom.rate}
              onFieldChange={this.handleFieldChange('rate')}
            />
          </div>
          <div>
            <Select
              className={classes.roomField}
              label="Room Type"
              value={selectedRoom.type}
              onFieldChange={this.handleFieldChange('type')}
            >
              {roomTypes && roomTypes.map((type, index) =>
                <MenuItem key={index} value={type}>{type}</MenuItem>
              )}
            </Select>
            <Select
              className={classes.roomField}
              label="Rate Type"
              value={selectedRoom.rateType}
              onFieldChange={this.handleFieldChange('rateType')}
            >
              {rateTypes && rateTypes.map((type, index) =>
                <MenuItem key={index} value={type}>{type}</MenuItem>
              )}
            </Select>
          </div>
          <div className={classes.setupStyle}>
            <div className={classes.setupStyleHeader}>
              <div>
                Setup Styles
              </div>
              <HighlightButton
                aria-label="Add or Remove Setup Style"
                onClick={() => this.setState({ setupStylesModalOpen: true })}
              >
                <Exposure/>
              </HighlightButton>
            </div>
            <SetupStylesTable
              setupStyles={selectedRoom.roomSetupStyles}
              onSort={this.onSort}
            />
          </div>
          <div className={classes.saveBar}>
            <SaveBar
              onSave={() => this.saveRoom()}
              isSaveDisabled={this.isSaveDisabled()}
              onCancel={this.cancel}
              isSticky={true}
            />
          </div>
        </div>
        <SetupStylesModal
          setupStylesModalOpen={setupStylesModalOpen}
          onSetupStyleCancel={this.onSetupStyleCancel}
          roomSetupStyles={selectedRoom.roomSetupStyles.filter(rss => rss.recordStatus !== 'Deleted')}
          setupStyles={setupStyles}
          usedSetupStyles={usedSetupStyles}
          getAllSetupStyles={getAllSetupStyles}
          onSetupStyleSave={selectedSetupStyles => this.onSetupStyleSave(selectedSetupStyles)}
          onDeleteSetupStyle={this.onDeleteSetupStyle}
        />
      </div>
      <SimpleDialog innerRef={this.dialog} />
    </>;
  }
}

const mapStateToProps = state => {
  const {
    api: {
      rooms,
      setupStyles,
    },
  } = state;

  return {
    rooms,
    setupStyles,
  };
};

const mapDispatchToProps = {
  getRooms,
  createRoom,
  updateRoom,
  getAllSetupStyles,
  createSetupStyle,
  updateSetupStyle,
  deleteSetupStyle,
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CouponGrid)));
