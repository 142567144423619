import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  getAccountsForContact,
  deleteAccountContact,
  setAccountAsPrimary,
  addAccountContact,
} from 'actions/contact';
import { searchAccounts } from 'actions/account';
import SectionContext from 'Components/Contexts/SectionContext';
import AccountContact from 'accounts/AccountContact';
import SimpleDialog from 'Components/SimpleDialog';
import {
  ArrowDropDown,
  Add,
} from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import classNames from 'classnames';
import {
  getAccount,
} from 'actions/account';
import ComboBoxAsync from 'Components/ComboBoxAsync';
import QuickAccountModal from 'Components/QuickAccountModal';

const styles = theme => ({
  filterLabel: {
    color: theme.palette.grey[900],
    fontSize: 16,
  },
  filterRow: {
    display: 'flex',
    padding: 16,
    justifyContent: 'space-between',
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
});

class ContactAccountsPanel extends Component {
  state = {
    contactsOutsideAccount: [],
    isQuickContactOpened: false,
    editAccount: {},
    startAddAccount: false,
  };

  confirmDeleteFileDialog = React.createRef();

  componentDidMount() {
    this.getAccountsForContact();
  }

  getAccountsForContact = () => {
    this.props.getAccountsForContact(this.props.contactId).then(data => {
      this.setState({ accounts: data, total: data.length });
    });
  }

  onRemoveAccount = account => {
    this.confirmDeleteFileDialog.current.open().then(() => {
      this.props.deleteAccountContact(this.props.contactId, account.id).then(() => {
        this.getAccountsForContact();
      });
    });
  }

  onMarkPrimary = account => {
    this.props.primaryAccountUpdated(account);
    this.props.setAccountAsPrimary(this.props.contactId, account.id).then(() => {
      this.getAccountsForContact();
    });
  }

  onAccountQuickPick = accountToAdd => {
    this.setState({ startAddAccount: false });

    if (!accountToAdd || !accountToAdd.id) {
      return;
    }

    const {
      addAccountContact,
      contactId,
    } = this.props;

    addAccountContact(contactId, accountToAdd.id).then(() => {
      this.getAccountsForContact();
    });
  }

  onEditAccount = account => {
    this.props.getAccount(account.id).then(editAccount => {
      this.setState({
        isQuickContactOpened: true,
        editAccount,
      });
    });
  }

  onQuickAddAccount = payload => {
    this.setState({
      isQuickContactOpened: true,
      editAccount: { name: payload, primaryContactId: this.props.contactId },
    });
  }

  onLeaveAddContact = () => {
    this.setState({ startAddAccount: false });
  }

  closeQuickContactModal = () => {
    this.setState({
      isQuickContactOpened: false,
      editAccount: {},
    });
  }

  onStartAddContact = () => {
    this.setState({ startAddAccount: true });
  }

  refreshContacts = () => {
    this.closeQuickContactModal();
    this.getAccountsForContact();
  }

  render() {
    const { classes, searchAccounts } = this.props;
    const {
      accounts,
      total,
      isQuickContactOpened,
      editAccount,
      startAddAccount,
    } = this.state;

    return (

      <div className={classes.accountContacts}>
        {!startAddAccount && <div className={classes.filterRow}>
          <div className={classes.filterLabel}>
            Filter <ArrowDropDown />
          </div>
          <HighlightButton onClick={this.onStartAddContact}>
            <Add />
          </HighlightButton>
        </div>}
        {startAddAccount && <div>
          <ComboBoxAsync
            label="Add Account"
            autoFocus={true}
            onBlur={this.onLeaveAddContact}
            getData={searchAccounts}
            autoLoadOptions={true}
            resultCount={5}
            onSelect={this.onAccountQuickPick}
            onCreateOption={this.onQuickAddAccount}
          />
        </div>}
        <div className={classNames(classes.filterLabel, classes.filterRow, classes.borderBottom)}>
          Accounts ({total})
        </div>
        {accounts && accounts.map((account, index) => (
          <AccountContact
            onRemove={this.onRemoveAccount}
            onMarkPrimary={this.onMarkPrimary}
            onEdit={this.onEditAccount}
            key={account.id}
            item={account}
            isPrimary={index === 0}
            baseUrl="/accounts/"
          />))}
        <SimpleDialog
          message="Are you sure you remove the account from this contact?"
          innerRef={this.confirmDeleteFileDialog}
        />
        <QuickAccountModal
          isOpened={isQuickContactOpened}
          account={editAccount}
          onCancel={this.closeQuickContactModal}
          onClose={this.refreshContacts}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getAccountsForContact,
  deleteAccountContact,
  setAccountAsPrimary,
  addAccountContact,
  getAccount,
  searchAccounts,
};

//can storybook this guy
export const ContactAccountsPanelWithNoContext = withStyles(styles)(ContactAccountsPanel);

export default connect(undefined, mapDispatchToProps)(props => {
  const {
    relationship: { entityId },
  } = useContext(SectionContext);

  return (
    <ContactAccountsPanelWithNoContext
      contactId={entityId}
      {...props}
    />
  );
});
