import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

export const getRevenueTypes = () => async dispatch =>
  dispatch(callApi('RevenueType'))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results));

export const getRevenueType = RevenueTypeId => async dispatch =>
  dispatch(callApi(`RevenueType/${RevenueTypeId}`))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

export const updateRevenueType = RevenueType => async dispatch =>
  dispatch(callApi(`RevenueType/${RevenueType.id}`, { body: RevenueType, method: 'PUT' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

export const createRevenueType = RevenueType => async dispatch =>
  dispatch(callApi(`RevenueType`, { body: RevenueType, method: 'POST' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

export const deleteRevenueType = RevenueType => async dispatch =>
  dispatch(callApi(`RevenueType/${RevenueType.id}`, { body: RevenueType, method: 'DELETE' }))
    .catch(console.error);

export const transitionRevenueType = (fromRevenueTypeId, toRevenueTypeId) => async dispatch =>
  dispatch(callApi(`RevenueType/${fromRevenueTypeId}/TransitionTo/${toRevenueTypeId}`, { method: 'POST' }))
    .catch(console.error);
