import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import LargeBarChartWidget from 'Components/GraphWidgets/LargeBarChartWidget';
import CardStyledGraphWidget from 'Components/GraphWidgets/CardStyledGraphWidget';
import { getDashboardData } from 'actions/order';
import { getSettingAreas } from 'actions/settings';
import { Paper, withStyles } from '@material-ui/core';
import QuickOrderStatus from './quickOrderStatus';
import { getStatuses } from 'actions/settings';
import ConfirmationBannerAutoClose from 'Components/ConfirmationBannerAutoClose';
import DataLoader from 'Components/DataLoader';

const customerTypes = [
  {
    id: 1,
    name: 'All',
    amount: 398,
  },
  {
    id: 2,
    name: 'Meeting',
    amount: 152,
  },
  {
    id: 3,
    name: 'Banquet',
    amount: 54,
  },
  {
    id: 4,
    name: 'Wedding Reception',
    amount: 124,
  },
  {
    id: 5,
    name: 'Seminar',
    amount: 9,
  },
  {
    id: 6,
    name: 'Retirement',
    amount: 3,
  },
  {
    id: 7,
    name: 'Birthday Party',
    amount: 28,
  },
  {
    id: 8,
    name: 'Office Party',
    amount: 17,
  },
  {
    id: 9,
    name: 'Convention',
    amount: 32,
  },
  {
    id: 10,
    name: 'Wedding Ceremony',
    amount: 34,
  },
  {
    id: 11,
    name: 'Luncheon',
    amount: 25,
  },
  {
    id: 12,
    name: 'Delivery',
    amount: 10,
  },
];

const styles = theme => ({
  leftContainer: {
    flexGrow: 1,
  },
  rightContainer: {
    minWidth: 165,
    maxWidth: 275,
  },
  spaceRight: {
    marginRight: '1%',
    [theme.breakpoints.up('lg')]: {
      marginRight: 30,
    },
  },
  mainContent: {
    marginBottom: 30,
  },
  lowerContent: {
    display: 'flex',
    width: '100%',
    flex: '0 1 auto',
    flexWrap: 'wrap',
    marginBottom: 30,
  },
  card: {
    padding: 16,
    width: '100%',
  },
  cardchart: {
    flexGrow: 1,
  },
  cardrow: {
    display: 'flex',
  },
  cardpanel: {
    display: 'flex',
    overflow: 'auto',
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    padding: '24px 24px 0',
  },
  cardheading: {
    fontSize: 18,
    padding: '23px 30px',
  },
  chartContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  loader: {
    left: '50%',
    top: '50%',
  },
});

export class HomeSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mockSeed: customerTypes[0].amount,
      salesdata: [],
      series: [{
        name: 'World',
        data: [],
      }],
      last30days: [],
      totalRevenue: 0,
      totalOrders: -1,
      totalMenuItems: 0,
      itemsOrdered: [],
      ordersStatuses: [],
      statusPickup: [],
      statusDelivery: [],
      sumTotalDelivery: 0,
      sumTotalPickup: 0,
      filter: {},
    };
    this.date = moment();

  };

  statusBanner = React.createRef();

  componentDidMount() {
    this.props.getStatuses();
    this.props.getSettingAreas();

    this.loadDashboardData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.dashboardData !== prevProps.dashboardData) {
      this.prepareData();
    }
  }

  loadDashboardData = () => {
    this.props.getDashboardData('last24hours', '');
  }

  prepareData = () => {
    if (!this.props.dashboardData)
      return;
    const { orderTotals, previousPeriodOrderTotals } = this.props.dashboardData;

    const groups = Array.isArray(orderTotals) ? orderTotals.map(ot => ot.groupName) : [];
    const orderTotalsTrend = Array.isArray(orderTotals) ? orderTotals.map(ot => ot.total) : [];
    const pickupOrderTotalsTrend = Array.isArray(orderTotals) ? orderTotals.map(ot => ot.pickupTotal) : [];
    const deliveryOrderTotalsTrend = Array.isArray(orderTotals) ? orderTotals.map(ot => ot.deliveryTotal) : [];
    const averageOrderTotalsTrend = Array.isArray(orderTotals) ? orderTotals.map(ot => ot.averageOrder) : [];
    const orderCountTrend = Array.isArray(orderTotals) ? orderTotals.map(ot => ot.count) : [];

    const compareOrderTotalsTrend = Array.isArray(orderTotals) ? previousPeriodOrderTotals.map(ot => ot.total) : [];
    const comparePickupOrderTotalsTrend = Array.isArray(orderTotals) ? previousPeriodOrderTotals.map(ot => ot.pickupTotal) : [];
    const compareDeliveryOrderTotalsTrend = Array.isArray(orderTotals) ? previousPeriodOrderTotals.map(ot => ot.deliveryTotal) : [];
    const compareAverageOrderTotalsTrend = Array.isArray(orderTotals) ? previousPeriodOrderTotals.map(ot => ot.averageOrder) : [];
    const compareOrderCountTrend = Array.isArray(orderTotals) ? previousPeriodOrderTotals.map(ot => ot.count) : [];

    this.setState({
      groups,
      orderTotalsTrend,
      pickupOrderTotalsTrend,
      deliveryOrderTotalsTrend,
      averageOrderTotalsTrend,
      orderCountTrend,
      compareOrderTotalsTrend,
      comparePickupOrderTotalsTrend,
      compareDeliveryOrderTotalsTrend,
      compareAverageOrderTotalsTrend,
      compareOrderCountTrend,
      previousPeriodTrend: this.props.dashboardData.trend,
    });
  }

  AddStatusToDictionary = (ordersStatuses, order) => {
    const indexOfItem = ordersStatuses.findIndex(orderstatus =>
      orderstatus.name === order.orderStatus
    );

    if (indexOfItem > -1) {
      ordersStatuses[indexOfItem].count += 1;
      ordersStatuses[indexOfItem].total += order.total;
    } else {
      ordersStatuses.push({ id: order.orderStatusId, name: order.status, count: 1, total: order.total });
    }
  }

  AddItemToDictionary = (itemsOrdered, item) => {
    const indexOfItem = itemsOrdered.findIndex(dictionaryitem =>
      dictionaryitem.id === item.menuCategoryItemId
    );

    if (indexOfItem > -1) {
      itemsOrdered[indexOfItem].count += 1;
      itemsOrdered[indexOfItem].price += item.price;
    } else {
      itemsOrdered.push({ id: item.menuCategoryItemId, name: item.name, count: 1, price: item.price });
    }
  }

  getSalesData = (mockSeed, date) => {
    this.props.getSalesData(mockSeed, date).then(newSalesData => {
      const categories = [];

      for (var i = 1; i <= newSalesData.length; i++) {
        categories.push(i);
      }
      this.setState({
        mockSeed: mockSeed,
        series: [{
          name: 'World',
          data: newSalesData,
        }],
        categories,
      });
    });
  }

  prepareTotalFromTrend = trend => {
    return trend.reduce((accumulator, number) => accumulator + number, 0);
  }

  filterChanged = filter => {
    console.log(filter);

    let statusFilter = '';
    let type = '';
    let total = '';

    if (filter.Status) {
      statusFilter = ` and OrderStatus/Name eq '${filter.Status}'`;
    }

    if (filter.filterDelivery) {
      type = ` and Type eq 'Delivery'`;
    }

    if (filter.filterPickup) {
      type = ` and Type eq 'Pickup'`;
    }

    if (filter.useMinMax) {
      total = ` and Total ge ${filter.minSpend} and Total le ${filter.maxSpend}`;
    }

    const oDataFilter = `$filter= 1 eq 1 ${statusFilter} ${type} ${total}`;

    this.props.getDashboardData(filter.dateRange, oDataFilter);
  }

  handleBannerConfirm = () => {

  }

  render() {
    const {
      classes,
      theme,
      statuses,
    } = this.props;

    const {
      groups,
      orderTotalsTrend,
      pickupOrderTotalsTrend,
      deliveryOrderTotalsTrend,
      averageOrderTotalsTrend,
      orderCountTrend,
      previousPeriodTrend,
      compareOrderTotalsTrend,
      comparePickupOrderTotalsTrend,
      compareDeliveryOrderTotalsTrend,
      compareAverageOrderTotalsTrend,
      compareOrderCountTrend,
    } = this.state;

    return (
      <div>
        <div>
          {orderCountTrend && orderCountTrend.length >= 0 ?
            <div className={classes.cardpanel}>
              <div className={classNames(classes.leftContainer, classes.spaceRight)}>
                <Paper className={classes.mainContent}>
                  <div className={classes.cardheading}>Overview</div>
                  <div className={classes.chartContainer}>
                    <LargeBarChartWidget series={[{ name: 'totals', data: orderTotalsTrend }]} datacategories={groups} valuePrefix='$' />
                  </div>
                </Paper>
                <div className={classes.cardrow}>
                  <div className={classNames(classes.cardchart, classes.spaceRight)}>
                    <CardStyledGraphWidget
                      heading="Total # of Order"
                      number={this.prepareTotalFromTrend(orderCountTrend)}
                      percent={previousPeriodTrend && previousPeriodTrend.count}
                      graphdata={orderCountTrend}
                      graphcolor={theme.common.blue}
                      compare={compareOrderCountTrend}
                      compareColor={theme.common.purple}
                    />
                  </div>
                  <div className={classNames(classes.cardchart, classes.spaceRight)}>
                    <CardStyledGraphWidget
                      heading="Order Revenue"
                      isCurrency={true}
                      number={this.prepareTotalFromTrend(orderTotalsTrend)}
                      percent={previousPeriodTrend && previousPeriodTrend.total}
                      graphcolor={theme.common.blue}
                      graphdata={orderTotalsTrend}
                      compare={compareOrderTotalsTrend}
                      compareColor={theme.common.purple}
                    />
                  </div>
                  <div className={classNames(classes.cardchart, classes.spaceRight)}>
                    <CardStyledGraphWidget
                      heading="Average Order Sale"
                      isCurrency={true}
                      number={this.prepareTotalFromTrend(orderCountTrend) > 0 ? this.prepareTotalFromTrend(orderTotalsTrend) / this.prepareTotalFromTrend(orderCountTrend) : 0}
                      percent={previousPeriodTrend && previousPeriodTrend.averageOrder}
                      graphdata={averageOrderTotalsTrend}
                      graphcolor={theme.common.blue}
                      compare={compareAverageOrderTotalsTrend}
                      compareColor={theme.common.purple}
                    />
                  </div>
                  <div className={classNames(classes.cardchart, classes.spaceRight)}>
                    <CardStyledGraphWidget
                      heading="Pickup Total"
                      isCurrency={true}
                      number={this.prepareTotalFromTrend(pickupOrderTotalsTrend)}
                      percent={previousPeriodTrend && previousPeriodTrend.pickupTotal}
                      graphdata={pickupOrderTotalsTrend}
                      graphcolor={theme.common.blue}
                      compare={comparePickupOrderTotalsTrend}
                      compareColor={theme.common.purple}
                    />
                  </div>
                  <div className={classNames(classes.cardchart, classes.spaceRight)}>
                    <CardStyledGraphWidget
                      heading="Delivery Total"
                      isCurrency={true}
                      number={this.prepareTotalFromTrend(deliveryOrderTotalsTrend)}
                      percent={previousPeriodTrend && previousPeriodTrend.deliveryTotal}
                      graphdata={deliveryOrderTotalsTrend}
                      graphcolor={theme.common.blue}
                      compare={compareDeliveryOrderTotalsTrend}
                      compareColor={theme.common.purple}
                    />
                  </div>

                </div>
              </div>
              <div className={classes.rightContainer}>
                {!!statuses && <QuickOrderStatus
                  statusDelivery={statuses ? statuses.find(s => s.type === 'DeliveryOrder').statuses : []}
                  statusPickup={statuses ? statuses.find(s => s.type === 'PickupOrder').statuses : []}
                  onFilterChanged={this.filterChanged}
                />}
              </div>
            </div>
            :
            <div className={classes.loader}>
              <DataLoader isLoading={true} />
            </div>
          }
        </div>
        <div>
          <ConfirmationBannerAutoClose
            innerRef={this.statusBanner}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      dashboardData,
    },
    api: {
      tasks,
    },
    settings: {
      statuses,
    },
  } = state;

  return {
    tasks,
    statuses,
    dashboardData,
  };
};

const mapDispatchToProps = {
  getDashboardData,
  getStatuses,
  getSettingAreas,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(HomeSales));
