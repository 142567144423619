import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AddressFields from 'Components/AddressFields';
import AddressSearch from 'Components/AddressSearch';
import Map from 'Components/Map';
import { fitBounds } from 'google-map-react';
import classNames from 'classnames';
import { handleAddressSearchDone } from 'shared/GoogleApi';
import SimpleDialog from 'Components/SimpleDialog';

const styles = theme => ({
  masonry: {
    display: 'flex',
    flex: 1,
    padding: '8px 0',
  },
  flex: {
    display: 'flex',
    flex: 1,
  },
  flexColumn: {
    flexDirection: 'column',
  },
  fullWidth: {
    display: 'flex',
    width: '100%',
  },
  halfWidth: {
    display: 'flex',
    width: '50%',
  },
  mapBuffer: {
    display: 'flex',
    width: '50%',
  },
  mapContainer: {
    height: '264px',
    margin: '16px 16px 8px 25px',
  },
  fieldIsEdited: {
    background: theme.palette.error.light,
  },
});

class AddressFormWithMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statesMap: {},
      mapAddress: [],
      latLng: this.getLatLng(),
      addressParts: {
        address1Number: null,
        address1Route: null,
      },
    };

    this.mapContainer = React.createRef();
    this.addressReplacingDialog = React.createRef();
  }

  componentDidMount() {
    this.updateMap();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entityId !== this.props.entityId) {
      this.updateMap();
    }
  }

  updateMap = () => {
    // No Map in Quick Add
    if (!this.props.isQuickAdd) {
      const zoom = 11;
      const bounds = {
        ne: {
          lat: 48,
          lng: -77,
        },
        sw: {
          lat: 31,
          lng: -123,
        },
      };
      const size = {
        width: this.mapContainer.current.clientWidth,
        height: this.mapContainer.current.clientHeight,
      };
      const { center } = fitBounds(bounds, size);
      const latLng = this.getLatLng();

      this.setState({ center, zoom, latLng });
    }
  }

  getLatLng = () => {
    const { address } = this.props;

    return {
      lat: address ? address.latitude : null,
      lng: address ? address.longitude : null,
    };
  }

  onAddressSearchDone = selection => {
    const { onFieldChange, address } = this.props;

    const hasAddress = !!address && Object.keys(address).some(fieldKey => fieldKey !== 'id' && !!address[fieldKey]);

    if (!!selection && hasAddress) {
      const { addressReplacingDialog } = this;

      addressReplacingDialog && addressReplacingDialog.current && addressReplacingDialog.current
        .open('Do you want to replace the existing address?')
        .then(() => {
          handleAddressSearchDone(selection, onFieldChange);
        });
    } else {
      handleAddressSearchDone(selection, onFieldChange); // Google Service will use local onFieldChange after parsing results
    }
  }

  render() {
    const { classes, address, isEditing, isFieldChanged, isQuickAdd, onFieldChange } = this.props;

    return (<>
      <div className={classes.masonry}>
        <div className={classNames(classes.flex, classes.flexColumn)}>
          <div className={classes.fullWidth}>
            {isEditing &&
              <AddressSearch
                onSelection={this.onAddressSearchDone}
              />
            }
          </div>
          <div className={classNames(isQuickAdd && classes.flex)}>
            <AddressFields
              address={address || {}}
              onFieldChange={onFieldChange}
              isFieldChanged={isFieldChanged}
              isEditing={isEditing}
            />
          </div>
        </div>
        {!isQuickAdd &&
          <div className={classNames(classes.flex, classes.mapBuffer)}>
            <div className={classNames(classes.mapContainer, classes.fullWidth)} ref={this.mapContainer}>
              <Map
                zoom={10}
                locations={[address]}
                selectedLocation={address}
              />
            </div>
          </div>
        }
      </div>
      <SimpleDialog innerRef={this.addressReplacingDialog} />
    </>);
  }
}

export default withStyles(styles)(AddressFormWithMap);
