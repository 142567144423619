import React, { Component, forwardRef } from 'react';
import GoogleMapReact from 'google-map-react';
import PlaceIcon from '@material-ui/icons/Place';
import { withStyles } from '@material-ui/core/styles';
import { apiKey } from 'shared/GoogleApi';

const Marker = withStyles({}, { withTheme: true })(({ theme }) => {
  const style = {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.error.main,
  };

  return <PlaceIcon style={style} />;
});

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiLoaded: false,
    };
    this.map = {};
    this.maps = {};
    this.geocoder = {};
    this.mapContainer = React.createRef();
  }

  static defaultProps = {
    center: {
      lat: 39,
      lng: -98,
    },
    zoom: 10,
  };

  componentDidUpdate(prevProps) {
    const { locations, selectedLocation } = this.props;

    if (prevProps.locations !== locations) {
      this.updateMap();
    }
    if (prevProps.selectedLocation !== selectedLocation) {
      this.updateMap();
    }
  }

  createMapOptions = maps => {
    return {
      // mapTypeControl: true, // Set true to enable Satellite/Map toggle
      scrollwheel: false,
      keyboardShortcuts: false,
    };
  }

  handleApiLoaded = locations => ({ map, maps }) => {
    this.geocoder = new maps.Geocoder();
    this.marker = new maps.Marker();
    this.map = map;
    this.setState({ isApiLoaded: true });

    this.updateMap(locations);
  }

  updateMap = () => {
    const { locations, selectedLocation } = this.props;
    const { isApiLoaded } = this.state;

    if (isApiLoaded && locations && locations.length) {
      const focusedLocation = selectedLocation || locations[0];

      if (focusedLocation && focusedLocation.latitude && focusedLocation.longitude) {
        const center = { lat: focusedLocation.latitude, lng: focusedLocation.longitude };

        this.setState({ center });
        this.marker.map = this.map;
        this.marker.position = center;

        return;
      }
      const address = !!focusedLocation && !!focusedLocation.address1 ? focusedLocation.address1 : '';

      if (this.geocoder && this.geocoder.geocode) {
        this.geocoder.geocode({ 'address': address }, (results, status) => {
          if (status === 'OK') {
            const geocoded = results[0].geometry.location;

            this.setState({
              center: {
                lat: geocoded.lat(),
                lng: geocoded.lng(),
              },
            });
            this.marker.map = this.map;
            this.marker.position = geocoded;
          } else {
            console.warn('Geocode was not successful for the following reason: ' + status);
          }
        });
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    this.updateMap([]);
  };

  //37.1654 -113.1743
  render() {
    const { locations, center, zoom } = this.props;

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          options={this.createMapOptions}
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={center}
          center={this.state.center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.handleApiLoaded(locations)}
        >

          {locations && locations.map(location =>
            location && location.latitude && location.longitude &&
            <Marker
              key={location.id}
              lat={parseFloat(location.latitude)}
              lng={parseFloat(location.longitude)}
            />
          )}

        </GoogleMapReact>
      </div>
    );
  }
}

export default forwardRef((props, ref) => {
  return <Map {...props} forwardRef={ref} />;
});
