import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from 'Components/Avatar';
import Button from '@material-ui/core/Button';
import {
  clearSearch,
} from 'actions/search';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  avatar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.common.bluegrey,
    fontSize: 30,
    fontWeight: '600',
    minWidth: '70px',
    maxWidth: '70px',
    height: 45,
    color: theme.palette.grey['50'],
    borderRadius: 5,
  },
  contactsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    width: '100%',
    height: '70px',
  },
  contact: {
    textTransform: 'none',
    width: '350px',
  },
  noResults: {
    paddingLeft: '20px',
    fontSize: '0.9rem',
    color: '#65666c',
    fontWeight: '100',
  },
});

class ContactSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAddress: null,
      addressParts: {},
    };
  }

  noResults() {
    const { classes } = this.props;

    return (<Typography className={classes.noResults} variant="subtitle1" gutterBottom>No contacts found...</Typography>);
  }

  renderContactResults() {
    const { classes, items } = this.props;

    return (
      <div className={classes.contactsContainer}>
        {items.map(item => (
          <Button key={`contact-${item.id}`} className={classes.contact} component="a" onClick={() => this.props.clearSearch()} href={`/contacts/${item.id}`}>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} name={item.name} />
                }
                title={item.name}
                subheader={item.email}
              />
            </Card>
          </Button>
        ))}
      </div>
    );
  }

  render() {
    const { items } = this.props;

    return items.length > 0 ? this.renderContactResults() : this.noResults();
  }
}

const mapStateToProps = state => {
  const {
    search: {
      searchResults: {
        contacts: {
          items,
        },
      },
    } } = state;

  return {
    items,
  };
};

const mapDispatchToProps = {
  clearSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContactSearchResults));
