import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { provideIntlService } from '@progress/kendo-react-intl';

import {
  Card,
} from '@material-ui/core';

const styles = theme => ({
  label: {
  },
  subLabel: {
  },
  financialSectionTitle: {
    padding: 5,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '18px',
    backgroundColor: '#F2F2F2',
    height: '35px',
    borderBottom: '1px solid #D1DBE2',
  },
  financial: {
    width: 300,
    display: 'flex',
    fontSize: '15px',
    flexDirection: 'column',
  },
  detailsContainer: {
    display: 'flex',
    width: '100%',
    padding: 15,
    justifyContent: 'center',
    backgroundColor: '#F2F2F2',
  },
  field: {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  labelBalanceDue: {
    fontSize: '15px',
    color: '#2189F8',
  },
  cardRoot: {
    display: 'flex',
    color: theme.palette.text.primary,
    margin: 5,
    padding: 10,

  },
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    padding: 8,
  },
  totals: {
    padding: 10,
  },
  field2: {
    display: 'flex',
    marginBottom: '10px',
    justifyContent: 'space-between',
    borderTop: '1px solid #F2F2F2',
    paddingTop: '15px',
  },
});

class FinancialsSummary extends Component {
  render() {
    const {
      classes,
      storeOrder,
    } = this.props;

    this.formatter = provideIntlService(this);

    const totalValue = storeOrder.tip + storeOrder.total;
    const totalServiceCharge = storeOrder.serviceCharge + (storeOrder.perItemServiceChargeOverride || storeOrder.perItemServiceCharge);
    const deliveryCharge = !!storeOrder.orderDelivery ? storeOrder.orderDelivery.deliveryCharge : 0;

    return (<>
      <div className={classes.detailsContainer}>
        <Card className={classes.cardRoot}>
          <div className={classes.financial}>
            <div className={classes.field}>
              <div className={classes.financialSectionTitle}>Financials Summary</div>
            </div>
            <div className={classes.totals}>
              <div className={classes.field}>
                <div className={classes.subLabel}>Order Subtotal</div>
                <div>{this.formatter.formatNumber(storeOrder.subTotal, 'c')}</div>
              </div>
              {!!storeOrder.orderEventDetails && !!storeOrder.orderEventDetails.banquetRoomRate &&
                <div className={classes.field}>
                  <div className={classes.subLabel}>Room Rate</div>
                  <div>{this.formatter.formatNumber(storeOrder.orderEventDetails.banquetRoomRate, 'c')}</div>
                </div>
              }
              <div className={classes.field}>
                <div className={classes.subLabel}>Service Charge</div>
                <div>{this.formatter.formatNumber(totalServiceCharge, 'c')}</div>
              </div>
              <div className={classes.field}>
                <div className={classes.subLabel}>Gratuity</div>
                <div>{this.formatter.formatNumber(storeOrder.gratuity, 'c')}</div>
              </div>
              <div className={classes.field}>
                <div className={classes.subLabel}>Tax</div>
                <div>{this.formatter.formatNumber(storeOrder.taxTotal, 'c')}</div>
              </div>
              <div className={classes.field}>
                <div className={classes.subLabel}>Tip</div>
                <div>{this.formatter.formatNumber(storeOrder.tip, 'c')}</div>
              </div>
              {!!deliveryCharge &&
                <div className={classes.field2}>
                  <div className={classes.subLabel}>Delivery Charge</div>
                  <div>{this.formatter.formatNumber(deliveryCharge, 'c')}</div>
                </div>
              }
              <div className={classes.field2}>
                <div className={classes.subLabelTotal}>Total</div>
                <div className={classes.subLabelTotal}>{this.formatter.formatNumber(totalValue, 'c')}</div>
              </div>
              {!!storeOrder.couponDiscount &&
                <div className={classes.field}>
                  <div className={classes.subLabel}>Discount</div>
                  <div>{this.formatter.formatNumber(storeOrder.couponDiscount, 'c')}</div>
                </div>
              }
              <div className={classes.field2}>
                <div className={classes.subLabel}>Total Paid</div>
                <div>{this.formatter.formatNumber(storeOrder.paid, 'c')}</div>
              </div>
              <div className={classes.field}>
                <div className={classes.labelBalanceDue}>Balance Due</div>
                <div className={classes.labelBalanceDue}>{this.formatter.formatNumber(storeOrder.balanceDue, 'c')}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>);
  }
}

export default withStyles(styles)(FinancialsSummary);
