import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import RevenueTypeRateGrid from './RevenueTypeRateGrid';
import RevenueTypeRateModal from './RevenueTypeRateModal';
import {
  getRevenueTypeRatesByRevenueConfiguration,
  updateRevenueTypeRate,
} from 'actions/RevenueTypeRates';

const styles = () => ({
  taxSchedule: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    flex: 1,
  },
  name: {
    display: 'flex',
    width: '65%',
  },
  lower: {
    display: 'flex',
    padding: '24px 16px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  topButtons: {
    display: 'flex',
  },
  button: {
    display: 'flex',
    margin: 8,
  },
  addButton: {
    marginTop: 32,
  },
  primary: {
    marginLeft: -10,
  },
});

const RevenueTypeRatesManager = ({ classes, getRevenueTypeRatesByRevenueConfiguration, updateRevenueTypeRate }) => {
  const [revenueTypeRates, setRevenueTypeRates] = useState([]);
  const [revenueTypeRateEdit, setRevenueTypeRateEdit] = useState(null);

  useEffect(() => {
    getRevenueTypesRatesApi();
  }, []);

  async function getRevenueTypesRatesApi() {
    var dbRevenueTypes = await getRevenueTypeRatesByRevenueConfiguration(1);

    setRevenueTypeRates(dbRevenueTypes);
  }

  function onEdit(revenueTypeRate) {
    setRevenueTypeRateEdit(revenueTypeRate);
  }

  function closeModal() {
    setRevenueTypeRateEdit(null);
    getRevenueTypesRatesApi();
  }

  return revenueTypeRates && (
    <div className={classes.financialConfig}>
      <div className={classes.main}>
        <div className={classes.content}>
          <div className={classes.lower}>
            <div className={classes.tableContainer}>
              <RevenueTypeRateGrid
                revenueTypeRates={revenueTypeRates}
                onEdit={onEdit}
              />
            </div>
          </div>
        </div>
      </div>
      {revenueTypeRateEdit && <RevenueTypeRateModal revenueTypeRate={revenueTypeRateEdit} onCloseModal={closeModal} />}
    </div>
  );
};

const mapDispatchToProps = {
  getRevenueTypeRatesByRevenueConfiguration,
  updateRevenueTypeRate,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(RevenueTypeRatesManager));
