import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  MenuItem,
} from '@material-ui/core';
import Select from 'Components/Select';
import { CurrencyField } from 'Components/currencyInput';
import classNames from 'classnames';
import WhiteButton from 'Components/Buttons/WhiteButton';
import PaymentGatewayModal from 'Components/PaymentGatewayModal';

const couponErrorMessage = 'Invalid or Inactive Coupon';

const styles = theme => ({
  contentSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    maxHeight: 180,
    color: theme.common.black,
    fontSize: 15,
  },
  inputLabel: {
    color: '#8b8b8b',
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    margin: '5px 0',
  },
  contentInput: {
    flexGrow: 'initial',
    width: '40%',
  },
  cashInput: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardPayment: {
    textAlign: 'center',
  },
  tipInput: {
    width: '60%',
    flexGrow: 'initial',
    margin: 0,
    padding: 0,
  },
  amountInput: {
    margin: 0,
  },
  select: {
    margin: 0,
  },
  paymentButton: {
    height: 32,
    minWidth: 150,
    margin: 'auto 0',
    transition: 'visibility 0s, opacity 0.5s linear',
  },
  paymentButtonCash: {
    minWidth: 'initial',
    width: '30%',
    flexGrow: 'initial',
  },
  hidden: {
    visibility: 'hidden',
  },
  couponContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '5px 0 10px',
  },
  contentCell: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '40%',
  },
  contentCouponInput: {
    width: '60%',
  },
  couponButtonContainer: {
    width: '30%',
    marginTop: 8,
  },
  couponButton: {
    height: 32,
    width: '100%',
    margin: 'auto 0',
  },
  couponError: {
    color: theme.palette.error.main,
    marginTop: 2,
    whiteSpace: 'nowrap',
  },
});

class PaymentMethodDetails extends Component {
  state = {
    paymentMethod: 'Cash',
    paymentOpen: false,
    balanceDue: 0,
    totalTip: 0,
    showToOrderButton: false,
    couponCode: '',
    couponError: false,
  };

  openPayment = () => {
    this.setState({ showToOrderButton: false, paymentOpen: true });
  }

  addPayment = () => {
    const { order, user, addOrderPayment } = this.props;
    const { balanceDue, paymentMethod, totalTip } = this.state;
    const newPayment = {
      totalPaid: balanceDue,
      totalTip,
      paymentMethod,
      dateApplied: new Date(),
      receivedBy: user.profile.name,
      madeBy: user.profile.name,
      orderId: order.id,
    };

    addOrderPayment(newPayment)
      .then(() => {
        this.paymentSuccess();
        this.setState({ totalTip: 0 });
      });
  }

  openCashPayment = () => {
    this.addPayment();
  }

  paymentSuccess = () => {
    const { paymentSuccess, closeOnSuccess } = this.props;

    if (closeOnSuccess) {
      this.closePayment();
    }

    setTimeout(() => {
      this.setState({ showToOrderButton: true });
    }, 300);

    if (paymentSuccess) {
      paymentSuccess();
    }
  }

  closePayment = () => {
    this.setState({ paymentOpen: false });
  }

  handleFieldChange = (fieldName, parser) => value => {
    this.setState({ [fieldName]: parser ? parser(value) : value });
  }

  couponClick = async () => {
    const { applyCoupon, removeCoupon, order } = this.props;
    const { couponCode } = this.state;
    const isRemoveCoupon = !!order.coupon && !!order.coupon.code && couponCode === order.coupon.code;

    if (isRemoveCoupon) {
      await removeCoupon(order.id);
      this.setState({ couponError: false });
    } else {
      const updatedOrder = await applyCoupon(order.id, couponCode);
      const invalidCoupon = updatedOrder === null;

      this.setState({
        couponCode: invalidCoupon
          ? !order.coupon
            ? ''
            : order.coupon.code
          : updatedOrder.coupon.code,
        couponError: invalidCoupon ? true : false,
      });
    }
  }

  couponOnChange = event => {
    const couponCode = event.target.value;

    if (couponCode.length > 20) {
      return;
    }

    this.setState({ couponCode, couponError: false });
  }

  setCouponCode = () => {
    const { order } = this.props;

    this.setState({ couponCode: !!order && !!order.coupon && !!order.coupon.code ? order.coupon.code : '' });
  }

  componentDidMount() {
    const { balanceDue } = this.props.order;

    this.setState({ balanceDue });
    this.setCouponCode();
  }

  componentDidUpdate(prevProps, prevState) {
    const { balanceDue } = this.props.order;
    const { paymentMethod } = this.state;

    if (prevProps.order.balanceDue !== balanceDue
      || prevState.paymentMethod !== paymentMethod) {
      this.setState({ balanceDue });
    }

    if (prevProps.order.coupon !== this.props.order.coupon) {
      this.setCouponCode();
    }
  }

  render() {
    const { paymentMethod, paymentOpen, balanceDue, totalTip, couponCode, couponError } = this.state;
    const { classes, className, user, order, readOnly } = this.props;

    const cardPayment = paymentMethod === 'Credit Card';

    return (
      <div className={classNames(className, classes.contentSection)}>
        <div className={classes.contentRow}>
          <Select
            label="Payment Method"
            value={paymentMethod}
            onFieldChange={value => this.setState({ paymentMethod: value })}
            className={`${classes.select} ${classes.contentInput}`}
            InputLabelProps={{ className: classes.inputLabel }}
            disabled={readOnly}
          >
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="Credit Card">Credit Card</MenuItem>
          </Select>
          <CurrencyField
            className={classNames(classes.amountInput, classes.contentInput)}
            label="Amount"
            value={balanceDue}
            InputProps={{ readOnly: cardPayment ? true : false }}
            onFieldChange={this.handleFieldChange('balanceDue', parseFloat)}
            InputLabelProps={{ className: classes.inputLabel }}
            disabled={readOnly}
          />
        </div>
        <div className={classes.contentRow}>
          <TextField
            className={classes.contentInput}
            label="Received By"
            value={user.profile.name}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ className: classes.inputLabel }}
            disabled={readOnly}
          />
          {cardPayment &&
            <div className={classNames(classes.contentInput, classes.cardPayment)}>
              <WhiteButton
                className={classNames(classes.paymentButton, { [classes.hidden]: !balanceDue })}
                onClick={this.openPayment}
              >
                Process payment
              </WhiteButton>
            </div>
          }
          {!cardPayment &&
            <div className={classNames(classes.contentInput, classes.cashInput)}>
              <CurrencyField
                className={classes.tipInput}
                label="Tip"
                value={totalTip}
                onFieldChange={this.handleFieldChange('totalTip', parseFloat)}
                InputLabelProps={{ className: classes.inputLabel }}
                disabled={readOnly}
              />
              <WhiteButton
                className={classNames(classes.paymentButton, classes.paymentButtonCash, { [classes.hidden]: !balanceDue && !totalTip }, { [classes.hidden]: readOnly })}
                onClick={this.openCashPayment}
              >
                Add
              </WhiteButton>
            </div>
          }
        </div>
        <div className={classes.couponContainer}>
          <div className={classes.contentCell}>
            <TextField
              className={classes.contentCouponInput}
              label="Coupon Code"
              value={couponCode}
              InputProps={{ maxLength: 20 }}
              InputLabelProps={{ className: classes.inputLabel }}
              onChange={this.couponOnChange}
              disabled={readOnly} />
            <div className={readOnly ? classNames(classes.couponButtonContainer, classes.hidden) : classes.couponButtonContainer}>
              <WhiteButton
                className={classes.couponButton}
                onClick={this.couponClick}
                disabled={!couponCode}
              >
                {!!order.coupon && !!order.coupon.code && order.coupon.code === couponCode ? 'Remove' : 'Apply'}
              </WhiteButton>
              <div className={classes.couponError} style={couponError ? {} : { visibility: 'hidden' }}>
                {couponErrorMessage}
              </div>
            </div>
          </div><div className={classes.contentCell}></div>
        </div>
        <PaymentGatewayModal
          isOpen={paymentOpen}
          amount={balanceDue}
          closePayment={this.closePayment}
          paymentSuccess={this.paymentSuccess}
          showToOrderButton={this.state.showToOrderButton}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    readOnly: state.settings.allAreaSettings.ENABLE_CATEREASE_INTEGRATION
      ? state.settings.allAreaSettings.ENABLE_CATEREASE_INTEGRATION.value
      : true,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(PaymentMethodDetails));
