import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import NotesPanel from 'Components/NotesPanel';
import ActivityPanel from 'Components/ActivityPanel';
import ContactAccountsPanel from './ContactAccountsPanel';
import FilesPanel from 'Components/FilesPanel';
import ContactContext from './ContactContext';
import BlueUnderlineTab from 'Components/Tabs/BlueUnderlineTab';

const styles = theme => ({
  contactNotesTabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  tabsWrapper: {
    height: 40,
  },
});

const tabs = [
  'Notes',
  'Activity',
  'Files',
];

const fullCoverTabs = [
  'Notes',
  'Activity',
  'Files',
  'Accounts',
];

class ContactSideTabs extends Component {

  state = {
    tabIndex: 0,
  };

  handleChange = tabIndex => {
    this.setState({ tabIndex });
  };

  render() {
    const {
      classes,
      primaryAccountUpdated,
      onFileAddSuccess,
      onChitChatUpdated,
      userHasFullCover,
    } = this.props;
    const { tabIndex } = this.state;

    return (
      <Paper className={classes.contactNotesTabsRoot}>
        <div className={classes.tabsWrapper}>
          <BlueUnderlineTab
            onTabChange={this.handleChange}
            tabs={userHasFullCover ? fullCoverTabs : tabs}
            tabIndex={tabIndex}
          />
        </div>
        {tabIndex === 0 && <NotesPanel displayAddButton={true} onRefresh={onChitChatUpdated} />}
        {tabIndex === 1 && <ActivityPanel refresh={onChitChatUpdated} />}
        {tabIndex === 2 && <FilesPanel onFileAddSuccess={onFileAddSuccess} />}
        {tabIndex === 3 && <ContactAccountsPanel primaryAccountUpdated={primaryAccountUpdated}  />}
      </Paper>
    );
  }
}

const ContactSideTabsWithContext = props => {
  const {
    onChitChatUpdated,
    onFileAddSuccess,
  } = useContext(ContactContext);

  return (<ContactSideTabs
    {...props}
    onChitChatUpdated={onChitChatUpdated}
    onFileAddSuccess={onFileAddSuccess}
  />);
};

export default withStyles(styles)(ContactSideTabsWithContext);
