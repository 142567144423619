import { WARNING_TOAST_SHOW, WARNING_TOAST_HIDE, SET_CURRENT_VERSION, APPLICATION_UPDATED } from 'actions';

const initialState = {
  warningToast: {
    show: false,
    warningDetails: undefined,
  },
  appVersion: {
    currentVersion: undefined,
    newVersion: undefined,
    showUpdateToast: false,
  },
};

export function global(state = initialState, action) {
  switch (action.type) {
    case WARNING_TOAST_SHOW:
      const { warningDetails } = action;

      return {
        ...state,
        warningToast: {
          show: true,
          warningDetails,
        },
      };
    case WARNING_TOAST_HIDE:
      return {
        ...state,
        warningToast: {
          show: false,
          warningDetails: undefined,
        },
      };
    case SET_CURRENT_VERSION:
      const { version } = action;

      var currentVersion = state.appVersion.currentVersion ? state.appVersion.currentVersion : version;

      if (currentVersion !== version) {

        return {
          ...state,
          appVersion: {
            currentVersion: currentVersion,
            newVersion: version,
            showUpdateToast: currentVersion !== version,
          },
        };
      }
      else {
        return state;
      };
    case APPLICATION_UPDATED:
      return {
        ...state,
        appVersion: {
          currentVersion: state.appVersion.newVersion,
          showUpdateToast: false,
        },
      };
    default:
      return state;
  }
}
