import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import { companyReceived } from './admin';

export function getCompany() {
  return dispatch => {
    dispatch(callApi('company/active'))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .then(company => dispatch(companyReceived(company)));
  };
}

export function saveCompany(company) {
  return dispatch => company.id
    ? dispatch(callApi(`company/${company.id}`, { method: 'PUT', body: company }))
    : dispatch(callApi('company', { method: 'POST', body: company }));
}
