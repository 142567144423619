import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  FolderOpen as FolderIcon,
} from '@material-ui/icons';
import { APP_TYPES } from 'constants/entityTypes';
import { userHasFullCoverLicense } from 'helpers/userHelpers';

const styles = theme => ({
  folders: {
    display: 'flex',
    marginTop: 16,
    padding: 16,
    justifyContent: 'space-around',
  },
  foldersJustifyLeft: {
    justifyContent: 'flex-start',
  },
  folder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 140,
    height: 140,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    cursor: 'pointer',
    marginRight: 16,
    padding: 16,
    fontSize: 13,
  },
  folderTitle: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '400',
    paddingLeft: 0,
    paddingRight: 0,
  },
  selected: {
    borderColor: theme.palette.action.active,
    borderWidth: 2,
    '& $folderTitle': {
      fontWeight: '500',
    },
  },
  folderIcon: {
    color: theme.palette.grey[800],
    fontSize: 60,
  },
});

const EmailFolderManager = ({
  classes,
  onFolderSelected,
  folders,
  selectedFolder,
  user,
}) => {

  if (!folders) {
    return null;
  }

  const userHasFullCover = userHasFullCoverLicense(user);
  const filteredFolders = userHasFullCover
    ? folders
    : folders.filter(folder => folder.appType === APP_TYPES.all);

  return (
    <div className={classNames(
      classes.folders,
      { [classes.foldersJustifyLeft]: filteredFolders.length < 4 },
    )}>
      {filteredFolders.map(folder => (
        <div
          className={classNames(
            classes.folder,
            { [classes.selected]: selectedFolder.id === folder.id },
          )}
          onClick={() => onFolderSelected(folder)}
          key={folder.id}
        >
          <FolderIcon className={classes.folderIcon} />
          <span className={classes.folderTitle}>{folder.name}</span>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.oidc.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(EmailFolderManager));

