import React, { Component } from 'react';
import { connect } from 'react-redux';
import OneDimensionalGraphWidget from 'Components/GraphWidgets/OneDimensionalGraphWidget';
import { withStyles } from '@material-ui/core/styles';
import { widgetGraphs } from 'helpers/enums';
import { getContactTotalSummary, getSelectedContactTotalSummary } from 'actions/reports';
import { getContactSales } from 'actions/contact';
import SalesWidget from 'Components/GraphWidgets/SalesWidget';

const styles = () => ({
  graphContainer: {
    display: 'flex',
    paddingBottom: '3.2%',
  },
  lastGraphContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  graphGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

class ContactGraphs extends Component {
  state = {
    allContacts: {
      contactCount: 0,
      totalSpend: 0,
      totalEvents: 0,
    },
    contacts: [],
    selectedContacts: [],
  }

  componentDidMount() {
    this.props.getContactSales();

    const getContactTotalSummary = this.props.getContactTotalSummary();

    if (this.props.selectedContacts && this.props.selectedContacts.length === 0) {
      Promise.all([getContactTotalSummary, this.props.getSelectedContactTotalSummary(this.props.selectedIds)])
        .then(([allContacts, selectedContacts]) =>
          this.setState({ allContacts, selectedContacts }));
    } else {
      getContactTotalSummary.then(allContacts => this.setState({ allContacts }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contacts !== this.props.contacts) {
      const selectedContacts = this.props.contacts.filter(contact => contact.selected);

      this.props.getContactSales();

      this.setState({
        selectedContacts,
        contacts: this.props.contacts,
      });
    }
  }

  render() {
    const {
      classes,
      contactsCountSettings,
      contactsSpendSettings,
      sales,
    } = this.props;

    const {
      selectedContacts,
    } = this.state;

    return (
      <div className={classes.graphGroupContainer}>
        {sales && sales.totalContactsCount && <div className={classes.graphContainer}>
          <OneDimensionalGraphWidget
            denominator={sales.totalContactsCount}
            numerator={selectedContacts.length}
            settings={contactsCountSettings}
          />
        </div>}
        {sales && sales.totalSoldLast30Days && <><div className={classes.graphContainer}>
          <SalesWidget
            data={sales}
          />
        </div>
          <div className={classes.graphContainer}>
            <OneDimensionalGraphWidget
              denominator={sales.totalSoldLast30Days.totalSold || 0}
              numerator={sales.totalSold}
              settings={contactsSpendSettings}
            />
          </div></>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    widgets,
    contact,
  } = state;

  return {
    contactsCountSettings: widgets[widgetGraphs.contactsCount],
    contactsSpendSettings: widgets[widgetGraphs.contactsSpend],
    contactsEventsCostSettings: widgets[widgetGraphs.contactsEvents],
    contacts: contact.contacts,
    sales: contact.sales || {},
  };
};

const mapDispatchToProps = {
  getContactTotalSummary,
  getSelectedContactTotalSummary,
  getContactSales,
};

const ContactGraphsContainer = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContactGraphs));

export default ContactGraphsContainer;
