import React, { Component } from 'react';
import { withTheme } from '@material-ui/core/styles';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartArea,
} from '@progress/kendo-react-charts';
import moment from 'moment';

export const formatNumber = prefix => e => {
  if (e.value < 1000)
    return `${prefix} ${e.value}`;
  if (e.value < 1000000)
    return `${prefix} ${e.value / 1000} k`;

  return `${prefix} ${e.value / 1000000} m`;
};

class LargeBarChartWidget extends Component {

  constructor(props) {
    super(props);

    this.yAxisLabels = {
      padding: 20,
      font: '12px "Open Sans", "Helvetica Neue", sans-serif',
      color: props.theme.palette.grey.A200,
      content: formatNumber(props.valuePrefix ? props.valuePrefix : ''),
    };
    this.todaysDate = moment().get('date');

  };

  switchColorBasedOnDay = data => {
    if (data.category === this.todaysDate) {
      return this.props.theme.palette.primary.main;
    }

    return this.props.theme.palette.secondary.dark;
  }

  render() {
    const {
      datacategories,
      series,
    } = this.props;

    return (
      <Chart zoomable={false}>
        <ChartArea margin={0} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            categories={datacategories}
            line={{ visible: false }}
            majorGridLines={{ visible: false }}
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem
            line={{ visible: false }}
            labels={this.yAxisLabels}
          />
        </ChartValueAxis>
        <ChartSeries>
          {series.map((item, idx) => (
            <ChartSeriesItem
              key={idx}
              type="column"
              data={item.data}
              color={this.switchColorBasedOnDay}
            />)
          )}
        </ChartSeries>
      </Chart>
    );
  }
}

export default withTheme()(LargeBarChartWidget);
