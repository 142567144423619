import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ContactContext from './ContactContext';
import ContactDetails from './ContactDetails';
import ProposalSection from 'leads/ProposalSection';
import BookingSection from './BookingSection';
import OrdersSection from './OrdersSection';
import BlueTextTab from 'Components/Tabs/BlueTextTab';
import TabContainer from 'Components/TabContainer';
import SaveBar from 'Components/SaveBar';
import { PROPOSAL_TYPES } from 'constants/proposalTypes';

const styles = theme => ({
  root: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },
  tabContainer: {
    height: 40,
    backgroundColor: theme.palette.grey[50],
  },
  tabContent: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

const tabs = [
  'Details',
  'Orders',
];

const fullCoverTabs = [
  'Details',
  'Orders',
  'Bookings',
  'Proposals',
];

function ContactTabs({ classes }) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const {
    contact,
    isEditing,
    onSave,
    onCancelEdit,
    userHasFullCover,
  } = useContext(ContactContext);

  return (
    <div className={classes.root}>
      <div className={classes.tabContainer}>
        <BlueTextTab
          onTabChange={setTabIndex}
          tabs={userHasFullCover ? fullCoverTabs : tabs}
          tabIndex={tabIndex}
        />
      </div>
      <TabContainer>
        {tabIndex === 0 && <ContactDetails />}
        {tabIndex === 1 && <OrdersSection contact={contact} />}
        {tabIndex === 2 && <BookingSection contact={contact} />}
        {tabIndex === 3 && <ProposalSection linkedEntityId={contact.id} entityType={PROPOSAL_TYPES.contact} />}
      </TabContainer>
      {isEditing && <SaveBar onSave={onSave} onCancel={onCancelEdit} isSticky={true} />}
    </div>
  );
}

export default withStyles(styles)(ContactTabs);
