import { callApi, callReportsApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import { reportsApi } from 'shared/api';

export const getContactTotalSummary = () =>
  async dispatch =>
    dispatch(callApi('reports/ContactSummary'))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);

export const getSelectedContactTotalSummary = selectedIds =>
  async dispatch =>
    dispatch(callApi('reports/ContactSummary', { body: selectedIds }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);

export const getBookingTotalSummary = () =>
  async dispatch =>
    dispatch(callApi('reports/BookingSummary'))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);

export const getSelectedBookingTotalSummary = selectedIds =>
  async dispatch =>
    dispatch(callApi('reports/BookingSummary', { body: selectedIds }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);

export const getOrderTotalSummary = () =>
  async dispatch =>
    dispatch(callApi('reports/OrderSummary'))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);

export const getSelectedOrderTotalSummary = selectedIds =>
  async dispatch =>
    dispatch(callApi('reports/OrderSummary', { body: selectedIds }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);

export const getReportsList = () =>
  async dispatch =>
    dispatch(callReportsApi('library/details'))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const reportAboluteUrl = (report, params) => {

  const url = `${reportsApi}Library/Report/${report}`;

  if (params) {
    const convertToQueryParams = Object.keys(params)
      .reduce(
        (a, b) => `${a}${a ? '&' : ''}${b}=${encodeURIComponent(params[b])}`
        , '');

    return `${url}?${convertToQueryParams}`;
  }

  return url;
};

export const reportPdfUrl = (report, params) => {

  const url = `${reportsApi}Library/Pdf/${report}`;

  if (params) {
    const convertToQueryParams = Object.keys(params)
      .reduce(
        (a, b) => `${a}${a ? '&' : ''}${b}=${encodeURIComponent(params[b])}`
        , '');

    return `${url}?${convertToQueryParams}`;
  }

  return url;
};

export const getReportDetails = report =>
  async dispatch =>
    dispatch(callReportsApi(`library/details/${report}`))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response));
