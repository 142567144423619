export const CHIT_CHAT_TYPES = {
  post: 0,
  email: 1,
  systemGeneratedAction: 2,
  userCreatedAction: 3,
  task: 4,
};

const chitChatTypeKeys = Object.keys(CHIT_CHAT_TYPES).reduce(
  (acc, key) => {
    acc[CHIT_CHAT_TYPES[key]] = key;

    return acc;
  },
  {}
);

export const getChitChatTypeKey = chitChatType => {
  return chitChatTypeKeys[chitChatType] || chitChatType;
};

export const ENTITY_TYPES = {
  account: 'Account',
  booking: 'Booking',
  contact: 'Contact',
  order: 'Order',
  lead: 'Lead',
  main: 'Main',
  admin: 'Admin',
};

export const EMAIL_TYPES = {
  account: 'Account',
  booking: 'Booking',
  contact: 'Contact',
  order: 'Order',
  lead: 'Lead',
  other: 'Other',
};

export const APP_TYPES = {
  all: 'All',
};

