import React, { Component } from 'react';
import { connect } from 'react-redux';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import Modal from 'Components/Modal';
import { saveCompany } from 'actions/company';
import { companyReceived } from 'actions/admin';

import CompanyBullet from './companyBullet';
import BottomSubContent from './BottomSubContent';
import Hero from './Hero';
import CompanyEdit from './CompanyEdit';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';

import IconButton from 'Components/Buttons/IconButton';
import { FileCopyOutlined as FileCopyIcon } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import { time_zone } from 'constants/user.constants';

const styles = theme => ({
  splash: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  companyWhiteBar: {
    backgroundColor: 'white',
    padding: '25px 25px 0px 25px',
    whiteSpace: 'normal', //white-space:'nowrap' is coming from some where
    display: 'flex',
  },
  white: {
    color: 'white',
  },
  whiteButton: {
    borderColor: 'white',
    color: 'white',
    marginRight: '20px',
    textTransform: 'none',
    height: '36px',
  },
  buttons: {
    borderColor: 'white',
    borderRadius: '4px',
    textTransform: 'none',
    height: '36px',
  },
  dropbutton: {
    borderColor: 'white',
    padding: 0,
    height: 36,
    minWidth: 45,
    marginRight: 10,
    borderRadius: '0px 4px 4px 0px',
  },
  marginTop: {
    marginTop: 20,
  },
  iconButton: {
    color: theme.palette.grey[600],
  },
});

export class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translations: {},
      editingCompany: false,
      errors: {},
    };
  }

  handleOpen = () => {
    this.setState(
      {
        editingCompany: true,
        company: { ...this.props.company },
      });
  };

  cancelled = () => {
    this.setState({ editingCompany: false });
  };

  companySaved = () => {
    const company = this.state.company;

    this.props.saveCompany(company).then(response => {
      response.json().then(updatedCompany => {
        if (updatedCompany.domainNameInUseError) {
          this.setState({
            errors: {
              ...this.state.errors,
              domainName: 'Domain name in use. Please choose other name.',
            },
          });
        } else if (updatedCompany.postalCode.length < 5) {
          this.setState({
            errors: {
              ...this.state.errors,
              postalCode: 'A valid Zip Code is required.',
            },
          });
        } else {
          this.props.companyReceived(company);
          this.setState({ editingCompany: false });
        }
      });
    });
  };

  handleInputChange = fieldname => value => {

    const company = {
      ...this.state.company
      , [fieldname]: value,
    };
    const errors = {
      ...this.state.errors,
    };

    delete errors[fieldname];

    this.setState({ company, errors });
  }

  checkValidity = fieldname => _event => {
    const domain = this.state.company.domainName;

    if (fieldname === 'domainName') {
      if (!/^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/.test(domain)) {
        this.setState({ errors: { ...this.state.errors, domainName: 'Domain name is invalid' } });
      }
    }
  }

  componentDidMount() {
    const translations = this.getTranslations();

    this.setState({ translations });
  }

  getTranslations() {
    const localizationService = provideLocalizationService(this);

    //const edit = localizationService.toLanguageString('crud.edit');
    //    const companyLabel = localizationService.toLanguageString('company.label');
    return {
      franchises: localizationService.toLanguageString('franchise.labelPlural'),
      locations: localizationService.toLanguageString('location.labelPlural'),
      edit: localizationService.toLanguageString('crud.edit'),
      details: localizationService.toLanguageString('company.details'),
    };
  }

  render() {
    const { classes, company, theme } = this.props;
    const { translations, editingCompany } = this.state;
    const grazeUrl = company.domainName ? `${company.domainName}.${window.env.REACT_APP_GRAZE_DOMAIN_NAME}` : '';

    return (
      <div className={classes.splash}>
        <Hero
          iconColor={theme.palette.secondary.main}
          label={translations.details || ''}
          text={company.name || ''}
          backgroundColor={theme.palette.grey[200]}
          color="white"
        >
          <Button variant="outlined" mini
            onClick={this.handleOpen}
            classes={{
              root: classes.buttons,
              label: classes.white,
            }}>
            {translations.edit || ''}
          </Button>
        </Hero>
        <Card>
          <div className={classes.companyWhiteBar}>
            <Grid container spacing={16}>
              <Grid item xs={3}>
                <CompanyBullet
                  label={"Company Name"}
                  line1={company.name ? company.name : ''}
                  style={{ marginRight: '5px' }}
                />
              </Grid>
              <Grid item xs={3}>
                <CompanyBullet
                  label={"Address"}
                  line1={`${company.address ? company.address : ''}${!!company.city ? ', ' + company.city : ''}${!!company.state ? ', ' + company.state : ''} ${company.postalCode ? company.postalCode : ''}`}
                  style={{ marginRight: '5px' }}
                />
              </Grid>
              <Grid item xs={3}>
                <CompanyBullet
                  label="Primary Contact"
                  line1={company.primaryContact}
                  line2={company.primaryContactTitle}
                />
              </Grid>
              <Grid item xs={3}>
                <CompanyBullet
                  label="Time Zone"
                  line1={company.region}
                  line2={company.timeZone ? time_zone[company.timeZone] : ''}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.companyWhiteBar} style={{ paddingTop: 0 }}>
            <Grid container spacing={16}>
              <Grid item xs={3}>
                <CompanyBullet
                  label="Domain/Subdomain Name"
                  line1={company.domainName}
                />
              </Grid>
              <Grid item xs={3}>
                <CompanyBullet
                  label="Phone Number"
                  line1={company.phone}
                />
              </Grid>
              <Grid item xs={3}>
                <CompanyBullet
                  label="Contact Email"
                  line1={company.email}
                />
              </Grid>
              <Grid item xs={3}>
                <CompanyBullet
                  label="Graze URL"
                  line1={<>
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`//${grazeUrl}`}
                    >
                      {grazeUrl}
                    </a>
                    <IconButton
                      className={classes.iconButton}
                      icon={() =>
                        <Tooltip title='Copy to Clipboard'>
                          <FileCopyIcon />
                        </Tooltip>
                      }
                      onClick={() => {
                        navigator.clipboard.writeText(grazeUrl);
                      }}
                    />
                  </>}
                />
              </Grid>
            </Grid>
          </div>
        </Card>
        {/* <ButtonBar tab1="Enterprise" tab2="(Secondary)" /> TODO: Hidden for BETA */}
        <div className={classes.marginTop}>
          <BottomSubContent />
        </div>
        <Modal
          isOpened={editingCompany}
          onCancel={this.cancelled}
          title={company.name}
          onSave={this.companySaved}
        >
          <CompanyEdit
            company={this.state.company}
            onFieldChange={this.handleInputChange}
            onBlur={this.checkValidity}
            errors={this.state.errors}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    company: state.admin.company || {},
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ companyReceived, saveCompany }, dispatch);
};

Splash.propTypes = {
  classes: PropTypes.object.isRequired,
};

registerForLocalization(Splash);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Splash));
