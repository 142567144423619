import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import classNames from 'classnames';

const styles = theme => ({
  menus: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    overflow: 'auto',
    '&::-webkit-scrollbar': { //custom horizontal scrollbar
      width: 0,
      height: 10,
      background: theme.palette.grey[50],
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey[500],
    },
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    cursor: 'pointer',
    marginRight: 16,
    padding: 16,
    fontSize: 13,
  },
  menuTitle: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '400',
    paddingLeft: 0,
    paddingRight: 0,
  },
  selected: {
    backgroundColor: theme.palette.action.active,
    '& $menuTitle': {
      color: theme.palette.common.white,
      fontWeight: '500',
    },
  },
});

const MenuSelector = ({
  classes,
  onSelection,
  menus,
  workingMasterMenuId,
  menuName,
}) => {

  return (
    <div className={classes.menus}>
      {menus && menus.map(menu =>
        <div className={classNames(classes.menu, workingMasterMenuId === menu.id && classes.selected)} onClick={() => onSelection(menu)} key={menu.id}>
          <span className={classes.menuTitle}>{workingMasterMenuId === menu.id ? menuName : menu.name}</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    masterMenuManagement: {
      workingMasterMenuId,
      menuName,
    },
  } = state;

  return {
    workingMasterMenuId,
    menuName,
  };
};

export default connect(mapStateToProps, undefined)(withStyles(styles)(MenuSelector));

