import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';

import { getWidgetColor } from 'shared/themes';

const styles = theme => ({
  denominator: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: '18px',
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '162px',
    margin: '0 -18px', // Net 12 padding
  },
  graphLabelsContainer: {
    marginTop: '24%',
    textAlign: 'end',
    whiteSpace: 'normal',
    width: '29.32%',
  },
  horizontalAdjustment: {
    backgroundColor: theme.palette.grey[50],
    margin: '11px -30px',
  },
  totalDescription: {
    color: theme.palette.grey[200],
    fontSize: '12px',
  },
  fieldSelector: {
    flexGrow: 0,
  },
  container: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '25px 30px',
    width: '100%',
  },
  headerRow: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  title: {
    fontSize: 16,
    whiteSpace: 'pre',
    color: '#656565',
    marginTop: -10,
  },
});

class GenericDoughnut extends React.Component {
  constructor(props) {
    super(props);

    this.chartContainerRef = React.createRef();
    this.chartRef = React.createRef();

    this.state = {
      adjustChartMargin: 0,
      dimensionInPx: 0,
      selectedFieldIndex: 1,
    };
  }

  render() {
    const {
      classes,
      data,
      title,
      minWidth = '250px',
      minHeight = '175px',
    } = this.props;

    if (!data) {
      return (<></>);
    }

    const { adjustChartMargin, dimensionInPx } = this.state;

    const nestedTooltipRender = ({ point, dataItem }) => {
      const key = dataItem ? dataItem.menuName : point.dataItem.menuName;
      const value = dataItem ? dataItem.menuItemsCount : point.dataItem.menuItemsCount;

      return `${key} : ${value}`;
    };

    const indexedData = data.map((item, index) => ({ ...item, index }));

    const getColor = ({ dataItem }) => {
      if (dataItem) {
        return getWidgetColor(dataItem.index);
      }
    };

    return (
      <div style={{ minWidth, minHeight }} className={classes.container}>
        <div ref={this.chartContainerRef} className={classes.graphContainer}>
          <h4 className={classes.title}>{title}</h4>
          <Chart ref={this.chartRef} style={{ height: dimensionInPx + 30 }}>
            <ChartTooltip render={nestedTooltipRender} />
            <ChartSeries>
              <ChartSeriesItem type="donut" data={indexedData} categoryField="menuName" color={getColor} field="menuItemsCount" startAngle={0} />
            </ChartSeries>
            <ChartArea margin={adjustChartMargin} />
            <ChartLegend visible={false} />

          </Chart>
        </div></div>
    );
  }

  componentDidMount() {
    this.updateDimensionsIfNeeded();
  }

  componentDidUpdate() {
    this.updateDimensionsIfNeeded();
  }

  updateDimensionsIfNeeded() {
    const { dimensionInPx: prevDimensionInPx, adjustChartMargin: prevAdjustChartMargin } = this.state;

    const container = this.chartContainerRef.current;
    const chart = this.chartRef.current;
    const kendoMarginAdjustment = -.21;
    const adjustChartMargin = chart && chart.element ? chart.element.clientWidth * kendoMarginAdjustment : 0;
    let dimensionInPx = 0;

    if (container) {
      if (container.clientWidth === 0) {
        dimensionInPx = container.clientHeight;
      } else if (container.clientHeight === 0) {
        dimensionInPx = container.clientWidth;
      } else {
        dimensionInPx = container.clientWidth > container.clientHeight ? container.clientHeight : container.clientWidth;
      }
    }

    if (prevDimensionInPx !== dimensionInPx || prevAdjustChartMargin !== adjustChartMargin) {
      this.setState({ dimensionInPx, adjustChartMargin });
    }
  }
}

export default withStyles(styles, { withTheme: true })(GenericDoughnut);
