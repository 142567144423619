import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from 'Components/Grid/Grid';
import { GridColumn } from '@progress/kendo-react-grid';
import {
  Edit as EditIcon,
  Clear as DeleteIcon,
} from '@material-ui/icons';

const styles = theme => ({
  modifyIcon: {
    cursor: 'pointer',
    marginRight: 7,
    marginLeft: 3,
    color: theme.palette.grey[700],
    fontSize: 26,
  },
});

const RevenueTypeGrid = ({ classes, RevenueTypes, onEditRevenueType, onDeleteRevenueType }) => {

  return (
    <Grid
      items={RevenueTypes}
    >

      <GridColumn field="name" title="Name" />
      <GridColumn field="" title="" width="100px" cell={props => (
        <td className={classes.crudIcons}>
          {props.dataItem.name !== 'Room' ? <>
            <EditIcon
              className={classes.modifyIcon}
              onClick={() => onEditRevenueType(props.dataItem)}
              aria-label={`Edit RevenueType`}
            />
            <DeleteIcon
              className={classes.modifyIcon}
              onClick={() => onDeleteRevenueType(props.dataItem)}
              aria-label={`Delete RevenueType`}
            />
          </> : <span>System Type</span>
          }
        </td>
      )}
      />
    </Grid>
  );
};

export default withStyles(styles)(RevenueTypeGrid);
