import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

import {
  MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_BEGIN,
  MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_END,
  RECEIVE_MENU_ITEM_MODIFIER_TEMPLATES,
  DELETE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS,
  START_MODIFIER_TEMPLATE_STACK,
  PUSH_MODIFIER_TEMPLATE_TO_STACK,
  REMOVE_MODIFIER_TEMPLATES_FROM_STACK,
  SAVE_MODIFIER_TEMPLATE,
  UPDATE_MODIFIER_TEMPLATE_IN_STACK,
} from './constants';

export const getMenuItemModifierTemplates = (params = '', append = false) =>
  (dispatch, getState) => {
    dispatch(fetchMenuItemModifierTemplatesBegin());

    return getMenuItemModifierTemplatesApi(dispatch, params).then(result => {
      dispatch(fetchMenuItemModifierTemplatesSuccess(result, append));

      return result;
    }).finally(() => dispatch(fetchMenuItemModifierTemplatesEnded()));
  };

export const saveMenuItemModifierTemplate = (modifierTemplate, index) =>
  dispatch => {
    if (index === 0) { // index 0 means we are saving top level parent and we need to call api
      if (modifierTemplate.id > 0) {
        return dispatch(updateMenuItemModifierTemplateApi(modifierTemplate)).then(result => {
          dispatch(saveMenuItemModifierTemplateSuccess(result, index));
        });
      } else {
        return dispatch(createMenuItemModifierTemplateApi(modifierTemplate)).then(result => {
          dispatch(saveMenuItemModifierTemplateSuccess(result, index));
        });
      }
    }
    else {
      return dispatch(saveMenuItemModifierTemplateSuccess(modifierTemplate, index));
    }
  };

export const deleteMenuItemModifierTemplate = (modifierTemplate, index) =>
  dispatch => {
    if (index === 0 && modifierTemplate.id > 0) { // index 0 means we are deleting top level parent and we need to call api
      return dispatch(deleteMenuItemModifierTemplateApi(modifierTemplate)).then(result => {
        dispatch(deleteMenuItemModifierTemplateSuccess(modifierTemplate, index));
      });
    }
    else {
      return dispatch(deleteMenuItemModifierTemplateSuccess(modifierTemplate, index));
    }
  };

const getMenuItemModifierTemplatesApi = (dispatch, params) => {
  return dispatch(
    callApi(`MenuItemModifierTemplate?${params}`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

const deleteMenuItemModifierTemplateApi = menuItemModifierTemplate =>
  dispatch => {
    return dispatch(callApi(`MenuItemModifierTemplate/${menuItemModifierTemplate.id}`, { method: 'DELETE' }))
      .then(menuItemModifierTemplate)
      .catch(console.error);
  };

const updateMenuItemModifierTemplateApi = menuItemModifierTemplate =>
  dispatch => {

    return dispatch(callApi(`MenuItemModifierTemplate/${menuItemModifierTemplate.id}`, { method: 'PUT', body: menuItemModifierTemplate }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);
  };

const createMenuItemModifierTemplateApi = menuItemModifierTemplate =>
  dispatch => {

    return dispatch(callApi(`MenuItemModifierTemplate`, { method: 'POST', body: menuItemModifierTemplate }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .catch(console.error);
  };

export const fetchMenuItemModifierTemplatesBegin = params => ({
  type: MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_BEGIN,
});

export const fetchMenuItemModifierTemplatesSuccess = (response, append) => ({
  type: RECEIVE_MENU_ITEM_MODIFIER_TEMPLATES,
  response,
  append,
});

export const fetchMenuItemModifierTemplatesEnded = () => ({
  type: MENU_ITEM_MODIFIER_TEMPLATES_REQUEST_END,
});

export const deleteMenuItemModifierTemplateSuccess = (modifierTemplate, index) => ({
  type: DELETE_MENU_ITEM_MODIFIER_TEMPLATE_SUCCESS,
  modifierTemplate,
  index,
});

export const startModifierTemplateStack = modifierTemplate => ({
  type: START_MODIFIER_TEMPLATE_STACK,
  modifierTemplate,
});

export const pushModifierTemplateToStack = (modifierTemplate, index) => ({
  type: PUSH_MODIFIER_TEMPLATE_TO_STACK,
  modifierTemplate,
  index,
});

export const removeModifierTemplatesFromStack = index => ({
  type: REMOVE_MODIFIER_TEMPLATES_FROM_STACK,
  index,
});

export const saveMenuItemModifierTemplateSuccess = (modifierTemplate, index) => ({
  type: SAVE_MODIFIER_TEMPLATE,
  modifierTemplate,
  index,
});

export const updateModifierTemplateInStack = (modifierTemplate, index) => ({
  type: UPDATE_MODIFIER_TEMPLATE_IN_STACK,
  modifierTemplate,
  index,
});
