import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import GridIcon from '@material-ui/icons/GridOn';
import { List as DisplayListViewIcon } from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { getTags } from 'actions/item';
import { connect } from 'react-redux';
import ImagePreview from './ImagePreview';
import MenuItemFilterField from 'Components/Menu/MenuItemFilterField';
import SelectionBar from 'Components/SelectionBar';
import List from './List';
import { getMasterMenus } from 'actions/masterMenuManagement';
import { Select, MenuItem } from '@material-ui/core';

const styles = theme => {
  return {
    container: {
      overflow: 'auto',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexGrow: 1,
    },
    gridContainer: {
      display: 'flex',
      flexGrow: 1,
      '& .k-header': {
        '& .k-checkbox, & .k-checkbox-label': {
          visibility: 'hidden',
          display: 'none',
          pointerEvents: 'none',
        },
      },
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 6,
      minHeight: 44,
      width: '100%',
    },
    tagsContainer: {
      '& tags': {
        fontSize: '16px',
        border: '0',
        margin: '0',
        flex: 1,
        width: '100%',
        height: '100%',
      },
      '& div': {
        width: '100%',
      },
      '&:hover': {
        paddingBottom: 0,
      },
      '& .searchIcon': {
        marginLeft: '12px',
        fill: '#bcbcbc',
        fontSize: '25px',
        width: 30,
        alignSelf: 'center',
      },
      '& .tagify__tag__removeBtn::after ': {
        width: 9,
        margin: 3,
      },
      display: 'flex',
      width: '100%',
      border: '0',
      flexDirection: 'row',
      marginTop: '6px',
      paddingBottom: 1,
      borderBottom: '1px solid rgb(97,97,97)',
    },
  };
};

class MenuItemSelectionModal extends Component {
  allCategories = {
    name: 'All Menu Items',
    id: 0,
    menuCategoryItems: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedItemIds: [],
      isImagesOpened: props.isImagesOpenedFirst ? true : false,
      filteredMenuItems: props.bookingMenuSelect ? [] : props.menuItems,
      selectedOption: this.allCategories,
      selectedMenu: 0,
    };
  }

  getAllCategories = () => {
    const { masterMenus } = this.props;
    const { selectedMenu } = this.state;

    if (!masterMenus || !masterMenus.length) {
      return;
    }

    const selectedMasterMenu = masterMenus[selectedMenu];

    const allMasterMenuItems = [];
    const addedMenuItemIds = {};

    selectedMasterMenu.menuCategories.forEach(category => {
      category.menuCategoryItems.forEach(categoryItem => {
        if (!addedMenuItemIds[categoryItem.id]) {
          allMasterMenuItems.push(categoryItem);
          addedMenuItemIds[categoryItem.id] = true;
        }
      });
    });

    return allMasterMenuItems;
  }

  setAllCategories = () => {
    this.setState({
      selectedOption: {
        ...this.allCategories,
        menuCategoryItems: this.props.bookingMenuSelect
          ? this.getAllCategories()
          : this.props.menuItems,
      },
    }, () => this.setFilter());
  }

  componentDidMount() {
    const { getTags, getMasterMenus, bookingMenuSelect } = this.props;

    getTags();
    if (bookingMenuSelect) {
      getMasterMenus();
    }
  }

  componentDidUpdate(prevProps) {
    const { bookingMenuSelect, masterMenus } = this.props;

    if (bookingMenuSelect && prevProps.masterMenus !== masterMenus && !!masterMenus) {
      this.setAllCategories();
    }
  }

  toggleViews = () => {
    this.setState(prevState => ({ isImagesOpened: !prevState.isImagesOpened }));
  }

  rightButtons = () => {
    const { isImagesOpened } = this.state;

    return (
      <div>
        <HighlightButton
          variant="left"
          minWidth={34}
          pressed={isImagesOpened}
          onClick={this.toggleViews}
        >
          <GridIcon />
        </HighlightButton>
        <HighlightButton
          variant="right"
          minWidth={34}
          pressed={!isImagesOpened}
          onClick={this.toggleViews}
        >
          <DisplayListViewIcon />
        </HighlightButton>
      </div>
    );
  }

  onMenuChanged = event => {
    this.setState({
      selectedMenu: event.target.value,
    }, () => this.setAllCategories());
  }

  leftItem = () => (
    <Select
      variant="standard"
      value={this.state.selectedMenu}
      onChange={this.onMenuChanged}
    >
      {this.props.masterMenus.map((menu, index) =>
        <MenuItem value={index} key={'menuitem-' + index}>{menu.name}</MenuItem>
      )}
    </Select>
  )

  onSelectAll = newSelected => {
    const selectedItemIds = this.state.selectedItemIds.filter(selectedItemId => {
      if (this.state.menuItems.find(menuItem => menuItem.id === selectedItemId)) {
        return false; //just remove items from this menu
      }

      return true;//keep other ids
    });

    const menuItems = this.state.menuItems.map(menuItem => {
      if (newSelected) {
        selectedItemIds.push(menuItem.id);
      }

      return {
        ...menuItem,
        selected: newSelected,
      };
    });

    this.setState({ menuItems, selectedItemIds });
  }

  massageData = apiItems => {
    const { selectedItemIds } = this.state;

    return apiItems.map(menuItem => {
      const selected = selectedItemIds.indexOf(menuItem.id) !== -1;

      return {
        ...menuItem,
        selected,
      };
    });
  }

  onItemSelected = itemSelected => {
    const { filteredMenuItems } = this.state;

    const menuItemsSelected = filteredMenuItems.map(menuItem => {
      if (menuItem === itemSelected) {
        return {
          ...menuItem,
          selected: !menuItem.selected,
        };
      }

      return menuItem;
    });

    const selectedItemIds = this.updateSelectedItems(itemSelected.id);

    this.setState({ filteredMenuItems: menuItemsSelected, selectedItemIds });
  }

  // Need to keep track of selected items on front end, so filtered API results can persist selections
  updateSelectedItems = menuItemId => {
    const { selectedItemIds } = this.state;
    const indexOfId = selectedItemIds.indexOf(menuItemId);

    return indexOfId > -1 ?
      selectedItemIds.filter(id => id !== menuItemId) :
      [...selectedItemIds, menuItemId];
  }

  onSave = () => {
    const menuItems = this.state.selectedItemIds.map(id => {
      const munuItem = this.props.bookingMenuSelect
        ? this.state.selectedOption.menuCategoryItems.find(menuCategoryItem => menuCategoryItem.id === id)
        : this.props.menuItems.find(menuItem => menuItem.id === id);

      return { ...munuItem };
    });

    this.props.onSave(menuItems);
  }

  setFilter = filter => {
    const filteredMenuItems = this.filterMenuItems(filter);

    this.setState({ filteredMenuItems });
  };

  filterMenuItems = filter => {
    const { selectedOption } = this.state;
    const menuItems = this.props.bookingMenuSelect
      ? selectedOption.menuCategoryItems
      : this.props.menuItems;

    if (filter && filter.length > 0) {
      const tagFilters = filter && filter.filter(filter => filter.type === 'tag').map(tag => tag.value);
      const searchFilter = filter && filter.filter(filter => filter.type !== 'tag').map(tag => tag.value.toLowerCase());
      const allMenuItems = JSON.parse(JSON.stringify(menuItems));

      const filteredMenuItems = allMenuItems.filter(menuItem => ((!tagFilters || tagFilters.length === 0)
        || (menuItem.tags
          && tagFilters.every(tag => menuItem.tags.some(menuItemTag => menuItemTag === tag))))
        && ((!searchFilter || searchFilter.length === 0)
          || searchFilter.every(searchTerm => menuItem.name.toLowerCase().indexOf(searchTerm) > -1)));

      return filteredMenuItems;
    } else {
      return menuItems;
    }
  };

  categoryPicked = selectedOption => {
    if (selectedOption.id === 0) {
      this.setAllCategories();
    } else {
      this.setState(
        { selectedOption },
        () => this.setFilter(),
      );
    }
  }

  render() {
    const {
      classes,
      onModalClosed,
      masterMenus,
      bookingMenuSelect,
    } = this.props;
    const {
      isImagesOpened,
      sort,
      filteredMenuItems,
      selectedOption,
      selectedMenu,
    } = this.state;

    return (

      <Modal
        isOpened={true}
        onCancel={onModalClosed}
        onSave={this.onSave}
        title="Select Menu Items"
        addTitleBottomBorder={true}
        dimensions={{ width: '85%', height: '85%', maxWidth: 1287, maxHeight: 832 }}
        saveText="Select"
        rightItem={this.rightButtons()}
        leftItem={bookingMenuSelect ? this.leftItem() : null}
      >
        {!!bookingMenuSelect &&
          <div className={classes.categoryContainer}>
            <SelectionBar
              onSelectionChange={this.categoryPicked}
              leftFixedOptions={[this.allCategories]}
              options={masterMenus[selectedMenu].menuCategories}
              selectedOption={selectedOption}
              showConfig={false}
            />
          </div>
        }
        <div className={classes.filterContainer}>
          <MenuItemFilterField
            className={classes.tagsContainer}
            onChange={this.setFilter}
          />
        </div>
        <div className={classes.container}>
          <div className={classes.gridContainer}>
            {isImagesOpened ?
              <ImagePreview
                menuItems={filteredMenuItems}
                selectedOption={selectedOption}
                selectedMenu={selectedMenu}
                onItemSelected={this.onItemSelected}
              />
              :
              <List
                menuItems={filteredMenuItems}
                onItemSelected={this.onItemSelected}
                onSelectAll={this.onSelectAll}
                // onSortChange={this.onSortChange} TODO: grid sorting with new API
                sort={sort}
              />
            }
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    admin: {
      itemTags,
    },
    menuItem: {
      all,
    },
    masterMenuManagement: {
      masterMenus,
    },
  } = state;

  return {
    tags: itemTags,
    menuItems: all,
    masterMenus,
  };
};

const mapDispatchToProps = {
  getTags,
  getMasterMenus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuItemSelectionModal));
