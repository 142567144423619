import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { handleEventTypeFilterClick } from 'actions/calendar';
import { connect } from 'react-redux';
import { CALENDAR_VIEW } from 'constants/calendarMode';

const styles = theme => ({
  calendarFooter: {
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
  },
  row: {
    width: '100%',
  },
  footerTitle: {
    textAlign: 'center',
    paddingTop: 4,
    fontSize: 13,
    color: theme.palette.grey[400],
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 10,
  },
  subHeading: {
    color: theme.palette.grey['A200'],
    fontSize: 13,
  },
  controlLabelRoot: {
    alignItems: 'flex-start',
    marginRight: theme.spacing.unit * 5,
  },
  controlLabel: {
    color: theme.palette.grey[400],
    fontSize: '13px !important',
    paddingTop: 1,
  },
  checkBox: {
    paddingTop: 0,
    paddingRight: 6,
    marginTop: -2,
  },
  personal: {
    color: `${theme.palette.personalEvent} !important`,
  },
  proposal: {
    color: `${theme.palette.proposalEvent} !important`,
  },
  booking: {
    color: `${theme.palette.bookingEvent} !important`,
  },
  task: {
    color: `${theme.palette.taskEvent} !important`,
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.grey['A200']}`,
    height: 30,
    margin: '12px 40px 0 0',
  },
  addButtonContainer: {
    display: 'inline-flex',
  },
  addCalendarButton: {
    width: 20,
    height: 20,
    alignSelf: 'center',
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 2,
    padding: 0,
    marginRight: theme.spacing.unit,
    fontSize: 18,
  },
});

class CalendarFooter extends Component {

  filterClicked = fieldName => event => {
    const isChecked = event.target.checked;

    this.props.handleEventTypeFilterClick(fieldName, isChecked);
  }

  render() {
    const { classes, selectedEventTypes, view } = this.props;

    return (
      <div className={classNames(classes.row, classes.calendarFooter)}>
        <div className={classNames(classes.row)}>
          <Typography variant="body2" className={classes.footerTitle}>Calendars</Typography>
        </div>

        <div className={classNames(classes.row, classes.controlsContainer)}>
          <div className={classNames(classes.filterButtons)}>
            <div className={classNames(classes.row)}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="Personal"
                    disabled={view === CALENDAR_VIEW.Timeline}
                    checked={ view !== CALENDAR_VIEW.Timeline ? selectedEventTypes.Personal : false}
                    onChange={this.filterClicked('Personal')}
                    classes={{
                      root: classNames(classes.checkBox, classes.personal),
                      checked: classes.personal,
                    }}
                  />
                }
                label="Personal"
                classes={{
                  root: classes.controlLabelRoot,
                  label: classes.controlLabel,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Proposal"
                    checked={!!selectedEventTypes.Proposal}
                    onChange={this.filterClicked('Proposal')}
                    classes={{
                      root: classNames(classes.checkBox, classes.proposal),
                      checked: classes.proposal,
                    }}
                  />
                }
                label="Proposal"
                classes={{
                  root: classes.controlLabelRoot,
                  label: classes.controlLabel,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Booking"
                    checked={!!selectedEventTypes.Booking}
                    onChange={this.filterClicked('Booking')}
                    classes={{
                      root: classNames(classes.checkBox, classes.booking),
                      checked: classes.booking,
                    }}
                  />
                }
                label="Booking"
                classes={{
                  root: classes.controlLabelRoot,
                  label: classes.controlLabel,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="Task"
                    disabled={view === CALENDAR_VIEW.Timeline}
                    checked={view !== CALENDAR_VIEW.Timeline ? !!selectedEventTypes.Task : false}
                    onChange={this.filterClicked('Task')}
                    classes={{
                      root: classNames(classes.checkBox, classes.task),
                      checked: classes.task,
                    }}
                  />
                }
                label="Task/ToDo"
                classes={{
                  root: classes.controlLabelRoot,
                  label: classes.controlLabel,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    calendar: {
      selectedEventTypes,
    },
  } = state;

  return {
    selectedEventTypes,
  };
};

const mapDispatchToProps = {
  handleEventTypeFilterClick,
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CalendarFooter));
