import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from 'Components/TextField';

import { time_zone } from 'constants/user.constants';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    flexGrow: '1',
  },
  textHalf: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '50%',
  },
  row: {
    display: 'flex',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,

  },
  secondaryBackground: {
    backgroundColor: theme.palette.grey[50],
  },
});

export class CompanyEdit extends Component {
  render() {
    const { classes, company, errors, onFieldChange, onBlur } = this.props;

    return (
      <>
        <Typography className={classes.secondaryBackground} variant="body1" style={{ width: '100%', paddingLeft: '10px', paddingTop: '6px', textAlign: 'left', height: '31px' }} id="modal-title">
          Location Information
        </Typography>
        <div className={classes.row}>
          <TextField
            label="Company Name"
            className={classes.textField}
            onFieldChange={onFieldChange('name')}
            name="name"
            value={company.name ? company.name : ''}
            error={!!errors.name}
            onBlur={onBlur('name')}
            margin="normal"
          />
          <FormControl className={classes.textField} margin="normal">
            <InputLabel> Time Zone </InputLabel>
            <Select
              value={company.timeZone ? company.timeZone : ''}
              onChange={event => onFieldChange('timeZone')(event.target.value)}
              name="timeZone"
              error={!!errors.timeZoneInvalid}
              onBlur={onBlur('timeZone')}
            >
              {Object.entries(time_zone).map(([key, value], index) =>
                <MenuItem key={`time-zone-${key}`} value={key}>{value}</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        <div className={classes.row}>
          <TextField
            label="Domain/Subdomain Name"
            className={classes.textField}
            onFieldChange={onFieldChange('domainName')}
            name="domainName"
            value={company.domainName ? company.domainName : ''}
            error={!!errors.domainName}
            helperText={errors.domainName}
            onBlur={onBlur('domainName')}
            margin="normal"
          />
          <TextField
            label="Root Domain Name"
            disabled={true}
            className={classes.textField}
            name="rootDomainName"
            value={`.${window.env.REACT_APP_GRAZE_DOMAIN_NAME}`}
            margin="normal"
          />
        </div>
        <div className={classes.row}>
          <TextField
            label={"Address"}
            value={company.address ? company.address : ''}
            className={classes.textField}
            onFieldChange={onFieldChange('address')}
            name="address"
            error={!!errors.addressInvalid}
            onBlur={onBlur('address')}
            margin="normal"
          />
          <TextField
            label={"City"}
            value={company.city ? company.city : ''}
            className={classes.textField}
            onFieldChange={onFieldChange('city')}
            name="city"
            error={!!errors.cityInvalid}
            onBlur={onBlur('city')}
            margin="normal"
          />
        </div>
        <div className={classes.row}>
          <TextField
            label={"State"}
            value={company.state ? company.state : ''}
            className={classes.textField}
            onFieldChange={onFieldChange('state')}
            name="state"
            error={!!errors.stateInvalid}
            onBlur={onBlur('state')}
            margin="normal"
          />
          <TextField
            label={"Zip Code"}
            value={company.postalCode}
            className={classes.textField}
            onFieldChange={onFieldChange('postalCode')}
            name="postalCode"
            error={!!errors.postalCode}
            helperText={errors.postalCode}
            onBlur={onBlur('postalCode')}
            margin="normal"
            required={true}
          />
        </div>
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Typography variant="body1" className={classes.secondaryBackground} style={{ width: '100%', paddingLeft: '10px', paddingTop: '6px', textAlign: 'left', height: '31px' }} id="modal-title">
            Contact Information
          </Typography>
          <div className={classes.row}>
            <TextField
              label={"Primary Contact"}
              className={classes.textField}
              value={company.primaryContact ? company.primaryContact : ''}
              onFieldChange={onFieldChange('primaryContact')}
              name="primaryContact"
              error={!!errors.primaryContactInvalid}
              onBlur={onBlur('primaryContact')}
              margin="normal"
            />
            <TextField
              label={"Primary Title"}
              className={classes.textField}
              value={company.primaryContactTitle ? company.primaryContactTitle : ''}
              onFieldChange={onFieldChange('primaryContactTitle')}
              error={!!errors.primaryContactTitleInvalid}
              onBlur={onBlur('primaryContactTitle')}
              name="primaryContactTitle"
              margin="normal"
            />
          </div>
          <div className={classes.row}>
            <TextField
              label={"Phone Number"}
              className={classes.textField}
              onFieldChange={onFieldChange('phone')}
              name="phone"
              value={company.phone ? company.phone : ''}
              error={!!errors.phoneInvalid}
              onBlur={onBlur('phone')}
              type="phone"
              margin="normal"
            />
            <TextField
              label={"Contact Email"}
              type="email"
              value={company.email ? company.email : ''}
              className={classes.textField}
              onFieldChange={onFieldChange('email')}
              name="email"
              error={!!errors.emailInvalid}
              onBlur={onBlur('email')}
              margin="normal"
            />
          </div>
        </div>
      </>);
  }
}

CompanyEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompanyEdit);
