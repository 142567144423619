import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { GridColumn } from '@progress/kendo-react-grid';
import ODataGrid from 'Components/Grid/ODataGrid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getAccounts,
  getAccountColumns,
  saveAccountColumns,
  saveAccounts,
  reorderAccountColumns,
} from 'actions/account';
import { getStateODataSettings } from 'actions/settings'; // this should replace page size
import { getSalesRepsIfNeeded } from 'actions/contact';
import { accountsSelected } from 'actions/api';
import { getLocations } from 'actions/location';
import SelectedColumn from 'Components/Grid/SelectedColumn';
import DropdownCell from 'Components/Grid/DropdownCell';
import dropdownFilterCell from 'Components/Grid/dropdownFilterCell';
import GridToolBar from 'Components/Grid/GridToolBar';
import { buildGridCellLink } from 'Components/buildGridCellLink';
import { addAccount } from 'actions/account';
import SimpleDialog from 'Components/SimpleDialog';
import moment from 'moment';

const styles = () => ({
  readOnlyColumn: {
    backgroundColor: '#ededed',
    '& .k-link, .k-link:hover': {
      cursor: 'not-allowed',
    },
  },
});

const okDialog = React.createRef();

class AccountGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIds: {},
      isFilterable: false,
      isEditable: false,
      isGridToggled: false,
      salesRepsMap: {},
      salesRepData: [],
      columns: props.columns,
    };
    this.linkOptions = {
      url: account => `/accounts/${account.id}`,
      text: account => account.name,
    };
  }

  customFilters = [
    {
      id: 2, name: 'Accounts Added Today',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'createdUtcDateTime',
            operator: 'gt',
            value: moment().startOf('day').utc().toDate(),
          },
          {
            field: 'createdUtcDateTime',
            operator: 'lt',
            value: moment().endOf('day').utc().toDate(),
          },
        ],
      },
    },
    {
      id: 3, name: 'Accounts Added This Week',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'createdUtcDateTime',
            operator: 'gt',
            value: moment().startOf('week').utc().toDate(),
          },
          {
            field: 'createdUtcDateTime',
            operator: 'lt',
            value: moment().endOf('week').utc().toDate(),
          },
        ],
      },
    },
  ];

  componentDidMount() {
    this.props.accountsSelected({});
    // this.props.getSalesRepsIfNeeded(); TODO
    this.props.getAccountColumns();

    // if (this.props.salesRepsData.length) {
    //   this.setupSalesReps(this.props.salesRepsData);
    // } TODO

    // this.setupLocations(getLocations()); TODO
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accounts !== this.props.accounts) {
      this.setState({ accounts: this.setSelectedAccounts(this.state.selectedIds) });
    }
    if (prevProps.salesRepsData !== this.props.salesRepsData) {
      this.setupSalesReps(this.props.salesRepsData);
    }
    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: this.props.columns });
    }
  }

  setupSalesReps = salesReps => {
    const dropDownOptions = [];
    const salesRepsMap = {};

    if (salesReps) {
      salesReps.forEach(s => {
        dropDownOptions.push({
          text: s.name,
          id: s.id,
        });
        salesRepsMap[s.id] = s.name;
      });
    }

    this.salesRepFilterCell = dropdownFilterCell(dropDownOptions, 'Sales Rep');
    this.setState({ salesRepsMap, salesReps });
  };

  setSelectedAccounts = (selectedIds = {}) => {
    const { accounts } = this.props;

    if (accounts.length === 0) {
      return accounts;//avoid creating a new array each time
    }

    return accounts.map(account => ({
      ...account,
      selected: selectedIds[account.id] === true,
    }));
  }

  saveAccounts = accounts => {
    if (this.state.isQuickAdding) {
      accounts = accounts.filter(c => c.isEditable);
    }
    this.props.saveAccounts(accounts)
      .then(() => {
        this.cancelEditMode();
        this.props.getAccounts();
      })
      .catch(window.alert);
  }

  enterEditMode = () => {
    if (!this.state.isQuickAdding) {
      this.setState({ isEditable: true });
    }
  }

  cancelEditMode = () => {
    if (this.state.isQuickAdding) {
      const accounts = this.state.accounts.filter(c => !c.isEditable);

      this.setState({ accounts });
    }
    this.setState({ isEditable: false, isQuickAdding: false });
  }

  onQuickAdd = () => {
    const { currentLocation } = this.props;

    if (currentLocation && currentLocation.id) {
      this.createAccountAndGo();
    } else {
      okDialog.current.open('Please select a location before creating an account');
    }
  }

  createAccountAndGo = () => {
    const { addAccount, currentLocation, history } = this.props;

    addAccount({ name: 'New', locationId: currentLocation.id }).then(account => {
      history.push(`/accounts/${account.id}`);
    });
  }

  onFilterClick = () => {
    this.setState({ isFilterable: !this.state.isFilterable });
  }

  onAccountSelected = ({ id, selected, allSelected }) => {
    const { accounts, selectedIds } = this.state;
    let newAccounts;

    if (allSelected !== undefined) {
      newAccounts = accounts.map(c => {
        c.selected = allSelected;
        selectedIds[c.id] = allSelected;

        return c;
      });
    } else {
      newAccounts = accounts.map(c => {
        if (c.id === id) {
          c.selected = selected;
        }
        selectedIds[c.id] = c.selected;

        return c;
      });
    }
    this.setState({ accounts: newAccounts, selectedIds });
    this.props.accountsSelected(selectedIds);
  };

  onGridEditClick = () => {
    this.setState({ isGridToggled: !this.state.isGridToggled });
  }

  getToolbar = () => (
    <GridToolBar
      isFilterToggled={this.state.isFilterable}
      isEditToggled={this.state.isEditable}
      isGridToggled={this.state.isGridToggled}
      onFilterClick={this.onFilterClick}
      onEditClick={this.enterEditMode}
      onAddClick={this.onQuickAdd}
      onGridEditClick={this.onGridEditClick}
      onColumnsSubmit={this.saveGridColumns}
      onCloseMenu={this.onGridEditClose}
      columns={this.state.columns}
      gridContext="Accounts"
      customFilters={this.customFilters}
      onApplyCustomFilter={this.applyCustomFilter}
      activeCustomFilter={this.state.globalFilter}
    />
  )

  applyCustomFilter = filterId => {
    const foundFilter = this.customFilters.find(filter => filter.id === filterId);

    this.setState({ globalFilter: foundFilter });
  }

  onSalesRepChange = props => newValue => {
    props.onChange({ dataItem: props.dataItem, field: newValue.name, value: newValue.value });
  }

  saveGridColumns = columns => {
    this.props.saveAccountColumns(columns);
  }

  onGridEditClose = () => {
    this.setState({ isGridToggled: false });
  }

  reorderColumns = event => {
    const { columns: reOrderColumns } = event;
    // If the data field does not match the column field name, we have to fix it here:
    const fieldNames = {
      'salesRepId': 'salesRep',
    };
    const reorderedColumns = reOrderColumns.sort((a, b) => a.orderIndex - b.orderIndex).map(column => fieldNames[column.field] || column.field);

    this.props.reorderAccountColumns(reorderedColumns);
  }

  render() {
    const {
      accounts,
      isEditable,
      isFilterable,
      isQuickAdding,
      columns,
      globalFilter,
    } = this.state;

    const {
      isLoading,
      total,
      getStateODataSettings,
    } = this.props;

    return (
      columns.length > 0 && <>
      <ODataGrid
        getData={this.props.getAccounts}
        items={accounts}
        total={total}
        isLoading={isLoading}
        isQuickAdding={isQuickAdding}
        selectionChanged={this.onAccountSelected}
        allItemsSelected={({ selected }) => this.onAccountSelected({ allSelected: selected })}
        isSortable={!isEditable}
        filterable={!isEditable && isFilterable}
        isEditable={isEditable}
        pageSize={getStateODataSettings()}
        onSave={this.saveAccounts}
        onCancel={this.cancelEditMode}
        toolBar={this.getToolbar()}
        onColumnReorder={this.reorderColumns}
        filter={(globalFilter && globalFilter.filter)}
      >
        {columns.map(column => this.getColumn(column))}
      </ODataGrid>
      <SimpleDialog
        onlyOkayButton={true}
        innerRef={this.okDialog}
      />
    </>);
  }

  setupLocations = locations => {
    const dropDownOptions = locations.map(s => (
      {
        text: s.name,
        id: s.id,
      }));
    const locationsFilterCell = dropdownFilterCell(dropDownOptions, 'Locations');

    this.setState({ locationsFilterCell });
  };

  getColumn(column) {
    const {
      isEditable,
      isQuickAdding,
      salesReps,
      salesRepsMap,
      accounts,
      locationsFilterCell,
    } = this.state;
    const { classes } = this.props;

    if (column.isVisible) {
      switch (column.field) {
        case 'selected':
          return SelectedColumn(accounts, !isEditable && !isQuickAdding);
        case 'name':
          return isEditable || isQuickAdding ?
            <GridColumn key={column.field} field={column.field} title={column.title} /> :
            <GridColumn key={column.field} field={column.field} title={column.title} cell={buildGridCellLink(this.linkOptions)} />;
        case 'type':
          return (
            <GridColumn
              key={column.field}
              field={column.field}
              title={column.title}
              editable={false} //TODO: make this a dropdown with account type options
            />);
        case 'salesRep':
          return (
            <GridColumn
              key={column.field}
              field="salesRepId"
              title={column.title}
              filterCell={this.salesRepFilterCell}
              cell={props => isEditable || props.dataItem.isEditable ?
                <DropdownCell options={salesReps} onChange={this.onSalesRepChange(props)} value={props.dataItem.salesRepId || -1} name="salesRepId" /> :
                <td>{salesRepsMap[props.dataItem.salesRepId]}</td>} />);
        case 'location':
          return (
            <GridColumn
              key={column.field}
              field="location"
              title={column.title}
              sortable={false}
              filterCell={locationsFilterCell}
            />);
        case 'primaryContact.name':
          return (
            <GridColumn
              headerClassName={classes.readOnlyColumn}
              key={column.field}
              field="primaryContact.name"
              title={'Contact Name'}
              sortable={false}
              editable={false}
              filterable={false}
            />);
        case 'primaryContact.phone':
          return (
            <GridColumn
              headerClassName={classes.readOnlyColumn}
              key={column.field}
              field="primaryContact.phone"
              title={'Contact Phone'}
              sortable={false}
              editable={false}
              filterable={false}
            />);
        case 'primaryContact.email':
          return (
            <GridColumn
              headerClassName={classes.readOnlyColumn}
              key={column.field}
              field="primaryContact.email"
              title={'Contact Email'}
              sortable={false}
              editable={false}
              filterable={false}
            />);
        default:
          return <GridColumn key={column.field} field={column.field} title={column.title} />;
      }
    }
  }
}

const mapStateToProps = state => {
  const {
    api: {
      currentLocation,
      accounts: {
        data,
        isLoading,
        total,
      },
      salesReps: {
        salesRepsData,
      },
    },
    settings: {
      accounts: {
        columns,
      },
    },
  } = state;

  return {
    currentLocation,
    accounts: data,
    columns,
    isLoading,
    total,
    salesRepsData,
  };
};

const mapDispatchToProps = {
  getSalesRepsIfNeeded,
  saveAccounts,
  getAccounts,
  accountsSelected,
  getAccountColumns,
  saveAccountColumns,
  getLocations,
  reorderAccountColumns,
  addAccount,
  getStateODataSettings,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountGrid)));
