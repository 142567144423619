import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import PropTypes from 'prop-types';
import { addItemToStoreOrder, updateStoreOrderItem } from 'actions/order';
import TextField from 'Components/TextField';
import classNames from 'classnames';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SectionHeader from 'Components/SectionHeader';
import DecimalField from 'Components/DecimalField';
import {
  getRevenueTypes,
} from 'actions/item';

const styles = theme => ({

  formSection: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%', //this allows for 2 columns of checkboxes - which matches mocks. Remove width to use the full width
  },

  container: {
    display: 'flex',
    overflow: 'hidden',
  },
  detailsContainer: {
    display: 'flex',
    overflow: 'hidden',
    marginLeft: 15,
    marginRight: 15,
  },
  modifierContainer: {
    flex: '1',
    margin: '5px',
    maxHeight: '400px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  imageContainer: {
    flex: '0 0 300px',
    padding: '20px',
    width: '300px',
    borderRight: '1px solid #e6eaee',
  },
  quantitySection: {
    paddingTop: '10px',
  },
  priceLabel: {
    width: '100%',
    color: theme.palette.primary.dark,
    fontSize: 20,
    paddingLeft: 5,
    paddingTop: 10,
  },
  caloriesLabel: {
    width: '100%',
    fontSize: 20,
    paddingLeft: 5,
  },
  controlLabelRoot: {
    marginRight: theme.spacing.unit * 5,
  },
  radioSelectItem: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 15,
  },

  descriptionSection: {
    padding: 5,
    fontSize: '15px',
    height: 'auto',
  },

  itemQtyContainer: {
    display: 'flex',
    border: '1px solid #2189F8',
    borderRadius: 4,
    padding: 4,
  },
  itemQtyLabel: {
    paddingRight: 2,
    textAlign: 'left',
    fontSize: '15px',
  },
  itemQtyValue: {
    width: '40px',
    textAlign: 'center',
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
    fontSize: '15px',
  },
  itemDetails: {
    display: 'flex',
    width: '260px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  itemButton: {
    padding: 4,
    display: 'flex',
    fontSize: '15px',
    color: '#2189F8',
    textTransform: 'none',
    minWidth: '30px',
  },
  radioSection: {
    width: '514px',
  },
  masonry: {
    display: 'flex',
    padding: '8px 0',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
  },
  fieldWrapper: {
    display: 'flex',
    flex: 1,
  },
  halfWidth: {
    display: 'flex',
    width: '50%',
  },
  fieldIsEdited: {
    background: theme.palette.error.light,
  },
  formRow: {
    display: 'flex',
    flexGrow: 1,
    marginLeft: 30,
  },
  checkboxLabel: {
    color: '#9B9B9B',
    marginLeft: -5,
  },
});

class AlacarteModal extends Component {

  state = {
    name: '',
    openItem: true,
    quantity: 1,
    price: '',
    unitPrice: 0,
    revenueTypeId: 5,
    notes: '',
    index: 0,
    id: 0,
    selected: '',
  };

  componentDidMount() {
    const { selectedOrderItem } = this.props;

    this.props.getRevenueTypes();

    if (selectedOrderItem) {
      const item = selectedOrderItem.orderItem;

      this.setState({
        name: item.name,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        revenueTypeId: item.revenueTypeId,
        notes: item.notes,
        id: item.id,
        index: selectedOrderItem.index,
        selected: item.selected,
        noGratuity: item.noGratuity,
        noServiceCharge: item.noServiceCharge,
        noTax: item.noServiceCharge,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.quantity !== this.state.quantity || prevState.unitPrice !== this.state.unitPrice) {
      this.setState({ price: this.state.quantity * this.state.unitPrice });
    }
  }

  onFieldChange = fieldName => value => {
    console.log(value);

    this.setState({ [fieldName]: value });
  }

  fieldChecked = fieldName => event => {
    this.setState({ [fieldName]: event.target.checked });
  }

  onSave2 = () => {
    const {
      name,
      quantity,
      price,
      unitPrice,
      revenueTypeId,
      notes,
      id,
      noTax,
      noServiceCharge,
      noGratuity,
    } = this.state;

    if (id) {
      this.props.updateStoreOrderItem({ id, name, orderItemModifiers: [], quantity, price, unitPrice, revenueTypeId, notes, noTax, noServiceCharge, noGratuity, openItem: true });
    } else {
      this.props.addItemToStoreOrder({ name, orderItemModifiers: [], quantity, price, unitPrice, revenueTypeId, notes, noTax, noServiceCharge, noGratuity, openItem: true });
    }
    this.props.onModalClosed();
  }

  render() {
    const {
      classes,
      onModalClosed,
      revenueTypes,
    } = this.props;
    const {
      name,
      quantity,
      unitPrice,
      revenueTypeId,
      notes,
      noTax,
      noServiceCharge,
      noGratuity,
    } = this.state;

    return (
      <Modal
        isOpened={true}
        onCancel={onModalClosed}
        onSave={this.onSave2} // TODO
        title={'Open Item'}
        addTitleBottomBorder={true}
        dimensions={{ width: 'unset', height: 'unset', maxWidth: '823px', maxHeight: '775px' }}
      >
        <div className={classes.container}>
          <div className={classNames(classes.column)}>
            <SectionHeader>
              Item Information
            </SectionHeader>
          </div>
        </div>
        {revenueTypes && <div className={classes.detailsContainer}>
          <div className={classNames(classes.column, classes.halfWidth)}>
            <div className={classNames(classes.fieldWrapper)}>
              <TextField
                label="Title"
                value={name}
                onFieldChange={this.onFieldChange('name')}
              />
            </div>
            <div className={classNames(classes.fieldWrapper)}>
              <DecimalField
                prefix="$"
                minValue={0}
                label="Price"
                value={unitPrice}
                onFieldChange={this.onFieldChange('unitPrice')}
                maxLength={20}
              />
            </div>
          </div>
          <div className={classNames(classes.column, classes.halfWidth)}>
            <div className={classNames(classes.fieldWrapper)}>
              <TextField
                label="Quantity"
                value={quantity}
                onFieldChange={this.onFieldChange('quantity')}
                type="number"
                minValue={0}
              />
            </div>
            <div className={classNames(classes.fieldWrapper)}>
              <Select
                label="Tax"
                value={revenueTypeId}
                onFieldChange={this.onFieldChange('revenueTypeId')}
              >
                {revenueTypes.map(revenueType => <MenuItem key={revenueType.id} value={revenueType.id} selected={true}>{revenueType.name}</MenuItem>)}
              </Select>
            </div>
          </div>
        </div>}
        <div className={classes.detailsContainer}>
          <div className={classNames(classes.column)}>
            <div className={classNames(classes.fieldWrapper)}>
              <TextField
                label="Notes"
                value={notes}
                onFieldChange={this.onFieldChange('notes')}
              />
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classNames(classes.column)}>
            <SectionHeader>
              Additional Adjustments
            </SectionHeader>
            <div className={classNames(classes.fieldWrapper)}>
              <div className={classes.formRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.selected}
                      onChange={this.fieldChecked('noTax')}
                      checked={!!noTax}
                      color="primary"
                      className={classes.checkboxWidth}
                      disableRipple={true}
                    />
                  }
                  label="No Tax"
                  classes={{ label: classes.checkboxLabel }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.selected}
                      onChange={this.fieldChecked('noServiceCharge')}
                      checked={!!noServiceCharge}
                      color="primary"
                      className={classes.checkboxWidth}
                      disableRipple={true}
                    />
                  }
                  label="No Service Charge"
                  classes={{ label: classes.checkboxLabel }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.props.selected}
                      onChange={this.fieldChecked('noGratuity')}
                      checked={!!noGratuity}
                      color="primary"
                      className={classes.checkboxWidth}
                      disableRipple={true}
                    />
                  }
                  label="No Gratuity"
                  classes={{ label: classes.checkboxLabel }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>);
  }
}

AlacarteModal.propTypes = {
  onModalClosed: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addItemToStoreOrder,
  updateStoreOrderItem,
  getRevenueTypes,
};

const mapStateToProps = state => {
  const {
    api: {
      revenueTypes,
    },
  } = state;

  return {
    revenueTypes: revenueTypes,
  };
};

// We need an intermediary variable for handling the recursive nesting.

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlacarteModal));
