export function convertTimeStringToSeconds(timeString) {
  if (timeString && /:/.test(timeString) > -1) {
    var splitValue = timeString.split(':');
    var seconds = parseInt(splitValue[0]) * 3600 + parseInt(splitValue[1]) * 60 + parseInt(splitValue[2]);

    return seconds;
  }

  return 0;
}
