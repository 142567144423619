import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

import {
  GET_COUPONS,
  COUPONS_LOADED,
  SET_CURRENT_COUPON,
  CLEAR_CURRENT_COUPON,
  COUPON_CREATED,
  COUPON_UPDATED,
} from './constants';

export const getCoupon = couponId => async dispatch =>
  dispatch(callApi(`coupon/${couponId}`))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

export const updateCoupon = coupon => async dispatch =>
  dispatch(callApi(`coupon/${coupon.id}`, { body: coupon, method: 'PUT' }))
    .then(results => {
      ParseJsonOnSuccessOrStop(dispatch, results).then(result => {
        if (result) {
          dispatch(couponUpdated(result));
        }

        return result;
      });
    })
    .catch(console.error);

export const createCoupon = coupon => async dispatch => {
  dispatch(callApi(`coupon`, { body: coupon, method: 'POST' }))
    .then(results => {
      ParseJsonOnSuccessOrStop(dispatch, results).then(result => {
        if (result) {
          dispatch(couponCreated(result));
        }

        return result;
      });
    })
    .catch(console.error);
};

export const deleteCoupon = coupon => async dispatch => {
  dispatch(callApi(`coupon/${coupon.id}`, { body: coupon, method: 'DELETE' }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);
};

export const getCouponsBegin = params => ({
  type: GET_COUPONS,
  params,
});

export const getCoupons = (params, append = false) => {
  return dispatch => {
    dispatch(getCouponsBegin(params));

    return getCouponsApi(dispatch, params).then(result => {
      // TODO: Find a better to clean up bad address

      dispatch(couponsReceived(result, append));

      return result;
    });
  };
};

const getCouponsApi = (dispatch, params) => {
  return dispatch(callApi(`coupon?${params}`)).then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

export const couponsReceived = (data, append) => ({
  type: COUPONS_LOADED,
  data,
  append,
});

export const setCurrentCoupon = coupon => dispatch => dispatch({
  type: SET_CURRENT_COUPON,
  coupon,
});

export const clearCurrentCoupon = () => dispatch => dispatch({
  type: CLEAR_CURRENT_COUPON,
});

export const couponUpdated = coupon => dispatch => dispatch({
  type: COUPON_UPDATED,
  coupon,
});

export const couponCreated = coupon => dispatch => dispatch({
  type: COUPON_CREATED,
  coupon,
});
