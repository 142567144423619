import React, { Component, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProductDetailsModal from 'orders/wizard/ModifierModal/ProductDetailsModal';
import PropTypes from 'prop-types';
import MenuCategoryContext from './MenuCategoryContext';

const styles = theme => ({
  checkboxWidth: {
    width: 182,
  },
  formSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 9,
    width: 450, //this allows for 2 columns of checkboxes - which matches mocks. Remove width to use the full width
  },
  container: {
    overflowX: 'auto',
  },
});

export class BulkModifiersModal extends Component {

  state = {
    itemIndex: 0,
  };

  onSave = async item => {
    const {
      onSave,
      onModalClosed,
      sets,
    } = this.props;
    const { itemIndex } = this.state;
    const lastItem = itemIndex + 1 === sets.length;

    await onSave(itemIndex, item);

    if (lastItem) {
      onModalClosed();
    } else {
      this.setState({ itemIndex: itemIndex + 1 });
    }
  }

  checkChanged = (set, modifier) => event => {
    modifier.selected = event.target.checked;

    const sets = [...this.state.sets]; //this is okay that this is not a deep copy because the parent components doesn't used this object at all

    this.setState({ sets });
  }

  setOrderItemModifiers = () => {};

  setModifiersError = () => {};

  render() {
    const { onModalClosed, title, sets } = this.props;
    const { itemIndex } = this.state;

    return (
      <ProductDetailsModal
        onModalClosed={onModalClosed}
        title={title}
        isSaveDisabled={false}
        onSave={this.onSave}
        item={sets[itemIndex]}
        itemsTotal={sets.length}
        itemIndex={itemIndex}
        setIndex={itemIndex => this.setState({ itemIndex })}
        modifiersFromOrder={() => {}}
      />
    );
  }
}

BulkModifiersModal.propTypes = {
  sets: PropTypes.array.isRequired,
  onModalClosed: PropTypes.func.isRequired,
  title: PropTypes.string,
};

const BulkModifiersModalContainer = props => {
  const {
    updateSetsOfModifiers,
  } = useContext(MenuCategoryContext);

  return (<BulkModifiersModal
    {...props}
    updateSetsOfModifiers={updateSetsOfModifiers}
  />);
};

export default withStyles(styles)(BulkModifiersModalContainer);
