import React, { Component } from 'react';
import {
  TextField as MaterialTextField,
  InputAdornment,
} from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  flexGrowWithPad: {
    flexGrow: 1,
    marginLeft: 16,
    marginRight: 16,
  },
  label: {
    color: theme.palette.grey[200],
  },
  input: {
    color: theme.palette.grey[700],
  },
  adornment: {
    marginLeft: 0,
    '& p': {
      width: 5,
    },
  },
});

class DecimalField extends Component {

  fieldChanged = e => {
    if (!this.props.onFieldChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    this.start = e.target.selectionStart;
    let val = e.target.value;

    val = val.replace(/([^0-9.]+)/, "");
    //val = val.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
    const value = match[1] + match[2];

    e.target.value = value;
    this.props.onFieldChange(value);

    if (val.length > 0) {
      e.target.value = Number(value).toFixed(2);
      e.target.setSelectionRange(this.start, this.start);
      this.props.onFieldChange(Number(value).toFixed(2));
    }
  }

  onBlur = () => {
    this.props.onBlur && this.props.onBlur();
  }

  render() {
    const {
      classes,
      disabled,
      value,
      fieldName,
      label,
      placeholder,
      error,
      type,
      className,
      margin,
      inputRef,
      helperText,
      maxLength,
      prefix,
      suffix,
    } = this.props;

    return (
      <MaterialTextField
        label={label}
        placeholder={placeholder}
        error={error}
        className={classNames(classes.flexGrowWithPad, className)}
        onChange={this.fieldChanged}
        name={fieldName}
        margin={margin || "normal"}
        value={value}
        disabled={disabled}
        type={type}
        helperText={helperText}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },

        }}
        InputProps={{
          maxLength: maxLength,
          classes: {
            root: classes.input,
            disabled: classes.input,
          },
          onBlur: this.onBlur,
          endAdornment: suffix && <InputAdornment className={classes.adornment} position="end">
            {suffix}
          </InputAdornment>,
          startAdornment: prefix && <InputAdornment className={classes.adornment} position="start">
            {prefix}
          </InputAdornment>,
        }}
        inputRef={inputRef}
      />
    );
  }
}

export default withStyles(styles)(DecimalField);
