import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import OneDimensionalGraphWidget from 'Components/GraphWidgets/OneDimensionalGraphWidget';
import GenericDoughnut from 'Components/GraphWidgets/GenericDoughnut';
import SimpleDoghnut from 'Components/GraphWidgets/SimpleDoghnut';
import SalesWidget from 'Components/GraphWidgets/SalesWidget';
import { getLocations } from 'actions/location';
import { getMenuItemSales } from 'actions/menu';
import { oneDimensionalWidgetGraphType } from 'helpers/enums';

const styles = theme => {
  return {
    graphContainer: {
      display: 'flex',
      flexGrow: 1,
      paddingBottom: '3.2%',
    },
    lastGraphContainer: {
      display: 'flex',
      flexGrow: 1,
    },
    graphGroupContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
};

class MenuItemWidgets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      revenueTypeLabels: {},
    };
  }

  componentDidMount() {
    this.props.getMenuItemSales();

    const locations = this.props.getLocations();

    const locationLabels = locations.reduce((result, location) => ({ ...result, [location.id]: location.name }), {});

    this.setState({ locationLabels });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.revenueTypes !== this.props.revenueTypes && this.props.revenueTypes) {
      console.log(this.props.revenueTypes);

      const revenueTypeLabels = this.props.revenueTypes.reduce((result, item) => ({ ...result, [item.id]: item.name }), {});

      this.setState({ revenueTypeLabels });
    }

    if (prevProps.items !== this.props.items) {
      this.props.getMenuItemSales();
    }
  }

  render() {
    const { classes, items, sales } = this.props;
    const { revenueTypeLabels, locationLabels } = this.state;
    const selectedItems = items && items.length ? items.filter(item => item.selected) : items;

    const itemCountSettings = {
      isDifferenceVisible: false,
      isLabelVisible: true,
      label: 'Total Menu Item Count',
      title: '# of Products',
      denominatorUnits: '',
      graphType: oneDimensionalWidgetGraphType.progress,
    };

    let data = items && Array.isArray(items) ? items.filter(items => items.selected) : [];

    if (data.length === 0) {
      data = items;
    }

    const fieldFilters = [
      {
        name: 'preparationArea',
        displayName: 'Preparation Area',
      },
      {
        name: 'revenueTypeId',
        displayName: 'Revenue Type',
        valueLabels: revenueTypeLabels,
      },
      {
        name: 'locationId',
        displayName: 'Location',
        valueLabels: locationLabels,
      },
      {
        name: 'serviceware',
        displayName: 'Serviceware',
        stringList: true,
      },
      {
        name: 'storageArea',
        displayName: 'Storage Area',
      },
      {
        name: 'tags',
        displayName: 'Tags',
        list: true,
      }];

    return (
      <div className={classes.graphGroupContainer}>
        <div className={classes.graphContainer}>
          {items && <OneDimensionalGraphWidget
            denominator={items.length}
            numerator={selectedItems.length}
            settings={itemCountSettings}
          />}
        </div>
        <div className={classes.graphContainer}>
          <GenericDoughnut
            data={data}
            title="Menu Item Breakdown"
            fields={fieldFilters}
          />
        </div>
        <div className={classes.graphContainer}>
          <SimpleDoghnut
            title="Menu Associations"
            data={sales.menuItemMenuAssociationTotals}
          />
        </div>
        <div className={classes.graphContainer}>
          <SalesWidget
            data={sales}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    menuItem: {
      data,
      sales,
    },
    api: {
      revenueTypes,
    },
  } = state;

  return {
    items: data,
    revenueTypes,
    sales,
  };
};

const mapDispatchToProps = {
  getLocations,
  getMenuItemSales,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MenuItemWidgets));
