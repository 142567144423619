import {
  SET_STORE_ORDER,
  ADD_STORE_ORDER_ITEM,
  SET_STORE_MENU,
  SET_STORE_MENU_INDEX,
  UPDATE_STORE_MENU,
  UPDATE_STORE_ORDER,
  UPDATE_STORE_MENU_SELECTED_CATEGORY_ITEMS,
  RECEIVE_ORDER,
  REVERT_STORE_MANAGED_ORDER,
  ORDER_WIDGET_DATA_RECEIVED,
  ORDER_DASHBOARD_DATA_RECEIVED,
  FETCH_ORDERS_BEGIN,
  RECEIVE_ORDERS,
  RECEIVE_ORDERS_LIST,
  ORDERS_SELECTED,
  SET_STORE_ORIGINAL_ORDER,
  SET_STORE_MENU_FILTER,
  SET_STORE_MENU_CATEGORIES,
  CLEAR_STORE_ORDER,
} from "actions/constants";

const initialState = {
  orders: {
    isLoading: true,
    data: [],
    list: [],
    selectedOrders: [],
  },
  storeMenuFilter: [],
  widgetData: {},
  dashboardData: [],
  storeOrder: {},
  storeOriginalOrder: {},
  storeMenus: [],
  storeCurrentMenuIndex: 0,
  storeAllMenuItems: [],
  storeMenuCategories: [],
  storeMenuSelectedCategoryItems: [],
};

export function storeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDERS_BEGIN: {
      const {
        params: { filters, pageSize, search, skip, sortDirection, sortField },
      } = action;
      const params = {
        filters,
        pageSize,
        search,
        skip,
        sortDirection,
        sortField,
      };
      const { orders } = state;

      return {
        ...state,
        orders: {
          ...orders,
          isLoading: true,
          params,
        },
      };
    }
    case RECEIVE_ORDERS: {
      const { data, append } = action;
      const { orders } = state;

      return {
        ...state,
        orders: {
          ...orders,
          isLoading: false,
          data: append ? orders.data.concat(data) : data,
          selectedOrders: append ? orders.selectedOrders : [],
        },
      };
    }
    case RECEIVE_ORDERS_LIST: {
      const { list, append } = action;
      const { orders } = state;

      // Ignore orders that are in "Created" status to indirectly address early order confirmation
      // emails
      const filteredList = list.filter(
        item => item.orderStatus !== "Created"
      );

      return {
        ...state,
        orders: {
          ...orders,
          isLoading: false,
          list: append ? orders.list.concat(filteredList) : filteredList,
          selectedOrders: append ? orders.selectedOrders : [],
        },
      };
    }
    case ORDERS_SELECTED:
      const { orders } = action;

      return {
        ...state,
        orders: {
          ...state.orders,
          selectedOrders: orders,
        },
      };

    case CLEAR_STORE_ORDER: {
      const newState = {
        ...state,
        storeOrder: {},
      };

      return newState;
    }

    case SET_STORE_ORDER: {
      const { storeOrder } = action;

      const newState = {
        ...state,
        storeOrder,
      };

      return newState;
    }

    case SET_STORE_ORIGINAL_ORDER: {
      const { storeOriginalOrder } = action;

      const newState = {
        ...state,
        storeOriginalOrder,
      };

      return newState;
    }

    case SET_STORE_MENU: {
      // SELECT_PARENT_MENU
      const { storeMenuConfig } = action;
      const newState = {
        ...state,
        storeMenus: storeMenuConfig.storeMenus,
        storeMenuCategories: storeMenuConfig.storeMenuCategories,
        storeMenuSelectedCategoryItems:
          storeMenuConfig.storeMenuSelectedCategoryItems,
        storeMenuSelectedTags: storeMenuConfig.storeMenuSelectedTags,
        storeAllMenuItems: storeMenuConfig.storeAllItems,
      };

      return newState;
    }

    case UPDATE_STORE_MENU: {
      // SELECT_PARENT_MENU
      const { storeMenuConfig } = action;
      const newState = {
        ...state,
        storeMenuCategories: storeMenuConfig.storeMenuCategories,
        storeMenuSelectedCategoryItems:
          storeMenuConfig.storeMenuSelectedCategoryItems,
        storeMenuSelectedTags: storeMenuConfig.storeMenuSelectedTags,
      };

      return newState;
    }

    case SET_STORE_MENU_INDEX: {
      const { storeCurrentMenuIndex } = action;
      const newState = {
        ...state,
        storeCurrentMenuIndex,
      };

      return newState;
    }

    case SET_STORE_MENU_CATEGORIES: {
      const { storeMenuCategories } = action;

      const newState = {
        ...state,
        storeMenuCategories,
      };

      return newState;
    }

    case ADD_STORE_ORDER_ITEM: {
      const { item } = action;
      const storeOrder = state.storeOrder;
      let items = [];
      let newState = {};

      if (storeOrder.orderItems) {
        items = storeOrder.orderItems;

        newState = {
          ...state,
          storeOrder: { ...storeOrder, orderItems: [...items, item] },
        };
      } else {
        newState = {
          ...state,
          storeOrder: { ...storeOrder, orderItems: [item] },
        };
      }

      return newState;
    }

    case REVERT_STORE_MANAGED_ORDER: {
      const storeOriginalOrder = state.storeOriginalOrder;
      const newState = {
        ...state,
        storeOrder: storeOriginalOrder,
      };

      return newState;
    }

    case UPDATE_STORE_MENU_SELECTED_CATEGORY_ITEMS: {
      const { menuItems } = action;
      const newState = {
        ...state,
        storeMenuSelectedCategoryItems: menuItems,
      };

      return newState;
    }

    case UPDATE_STORE_ORDER: {
      const { storeOrder } = action;
      const newState = {
        ...state,
        storeOrder,
      };

      return newState;
    }
    case RECEIVE_ORDER: {
      const { order } = action;
      const newState = {
        ...state,
        storeOrder: order,
      };

      return newState;
    }
    case SET_STORE_MENU_FILTER: {
      const { filteredMenuItems, filteredCategories, currentCategoryItems } =
        action;
      const newState = {
        ...state,
        storeMenuSelectedCategoryItems: currentCategoryItems,
        storeMenuCategories: filteredCategories,
        filteredMenuItems,
      };

      return newState;
    }
    case ORDER_WIDGET_DATA_RECEIVED: {
      const { widgetData } = action;

      return {
        ...state,
        widgetData,
      };
    }
    case ORDER_DASHBOARD_DATA_RECEIVED: {
      const { dashboardData } = action;

      return {
        ...state,
        dashboardData,
      };
    }
    default:
      return state;
  }
}
