import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import { addClass } from '@syncfusion/ej2-base';
import {
  Drawer,
  Menu,
  MenuItem,
} from '@material-ui/core';
import classNames from 'classnames';
import { CALENDAR_MODE, CALENDAR_VIEW } from 'constants/calendarMode';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import Agenda from './Agenda';
import CalendarFooter from './CalendarFooter';
import ToolBar from './ToolBar';
import Scheduler from './Scheduler';
import DetailedAgenda from './DetailedAgenda';
import './MiniCalendar.scss';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

const drawerWidth = 290;

const styles = theme => {
  return {
    dateRangeHighlight: {
      fontWeight: 'bold !important',
    },

    nonAgenda: {
      flexShrink: 0,
    },
    calendarContainer: {
      flexGrow: 1,
      overflowY: 'auto',
      //display: 'flex',
    },
    calendarAgendaContainer: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: drawerWidth,
    },
    contentContainer: {
      display: 'flex',
      flexGrow: 1,
    },
    agendaContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      borderRight: `1px solid ${theme.palette.grey[100]}`,
    },
    detailedAgendaContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      padding: `12px 12px 0`,
      minWidth: '75%',
      maxWidth: '100%',
    },
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowY: 'hidden',
    },
    footerContainer: {
      alignItems: 'center',
      borderTop: `1px solid ${theme.palette.grey[400]}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    button: {
      border: '1px solid',
      borderRadius: '4px',
      fontSize: '13px',
      fontWeight: '400',
      minHeight: '32px',
      textTransform: 'initial',
    },
    addButton: {
      position: 'absolute',
      bottom: '88px',
      right: '24px',
      padding: '0 32px',
      boxShadow: theme.shadows[4],
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      position: 'relative',
      width: drawerWidth,
      overflowX: 'hidden',
    },
    drawerContent: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    drawerContentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    docked: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: drawerWidth,
    },
    dockedShift: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: 0,
    },
  };
};

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agendaHeight: -1,
      miniCalendarDate: this.props.schedulerDateSelected,
      mode: CALENDAR_MODE.Month,
      view: CALENDAR_VIEW.Calendar,
      isScheduleLoading: true,
      isDrawerOpen: true,
      menuTypeOpenedBy: null,
    };

    this.miniCalendar = React.createRef();
    this.agenda = React.createRef();
  }

  // This is how their demo does this
  onRenderDayCell = args => {
    const {
      firstDate,
      lastDate,
    } = this.state;
    const startOfDay = args.date.setHours(0, 0, 0);

    if (startOfDay >= firstDate && startOfDay <= lastDate) {
      const dateBoxElement = args.element;

      addClass([dateBoxElement.firstElementChild], ['e-day', 'special', this.props.classes.dateRangeHighlight]);
    }
  }

  onCalendarCreated = () => {
    this.setState({ agendaHeight: this.agenda.current.clientHeight });
  }

  onMiniCalendarDateSelected = args => {

  }

  onScheduleLoad = args => {
    this.setState({ isScheduleLoading: false, scheduleHeight: args.height });
  }

  gotoToday = () => {
    const today = moment(new Date()).startOf('day').toDate();

    this.scheduler.gotoDate(today);
  }

  gotoMode = mode => () => {
    this.setState({ mode });

    this.scheduler.gotoMode({ mode, view: this.state.view });
  }

  gotoPrevious = () => {
    const { mode, firstDate, lastDate } = this.state;

    if (mode === CALENDAR_MODE.Agenda) {
      const updatedFirstDate = moment(firstDate).clone().add(-7, 'days').startOf('day').toDate();
      const updatedLastDate = moment(lastDate).clone().add(-7, 'days').startOf('day').toDate();

      this.mainCalendarRangeChanged(updatedFirstDate, updatedLastDate, mode);
    } else {
      this.scheduler.gotoPrevious();
    }
  }

  gotoNext = () => {
    const { mode, firstDate, lastDate } = this.state;

    if (mode === CALENDAR_MODE.Agenda) {
      const updatedFirstDate = moment(firstDate).clone().add(7, 'days').startOf('day').toDate();
      const updatedLastDate = moment(lastDate).clone().add(7, 'days').startOf('day').toDate();

      this.mainCalendarRangeChanged(updatedFirstDate, updatedLastDate, mode);
    } else {
      this.scheduler.gotoNext();
    }
  }

  mainCalendarRangeChanged = (firstDate, lastDate, mode) => {
    this.setState(
      {
        miniCalendarDate: firstDate,
        firstDate,
        lastDate,
        mode,
      },
      () => {
        if (this.miniCalendar.current) {
          this.miniCalendar.current.navigateTo('Month', firstDate);
        }
      });
  }

  openEventTypeMenu = event => {
    this.setState({ menuTypeOpenedBy: event.currentTarget });
  }

  closeEventTypeMenu = () => {
    this.setState({ menuTypeOpenedBy: null });
  }

  openEditEventModal = () => {
    this.closeEventTypeMenu();
    this.scheduler.startNewEvent();
  }

  openBookingWizard = wizardType => () => {
    if (wizardType === 'booking' || wizardType === 'proposal') {
      this.closeEventTypeMenu();
      this.scheduler.openBookingWizard({ wizardType });
    }
  }

  handleDrawerOpen = () => {
    this.setState(prevState => ({ isDrawerOpen: !prevState.isDrawerOpen }));
  };

  switchView = view => () => {
    this.setState(prevState => {
      const newState = {
        view,
        mode: prevState.mode === CALENDAR_MODE.Week ? CALENDAR_MODE.Week : CALENDAR_MODE.Day,
      };

      this.scheduler.gotoMode(newState);

      return newState;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { schedulerDateSelected } = this.props;

    if (schedulerDateSelected !== prevProps.schedulerDateSelected) {
      if (this.miniCalendar.current) {
        this.miniCalendar.current.value = schedulerDateSelected;
      }
    }
  }

  onCalendarDateSelected = args => {
    const { mode } = this.state;
    const updatedDate = moment(args.value).startOf('day').toDate();

    if (mode === CALENDAR_MODE.Agenda) {
      this.mainCalendarRangeChanged(updatedDate, updatedDate, mode);

      return;
    };
    this.scheduler.gotoDate(updatedDate);
  }

  render() {
    const { classes } = this.props;
    const {
      agendaHeight,
      miniCalendarDate,
      mode,
      firstDate,
      isScheduleLoading,
      scheduleHeight,
      isDrawerOpen,
      menuTypeOpenedBy,
      view,
    } = this.state;
    const agendaStyle = agendaHeight === -1 ? {} : { height: agendaHeight - 16 };
    const calendarClass = mode === CALENDAR_MODE.Agenda ? 'hidden' : classes.calendarContainer;

    return (
      <div className={classes.pageContainer}>
        <ToolBar
          currentView={view}
          selectedDate={firstDate}
          currentMode={mode}
          onSwitchView={this.switchView}
          onMonthClick={this.gotoMode(CALENDAR_MODE.Month)}
          onWeekClick={this.gotoMode(CALENDAR_MODE.Week)}
          onDayClick={this.gotoMode(CALENDAR_MODE.Day)}
          onAgendaClick={this.gotoMode(CALENDAR_MODE.Agenda)}
          onTodayClicked={this.gotoToday}
          onNext={this.gotoNext}
          onPrevious={this.gotoPrevious}
          onDrawerOpen={this.handleDrawerOpen}
          onCalendarDateSelected={this.onCalendarDateSelected}
        />
        <div className={classes.contentContainer}>
          <Drawer
            variant="persistent"
            anchor="left"
            open={isDrawerOpen}
            classes={{
              paper: classes.drawerPaper,
              docked: classNames(classes.docked, !isDrawerOpen && classes.dockedShift),
            }}
          >
            <div className={classNames(classes.calendarAgendaContainer, classes.drawerContent, isDrawerOpen && classes.drawerContentShift)}>
              <div className={classes.nonAgenda}>
                {!isScheduleLoading &&
                  <CalendarComponent
                    ref={this.miniCalendar}
                    value={miniCalendarDate}
                    change={this.onMiniCalendarDateSelected}
                    created={this.onCalendarCreated}
                    renderDayCell={this.onRenderDayCell}
                    showTodayButton={false}
                  />
                }
              </div>
              <div className={classes.agendaContainer} ref={this.agenda} style={agendaStyle}>
                {isDrawerOpen && <Agenda height={agendaHeight} />}
              </div>
            </div>
          </Drawer>
          {mode === CALENDAR_MODE.Agenda &&
            (
              <div className={classes.detailedAgendaContainer}>
                <DetailedAgenda height={scheduleHeight} selectedDate={firstDate} />
              </div>
            )}
          <div className={calendarClass}>
            <Scheduler
              wrappedComponentRef={ref => {
                this.scheduler = ref && ref.wrappedInstance;
              }}
              theme={this.props.theme}
              onScheduleLoad={this.onScheduleLoad}
              currentMode={mode}
              currentView={view}
              onCalendarRangeChanged={this.mainCalendarRangeChanged}
            />
            <PrimaryButton
              classes={{ root: classes.addButton }}
              onClick={this.openEventTypeMenu}
              aria-owns={menuTypeOpenedBy ? 'simple-menu' : undefined}
              aria-haspopup="true"
            >
              + Add New
            </PrimaryButton>
            <Menu
              id="simple-menu"
              anchorEl={menuTypeOpenedBy}
              open={Boolean(menuTypeOpenedBy)}
              onClose={this.closeEventTypeMenu}
            >
              <MenuItem onClick={this.openBookingWizard('booking')}>Booking</MenuItem>
              <MenuItem onClick={this.openBookingWizard('proposal')}>Proposal</MenuItem>
              <MenuItem onClick={this.openEditEventModal}>Task or Personal</MenuItem>
            </Menu>
          </div>
        </div>
        <div className={classes.footerContainer}>
          <CalendarFooter view={view}  />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { api:
    {
      schedulerDateSelected,
    },
  } = state;

  return {
    schedulerDateSelected,
  };
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Calendar));

