import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import classNames from 'classnames';
import {
  AppBar,
  Paper,
  Menu,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  LocalDining as OrderIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as CloneIcon,
  Forum as ChitChatIcon,
  Email as EmailIcon,
} from '@material-ui/icons';
import { provideIntlService } from '@progress/kendo-react-intl';
import { withRouter } from 'react-router-dom';
import PrimaryButtonWithSpinner from 'Components/Buttons/PrimaryButtonWithSpinner';
import HighlightButton from 'Components/Buttons/HighlightButton';
import HeaderButton from 'Components/Buttons/HeaderButton';
import MiniButtonSet from 'Components/Buttons/MiniButtonSet';
import ArrowDownButton from 'Components/Buttons/ArrowDownButton';
import TaxAndServices from './TaxAndServices';
import ReportPreviewModal from 'Components/ReportPreviewModal';
import OrderPrints from './OrderPrints';
import { getReportDetails } from 'actions/reports';
import { uploadFile } from 'actions/file';
import { getStatusId } from 'actions/order';
import { ENTITY_TYPES } from 'constants/entityTypes';
import { connect } from 'react-redux';
import SimpleDialog from 'Components/SimpleDialog';
import CouponModal from 'portal/admin/coupons/CouponModal';
import {
  applyCoupon,
} from 'actions/order';
import {
  getRevenueTypes,
} from 'actions/item';
import DiscountType from 'models/DiscountType';

const styles = theme => ({
  appBarOverrides: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    fontSize: 16,
    height: 'unset',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 14px',
    backgroundColor: '#0095a4',
    color: theme.palette.common.white,
  },
  disabledTopbar: {
    backgroundColor: '#d1dbe1',
    color: '#677d7d',
  },
  topLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  topRight: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 32,
    width: 32,
    marginRight: 16,
    borderRadius: 4,
    fontSize: 32,
  },
  orderIcon: {
    fontSize: '48px',
    color: theme.palette.common.white,
  },
  rightIcon: {
    fontSize: '20px',
  },
  smallerFont: {
    fontSize: 17,
  },
  verticalAlign: {
    verticalAlign: 'middle',
  },
  orderName: {
    fontSize: 22,
    lineHeight: 1,
  },
  widerButton: {
    padding: '0 30px',
  },
  bottomBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 32px 12px 16px',
    backgroundColor: theme.common.blueGrey,
  },
  glanceSection: {
    display: 'flex',
  },
  marginRight: {
    marginRight: 16,
  },
  marginLeft: {
    marginLeft: 26,
  },
  glanceLabel: {
    color: theme.palette.grey[200],
    fontSize: 10,
  },
  glanceData: {
    color: theme.palette.grey[300],
    fontSize: 12,
    minWidth: 85,
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
  },
  link: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkReadOnly: {
    color: theme.palette.primary.dark,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  PrimaryButton: {
    backgroundColor: '#0095a4',
    borderColor: '#0095a4',
  },
  bookingValue: {
    margin: '-12px 16px',
    padding: '9px 40px 9px 16px',
    backgroundColor: '#0095a4',
    '& $glanceLabel': {
      color: theme.palette.common.white,
      fontSize: 12,
    },
    '& $glanceData': {
      color: theme.palette.common.white,
      fontSize: 14,
      fontWeight: '500',
    },
  },
  buttonSpacing: {
    padding: '0px 10px',
  },
  readOnlyBottomBar: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 32px 12px 16px',
    backgroundColor: theme.common.navy,
  },
  readOnlyOrderTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  readOnlyBookingValue: {
    backgroundColor: '#0095a4',
    '& $glanceLabel': {
      fontSize: 12,
    },
    '& $glanceData': {
      fontSize: 14,
      fontWeight: '500',
    },
  },
});

class OrderHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPrintsModalOpen: false,
      isReportPreviewOpen: false,
      toolsOpenedBy: null,
      isPaymentsModalOpen: false,
      isCompleteOrderModalOpen: false,
      isTaxModalOpen: false,
      couponModalOpen: false,
    };

    this.completeOrderDialog = React.createRef();
    this.okDialog = React.createRef();
  }

  today = moment();

  goToContact = () => {
    this.props.history.push(`/contacts/${this.props.storeOrder.contactId}`);
  }

  areAllEventsOnSameDay = booking => {
    const events = booking && booking.events && [...booking.events];

    if (events && events.length > 1) {
      const firstEvent = events[0];
      const lastEvent = events.slice(-1)[0];

      return moment(firstEvent.startTime).isSame(lastEvent.endTime, 'date');
    }

    return true;
  }

  onSetView = (synthEvent, viewState) => {
    synthEvent.stopPropagation();
    this.props.onSetView(viewState);
  }

  openToolsMenu = event => {
    this.setState({ toolsOpenedBy: event.currentTarget });
  }

  closeToolsMenu = () => {
    this.setState({ toolsOpenedBy: null });
  }

  openPaymentsModal = () => {
    this.setState({ isPaymentsModalOpen: true, toolsOpenedBy: null });
  }

  closePaymentsModal = () => {
    this.setState({ isPaymentsModalOpen: false });
  }

  openPrintsModal = () => {
    this.setState({ isPrintsModalOpen: true, toolsOpenedBy: null });
  }

  closePrintsModal = () => {
    this.setState({ isPrintsModalOpen: false });
  }

  openTaxAndServices = () => {
    this.setState({ toolsOpenedBy: null, isTaxModalOpen: true });
  }

  closeTaxAndServices = () => {
    this.setState({ isTaxModalOpen: false });
  }

  openCouponModal = () => {
    this.props.getRevenueTypes();
    this.setState({ couponModalOpen: true, toolsOpenedBy: null });
  }

  closeCouponModal = coupon => {
    const { storeOrder, applyCoupon } = this.props;

    this.setState({ couponModalOpen: false });

    if (coupon && storeOrder) {
      applyCoupon(storeOrder.id, null, coupon.id);
    }
  }

  onGeneratePrint = print => {
    if (print) {
      this.props.getReportDetails(print).then(reportPreview => {
        this.setState({ isPrintsModalOpen: false, isReportPreviewOpen: true, reportPreview });
      });
    }
  }

  onSavedAndEmailed = (andEmail, formData) => {
    this.setState({ isReportPreviewOpen: false });
    if (!formData) {
      return;
    }

    this.props.uploadFile(ENTITY_TYPES.order, this.props.storeOrder.id, 'private', formData).then(file => {
      if (andEmail) {
        this.props.openEmailWithFile(file);
      }
      this.props.filesUpdated();
    }).catch(error => this.okDialog.current.open(error.toString()));
  }

  completeOrderConfirmation = () => {
    const { storeOrder, statuses } = this.props;

    const statusId = getStatusId('Completed', storeOrder.type, statuses);

    this.setState({ isCompletingOrder: true });

    if (storeOrder.balanceDue > 0) {
      this.completeOrderDialog.current.open(`The order has a balance due of ${provideIntlService(this).formatNumber(storeOrder.balanceDue, 'c')}, are you sure you want complete this order?`)
        .then(() => {
          this.props.onUpdateOrderStatus(statusId).then(() => {
            this.setState({ isCompletingOrder: false });
          });
        }).catch(() => this.setState({ isCompletingOrder: false }));
    } else {
      this.props.onUpdateOrderStatus(statusId).then(() => {
        this.setState({ isCompletingOrder: false });
      }).catch(() => this.setState({ isCompletingOrder: false }));
    }
  }

  cancelOrderConfirmation = () => {
    const { storeOrder, statuses } = this.props;

    const statusId = getStatusId('Cancelled', storeOrder.type, statuses);

    this.setState({ isCancellingOrder: true });

    if (storeOrder.balanceDue !== storeOrder.total) {
      this.completeOrderDialog.current.open(`The order has a payments totaling ${provideIntlService(this).formatNumber(storeOrder.total - storeOrder.balanceDue, 'c')}, are you sure you want cancel this order?`)
        .then(() => {
          this.props.onUpdateOrderStatus(statusId).then(() => {
            this.setState({ isCancellingOrder: false });
          });
        }).catch(() => this.setState({ isCancellingOrder: false }));
    } else {
      this.props.onUpdateOrderStatus(statusId).then(() => {
        this.setState({ isCancellingOrder: false });
      });
    }
  }

  closeCompleteOrderModal = () => {
    this.setState({
      isCompleteOrderModalOpen: false,
    });
  }

  render() {
    const {
      classes,
      label,
      onEdit,
      onDelete,
      isEditing,
      onClone,
      viewState,
      storeOrder,
      statuses,
      revenueTypes,
      readOnly,
    } = this.props;
    const {
      toolsOpenedBy,
      isPrintsModalOpen,
      isTaxModalOpen,
      isReportPreviewOpen,
      reportPreview,
      isCompletingOrder,
      isCancellingOrder,
      couponModalOpen,
    } = this.state;

    const dateNeededMoment = moment(storeOrder.requestedDateTime);
    const timeNeeded = storeOrder.asap ? 'ASAP' : dateNeededMoment.clone().format('LT');
    const dateNeeded = dateNeededMoment.isValid() ? dateNeededMoment.clone().format('l') : null;
    const isCanceledOrder = storeOrder.orderStatusId === getStatusId('Cancelled', storeOrder.type, statuses);
    const isCompletedOrder = storeOrder.orderStatusId === getStatusId('Completed', storeOrder.type, statuses);

    return (
      <>
        <AppBar position="static" classes={{ root: classes.appBarOverrides }}>
          <div className={classes.topBar}>
            <div className={classes.topLeft}>
              <div className={classes.iconContainer}>
                <OrderIcon classes={{ root: classes.orderIcon }} />
              </div>
              <div className={classNames(classes.verticalAlign)}>
                <div className={classes.smallerFont}>{label}</div>
                <p className={classes.orderName}>
                  {storeOrder.id}
                </p>
              </div>
            </div>
            <div className={classes.topRight}>
              <MiniButtonSet>
                <HeaderButton
                  onClick={synthEvent => this.onSetView(synthEvent, 'orderDetails')}
                  isActive={viewState === 'orderDetails'}
                  name="Order"
                  disabled={readOnly}
                  icon={<OrderIcon className={classes.rightIcon} />}>
                </HeaderButton>
                <HeaderButton
                  onClick={synthEvent => this.onSetView(synthEvent, 'chitChat')}
                  isActive={viewState === 'chitChat'}
                  name="Chit Chat"
                  disabled={readOnly}
                  icon={<ChitChatIcon className={classes.rightIcon} />}>
                </HeaderButton>
                <HeaderButton
                  onClick={synthEvent => this.onSetView(synthEvent, 'email')}
                  isActive={viewState === 'email'}
                  name="Email"
                  disabled={readOnly}
                  icon={<EmailIcon className={classes.rightIcon} />}>
                </HeaderButton>
              </MiniButtonSet>
              <MiniButtonSet>
                <HeaderButton onClick={onEdit} isActive={isEditing} name="Edit" disabled={readOnly} icon={<EditIcon className={classes.rightIcon} />}></HeaderButton>
                <HeaderButton onClick={onDelete} name="Delete" disabled={readOnly} icon={<DeleteIcon className={classes.rightIcon} />}></HeaderButton>
                <HeaderButton onClick={onClone} name="Clone" disabled={readOnly} icon={<CloneIcon className={classes.rightIcon} />}></HeaderButton>
                <ArrowDownButton onClick={this.openToolsMenu} disabled={readOnly} />
                <Menu
                  id="tools-menu"
                  anchorEl={toolsOpenedBy}
                  open={Boolean(toolsOpenedBy)}
                  onClose={this.closeToolsMenu}
                >
                  <MenuItem onClick={this.openTaxAndServices}>Tax &amp; Service Charge</MenuItem>
                  <MenuItem onClick={this.openCouponModal}>Discounts</MenuItem>
                  <MenuItem onClick={this.openPrintsModal}>Prints</MenuItem>
                </Menu>
              </MiniButtonSet>
            </div>
          </div>
          <Paper elevation={1} square className={classes.bottomBar}>
            <div className={classes.glanceSection}>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Contact</p>
                <p onClick={this.goToContact} className={classNames(classes.glanceData, classes.link)}>{storeOrder.customerName || ''}</p>
              </div><div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Number</p>
                <p className={classNames(classes.glanceData, classes.linkReadOnly)}>{storeOrder.customerPhone || ''}</p>
              </div><div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Date</p>
                <p className={classes.glanceData}>{dateNeeded}</p>
              </div><div className={classes.marginRight}>
                {storeOrder.type === 'Event' ?
                  <p className={classes.glanceLabel}>Event Time</p> : <p className={classes.glanceLabel}>Time</p>}
                {!!storeOrder.orderEventDetails && storeOrder.type === 'Event' ?
                  <p className={classes.glanceData}>{moment(storeOrder.orderEventDetails.eventStartTime, 'H:mm').format('h:mm A') + ' - ' + moment(storeOrder.orderEventDetails.eventEndTime, 'H:mm').format('h:mm A')}</p> : <p className={classes.glanceData}>{timeNeeded}</p>}
              </div>{storeOrder.type === 'Event' ?
                <div className={classes.marginLeft}>
                  <p className={classes.glanceLabel}>Guests</p>
                  <p className={classes.glanceData}>{storeOrder.orderEventDetails.expectedGuestCount}</p></div> : ''}
            </div>
            <div className={classes.glanceSection}>
              <div className={classNames(classes.marginRight, classes.divider)}></div>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Order Received By</p>
                <p className={classes.glanceData}>{storeOrder.createdByName}</p>
              </div>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}>Placed Date Time</p>
                <p className={classes.glanceData}>{storeOrder.placedDateTime ? moment(storeOrder.placedDateTime).clone().format('l LT') : ''}</p>
              </div>
              <div className={classes.marginRight}>
                <p className={classes.glanceLabel}># of items</p>
                <p className={classes.glanceData}>{storeOrder.orderItems ? storeOrder.orderItems.length : 0}</p>
              </div>
            </div>
            <div className={classes.glanceSection}>
              <div className={readOnly ? classNames(classes.marginRight, classes.divider) : ''}></div>
              <div className={readOnly ? classes.disabledBookingValue : classes.bookingValue}>
                <p className={classes.glanceLabel}>Order Total</p>
                <p className={classes.glanceData}>{provideIntlService(this).formatNumber(storeOrder.total - storeOrder.couponDiscount, 'c')}</p>
              </div>
            </div>
            <div className={classes.glanceSection} style={readOnly ? { visibility: 'hidden' } : {}}>
              <div className={classes.buttonSpacing}>
                <HighlightButton
                  disabled={isCanceledOrder}
                  minWidth={125}
                  onClick={this.cancelOrderConfirmation}
                  isLoading={isCancellingOrder}
                >
                  Cancel Order
                </HighlightButton>
              </div><div>
                <PrimaryButtonWithSpinner
                  disabled={isCompletedOrder}
                  minWidth={125}
                  onClick={this.completeOrderConfirmation}
                  isLoading={isCompletingOrder}
                >
                  Complete Order
                </PrimaryButtonWithSpinner>
              </div>
            </div>
          </Paper>

          {readOnly ?
            <Paper elevation={2} square className={classes.readOnlyBottomBar}>
              <div className={classes.readOnlyOrderTitle}>This order is Read Only. Go to Caterease to view or edit details.</div>
            </Paper> : ''}
          <OrderPrints
            isOpened={isPrintsModalOpen}
            onGenerate={this.onGeneratePrint}
            onCancel={this.closePrintsModal}
          />
          <ReportPreviewModal
            onSaveAndEmail={this.onSavedAndEmailed}
            isOpened={isReportPreviewOpen}
            params={{ orderId: storeOrder.id }}
            report={reportPreview}
          />
          <TaxAndServices
            isOpened={isTaxModalOpen}
            onClose={this.closeTaxAndServices}
          />
          <SimpleDialog innerRef={this.completeOrderDialog} />
          <SimpleDialog onlyOkayButton={true} innerRef={this.okDialog} />
        </AppBar>
        {couponModalOpen &&
          <CouponModal
            discountMode={true}
            orderId={storeOrder.id}
            coupon={storeOrder.coupon || { active: true, discountType: DiscountType.Flat, couponDiscounts: [] }}
            usedCouponCodes={null}
            revenueTypes={revenueTypes}
            onCloseModal={this.closeCouponModal}
          />
        }
      </>
    );
  }
}

OrderHeader.propTypes = {
  label: PropTypes.string.isRequired,
  onSetView: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    api,
    storeReducer: {
      storeOrder,
    },
    settings: {
      statuses,
    },
  } = state;

  return {
    revenueTypes: api && api.revenueTypes ? api.revenueTypes : [],
    storeOrder,
    statuses,
    readOnly: state.settings.allAreaSettings.ENABLE_CATEREASE_INTEGRATION
      ? state.settings.allAreaSettings.ENABLE_CATEREASE_INTEGRATION.value
      : true,
  };
};

const mapDispatchToProps = {
  getReportDetails,
  uploadFile,
  getStatusId,
  getRevenueTypes,
  applyCoupon,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(OrderHeader)));
