import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

export const getRevenueTypeRates = () => async dispatch =>
  dispatch(callApi('RevenueTypeRate'))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results));

export const getRevenueTypeRatesByRevenueConfiguration = revenueConfigurationId => async dispatch =>
  dispatch(callApi(`RevenueConfiguration/${revenueConfigurationId}/RevenueTypeRate`))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results));

export const getRevenueTypeRate = RevenueTypeRateId => async dispatch =>
  dispatch(callApi(`RevenueTypeRate/${RevenueTypeRateId}`))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);

export const updateRevenueTypeRate = RevenueTypeRate => async dispatch =>
  dispatch(callApi(`RevenueTypeRate/${RevenueTypeRate.id}`, {
    body: RevenueTypeRate, method: 'PUT',
  }))
    .then(results => ParseJsonOnSuccessOrStop(dispatch, results))
    .catch(console.error);
