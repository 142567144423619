import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import classNames from 'classnames';
import WhiteButton from 'Components/Buttons/WhiteButton';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[200],
    borderRadius: '0px 0px 4px 4px',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    paddingTop: theme.spacing.unit * 2.5,
    height: 80,
    maxHeight: 80,
  },
  rootWithoutErrorMessage: {
    paddingBottom: theme.spacing.unit * 2.5,
  },
  buttonBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sticky: {
    position: 'sticky',
    bottom: 0,
  },
  marginRight: {
    marginRight: '31px',
  },
  compactButton: {
    minWidth: '85px !important',
    padding: '0 !important',
  },
  deleteButton: {
    border: '1px solid',
    borderRadius: '4px',
    fontSize: '13px',
    minHeight: '32px',
    padding: '0 45px',
    textTransform: 'initial',
    background: theme.palette.secondary.contrastText,
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.dark,
    marginLeft: '31px',
  },
  errorMessage: {
    color: '#ff0000',
    marginTop: '5px',
    padding: '0 10px',
  },
});

class SaveBar extends Component {
  render() {
    const {
      classes,
      onCancel,
      showCancel = true,
      onSave,
      onDelete,
      isSticky,
      compact,
      isSaveDisabled,
      saveText,
      extraButtons: ExtraButtons,
      cancelText,
      errorMessage,
      className,
      isSaving,
    } = this.props;

    return (
      <div className={classNames(classes.root, className, isSticky && classes.sticky, !errorMessage && classes.rootWithoutErrorMessage)}>
        <div className={classes.buttonBar}>
          {showCancel && (<WhiteButton className={classNames(classes.marginRight, compact && classes.compactButton)} onClick={onCancel} minWidth={135}>
            {cancelText || 'Cancel'}
          </WhiteButton>)}
          <PrimaryButton className={compact && classes.compactButton} onClick={onSave} disabled={isSaveDisabled || (!!errorMessage && isSaveDisabled !== false)} isLoading={isSaving}>
            {saveText || 'Save'}
          </PrimaryButton>
          {ExtraButtons && <ExtraButtons />}
          {onDelete && (
            <Button className={classNames(classes.deleteButton, compact && classes.compactButton)} onClick={onDelete}>
            Delete
            </Button>
          )}
        </div>
        <div
          className={classes.errorMessage}
          dangerouslySetInnerHTML={{ __html: errorMessage }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SaveBar);
