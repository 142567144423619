export const CALENDAR_MODE = {
  Day: 'day',
  Week: 'week',
  Month: 'month',
};

export const CALENDAR_VIEW = {
  Timeline: 'Timeline',
  Calendar: 'Calendar',
};

export default CALENDAR_MODE;
