import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Snackbar,
  SnackbarContent,
  Button,
} from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    backgroundColor: theme.palette.success,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: theme.common.yellow,
  },
  icon: {
    fontSize: 20,
  },
  close: {
    padding: '4px 0 4px 16px',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const Toast = props => {
  const { classes, className, message, action, actionTitle, variant, open, onClose, autohide = 6000 } = props;
  const Icon = variantIcon[variant];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={autohide}
      onClose={onClose}
    >
      <SnackbarContent
        className={classNames(classes[variant], className)}
        aria-describedby="message-id"
        onClose={onClose}
        message={
          <span id="message-id">
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <Button
            key="action"
            aria-label={actionTitle}
            color="inherit"
            className={classes.close}
            onClick={action}
          >
            {actionTitle}
          </Button>,
        ]}
      />
    </Snackbar>
  );
};

Toast.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  action: PropTypes.func,
  actionTitle: PropTypes.string,
  autohide: PropTypes.number,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default withStyles(styles)(Toast);

