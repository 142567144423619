import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import Table from 'Components/Table';
import classNames from 'classnames';
import TextField from 'Components/TextField';
import Checkbox from 'Components/Checkbox';
import PercentageField from 'Components/PercentageField';
import CoverDatePicker from 'Components/CoverDatePicker';
import { CurrencyField } from 'Components/currencyInput';
import {
  updateCoupon,
  createCoupon,
} from 'actions/coupon';
import IconButton from 'Components/Buttons/IconButton';
import { Switch } from '@material-ui/core';
import {
  FileCopyOutlined,
} from '@material-ui/icons';
import DiscountType from 'models/DiscountType';

const styles = theme => ({
  container: {
    padding: '20px 26px',
  },
  header: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
  },
  table: {
    fontSize: 12,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    '& th': {
      padding: '6px 13px',
    },
    '& tr': {
      borderTop: 0,
      borderBottom: 0,
    },
  },
  borderedCell: {
    borderStyle: 'solid',
    borderColor: theme.palette.grey[50],
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopWidth: 0,
  },
  rowLabel: {
    color: theme.palette.grey[300],
    paddingTop: 11,
    paddingBottom: 11,
  },
  centerText: {
    textAlign: 'center',
  },
  sectionLabel: {
    height: 16,
    display: 'flex',
    paddingLeft: 13,
    marginTop: 8,
    alignItems: 'center',
  },
  moveDown: {
    marginTop: 13,
    marginRight: 0,
  },
  exemptContents: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftColumn: {
    minWidth: 126,
  },
  rightColumn: {
    flexGrow: 1,
  },
  flex: {
    display: 'flex',
  },
  switchTextContainer: {
    textAlign: 'center',
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  switchText: {
    fontSize: 11,
    color: '#9d9d9d',
  },
  switch: {
    marginTop: -5,
  },
  codeExists: {
    margin: '0 16px',
    color: theme.palette.error.dark,
  },
});

const CouponModal = ({ classes, coupon, usedCouponCodes, createCoupon, updateCoupon, onCloseModal, revenueTypes, discountMode, orderId, seedCoupons }) => {
  const [couponEdit, setCouponEdit] = useState(coupon);

  const onSave = () => {
    const newCoupon = !couponEdit.id;
    const replaceToAdminCoupon = discountMode && !couponEdit.adminUse && couponEdit.discountType === DiscountType.Percentage;

    if (discountMode) {
      couponEdit.code = `${orderId}_order_discount`;
      couponEdit.adminUse = true;
      couponEdit.expirationDate = null;
      couponEdit.active = true;
    }

    if (replaceToAdminCoupon) {
      couponEdit.id = null;
    }

    if (newCoupon || replaceToAdminCoupon) {
      if (seedCoupons) {
        for (var i = 0; i < couponEdit.numberOfCouponsToGenerate; i++) {
          var code = `${couponEdit.code}-${uuidv4().substring(0, 4)}`;

          const couponToCreate = { ...couponEdit, code };

          createCoupon(couponToCreate);
        }

        onCloseModal();

        return;
      }

      return createCoupon(couponEdit).then(onCloseModal);
    }

    return updateCoupon(couponEdit).then(onCloseModal);
  };

  const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) // eslint-disable-line no-mixed-operators
    );
  };

  const onCancel = () => {
    onCloseModal();
  };

  const copyToAll = couponDiscountIndex => () => {
    const updatedDiscounts = [...couponEdit.couponDiscounts];
    const updatedCoupon = { ...couponEdit, couponDiscounts: updatedDiscounts };
    const value = couponDiscountIndex !== -1 ? couponEdit.couponDiscounts[couponDiscountIndex].discountAmount : 0;

    const updatedRevenueTypes = [];

    updatedDiscounts.forEach(discount => {
      discount.discountAmount = value;
      updatedRevenueTypes.push(discount.revenueTypeId);
    });

    revenueTypes.forEach(revenueType => {
      if (!updatedRevenueTypes.includes(revenueType.id)) {
        updatedDiscounts.push({ couponId: couponEdit.id, revenueTypeId: revenueType.id, discountAmount: value });
      };
    });

    setCouponEdit(updatedCoupon);
  };

  const onFieldChange = fieldName => value => {
    if (value && value.length > 20) {
      return;
    }

    const updatedCoupon = {
      ...couponEdit,
      [fieldName]: value,
    };

    setCouponEdit(updatedCoupon);
  };

  const onDiscountChange = (couponDiscountIndex, revenueTypeId) => value => {
    const updatedDiscounts = [...couponEdit.couponDiscounts];

    if (couponDiscountIndex === -1) {
      const newCouponDiscount = { couponId: couponEdit.id, revenueTypeId, discountAmount: value };

      updatedDiscounts.push(newCouponDiscount);
    } else {
      updatedDiscounts[couponDiscountIndex].discountAmount = value;
    }

    const updatedCoupon = {
      ...couponEdit,
      couponDiscounts: updatedDiscounts,
    };

    setCouponEdit(updatedCoupon);
  };

  const onDiscountTypeChange = event => {
    const discountType = event.target.checked ? DiscountType.Percentage : DiscountType.Flat;

    onFieldChange('discountType')(discountType);
  };

  const couponCodeExist = () => {
    return !!usedCouponCodes &&
      !!couponEdit.code &&
      usedCouponCodes.findIndex(couponCode => couponCode.code.toLowerCase() === couponEdit.code.toLowerCase() && couponCode.id !== couponEdit.id) !== -1;
  };

  const invalidDiscount = () => {
    return (
      (couponEdit.discountType === DiscountType.Flat && !couponEdit.discountAmount) ||
      (
        couponEdit.discountType === DiscountType.Percentage &&
        !!couponEdit.couponDiscounts &&
        (couponEdit.couponDiscounts.findIndex(discount => discount.discountAmount > 100) !== -1)
      )
    );
  };

  return (<Modal
    isOpened={true}
    dimensions={{
      width: 340,
      height: couponEdit.discountType === DiscountType.Flat
        ? discountMode ? 295 : seedCoupons ? 530 : 454
        : discountMode ? 457 : seedCoupons ? 722 : 'auto',
    }}
    onCancel={onCancel}
    onSave={onSave}
    isSaveDisabled={(!couponEdit.code && !discountMode) || couponCodeExist() || invalidDiscount()}
    title={discountMode ? 'Discounts' : 'Coupon Code'}
    addTitleBottomBorder={true}
  >
    <div className={classes.container}>
      <div className={classes.switchContainer}>
        <div className={classes.switchTextContainer}>
          <div>Flat Amount</div>
          {!discountMode && <div className={classes.switchText}>one-time use</div>}
        </div>
        <Switch
          className={classes.switch}
          checked={couponEdit.discountType === DiscountType.Percentage}
          onChange={onDiscountTypeChange}
          color="default"
          inputProps={{ 'aria-label': 'checkbox with default color' }}
        />
        <div className={classes.switchTextContainer}>
          <div>Percentage Off</div>
          {!discountMode && <div className={classes.switchText}>multiple use</div>}
        </div>
      </div>
      {couponEdit.discountType === DiscountType.Percentage ? (
        <Table
          className={classes.table}
          header={
            <tr className={classes.header}>
              <th scope="col">Type</th>
              <th scope="col" colSpan="2" className={classes.centerText}>Discount</th>
            </tr>
          }
        >
          {revenueTypes.map((revenueType, index) => {
            const couponDiscountIndex = couponEdit.couponDiscounts.findIndex(discount => discount.revenueTypeId === revenueType.id);

            return (
              <tr key={`revenue-type-${index}`}>
                <th scope="row" className={classes.rowLabel}>{revenueType.name}</th>
                <td className={classes.borderedCell}>
                  <PercentageField
                    value={(couponDiscountIndex !== -1 ? couponEdit.couponDiscounts[couponDiscountIndex].discountAmount : 0)}
                    onFieldChange={onDiscountChange(couponDiscountIndex, revenueType.id)}
                  />
                </td>
                <td className={classes.centerText}>
                  <IconButton
                    height={40}
                    icon={FileCopyOutlined}
                    onClick={copyToAll(couponDiscountIndex)}
                  />
                </td>
              </tr>
            );
          }
          )}
        </Table>
      ) : (
        <div className={classes.exemptContents}>
          <CurrencyField
            label="Fixed Dollar Amount"
            value={couponEdit.discountAmount}
            onFieldChange={onFieldChange('discountAmount')}
          />
        </div>
      )}
      {!discountMode && <>
        <div className={classNames(classes.header, classes.sectionLabel)}>
          Promo
        </div>
        <div className={classes.exemptContents}>
          <TextField
            label={seedCoupons ? "Promo Code Prefix" : "Promo Code"}
            value={couponEdit.code}
            onFieldChange={onFieldChange('code')}
            maxLength={20}
          />
          {seedCoupons && <TextField
            label="Number of coupons to generate"
            value={couponEdit.numberOfCouponsToGenerate ? couponEdit.numberOfCouponsToGenerate : 50}
            onFieldChange={onFieldChange('numberOfCouponsToGenerate')}
            type="number"
          />}
          <div className={classes.codeExists} style={couponCodeExist() ? {} : { visibility: 'hidden' }}>
            This code already exists
          </div>
          <div className={classes.flex}>
            <Checkbox
              label="Active"
              checked={couponEdit.active}
              onFieldChange={onFieldChange('active')}
            />
          </div>
          <div className={classes.flex}>
            <CoverDatePicker
              label="Start Date"
              fieldName="startDate"
              value={couponEdit.startDate ? couponEdit.startDate : new Date()}
              onFieldChange={onFieldChange('startDate')}
            />
            <CoverDatePicker
              label="End Date"
              fieldName="endDate"
              value={couponEdit.endDate}
              onFieldChange={onFieldChange('endDate')}
              minDate={new Date()}
            />
          </div>
        </div>
      </>}
    </div>
  </Modal>
  );
};

const mapDispatchToProps = {
  updateCoupon,
  createCoupon,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(CouponModal));
