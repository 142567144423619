import { combineReducers } from 'redux';
import { api } from './api';
import { admin } from './admin';
import { booking } from './booking';
import { widgets } from './widgets';
import { locale } from './locale';
import { calendar } from './calendar';
import { recents } from './recents';
import { settings } from './settings';
import { storeReducer } from './storeReducer';
import { search } from './search';
import { reducer as oidc } from 'redux-oidc';
import { menuItem } from './admin/menuItem';
import { menuItemModifier } from './admin/menuItemModifier';
import { file } from './admin/file';
import { masterMenuManagement } from './admin/masterMenuManagement';
import { address } from './address';
import { emailTemplates } from './admin/emailTemplates';
import { emailTriggers } from './admin/emailTriggers';
import { emailAccounts } from './admin/emailAccounts';
import { coupons } from './admin/coupons';
import { payment } from './payment';
import { pickList } from './admin/pickList';
import { contact } from './contact';
import { merchantAccount } from './merchantAccount';
import { global } from './global';

const rootReducer = combineReducers({
  oidc,
  api,
  admin,
  booking,
  contact,
  menuItem,
  masterMenuManagement,
  menuItemModifier,
  widgets,
  locale,
  calendar,
  recents,
  settings,
  storeReducer,
  search,
  file,
  address,
  emailTemplates,
  emailTriggers,
  emailAccounts,
  payment,
  pickList,
  merchantAccount,
  global,
  coupons,
});

export default rootReducer;
