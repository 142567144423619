import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Splash from './Splash';
import Redirect from './Redirect';
import Portal from 'portal';
import userManager from './userManager';
import Toast from 'Components/Toast';
import ActionToast from 'Components/ActionToast';
import { hideWarningToast, applicationUpdated } from 'actions/GlobalToast';

class Authenticate extends Component {
  componentDidMount() {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        this.onPageReload();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user && !this.props.user) {
      //user is logging out!
      //removeUser from usermanager removed the user from the OIDC's internal storage
      //Fixes bug where re-logging in will attempt to log in the previous user (using this internal storage) and the new user at the same time
      userManager.removeUser();
    }
  }

  onPageReload() {
    this.props.applicationUpdated();
  }

  reloadApplication = () => {
    window.location.reload(true);
  }

  render() {
    const { user, warningToast, appVersion } = this.props;

    return (
      <><Toast open={warningToast && warningToast.show} message="Ooops, Something went wrong. Please try again." variant="warning" onClose={this.props.hideWarningToast} />
        <ActionToast open={appVersion && appVersion.showUpdateToast} message="New version of application has been published." variant="info" action={this.reloadApplication} actionTitle='Update' />
        <Router>
          <Switch>
            <Route
              exact
              path="/signin-oidc"
            >
              <Redirect />
            </Route>
            <Route
              path="*"
              component={user ? Portal : Splash}
            />
          </Switch>
        </Router></>);
  }
}

const mapStateToProps = state => {
  const { user } = state.oidc;
  const { warningToast, appVersion } = state.global;

  if (!user || user.expired) {
    return {};
  }

  return { user, warningToast, appVersion };
};

const mapDispatchToProps = {
  hideWarningToast,
  applicationUpdated,
};

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
