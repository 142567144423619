import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import SectionHeader from 'Components/SectionHeader';
import TextField from 'Components/TextField';
import Checkbox from 'Components/Checkbox';
import CoverDatePicker from 'Components/CoverDatePicker';
import { getClonePreferences, saveClonePreferences, cloneBooking } from 'actions/booking';
import moment from 'moment';

const styles = theme => ({
  halfWidthColumn: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: theme.palette.grey[200],
    fontSize: 15,
    paddingLeft: 16,
  },
  spaceSections: {
    marginTop: 5,
    marginBottom: 15,
  },
  startEndTimes: {
    margin: '16px 16px 12px 8px',
    flexGrow: 1,
    alignSelf: 'flex-end',
    fontSize: 16,
    color: theme.palette.grey[700],
  },
});

class Clone extends Component {
  state = {
    isOpened: false,
    booking: {},
    clonePreferences: this.props.clonePreferences,
  };

  open = existingBooking => {
    this.props.getClonePreferences().then(clonePreferences => this.setState({ clonePreferences }));

    this.setState({
      isOpened: true,
      booking: {
        ...existingBooking,
        name: existingBooking.name + ' (Copy)', //pass in booking (and isopened) via props is probably better once we have an API
      },
    }, () => {
      this.handleStartDateChange(Date());
    });
  }

  close = () => {
    this.setState({ isOpened: false });
  }

  isUserPreference = option => {
    const { clonePreferences } = this.state;

    return !!clonePreferences ? clonePreferences.indexOf(option) !== -1 : false;
  }

  handleCheckbox = (fieldName, dependencies = []) => checked => {
    const clonePreferences = [...this.state.clonePreferences];
    const indexOfPreference = clonePreferences.indexOf(fieldName);

    if (checked) {
      if (indexOfPreference === -1) {
        clonePreferences.push(fieldName);
      }
    } else {
      if (indexOfPreference !== -1) {
        clonePreferences.splice(indexOfPreference, 1);
      }
      for (const dependency in dependencies) {
        const dependencyIndex = clonePreferences.indexOf(dependency);

        if (dependencyIndex === -1) {
          clonePreferences.splice(dependencyIndex, 1);
        }
      }
    }

    this.setState({ clonePreferences });
  }

  handleSave = () => {
    const { booking, clonePreferences } = this.state;

    this.props.saveClonePreferences(clonePreferences);
    this.props.cloneBooking(booking.id, booking.name, booking.selectedStartTime, clonePreferences)
      .then(newBookingId => {
        this.close();
        this.props.onCloneSuccess(newBookingId);
      });
  }

  handleChange = fieldName => value => {
    const booking = {
      ...this.state.booking,
      [fieldName]: value,
    };

    this.setState({ booking });
  }

  handleStartDateChange = value => {
    const { booking } = this.state;
    const dateValue = moment(value);
    const startDateTime = moment(booking.startDateTime);

    const newStartDate = dateValue.set({
      'hour': startDateTime.hour(),
      'minute': startDateTime.minute(),
    }).toDate();

    this.handleChange('selectedStartTime')(newStartDate);
  }

  render() {
    const { classes } = this.props;
    const {
      isOpened,
      booking,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        onCancel={this.close}
        title={"Clone Booking"}
        onSave={this.handleSave}
        saveText="Clone"
      >
        <SectionHeader>
          New Booking
        </SectionHeader>
        <div className={classes.flex}>
          <TextField
            label="Booking Name"
            value={booking.name}
            onFieldChange={this.handleChange('name')}
          />
          <div className={classes.flex}>
            <CoverDatePicker
              label="Date"
              value={booking.selectedStartTime}
              onFieldChange={this.handleStartDateChange}
              minDate={moment().subtract(10, 'years').toDate()}
            />
            <div className={classes.startEndTimes}>
              {`${moment(booking.startDateTime).format('LT')} - ${moment(booking.endDateTime).format('LT')}`}
            </div>
          </div>
        </div>
        <SectionHeader className={classes.spaceSections}>
          Sections to Clone
        </SectionHeader>
        <div className={classes.flex}>
          <div className={classes.flexColumn}>
            <div className={classes.label}>
              Details
            </div>
            <Checkbox
              label="Venue Details"
              checked={this.isUserPreference('venueDetails')}
              onFieldChange={this.handleCheckbox('venueDetails')}
            />
            <Checkbox
              label="Site Details"
              checked={this.isUserPreference('siteDetails')}
              onFieldChange={this.handleCheckbox('siteDetails')}
            />
            <Checkbox
              label="Shift Details"
              checked={this.isUserPreference('shiftDetails')}
              onFieldChange={this.handleCheckbox('shiftDetails')}
            />
          </div>
          <div className={classes.flexColumn}>
            <div className={classes.label}>
              Menu
            </div>
            <Checkbox
              label="Categories"
              checked={this.isUserPreference('menuCategories')}
              onFieldChange={this.handleCheckbox('menuCategories', ['menuItems', 'menuItemQuantities'])}
            />
            <Checkbox
              label="Items"
              disabled={!this.isUserPreference('menuCategories')}
              checked={this.isUserPreference('menuItems')}
              onFieldChange={this.handleCheckbox('menuItems', ['menuItemQuantities'])}
            />
            <Checkbox
              label="Quantities"
              disabled={!(this.isUserPreference('menuCategories') && this.isUserPreference('menuItems'))}
              checked={this.isUserPreference('menuItemQuantities')}
              onFieldChange={this.handleCheckbox('menuItemQuantities')}
            />
          </div>
          <div className={classes.flexColumn}>
            <div className={classes.label}>
              Chit Chat
            </div>
            <Checkbox
              label="Notes"
              checked={this.isUserPreference('chitChatNotes')}
              onFieldChange={this.handleCheckbox('chitChatNotes')}
            />
            <Checkbox
              label="Messages"
              checked={this.isUserPreference('chitChatMessages')}
              onFieldChange={this.handleCheckbox('chitChatMessages')}
            />
          </div>
          <div className={classes.flexColumn}>
            <div className={classes.label}>
              Other
            </div>
            <Checkbox
              label="Files"
              checked={this.isUserPreference('files')}
              onFieldChange={this.handleCheckbox('files')}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    settings: {
      bookings: {
        clonePreferences,
      },
    },
  } = state;

  return {
    clonePreferences,
  };
};

const mapDispatchToProps = {
  getClonePreferences,
  saveClonePreferences,
  cloneBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Clone));
