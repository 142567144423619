import {
  SET_SEARCH_RESULTS,
  CLEAR_SEARCH,
  LOADING_SEARCH_RESULTS,
  INDEX_REBUILD_ENDED,
  INDEX_REBUILD_STARTED,
} from 'actions/constants';

const initialState = {
  searchResults: {
    contacts: { items: [] },
    menuItems: { items: [] },
    leads: { items: [] },
    accounts: { items: [] },
  },
  loading: false,
  rebuilding: false,
};

export function search(state = initialState, action) {
  switch (action.type) {

    case SET_SEARCH_RESULTS: {
      const { searchResults } = action;

      const newState = {
        ...state,
        searchResults,
        loading: false,
      };

      return newState;
    }
    case CLEAR_SEARCH: {

      return {
        ...state,
        searchResults: initialState.searchResults,
        loading: false,
      };
    }
    case LOADING_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: initialState.searchResults,
        loading: true,
      };
    }
    case INDEX_REBUILD_STARTED: {
      return {
        ...state,
        rebuilding: true,
      };
    }
    case INDEX_REBUILD_ENDED: {
      return {
        ...state,
        rebuilding: false,
      };
    }
    default:
      return state;
  }
}
