import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import {
  REFERENCES_RECEIVED,
  BOOKING_CATEGORIES_RECEIVED,
  BOOKING_THEMES_RECEIVED,
  BOOKING_EVENT_TYPES_RECEIVED,
} from 'actions/constants';
import IPickList from 'models/IPickList';
import IPickListOption from 'models/IPickListOption';

const filteredCategoryUrl = (name: string, category: string) => {
  return `pickList?$filter=((Name%20eq%20%27${name}%27)%20and%20(Category%20eq%20%27${category}%27))`;
}

const referencesReceived = (references: IPickListOption[]) => ({
  type: REFERENCES_RECEIVED,
  references,
});

const bookingCategoriesReceived = (bookingCategories: IPickListOption[]) => ({
  type: BOOKING_CATEGORIES_RECEIVED,
  bookingCategories,
});

const bookingThemesReceived = (bookingThemes: IPickListOption[]) => ({
  type: BOOKING_THEMES_RECEIVED,
  bookingThemes,
});

const bookingEventTypesReceived = (bookingEventTypes: IPickListOption[]) => ({
  type: BOOKING_EVENT_TYPES_RECEIVED,
  bookingEventTypes,
});

export const getReferences = () => (dispatch: any) =>
  dispatch(callApi(filteredCategoryUrl('Reference', 'General')))
    .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response))
    .then((pickLists: IPickList[]) => {
      const pickListOptions = pickLists && pickLists.length ? pickLists[0].pickListOptions : [];
      dispatch(referencesReceived(pickListOptions));

      return pickListOptions;
    });

export const getAccountTypes = () => (dispatch: any) =>
  dispatch(callApi(filteredCategoryUrl('Type', 'Accounts')))
    .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response))
    .then((pickLists: IPickList[]) => {
      const pickListOptions = pickLists && pickLists.length ? pickLists[0].pickListOptions : [];

      return pickListOptions;
    });

export const getBookingCategories = () => (dispatch: any) =>
  dispatch(callApi(filteredCategoryUrl('Category', 'Bookings')))
    .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response))
    .then((pickLists: IPickList[]) => {
      const pickListOptions = pickLists && pickLists.length ? pickLists[0].pickListOptions : [];
      dispatch(bookingCategoriesReceived(pickListOptions));

      return pickListOptions;
    });

export const getBookingThemes = () => (dispatch: any) =>
  dispatch(callApi(filteredCategoryUrl('Theme', 'Bookings')))
    .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response))
    .then((pickLists: IPickList[]) => {
      const pickListOptions = pickLists && pickLists.length ? pickLists[0].pickListOptions : [];
      dispatch(bookingThemesReceived(pickListOptions));

      return pickListOptions;
    });

export const getBookingStatuses = () => (dispatch: any) =>
  dispatch(callApi(filteredCategoryUrl('Status', 'Bookings')))
    .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response))
    .then((pickLists: IPickList[]) => {
      const pickListOptions = pickLists && pickLists.length ? pickLists[0].pickListOptions : [];

      return pickListOptions;
    });

export const getSalutations = () => (dispatch: any) =>
  dispatch(callApi(filteredCategoryUrl('Salutations', 'General')))
    .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response))
    .then((pickLists: IPickList[]) => {
      const pickListOptions = pickLists && pickLists.length ? pickLists[0].pickListOptions : [];

      return pickListOptions;
    });

export const getEventTypes = () => (dispatch: any) =>
  dispatch(callApi(filteredCategoryUrl('Event Type', 'Bookings')))
    .then((response: any) => ParseJsonOnSuccessOrStop(dispatch, response))
    .then((pickLists: IPickList[]) => {
      const pickListOptions = pickLists && pickLists.length ? pickLists[0].pickListOptions : [];
      dispatch(bookingEventTypesReceived(pickListOptions));

      return pickListOptions;
    });
