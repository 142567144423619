import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import NavLink from 'Components/NavLink';
import { withStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
} from '@material-ui/core';
import IconButton from 'Components/Buttons/IconButton';
import {
  Home as HomeIcon,
  // AssignmentInd as PersonIcon,
  Store as DomainIcon,
  Menu as HamburgerIcon,
  Settings as SettingsIcon,
  Email as EmailIcon,
  LibraryBooks as MenusIcon,
  Restaurant,
  RoundedCornerOutlined,
  LocalAtm,
  MeetingRoom,
} from '@material-ui/icons';
// import PrintsIcon from 'shared/icons/prints';
//import { userIsInRole, adminRole } from 'helpers/userHelpers';

const drawerWidth = 239;
const drawerCollapsedWidth = 80; //for screen sizes above small

const styles = theme => ({
  link: {
    display: 'block',
  },
  activeLink: {
    '& svg': {
      color: theme.palette.secondary.main,
    },
    '& $listIcons': {
      borderLeftColor: theme.palette.secondary.main,
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    minHeight: '100%',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  drawerPaperClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 30,
    [theme.breakpoints.up('sm')]: {
      width: drawerCollapsedWidth,
    },
  },
  listIcons: {
    margin: 0,
    color: theme.palette.grey[100],
    overflow: 'visible',
    width: 30,
    [theme.breakpoints.up('sm')]: {
      width: drawerCollapsedWidth,
    },
    justifyContent: 'center',
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.background.paper,
  },
  listText: {
    color: theme.palette.grey[800],
    fontSize: 15,
  },
  divider: {
    borderTop: `1.5px solid ${theme.palette.secondary.main}`,
    paddingTop: '40px',
  },
  gutter: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  hamburgerContainer: {
    width: 30,
    [theme.breakpoints.up('sm')]: { //.up becomes (min-width: 600px)
      width: drawerCollapsedWidth,
    },
    textAlign: 'center',
  },
  hamburgerButton: {
    color: theme.palette.grey[100],
    [theme.breakpoints.down('xs')]: { //.down('sm') becomes (max-width: 1023.95px)
      padding: 0,
    },
  },
});

class AdminNav extends React.Component {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  getNavLink = (to, icon, primary, exact, isActive) => {
    return (
      <NavLink
        to={to}
        exact={exact}
        name={primary}
        icon={icon}
        isActive={isActive}
      />
    );
  }

  isAdminHome = (match, location) => {
    return location.pathname === '/admin' || location.pathname === '/admin/items';
  }

  render() {
    const {
      classes,
      theme,
    } = this.props;

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
        }}
        open={this.state.open}
      >
        <List className={classes.divider}>
          {this.getNavLink("/home", <HomeIcon />, "Home")}
          {/* {this.getNavLink("/admin/profile", <PersonIcon />, "Profile")} */}
          {this.getNavLink("/admin/company", <DomainIcon />, "Company")}
          {this.getNavLink("/admin/items", <Restaurant />, "Menu Items", null, this.isAdminHome)}
          {this.getNavLink("/admin/menu", <MenusIcon />, "Menu Management")}
          {this.getNavLink("/admin/modifier-templates", <RoundedCornerOutlined viewBox="0 0 28 28" />, "Modifier Templates")}
          {this.getNavLink("/admin/coupons", <LocalAtm />, "Coupons")}
          {this.getNavLink("/admin/rooms", <MeetingRoom />, "Rooms")}
          {/* {this.getNavLink("/admin/prints", <PrintsIcon />, "Prints")} */}
          {this.getNavLink("/admin/email", <EmailIcon />, "Email")}
          {this.getNavLink("/admin/settings", <SettingsIcon />, "Settings")}
        </List>
        <div className={classes.hamburgerContainer}>
          <IconButton
            className={classes.hamburgerButton}
            color={theme.palette.grey[100]}
            aria-label="Open drawer"
            onClick={this.handleDrawerOpen}
            icon={HamburgerIcon}
          >
          </IconButton>
        </div>
      </Drawer>
    );
  }
}

AdminNav.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    oidc: { user },
  } = state;

  return {
    user,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(AdminNav));
