import {
  SET_SEARCH_RESULTS,
  CLEAR_SEARCH,
  LOADING_SEARCH_RESULTS,
  INDEX_REBUILD_STARTED,
  INDEX_REBUILD_ENDED,
} from 'actions/constants';

import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';

export const setSearchResults = searchResults => ({ type: SET_SEARCH_RESULTS, searchResults });
export const clearSearch = () => ({ type: CLEAR_SEARCH });
export const loadingResults = () => ({ type: LOADING_SEARCH_RESULTS });
export const indexRebuildStarted = () => ({ type: INDEX_REBUILD_STARTED });
export const indexRebuildEnded = () => ({ type: INDEX_REBUILD_ENDED });

export const search = query => dispatch => {
  dispatch(loadingResults());

  return dispatch(callApi(`Search?query=${query}`, { method: 'GET' }))
    .then(result => {
      if (result.ok) {
        return ParseJsonOnSuccessOrStop(dispatch, result);
      }

      throw new Error('Something went wrong.');
    }).then(searchResults => {
      dispatch(setSearchResults(searchResults));
    });
};

export const rebuildIndex = () => dispatch => {
  dispatch(indexRebuildStarted());

  return dispatch(callApi(`Search/RebuildIndex`, { method: 'POST' }))
    .then(result => {
      dispatch(indexRebuildEnded());
    });
};
