import React, { useRef, useState, useEffect } from 'react';
import EmailFolderManager from './EmailFolderManager';
import EmailTemplatePreview from './EmailTemplatePreview';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SimpleDialog from 'Components/SimpleDialog';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { EMAIL_TYPES, APP_TYPES } from 'constants/entityTypes';
import {
  getEmailTemplates,
  deleteEmailTemplate,
} from 'actions/emailTemplates';
import {
  Card,
} from '@material-ui/core';
import {
  Add as AddIcon,
} from '@material-ui/icons';

const styles = () => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  mainContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '16px 32px',
    // overflow: 'auto',
    // height: 'calc(100% - 83px - 64px)',
  },
  header: {
    marginBottom: 16,
  },
  hr: {
    margin: '16px 0',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  emailTemplateDisplay: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    padding: '0 16px',
  },
});

const EmailTemplates = ({ classes, emailTemplates, getEmailTemplates, deleteEmailTemplate }) => {
  const dialog = useRef();
  const history = useHistory();
  const folders = [
    { id: 1, name: EMAIL_TYPES.order, appType: APP_TYPES.all },
    { id: 2, name: EMAIL_TYPES.lead },
    { id: 3, name: EMAIL_TYPES.booking },
    { id: 4, name: EMAIL_TYPES.contact, appType: APP_TYPES.all },
    { id: 5, name: EMAIL_TYPES.account },
    { id: 6, name: EMAIL_TYPES.other, appType: APP_TYPES.all },
  ];
  const [selectedFolder, setSelectedFolder] = useState(folders[0]);

  useEffect(() => {
    getEmailTemplates();
  }, []);

  const addTemplate = () => {
    history.push(`/admin/email-templates/${selectedFolder.name}/new`);
  };

  const editTemplate = template => {
    history.push(`/admin/email-templates/${template.id}`);
  };

  const deleteTemplate = template => {
    dialog.current.open('Are you sure you want to delete this template?').then(() => {
      deleteEmailTemplate(template);
    });
  };

  const filterByType = emailTemplates => {
    if (selectedFolder.name === EMAIL_TYPES.other) {
      return emailTemplates.filter(t => t.type === EMAIL_TYPES.other || !t.type);
    }

    return emailTemplates.filter(t => t.type === selectedFolder.name);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.mainContent}>
        <EmailFolderManager onFolderSelected={setSelectedFolder} selectedFolder={selectedFolder} folders={folders} />
        <hr className={classes.hr} />
        <div className={classes.toolbar}>
          <HighlightButton
            onClick={addTemplate}
            aria-label="Add A New Template"
          >
            <AddIcon />
          </HighlightButton>
        </div>
        <div className={classes.emailTemplateDisplay}>
          {emailTemplates && filterByType(emailTemplates).map(template =>
            <EmailTemplatePreview
              template={template}
              onEditTemplate={editTemplate}
              onDeleteTemplate={deleteTemplate}
              key={template.id}
            />
          )}
        </div>
      </Card>
      <SimpleDialog innerRef={dialog} />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    emailTemplates: {
      data,
    },
  } = state;

  return {
    emailTemplates: data,
  };
};

const mapDispatchToProps = {
  getEmailTemplates,
  deleteEmailTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmailTemplates));
