import {
  SET_CURRENT_CONTACT,
  DELETE_CONTACT,
  RESET_CURRENT_CONTACT,
  CLONE_CONTACT,
  RECEIVE_SALES_REPS,
  FETCH_SALES_REPS_BEGIN,
  CONTACT_NOTES_RECEIVED,
  CONTACT_NOTE_ADDED,
  CONTACT_NOTE_UPDATED,
  CONTACT_NOTE_DELETED,
  RECEIVE_CONTACTS,
  RECEIVE_CONTACT_NAME_AND_IDS,
  UPDATE_CURRENT_CONTACT,
  ALL_CONTACTS_TOGGLED,
  CONTACT_SELECTED,
  CONTACT_SALES_DATA_RECEIVED,
} from './constants';
import {
  defaultContactsGridColumns,
  defaultContactsGridColumnsCoverLite,
} from 'constants/gridColumnDefaults';
import { callApi, ParseJsonOnSuccessOrStop } from 'shared/CallApi';
import _ from 'lodash';
import {
  fetchContactsBegin,
  contactColumnsReceived,
  fetchProposalsBegin,
  proposalsReceived,
} from './api';
import {
  logChitChatSystemMessage,
} from 'actions/chitchat';
import { ENTITY_TYPES } from 'constants/entityTypes';
import { toODataString } from '@progress/kendo-data-query';
import { userHasFullCoverLicense } from 'helpers/userHelpers';

export const contactsReceived = (data, append) => ({
  type: RECEIVE_CONTACTS,
  data,
  append,
});

export const setCurrentContact = contact => ({
  type: SET_CURRENT_CONTACT,
  contact,
});

export const updateCurrentContact = contact => dispatch => dispatch({
  type: UPDATE_CURRENT_CONTACT,
  contact,
});

export const contactDeleted = response => ({
  type: DELETE_CONTACT,
  response,
});

export const currentContactReset = () => ({
  type: RESET_CURRENT_CONTACT,
});

export const contactCloned = response => ({
  type: CLONE_CONTACT,
  response,
});

export const fetchSalesRepsBegin = () => ({
  type: FETCH_SALES_REPS_BEGIN,
});

export const receivedSalesReps = salesRepsData => ({
  type: RECEIVE_SALES_REPS,
  salesRepsData,
});

export const contactNameAndIdsReceived = nameAndIds => ({
  type: RECEIVE_CONTACT_NAME_AND_IDS,
  nameAndIds,
});

export const searchContacts = searchTerm => dispatch => {
  return dispatch(callApi(`contact/searchIndex?query=${searchTerm}`, { method: 'GET' }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(responseJson => responseJson && responseJson.items)
    .catch(console.error);
};

export const getProposals = contactId => {
  return dispatch => {
    dispatch(fetchProposalsBegin(contactId));

    return getProposalsApi(dispatch, contactId).then(json => {
      dispatch(proposalsReceived(json));

      return json;
    });
  };
};

export const getAccountsForContact = contactId =>
  dispatch =>
    dispatch(callApi(`contact/${contactId}/account`))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response));

export const deleteAccountContact = (contactId, accountId) =>
  dispatch =>
    dispatch(callApi(`contact/${contactId}/account/${accountId}`, { method: 'DELETE' }));

export const addAccountContact = (contactId, accountId) =>
  dispatch =>
    dispatch(callApi(`contact/${contactId}/account/${accountId}`, { method: 'POST' }));

export const setAccountAsPrimary = (contactId, accountId) =>
  dispatch =>
    dispatch(callApi(`contact/${contactId}/account/${accountId}/SetAsPrimary`, { method: 'PUT' }));

const getProposalsApi = (dispatch, contactId) => {
  let okay = true;
  let status = 0;
  let statusText;

  return dispatch(callApi(`contact/${contactId}/proposals`))
    .then(result => {
      if (!result.ok) {
        status = result.status;
        statusText = result.statusText;
        okay = false;
      }

      return ParseJsonOnSuccessOrStop(dispatch, result);
    })
    .then(json => {
      if (okay) {
        return json;
      }
      if (json.message) {
        throw Error(json.message);
      }
      if (statusText || status) {
        throw Error(`${status} - ${statusText}`);
      }
      throw Error('unknown');
    });
};

export const getContacts = (params, append = false) => {
  return dispatch => {
    dispatch(fetchContactsBegin(params));

    return getContactsApi(dispatch, params).then(result => {
      // TODO: Find a better to clean up bad address

      dispatch(contactsReceived(result, append));

      return result;
    });
  };
};

const getContactsApi = (dispatch, params) => {
  return dispatch(callApi(`contact?${params}`)).then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

export const getSalesRepsIfNeeded = () => {
  return (dispatch, getState) => {
    const { api: { salesReps: { salesRepsData } } } = getState();

    return getSalesRepsApi(dispatch).then(apiSalesReps => {
      if (_.isEqual(apiSalesReps, salesRepsData)) {
        return salesRepsData;
      }
      dispatch(receivedSalesReps(apiSalesReps));

      return apiSalesReps;

    });
  };
};

const getSalesRepsApi = dispatch => {
  dispatch(fetchSalesRepsBegin());

  return dispatch(callApi('QuickPick/salesReps'))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

export const saveContacts = contacts => {
  let okay = true;
  let status = 0;
  let statusText;

  // This is bandaid and we need a more clear solution
  const simpleContacts = contacts.map(function (item) {
    delete item.accountContacts;

    return item;
  });

  return dispatch => dispatch(callApi(`contact/batch`, {
    body: simpleContacts,
    method: 'POST',
  }))
    .then(result => {
      if (!result.ok) {
        status = result.status;
        statusText = result.statusText;
        okay = false;
      }

      return ParseJsonOnSuccessOrStop(dispatch, result);
    })
    .then(json => {
      if (okay) {
        return json;
      }
      if (json.message) {
        throw Error(json.message);
      }
      if (statusText || status) {
        throw Error(`${status} - ${statusText}`);
      }
      throw Error('unknown');
    });
};

export const getContact = contactId => {
  return dispatch => dispatch(callApi(`contact/${contactId}`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(json => {
      dispatch(setCurrentContact(json));

      return json;
    });
};

export const saveContact = contact => {
  if (contact && contact.contactAddresses && contact.contactAddresses.length) {
    contact.contactAddresses = contact.contactAddresses.map(address => {
      if (address.id && address.id < 0) delete address.id;

      return address;
    });
  }

  return dispatch => dispatch(callApi(`contact/${contact.id}`, { body: contact, method: 'PUT' }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(contact => {
      if (contact.contactAddresses) {
        contact.contactAddresses = contact.contactAddresses.filter(address => address.recordStatus !== 'Deleted');
      }
      dispatch(setCurrentContact(contact));

      return contact;
    });
};

export const addContact = contact => (dispatch, getState) => {
  const { api: { currentLocation }, oidc: { user } } = getState();

  contact.locationContacts = [{ locationId: currentLocation.id }];
  if (!contact.contactAddresses || contact.contactAddresses.length === 0) {
    contact.contactAddresses = [{ primary: true }];
  }

  return dispatch(callApi(`contact`, { body: contact, method: 'POST' }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(response => {
      const relationship = {
        id: response.id,
        entityType: ENTITY_TYPES.contact,
      };

      dispatch(logChitChatSystemMessage(`Contact created by ${user.profile.name}`, relationship, `Contact - ${response.name}`));

      return response;
    });
};

export const deleteContact = contactId => {
  return dispatch => dispatch(callApi(`contact/${contactId}`, { method: 'DELETE' }))
    .then(response => {
      dispatch(contactDeleted(response));
    });
};

export const clearCurrentContact = () => {
  return dispatch => {
    dispatch(currentContactReset());
  };
};

export const notesReceived = response => ({
  type: CONTACT_NOTES_RECEIVED,
  response,
});

export const noteAdded = response => ({
  type: CONTACT_NOTE_ADDED,
  response,
});

export const noteUpdated = response => ({
  type: CONTACT_NOTE_UPDATED,
  response,
});

export const noteDeleted = response => ({
  type: CONTACT_NOTE_DELETED,
  response,
});

export function cloneContact(contact) {
  return dispatch => dispatch(callApi(`contact`, {
    body: {
      ...contact,
      id: undefined,
      accountContacts: undefined,
      contactAddresses: contact.contactAddresses.map(address => ({ ...address, contactId: undefined, id: undefined })),
    },
  }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(json => {
      dispatch(setCurrentContact(json));

      return json;
    });
}

export function getContactColumns() {
  return (dispatch, getState) => dispatch(callApi('userSetting/contact_columns'))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(columnString => {
      const columnList = columnString ? columnString.split(',') : [];
      const { oidc: { user } } = getState();
      const userHasFullCover = userHasFullCoverLicense(user);

      dispatch(contactColumnsReceived(columnList, userHasFullCover));
    })
    .catch(error => {
      console.error(error);
    });
}

export const getContactNameAndIds = () => {
  return dispatch => {
    return dispatch(callApi(`Contact/NameAndId`))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .then(nameAndIds => {
        dispatch(contactNameAndIdsReceived(nameAndIds));
      });
  };
};

export function saveContactColumns(columns) {
  return (dispatch, getState) => {
    const { oidc: { user } } = getState();
    const userHasFullCover = userHasFullCoverLicense(user);
    const setting = columns.length
      ? columns.join()
      : userHasFullCover
        ? defaultContactsGridColumns.default.join()
        : defaultContactsGridColumnsCoverLite.default.join();

    dispatch(callApi('userSetting/contact_columns', { method: 'PUT', body: setting }))
      .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
      .then(dispatch(contactColumnsReceived(columns)));
  };
}

export function reorderContactColumns(columns) {
  return dispatch => dispatch(
    callApi('userSetting/contact_columns', { method: 'PUT', body: columns.join() }))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response));
}

export const allContactsSelected = ({ selected }) => ({ type: ALL_CONTACTS_TOGGLED, selected: selected });

export const contactSelected = ({ id, index, selected }) => ({
  type: CONTACT_SELECTED,
  id,
  index,
  selected,
});

export const getContactSales = () => (dispatch, getState) => {
  const { contact: { contacts } } = getState();

  const items = contacts && contacts.length ? contacts.filter(item => item.selected) : [];

  const queryString = items.map(item => `ids=${item.id}`).join('&');

  return getcontactsSales(dispatch, queryString).then(result => {
    dispatch(contactSalesReceived(result));

    return result;
  });
};

const getcontactsSales = (dispatch, params) => {
  return dispatch(
    callApi(`Contact/Sales?${params}`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .catch(console.error);
};

export const contactSalesReceived = response => ({
  type: CONTACT_SALES_DATA_RECEIVED,
  response,
});

export const inactiveContact = contactId => dispatch => {
  const filterByActiveContact = toODataString({
    filter: {
      logic: 'and',
      filters: [
        { field: 'id', operator: 'eq', value: contactId },
        { field: 'orders/$count', operator: 'eq', value: 0 },
      ],
    },
  });

  return dispatch(callApi(`contact?${filterByActiveContact}`))
    .then(response => ParseJsonOnSuccessOrStop(dispatch, response))
    .then(contacts => contacts && contacts.length > 0);
};
