import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
  },
  note: {
    color: theme.palette.grey[900],
    fontSize: 12,
  },
  description: {
    color: theme.palette.grey[500],
    fontSize: 13,
  },
  modifiers: {
    color: theme.palette.secondary.main,
  },
});

class StoreMenuItemDescription extends Component {

  findnonDefaultModifiersForItem = (options, nonDefaultModifiersForItem) => {
    options.forEach(option => {
      if (!option.default) {
        nonDefaultModifiersForItem.push(option.id);
      }
      if (option.options) {
        this.findnonDefaultModifiersForItem(option.options, nonDefaultModifiersForItem);
      }
    });
  }

  getItemModifierChanges = orderItem => {
    if (!orderItem.menuCategoryItem) {
      return '';
    }

    const { storeAllMenuItems } = this.props;
    const nonDefaultModifierIdsForItem = [];
    const menuCategoryItem = storeAllMenuItems.find(item => {
      return item.id === orderItem.menuCategoryItemId;
    });

    if (!menuCategoryItem) {
      return '';
    }

    this.findnonDefaultModifiersForItem(menuCategoryItem.menuItem.modifiers, nonDefaultModifierIdsForItem);

    const changedModifiers = [];

    orderItem.orderItemModifiers.filter(modifier => modifier.recordStatus === 'Active').forEach(modifier => {
      if (nonDefaultModifierIdsForItem.find(nonDefaultModifierId => nonDefaultModifierId === modifier.menuItemModifierId)) {
        if (modifier.menuItemModifier) // TODO: not sure why we have to do this.  something about pre PUT
          changedModifiers.push(modifier.menuItemModifier.name);
      }
    });

    const modifiers = changedModifiers.toString();

    if (modifiers.length > 1) {
      return modifiers;
    }

    return '';

  }

  stripeHTML = description => {
    if (!description || description === '') {
      return '';
    }

    return description.replace(/(<([^>]+)>)/gi, "");
  }

  render() {

    if (this.props.storeAllMenuItems) {
      const {
        item,
        classes,
      } = this.props;
      const modifiers = this.getItemModifierChanges(item);
      const desc = item.menuCategoryItem ? item.menuCategoryItem.description : '';

      return (
        <>
          {modifiers ?
            <div>
              <span className={classes.modifiers}>Modifiers: </span>
              <span >{modifiers} </span>
            </div>
            :
            <span className={classes.description}>{desc ? this.stripeHTML(desc) : ''}</span>

          }
          <br />
          <span className={classes.note}> {item.notes ? " * " +  item.notes + " * " : ''}</span>
        </>
      );
    } else {
      return (<div>Loading ...</div>);
    }
  }
}

const mapStateToProps = state => {
  const {
    storeReducer: {
      storeAllMenuItems,
    },
  } = state;

  return {
    storeAllMenuItems,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StoreMenuItemDescription));
