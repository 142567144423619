import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core/styles';
import { GridColumn } from '@progress/kendo-react-grid';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Clear as DeleteIcon,
  Place as PlaceIcon
} from '@material-ui/icons';
import Grid from 'Components/Grid/Grid';
import HighlightButton from 'Components/Buttons/HighlightButton';
import TextField from 'Components/TextField';
import Checkbox from 'Components/Checkbox';
import { formatMoney } from 'helpers/moneyHelpers';
import {
  getDeliveryZones,
  createDeliveryZone,
  updateDeliveryZone,
  deleteDeliveryZone,
} from 'actions/deliveryZones';
import {
  getSettingAreas,
  saveSettingValues,
} from 'actions/settings';
import IDeliveryZone from 'models/IDeliveryZone';
import DeliveryZoneType from 'models/DeliveryZoneType';
import { groupBy } from 'helpers/groupBy';
import Modal from 'Components/Modal';
import SaveBar from 'Components/SaveBar';
import SimpleDialog from 'Components/SimpleDialog';
import MapGL, { Marker } from 'react-map-gl';
import { Editor, DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import { RENDER_STATE } from 'react-map-gl-draw';
import classNames from 'classnames';

const dialog = React.createRef() as any;

const styles = createStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
    maxWidth: 1060,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 16px',
  },
  topButtons: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    display: 'flex',
    margin: 8,
  },
  tableContainer: {
  },
  gridElement: {
    padding: 0,
  },
  undeliverableContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  undeliverableCheckbox: {
    height: 47,
  },
  undeliverableCheckboxLabel: {
    fontSize: 16,
  },
  chargeColumn: {
    textAlign: 'right !important',
  },
  fieldsRowContainer: {
    display: 'flex',
  },
  modifyIcon: {
    cursor: 'pointer',
    marginRight: 7,
    marginLeft: 3,
    color: theme.palette.grey[700],
    fontSize: 26,
  },
  errorMessage: {
    color: '#ff0000',
    textAlign: 'center',
  },
  zoneRules: {
    textAlign: 'justify',
    whiteSpace: 'initial',
  },
  undeliverableMessage: {
    width: '100%',
    margin: '10px 0',
  },
  placeholderMessage: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
  },
  mapMarker: {
    color: theme.palette.error.dark,
    fontSize: 60,
  },
  distanceTravelTimeLabel: {
    width: '61%',
  },
}));

const NameField = [
  "",
  "Postal Code",
  "Miles (Greater Than/Equal)",
  "Minutes (Greater Than/Equal)",
  "Label"
];

const GRAZE_ORDER_UNDELIVERABLE_ZONE = 'GRAZE_ORDER_UNDELIVERABLE_ZONE';

interface IProps extends WithStyles {
  selectedSettingId: number;
  undeliverableZoneMessage: string;
  getDeliveryZones: () => Promise<IDeliveryZone[]>;
  createDeliveryZone: (DeliveryZone: IDeliveryZone) => Promise<IDeliveryZone>;
  updateDeliveryZone: (DeliveryZone: IDeliveryZone) => Promise<IDeliveryZone>;
  deleteDeliveryZone: (DeliveryZoneId: number) => void;
  getSettingAreas: () => Promise<any>;
  saveSettingValues: (settingsKeyValues: { [key: string]: any }) => Promise<any>;
  company: any;
}

interface IState {
  selectedDeliveryZone: IDeliveryZone,
  groupedDeliveryZones: { [key: string]: IDeliveryZone[] },
  undeliverableZoneMessage: string,
  viewport: any,
  marker: any,
  mode: any,
  selectedFeatureIndex: any,
  isochroneTime: number
}

const defaultState: IState = {
  selectedDeliveryZone: null,
  groupedDeliveryZones: {},
  undeliverableZoneMessage: '',
  viewport: {
    longitude: -91.874,
    latitude: 42.76,
    zoom: 16
  },
  marker: {
    longitude: -91.874,
    latitude: 42.76,
  },
  mode: null,
  selectedFeatureIndex: null,
  isochroneTime: 0
}

class DeliveryZones extends Component<IProps, IState> {
  state = defaultState;

  _token = 'pk.eyJ1IjoiYWxleHpoZWx1ZG92IiwiYSI6ImNsZTZnZml6djBtc2Ezbm1ybjRma3VnOGoifQ.VMIuGOn9PiP1XpE3s9Xg5w'
  _editorRef: any = React.createRef();
  _mapRef: any = React.createRef();
  _geoCodeContainerRef: any = React.createRef();
  private openZoneModal = (deliveryZone: IDeliveryZone = null) => {
    const selectedDeliveryZone = deliveryZone
      ? deliveryZone
      : {
        type: this.getDeliveryZoneType(),
      } as IDeliveryZone;

    this.setState({ selectedDeliveryZone });
  }



  private closeAddZoneModal = () => {
    this.setState({ selectedDeliveryZone: null });
  }

  private setDeliveryZoneProp = (key: string) => (value: any) => {
    const selectedDeliveryZone = {
      ...this.state.selectedDeliveryZone,
      [key]: value,
    };

    this.setState({ selectedDeliveryZone })
  }

  private saveDeliveryZone = async () => {
    const { createDeliveryZone, updateDeliveryZone } = this.props;
    const deliveryZone = { ...this.state.selectedDeliveryZone };
    const newDeliveryZone = !deliveryZone.id;

    this.setState({ selectedDeliveryZone: null });

    if (newDeliveryZone) {
      await createDeliveryZone(deliveryZone);
    } else {
      await updateDeliveryZone(deliveryZone);
    }

    this.getDeliveryZones();
  }

  private savePolygonDeliveryZone = async () => {
    const { createDeliveryZone, updateDeliveryZone } = this.props;
    const deliveryZone = { ...this.state.selectedDeliveryZone };
    const newDeliveryZone = !deliveryZone.id;

    const features = JSON.stringify(this._editorRef.current.getFeatures());

    deliveryZone.value = features;

    console.log(deliveryZone);

    this.setState({ selectedDeliveryZone: null });

    if (newDeliveryZone) {
      await createDeliveryZone(deliveryZone);
    } else {
      await updateDeliveryZone(deliveryZone);
    }

    this.getDeliveryZones();
  }

  private deleteDeliveryZone = async (deliveryZone: IDeliveryZone) => {
    const { deleteDeliveryZone } = this.props;

    return dialog.current.open('Are you sure you want to delete this zone?').then(async () => {
      await deleteDeliveryZone(deliveryZone.id);
      this.getDeliveryZones();
    });
  }

  private getDeliveryZoneType = () => {
    const { selectedSettingId } = this.props;

    return Object.keys(DeliveryZoneType)[selectedSettingId - 1];
  }

  private getDeliveryZones = async () => {
    const deliveryZones = await this.props.getDeliveryZones();
    const groupedDeliveryZones = groupBy(deliveryZones, 'type');

    this.setState({ groupedDeliveryZones });
  }

  private saveUndeliverableMessage = async () => {
    await this.props.saveSettingValues({ [GRAZE_ORDER_UNDELIVERABLE_ZONE]: this.state.undeliverableZoneMessage });
    this.props.getSettingAreas();
  }

  private cancelUndeliverableMessageEdit = () => {
    this.setState({ undeliverableZoneMessage: this.props.undeliverableZoneMessage });
  }

  public async componentDidMount() {
    this.setState({ undeliverableZoneMessage: this.props.undeliverableZoneMessage });
    this.getDeliveryZones();
    this.props.getSettingAreas();
    this._getAddressGeoCode()
  }


  _mapLoaded = () => {
    if (this.getDeliveryZoneType() == 'Polygon' && this.state.selectedDeliveryZone && this._editorRef.current) {
      if (this.state.selectedDeliveryZone.value) {
        const features = JSON.parse(this.state.selectedDeliveryZone.value);

        this.setState({ mode: new EditingMode() });

        this._editorRef.current.addFeatures(features);
      }
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.undeliverableZoneMessage !== this.props.undeliverableZoneMessage) {
      this.setState({ undeliverableZoneMessage: this.props.undeliverableZoneMessage });
    }

    if (prevProps.company !== this.props.company) {
      this._getAddressGeoCode();
    }
  }

  async _getAddressGeoCode() {
    const { company } = this.props;

    if (company && company.address) {
      const address = `${company.address}, ${company.city}, ${company.state} ${company.postalCode}`;
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          address
        )}.json?access_token=${this._token}`
      );
      const data = await response.json();
      console.warn(data);
      const { coordinates } = data.features[0].geometry;


      if (coordinates) {
        this.setState({
          marker: {
            latitude: coordinates[1],
            longitude: coordinates[0],
          },
          viewport: {
            ...this.state.viewport,
            latitude: coordinates[1],
            longitude: coordinates[0],
          }
        });


      }
    }
  }

  async _getIsochronePolygon(value: number) {
    const { marker } = this.state;
    this.setState({ mode: new EditingMode(), isochroneTime: value });
    if (marker.latitude && marker.longitude && this._editorRef) {
      const response = await fetch(
        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${marker.longitude},${marker.latitude}?contours_minutes=${value}&access_token=${this._token}&polygons=true`
      );
      const data = await response.json();

      const features = this._editorRef.current.getFeatures();

      for (let i = 0; i < features.length; i++) {
        this._editorRef.current.deleteFeatures(0);
      }



      this._editorRef.current.addFeatures(data.features);
    }
  }


  _updateViewport = (viewport: any) => {
    this.setState({ viewport });
  };

  _onSelect = (options: any) => {
    this.setState({ selectedFeatureIndex: options && options.selectedFeatureIndex });
  };

  _onDelete = () => {
    const selectedIndex = this.state.selectedFeatureIndex;
    if (selectedIndex !== null && selectedIndex >= 0) {

      console.error(selectedIndex)

      this._editorRef.current.deleteFeatures(selectedIndex);
    }
  };

  _onUpdate = ({ editType }: { editType: any }) => {
    if (editType === 'addFeature') {
      this.setState({
        mode: new EditingMode()
      });
    }
  };



  getEditHandleStyle({ feature, state }: { feature: any, state: any }) {
    switch (state) {
      case RENDER_STATE.SELECTED:
      case RENDER_STATE.HOVERED:
      case RENDER_STATE.UNCOMMITTED:
        return {
          fill: 'rgb(251, 176, 59)',
          fillOpacity: 1,
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 2,
          r: 7
        };

      default:
        return {
          fill: 'rgb(251, 176, 59)',
          fillOpacity: 1,
          stroke: 'rgb(255, 255, 255)',
          strokeWidth: 2,
          r: 5
        };
    }
  }

  getFeatureStyle({ feature, index, state }: { feature: any, index: any, state: any }) {
    switch (state) {
      case RENDER_STATE.SELECTED:
      case RENDER_STATE.HOVERED:
      case RENDER_STATE.UNCOMMITTED:
      case RENDER_STATE.CLOSING:
        return {
          stroke: 'rgb(251, 176, 59)',
          strokeWidth: 4,
          fill: 'rgb(251, 176, 59)',
          fillOpacity: 0.5,
          strokeDasharray: '4,2'
        };

      default:
        return {
          stroke: '#0070D2',
          strokeWidth: 3,
          fill: '#4A90E2',
          fillOpacity: 0.3
        };
    }
  }

  _increaseIsochronePolygonTime = () => {
    const { isochroneTime = 0 } = this.state;

    this.setState({ isochroneTime: isochroneTime + 1 })

    this._getIsochronePolygon(isochroneTime + 1);
  }

  _decreaseIsochronePolygonTime = () => {
    const { isochroneTime = 0 } = this.state;

    if (isochroneTime == 0) {
      return;
    }

    this.setState({ isochroneTime: isochroneTime - 1 })
    this._getIsochronePolygon(isochroneTime - 1);
  }

  _renderDrawTools = () => {
    const { isochroneTime = 0 } = this.state;

    // copy from mapbox
    return (
      <div className="mapboxgl-ctrl-top-left">
        <div className="mapboxgl-ctrl-group mapboxgl-ctrl">

          <button
            className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
            title="Polygon tool (p)"
            onClick={() => this.setState({ mode: new DrawPolygonMode() })}
          />
          <button
            className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
            title="Delete"
            onClick={this._onDelete}
          />
          <button
            className="mapbox-gl-draw_ctrl-draw-btn"
            title="Increase Isochrone Polygon travel time"
            onClick={this._increaseIsochronePolygonTime}
          >+</button>
          <button
            className="mapbox-gl-draw_ctrl-draw-btn"
            title="Isochrone Polygon Travel Time in minutes"
          >{isochroneTime.toString()}m</button>
          <button
            className="mapbox-gl-draw_ctrl-draw-btn"
            title="Decrease Isochrone Polygon travel time"
            onClick={this._decreaseIsochronePolygonTime}
          >-</button>

        </div>
      </div>
    );
  };

  public render() {
    const { classes, selectedSettingId, company } = this.props;
    const { groupedDeliveryZones, selectedDeliveryZone, undeliverableZoneMessage, viewport, mode, marker } = this.state;
    const selectedDeliveryZoneType = this.getDeliveryZoneType();
    const selectedTypeZones = groupedDeliveryZones[selectedDeliveryZoneType];
    const isUndeliverableMessageChange = undeliverableZoneMessage != this.props.undeliverableZoneMessage;
    const noCompanyAddress = !company || !company.address || !company.city || !company.state || !company.postalCode;
    const modalMode = !!selectedDeliveryZone && !!selectedDeliveryZone.id ? 'Edit' : 'Add';
    const duplicateValue =
      !!selectedDeliveryZone &&
      (
        !!selectedTypeZones &&
        !!selectedTypeZones.find(zone => zone.id !== selectedDeliveryZone.id && zone.value == selectedDeliveryZone.value)
      );

    return (<>
      {noCompanyAddress
        ? (
          <div className={classes.placeholderMessage}>
            Please set company address above to turn this feature on.
          </div>
        )
        : (
          <div className={classes.container}>
            <div className={classes.main}>
              <div className={classes.content}>
                <div className={classes.tableContainer}>
                  <Grid items={(selectedTypeZones ? selectedTypeZones : []).sort((a: IDeliveryZone, b: IDeliveryZone) => a.value > b.value ? 1 : -1)}>
                    <GridColumn
                      field="undeliverable"
                      title="Undeliverable"
                      width="110px"
                      cell={props => (
                        <td style={{ textAlign: 'center', padding: 0 }}>
                          <Checkbox
                            className={classes.gridElement}
                            disabled={true}
                            checked={props.dataItem.undeliverable}
                            onClick={() => { }}
                            onFieldChange={() => { }}
                          />
                        </td>
                      )}
                    />
                    <GridColumn
                      field="value"
                      title={NameField[selectedSettingId]}
                      width="200px"
                      cell={props => (
                        <td style={{ textAlign: 'center', padding: 0 }}>
                          <span className={classes.gridElement}>
                            {
                              selectedDeliveryZoneType === DeliveryZoneType.PostalCode
                                ? props.dataItem.value
                                : selectedDeliveryZoneType === DeliveryZoneType.Polygon ? props.dataItem.label : `From ${props.dataItem.value} ${selectedDeliveryZoneType === DeliveryZoneType.Distance
                                  ? 'miles'
                                  : 'minutes'}`
                            }
                          </span>
                        </td>
                      )}
                    />
                    <GridColumn
                      field="charge"
                      title="Charge"
                      width="130px"
                      cell={props => (
                        <td className={classes.chargeColumn}>
                          <span className={classes.gridElement}>
                            {props.dataItem.charge && !props.dataItem.undeliverable ? `$${formatMoney(props.dataItem.charge)}` : ''}
                          </span>
                        </td>
                      )}
                    />
                    <GridColumn field="" title="" width="100px" cell={props => (
                      <td className={classes.crudIcons}>
                        <EditIcon
                          className={classes.modifyIcon}
                          onClick={() => this.openZoneModal(props.dataItem)}
                          aria-label={`Edit Zone`}
                        />
                        <DeleteIcon
                          className={classes.modifyIcon}
                          onClick={() => this.deleteDeliveryZone(props.dataItem)}
                          aria-label={`Delete Zone`}
                        />
                      </td>
                    )}
                    />
                  </Grid>
                </div>
              </div>
              <div className={classes.buttons}>
                <div className={classes.topButtons}>
                  <div className={classes.button}>
                    <HighlightButton
                      onClick={(event: any) => this.openZoneModal()}
                      aria-label={modalMode}
                      className={classes.spaceButtons}
                    >
                      <AddIcon />
                    </HighlightButton>
                  </div>
                </div>
                <div className={classes.zoneRules}>
                  For delivery addresses that match multiple rule types (Postal Code, Distance, Travel Time),
                  the most expensive delivery charge will apply.
                  If an address matches any rule that is "undeliverable",
                  that order will be undeliverable.
                </div>
                <div>
                  <TextField
                    className={classes.undeliverableMessage}
                    label="Online Order Message If Undeliverable"
                    value={undeliverableZoneMessage}
                    onFieldChange={(value: string) => this.setState({ undeliverableZoneMessage: value })}
                  />
                </div>
              </div>
            </div>
            <Modal
              isOpened={selectedDeliveryZone !== null && selectedSettingId !== 4}
              onCancel={this.closeAddZoneModal}
              title={this.getDeliveryZoneType() === 'PostalCode' ? `${modalMode} Postal Code Delivery Zone` : this.getDeliveryZoneType() === 'TravelTime' ? `${modalMode} Travel Time Delivery Zone` : `${modalMode} ${this.getDeliveryZoneType()} Delivery Zone`}
              onSave={this.saveDeliveryZone}
              isSaveDisabled={duplicateValue}
              addTitleBottomBorder={false}
              dimensions={{ width: 'unset', height: 'unset', maxWidth: '550px' }}
            >
              {selectedDeliveryZone !== null && <>
                <div className={classes.fieldsRowContainer}>
                  <div className={classes.undeliverableContainer}>
                    <Checkbox
                      className={classes.undeliverableCheckbox}
                      classes={{ label: classes.undeliverableCheckboxLabel }}
                      label="Undeliverable"
                      checked={selectedDeliveryZone.undeliverable}
                      onFieldChange={this.setDeliveryZoneProp('undeliverable')}
                    />
                  </div>
                  <TextField
                    className={classNames(classes.gridElement, classes.distanceTravelTimeLabel)}
                    label={NameField[selectedSettingId]}
                    value={selectedDeliveryZone.value}
                    type="string"
                    onFieldChange={this.setDeliveryZoneProp('value')}
                  />
                  <TextField
                    className={classes.gridElement}
                    label="Charge"
                    value={selectedDeliveryZone.undeliverable ? null : selectedDeliveryZone.charge}
                    type="number"
                    disabled={selectedDeliveryZone.undeliverable}
                    onFieldChange={this.setDeliveryZoneProp('charge')}
                  />
                </div>
                <div
                  style={{ visibility: duplicateValue ? 'visible' : 'hidden' }}
                  className={classes.errorMessage}
                >
                  This zone already exists.
                </div>
              </>}
            </Modal>
            <Modal
              isOpened={selectedDeliveryZone !== null && selectedSettingId === 4}
              onCancel={this.closeAddZoneModal}
              title={'Polygon Delivery Zone'}
              onSave={this.savePolygonDeliveryZone}
              isSaveDisabled={duplicateValue}
              addTitleBottomBorder={false}
              dimensions={{ hasMargin: false, height: '100%', width: '100%' }}
            >
              {selectedDeliveryZone !== null && <>
                <div className={classes.fieldsRowContainer}>
                  <div className={classes.undeliverableContainer}>
                    <Checkbox
                      className={classes.undeliverableCheckbox}
                      classes={{ label: classes.undeliverableCheckboxLabel }}
                      label="Undeliverable"
                      checked={selectedDeliveryZone.undeliverable}
                      onFieldChange={this.setDeliveryZoneProp('undeliverable')}
                    />
                  </div>
                  <TextField
                    className={classes.gridElement}
                    label='Label'
                    value={selectedDeliveryZone.label}
                    type="string"
                    onFieldChange={this.setDeliveryZoneProp('label')}
                  />
                  <TextField
                    className={classes.gridElement}
                    label="Charge"
                    value={selectedDeliveryZone.undeliverable ? null : selectedDeliveryZone.charge}
                    type="number"
                    disabled={selectedDeliveryZone.undeliverable}
                    onFieldChange={this.setDeliveryZoneProp('charge')}
                  />
                </div>
                <div
                  ref={this._geoCodeContainerRef}
                  style={{ position: "absolute", top: 20, left: 20, zIndex: 1 }}
                />
                <MapGL
                  {...viewport}
                  width="100%"
                  height="100%"
                  ref={this._mapRef}
                  onLoad={this._mapLoaded}
                  mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
                  mapboxApiAccessToken={'pk.eyJ1IjoiYWxleHpoZWx1ZG92IiwiYSI6ImNsZTZnZml6djBtc2Ezbm1ybjRma3VnOGoifQ.VMIuGOn9PiP1XpE3s9Xg5w'}
                  onViewportChange={this._updateViewport}
                >
                  <Editor
                    ref={this._editorRef}
                    style={{ width: '100%', height: '100%' }}
                    clickRadius={12}
                    mode={mode}
                    onSelect={this._onSelect}
                    onUpdate={this._onUpdate}
                    editHandleShape={'circle'}
                    featureStyle={this.getFeatureStyle}
                    editHandleStyle={this.getEditHandleStyle}
                  />
                  <Marker longitude={marker.longitude} latitude={marker.latitude} >
                    <div><PlaceIcon className={classes.mapMarker} /></div>
                  </Marker>
                  {this._renderDrawTools()}
                </MapGL>
                <div
                  style={{ visibility: duplicateValue ? 'visible' : 'hidden' }}
                  className={classes.errorMessage}
                >
                  This zone already exists.
                </div>
              </>}
            </Modal>
            {isUndeliverableMessageChange && <SaveBar onSave={this.saveUndeliverableMessage} onCancel={this.cancelUndeliverableMessageEdit} />}
            <SimpleDialog innerRef={dialog} />
          </div>
        )
      }</>
    );
  }
};

const mapStateToProps = (state: any) => {
  const {
    admin: {
      company,
    },
    settings: {
      allAreaSettings,
    },
  } = state;

  return {
    company,
    undeliverableZoneMessage: allAreaSettings[GRAZE_ORDER_UNDELIVERABLE_ZONE]
      ? allAreaSettings[GRAZE_ORDER_UNDELIVERABLE_ZONE].value
      : '',
  };
};

const mapDispatchToProps = {
  getDeliveryZones,
  createDeliveryZone,
  updateDeliveryZone,
  deleteDeliveryZone,
  getSettingAreas,
  saveSettingValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DeliveryZones));
