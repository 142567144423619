import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'Components/Modal';
import { connect } from 'react-redux';
import { performHealthCheck } from 'actions/healthcheck';
import {
  Clear,
  Done,
} from '@material-ui/icons';
import classNames from 'classnames';
import ContentLoader, { } from 'react-content-loader';
import { Link } from 'react-router-dom';

const styles = theme => {
  return {
    loadingContainer: {
      backgroundImage: 'url("/images/searching.gif")',
      flexGrow: 1,
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPositionX: 'center',
    },
    healthChecksContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
    },
    healthCheckTile: {
      color: theme.palette.common.black,
      transition: 'background-color 1000ms linear',
      '&:hover, &:active, &:visited, &:focus': {
        color: theme.palette.common.black,
        textDecoration: 'none',
        cursor: 'pointer',
      },
      '&:hover': {
        backgroundColor: '#efefef',
        //    '-webkit-transition: background-color 1000ms linear;
        //-ms-transition: background-color 1000ms linear;
        transition: 'background-color 1000ms linear',
      },
      width: 'calc( (100% / 2) - 20px )',
      height: '80px',
      display: 'flex',
      padding: '10px',
      background: '#fefefe',
      margin: '2px',
    },
    healthCheckBox: {
      borderRadius: '2px',
      height: '60px',
      width: '60px',
      marginRight: '5px',
      paddingBottom: '2px',
    },
    backgroundFailure: {
      background: '#cf4b1a',
    },
    backgroundSuccess: {
      background: '#359657',
    },
    description: {
      fontSize: '12px',
    },
    healthCheckIcon: {
      height: '60px',
      width: '60px',
      fill: 'white',
    },
  };
};

export class HealthChecks extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      health: undefined,
    };
  }

  configurationAreas = {
    'Company': '/admin/company',
    'MenuItems': '/admin/items',
    'MenuBuilder': '/admin/menu',
  }

  componentDidMount() {
    this.performHealthCheck();
  }

  performHealthCheck = () => {
    const that = this;

    that.setState({ health: undefined, loading: true });

    this.props.performHealthCheck().then(r => {
      setTimeout(() => {
        that.setState({ health: r, loading: false });
      }, 2000);
    });
  }

  render() {
    const {
      onModalClosed,
      classes,
    } = this.props;
    const {
      loading,
      health,
    } = this.state;

    return (

      <Modal
        isOpened={true}
        onCancel={onModalClosed}
        onSave={this.performHealthCheck}
        isSaveDisabled={loading}
        title={loading ? "Analyzing Configuration..." : "Health Check Summary"}
        addTitleBottomBorder={true}
        dimensions={{ width: '85%', height: '85%', maxWidth: 1000, maxHeight: 476 }}
        saveText="Refresh"
        cancelText="Close"
      >
        {loading && <div className={classes.healthChecksContainer}>
          {[...Array(8)].map((x, y) =>
            <div className={classes.healthCheckTile}
              key={y}
            >
              <ContentLoader
                speed={2}
                width={400}
                height={100}
                viewBox="0 0 400 100"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="80" y="0" rx="2" ry="2" width="250" height="20" />
                <rect x="8" y="0" rx="2" ry="2" width="60" height="60" />
                <rect x="80" y="30" rx="2" ry="2" width="100" height="10" />
                <rect x="190" y="30" rx="2" ry="2" width="126" height="10" />
                <rect x="80" y="45" rx="2" ry="2" width="126" height="10" />
                <rect x="214" y="45" rx="2" ry="2" width="100" height="10" />
              </ContentLoader>
            </div>
          )}
        </div>}

        {health && <div className={classes.healthChecksContainer}>
          {health.results.map(check =>
            <Link to={this.configurationAreas[check.configurationArea]} onClick={this.props.onModalClosed} className={classes.healthCheckTile}
              key={check.healthCheckName}
            >
              <div className={classNames(classes.healthCheckBox, check.healthy ? classes.backgroundSuccess : classes.backgroundFailure)}>{check.healthy ? <Done className={classes.healthCheckIcon} /> : <Clear className={classes.healthCheckIcon} />}</div>

              <div className={classes.checkInfo}>
                <div className={classes.name}>{check.healthCheckName}</div>
                <div className={classes.description}>{check.description}</div>
              </div>
            </Link>
          )}
        </div>}
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      categories,
    },
    admin: {
      itemTags,
    },
  } = state;

  return {
    categories,
    tags: itemTags,
  };
};

const mapDispatchToProps = {
  performHealthCheck,
};

const HealthCheckModal = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HealthChecks));

export default HealthCheckModal;
