import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Select from 'Components/Select';
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartPlotArea,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import {

  MenuItem,
} from '@material-ui/core';
import { getWidgetColor } from 'shared/themes';

const styles = theme => ({
  denominator: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: '18px',
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '162px',
    margin: '0 -18px', // Net 12 padding
  },
  graphLabelsContainer: {
    marginTop: '24%',
    textAlign: 'end',
    whiteSpace: 'normal',
    width: '29.32%',
  },
  horizontalAdjustment: {
    backgroundColor: theme.palette.grey[50],
    margin: '11px -30px',
  },
  totalDescription: {
    color: theme.palette.grey[200],
    fontSize: '12px',
  },
  fieldSelector: {
    flexGrow: 0,
  },
  container: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '25px 30px',
    width: '100%',
  },
  headerRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  title: {
    fontSize: 16,
    whiteSpace: 'pre',
    color: '#656565',
    marginTop: -10,
    marginLeft: -15,
  },
});

class GenericDoughnut extends React.Component {
  constructor(props) {
    super(props);

    this.chartContainerRef = React.createRef();
    this.chartRef = React.createRef();

    this.state = {
      adjustChartMargin: 0,
      dimensionInPx: 0,
      selectedFieldIndex: 1,
    };
  }

  render() {
    const {
      classes,
      fields,
      title,
      data,
      minWidth = '250px',
      minHeight = '175px',
    } = this.props;

    if (!data) {
      return (<></>);
    }

    const { selectedFieldIndex } = this.state;
    const { adjustChartMargin, dimensionInPx } = this.state;

    const field = this.props.fields[selectedFieldIndex - 1];

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        if (field.stringList) {
          const value = x[key];

          if (value) {
            value.split(',').forEach(item => {
              (rv[item.trim()] = rv[item.trim()] || []).push(x);
            });
          }
          else {
            (rv.Undefined = rv.Undefined || []).push(x);
          }

        }
        else if (field.list) {
          const value = x[key];

          value.forEach(item => {
            (rv[item] = rv[item] || []).push(x);
          });
        }
        else {

          (rv[x[key]] = rv[x[key]] || []).push(x);
        }

        return rv;
      }, {});
    };

    const groupedData = groupBy(data, field.name);

    const chartData = groupedData && Object.keys(groupedData).length > 0 ? Object.entries(groupedData).map((entry, index) => ({ key: entry[0], value: entry[1].length, index })) : [];

    const nestedTooltipRender = ({ point, dataItem }) => {
      const key = dataItem ? dataItem.key : point.dataItem.key;
      const value = dataItem ? dataItem.value : point.dataItem.value;

      let label = key;

      if (field.valueLabels) {
        label = field.valueLabels[key];
      }

      return `${!label || label === 'null' ? 'Undefined' : label} : ${value}`;
    };

    const getColor = ({ dataItem }) => {
      if (dataItem) {
        return getWidgetColor(dataItem.index);
      }
    };

    return (
      <div style={{ minWidth, minHeight }} className={classes.container}>
        <h4 className={classes.title}>{title}</h4>
        <div className={classes.headerRow}>
          <Select
            value={selectedFieldIndex}
            className={classes.fieldSelector}
            onFieldChange={index => { this.setState({ selectedFieldIndex: index }); }}
          >
            {fields && fields.map((fieldOption, index) =>
              <MenuItem key={index} value={index + 1}>{fieldOption.displayName}</MenuItem>
            )}
          </Select>
        </div>
        <div ref={this.chartContainerRef} className={classes.graphContainer}>

          <Chart ref={this.chartRef} style={{ height: dimensionInPx + 30 }}>
            <ChartPlotArea margin={0} />
            <ChartSeries>
              <ChartSeriesItem type="donut" data={chartData} categoryField="key" field="value" color={getColor} startAngle={0} />
            </ChartSeries>
            <ChartArea margin={adjustChartMargin} />
            <ChartLegend visible={false} />
            <ChartTooltip render={nestedTooltipRender} />
          </Chart>
        </div></div>
    );
  }

  componentDidMount() {
    this.updateDimensionsIfNeeded();
  }

  componentDidUpdate() {
    this.updateDimensionsIfNeeded();
  }

  updateDimensionsIfNeeded() {
    const { dimensionInPx: prevDimensionInPx, adjustChartMargin: prevAdjustChartMargin } = this.state;

    const container = this.chartContainerRef.current;
    const chart = this.chartRef.current;
    const kendoMarginAdjustment = -.21;
    const adjustChartMargin = chart && chart.element ? chart.element.clientWidth * kendoMarginAdjustment : 0;
    let dimensionInPx = 0;

    if (container) {
      if (container.clientWidth === 0) {
        dimensionInPx = container.clientHeight;
      } else if (container.clientHeight === 0) {
        dimensionInPx = container.clientWidth;
      } else {
        dimensionInPx = container.clientWidth > container.clientHeight ? container.clientHeight : container.clientWidth;
      }
    }

    if (prevDimensionInPx !== dimensionInPx || prevAdjustChartMargin !== adjustChartMargin) {
      this.setState({ dimensionInPx, adjustChartMargin });
    }
  }
}

export default withStyles(styles, { withTheme: true })(GenericDoughnut);
